angular.module('crmApp').directive('showHtml5', function($timeout) {
	return {
		restrict: 'A',
		require: '^form',
		link: function (scope, el, attrs, formCtrl) {
        var blurred = false;
        el.bind('blur', function() {
            blurred = true;
            el.toggleClass('has-error', formCtrl.reportedContent.$invalid);

        });
        scope.$watch(function() {
            return formCtrl.reportedContent.$invalid
        }, function(invalid) {
          if (!blurred && invalid) { return }
            el.toggleClass('has-error', invalid);
            
    });
        scope.$on('show-errors-check-validity-html5', function() {
            el.toggleClass('has-error', formCtrl.reportedContent.$invalid);
        });
        
        scope.$on('show-errors-reset-html5', function() {
            $timeout(function() {
                el.removeClass('has-error');
            }, 0, false);
        });
    }
}
});