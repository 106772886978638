(function() {
	'use strict';

	angular.module('crmApp').controller('OrderWareHousingController',
			OrderWareHousingController);

	OrderWareHousingController.$inject = [ '$uibModal', '$scope', '$http',
			'$rootScope', '$stateParams', 'entity', 'Order', 'User',
			'OrderDetail', '$state', '$window', 'Principal' ];

	function OrderWareHousingController($uibModal, $scope, $http, $rootScope,
			$stateParams, entity, Order, User, OrderDetail, $state, $window,
			Principal) {
		var vm = this;
		vm.order = entity;
		vm.load = load;
		vm.hasAnyAuthority = Principal.hasAnyAuthority;
		vm.load();

		function load() {

		}
		;

		$scope.date = new Date();

		var unsubscribe = $rootScope.$on('crmApp:orderUpdate', function(event,
				result) {
			vm.order = result;
		});
		$scope.$on('$destroy', unsubscribe);

		vm.redirectEmail = function() {

		}
	}
})();
