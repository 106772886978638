(function() {
	'use strict';

	angular.module('crmApp').controller('StockWatchGoodReceiptController',
			StockWatchGoodReceiptController);

	StockWatchGoodReceiptController.$inject = ['$state','$http','entity'];

	function StockWatchGoodReceiptController($state, $http, entity) {

		var vm = this;
		vm.stock = entity;
		vm.loadPage = loadPage;
		loadPage();

		function loadPage(){
			// get information company
            $http({
                url: '/api/products-warehouse/'+vm.stock.id,
                method: 'GET'
            }).then(function(response) {
                vm.StockWatch = response.data;
            });
            $http({
                url: '/api/general-setting',
                method: 'GET'
            }).then(function(response) {
                vm.generalSetting = response.data;
            });
		}
	}
})();
