(function() {
  'use strict';

  angular
  .module('crmApp')
  .directive('backToTop', function () {
    return{
     restrict: 'E',
     replace: true,
     template: '<a id="back-to-top"><i class="fa fa-chevron-up"></i></a>',
     link : function( scope, element, attrs ){
      $(window).scroll(function(){
        if ($(this).scrollTop() > 150){
          $("#back-to-top").fadeIn(200);
        }else{
         $('#back-to-top').fadeOut(200);
       }
     });
      $('#back-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
    scrollTop : 0                       // Scroll to top of body
  }, 500);
      });
    }
  }
});
})();

