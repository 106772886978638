(function() {
	'use strict';

	angular.module('crmApp').controller('OrderDetailEditDeliveryController',
			OrderDetailEditDeliveryController);

	OrderDetailEditDeliveryController.$inject = [ '$http', '$rootScope', 'Order', 'entity', '$scope',
			'DateFormatService', 'SettingUtils', '$uibModalInstance' ];

	function OrderDetailEditDeliveryController($http, $rootScope, Order, entity, $scope,
			DateFormatService, SettingUtils, $uibModalInstance) {
		var vm = this;
		var temp = null;
		vm.getDistricts = getDistricts;
        vm.getWards = getWards;
        vm.provinces = [];
        vm.districts = [];
        vm.wards = [];
		if (entity.deliveryDate) {
			temp = new Date(entity.deliveryDate);
		}

		vm.order = entity;
		vm.loadAll = loadAll;
		var DELIVER_TYPE = 'DELIVER_TYPE';
		var DELIVER_METHOD = 'DELIVER_METHOD';
		var SHIP_FEE_TYPE = 'SHIP_FEE_TYPE';
		vm.loadAll();
		function getDataFromSetting() {
			SettingUtils.get({
				'keys' : [ DELIVER_TYPE, DELIVER_METHOD, SHIP_FEE_TYPE]
			}, function(success) {
				vm.deliverTypies = [];
				vm.deliverMethods = [];
				vm.shipFeeTypes = [];
				angular.forEach(success, function(value, key) {
					if (value.key === DELIVER_TYPE) {
						vm.deliverTypies.push(value);
					} else if (value.key === DELIVER_METHOD) {
						vm.deliverMethods.push(value);
					}else if (value.key === SHIP_FEE_TYPE) {
						vm.shipFeeTypes.push(value);
					}
				});
			}, function(error) {
			});
		}
		// button waiting cofirm
		function getListProvince(){
            $http.get('/api/list-provinces', {}).then(function (response) {
                vm.provinces = response.data;
            })
        }
        function getDistricts() {
            if(vm.order.deliveryProvinceId){
                $http.get('/api/list-districts', {params: { provinceId : vm.order.deliveryProvinceId}}).then(function (response) {
                    vm.districts = response.data;
                })
            }
        }

        function getWards() {
            if(vm.order.deliveryDistrictId) {
                $http.get('/api/list-wards', {params: { districtId : vm.order.deliveryDistrictId}}).then(function (response) {
                    vm.wards = response.data;
                })
            }
        }
		// change money delivery
		vm.chooseMoneyStatus = function(free){
            if(free != 1){
                vm.order.deliveryCost = 0;
            }
        }
		function loadAll() {
			configDateTime();
			getListProvince();
			getDataFromSetting();
			if (vm.order.deliveryProvinceId){
                vm.getDistricts();
            }
            if (vm.order.deliveryDistrictId){
                vm.getWards();
            }
		}
		function configDateTime() {
			if (vm.order.deliveryDate) {
				vm.order.deliveryDate = new Date(vm.order.deliveryDate);
			}
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = {
				startingDay : 1,
				dateDisabled : disabled
			};
			function disabled(data) {
				var nowDate = new Date();
				nowDate = nowDate.setDate(nowDate.getDate() - 1);
				var date = data.date, mode = data.mode;
				return date < nowDate;
			}
			vm.openDateFrom = function() {
				vm.isDateFromOpened = true;
			}

			vm.datePickerOpenStatus = function() {
				vm.isOrderDateOpened = true;
			}

			vm.saveDelivery = function() {
				$scope.$broadcast('show-errors-check-validity');
				if ($scope.editDelivery.$invalid) {
					var invalidControl = angular.element('.form-group.ng-invalid')
							.first();
					if (invalidControl != null) {
						invalidControl.focus();
					}
				} else {
					Order.update(vm.order, onUpdateSuccess, onUpdateError);
					$scope.$broadcast('show-errors-reset');
				}
			}

			var onUpdateSuccess = function(success) {
				$scope.$emit('crmApp:orderUpdate', success);
				$uibModalInstance.close(null);
			}

			var onUpdateError = function() {
			}

			vm.close = function() {
				$uibModalInstance.close(temp);
			}
			 $rootScope.$on('close-modal', function(){
	            $uibModalInstance.close();
	        });
		}
	}
})();
