(function() {
    'use strict';

    angular.module('crmApp').controller('StockWatchController',
        StockWatchController);

    StockWatchController.$inject = ['$uibModal', '$state', 'entity', '$http', 'pagingParams', 'ProductWarehouse', 'AlertService', 'ParseLinks'];

    function StockWatchController($uibModal, $state, entity, $http, pagingParams, ProductWarehouse, AlertService, ParseLinks) {

        var vm = this;
       vm.Warehouses = entity;
        if(vm.Warehouses){
            vm.Warehouses = entity;
            vm.warehouseId = vm.Warehouses.id;
        }
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "sản phẩm";
        vm.showItem = 10;
        vm.totalItems = null;
        vm.loadPage = loadPage;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.currentSearch = {};
        vm.stocksSelect = [];
        vm.orderQuantity = [];
        vm.ProductWarehouseDetail = [];

        function loadAll() {
            $http.get('/api/get-all-stocks')
                .then(function(success) {
                    vm.stocksSelect = success.data;
                    // angular.element('#warehouseId').selectpicker('refresh');
                }).$promise;

            vm.loadPage(false);
        }


          vm.loadAll();
        // load Page
        function loadPage(isSearchWithConditionBefore) {
            vm.ProductWarehouseDetail = [/*{
                id: 0,
                quantity: 0,
                nameCode: null,
                name: null,
                hasOrdered: 0,
                approved: 0,
                sold: 0
            }*/];
            vm.orderQuantity = [/*{
                productId: null,
                status: null,
                quantity: 0
            }*/];
               if (!isSearchWithConditionBefore) {
                    if(vm.Warehouses){
                         getOrderQuantityByWarehouseIdHttp(vm.Warehouses.id);
                    }

                } else if (vm.warehouseId) {
                    getOrderQuantityByWarehouseIdHttp(vm.warehouseId);
                }
                if (!vm.productWarehouseCO) {
                    vm.productWarehouseCO = {};
                }

                if (!isSearchWithConditionBefore) {
                    if(vm.Warehouses){
                        vm.productWarehouseCO.warehouseId = vm.Warehouses.id;
                        getQuery();
                    }
                }else{
                    if (vm.warehouseId) {
                        vm.productWarehouseCO.warehouseId = vm.warehouseId;
                        getQuery();
                    }else {
                        if(vm.Warehouses){
                            vm.productWarehouseCO.warehouseId = vm.Warehouses.id;
                            vm.productWarehouseCO.productCode = null;
                            getQuery();
                        }
                    }
                }

        };
        //end load page

        //
        function getQuery(){

            vm.productWarehouseCO.page = vm.page - 1;
            vm.productWarehouseCO.size = vm.entries;
            vm.productWarehouseCO.sort = sort();
            vm.showItem = vm.entries;
            ProductWarehouse.query(vm.productWarehouseCO, onSuccess, onError);
        }
         function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
            if (vm.predicate !== 'id') {
                result.push('id,desc');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.productWarehouses = data;
            vm.productWarehousesClone = angular.copy(vm.productWarehouses);
            angular.forEach(vm.productWarehouses, function(value, key) {
                vm.ProductWarehouseDetail.push({ id: value.product.id, productCode: value.product.productCode, name: value.product.productName, quantity: value.quantity, hasOrdered: 0, approved: 0, sold: 0 });
            });
            getProductWareHouse();
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function getProductWareHouse() {
            angular.forEach(vm.ProductWarehouseDetail, function(value, key) {
                angular.forEach(vm.orderQuantity, function(value1, key1) {
                    if ((value1.productId === value.id && value1.status === 1) || (value1.productId === value.id && value1.status === 2) ) {
                        vm.ProductWarehouseDetail[key].hasOrdered += value1.quantity;
                        vm.ProductWarehouseDetail[key].approved += value1.quantity;
                    }
                    if ((value1.productId === value.id && value1.status === 3) || (value1.productId === value.id && value1.status === 4) || (value1.productId === value.id && value1.status === 5) ) {
                        vm.ProductWarehouseDetail[key].approved += value1.quantity;
                    }
                    if (value1.productId === value.id && value1.status === 6) {
                        vm.ProductWarehouseDetail[key].sold += value1.quantity;
                    }
                });
            });

        };

        function getOrderQuantityByWarehouseIdHttp(StockId) {
            $http.get('/api/get-order-quantity-by-warehouse-id/' + StockId).success(function(success) {
                angular.forEach(success, function(value, key) {
                    vm.orderQuantity.push({ productId: value[0], status: value[1], quantity: value[2] });
                });
            });
        };



        function search() {
            if (!vm.productWarehouseCO) {
                vm.productWarehouseCO = {};
            }
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.loadPage(true);
        };

         // change state Stock
        vm.changeStock = function(warehouseId) {
            if (warehouseId) {
                $state.go('stock.watch', {id: warehouseId}, {
                    reload: false
                });
            }
        }
        // pagination
        function transition() {
            if (vm.currentSearch) {
                vm.productWarehouseCO = angular.copy(vm.currentSearch);
            }
            vm.loadPage(true);
            vm.selectedAll = false;
            vm.showItem = vm.entries;
        }

        // add product
        vm.changeProduct = function () {
            vm.modalInstanceProduct = $uibModal.open({
                url: '/?page&sort&search',
                templateUrl: 'app/stock/stock-transfer-product.html',
                size: 'lg',
                 backdrop: 'static',
                controller: 'StockTransferProductController',
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    entity: function() {
                        return vm.ProductWarehouseDetail;
                    },
                    WarehousesId: function () {
                        // body...
                        return vm.Warehouses.id;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            });

            vm.modalInstanceProduct.result.then(function () {
            }, function () {
                $state.go('stock.watch', {id: vm.Warehouses.id}, {
                    reload: true
                });
            });
        }

    }
})();
