(function() {
	'use strict';

	angular.module('crmApp').controller('OrderEmailController',
			OrderEmailController);

	OrderEmailController.$inject = [ 'UserUtils', 'AlertService',
			'CommonConstants', '$scope', 'Principal',
			'EmailSendWithAttachment', '$state', 'EmailTemplate',
			'EmailTemplateProcess', 'EmailSend', 'entity', '$http', '$window',
			'$localStorage' ];

	function OrderEmailController(UserUtils, AlertService, CommonConstants,
			$scope, Principal, EmailSendWithAttachment, $state, EmailTemplate,
			EmailTemplateProcess, EmailSend, entity, $http, $window,
			$localStorage) {
		var vm = this;
		vm.order = entity;
		vm.loadEmailTemplate = loadEmailTemplate;
		vm.sendEmail = sendEmail;

		vm.isCurrentStockRole = function() {
			return getCurrentRoleName() == CommonConstants.Stock;
		};
		function getCurrentRoleName() {
			var roleName = null;
			angular.forEach($localStorage.account.roles, function(role, key) {
				if (role.id == $localStorage.currentRoleId) {
					roleName = role.name;
					return false;
				}
			});
			return roleName;
		}

		loadAllTemplates();
		loadEmailTemplate();

		function loadEmailTemplate() {

			if (vm.selectedEmailTemplate && vm.selectedEmailTemplate.id) {
				if (!vm.order) {
					vm.orderId = null;
					vm.customerId = null;
				} else {
					vm.orderId = vm.order.id;
					vm.customerId = vm.order.customer.id;
				}
				EmailTemplateProcess.get({
					id : vm.selectedEmailTemplate.id,
					orderId : vm.orderId,
					customerId : vm.customerId
				}, onProcessSucess);
			} else if (vm.order != null) {
				if (vm.isCurrentStockRole() == false)
					vm.attachFile = vm.order.orderReport;
				else
					vm.attachFile = vm.order.stockOrderReport;

				vm.emailTemplate = {
					receiver : vm.order.customer.email,
					title : "",
					content : "",
					attachFile : vm.attachFile
				}
			} else {
				vm.emailTemplate = {
					receiver : "",
					title : "",
					content : "",
					attachFile : $state.params.filePath
				}
			}
		}

		function loadAllTemplates() {
			EmailTemplate.query({}, onLoadAllTemplatesSuccess);
		}

		function onLoadAllTemplatesSuccess(result) {
			vm.emailTemplates = result;
		}

		function onProcessSucess(result) {
			vm.emailTemplate = result;
			//vm.emailTemplate.attachFile = $state.params.filePath;
			vm.emailTemplate.attachFile = vm.order.orderReport;
		}
		$scope.uploadFile = function(files) {
			vm.attachmentFile = files;
		};

		function sendEmail() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.editForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first();
				if (invalidControl.length != 0) {
					invalidControl.focus();
				}
			} else {
					if (vm.order) {
						EmailSend.send({
							orderId : vm.order.id,
							customerId : vm.order.customer.id,
							file : vm.emailTemplate.attachFile
						}, vm.emailTemplate, onSendSuccess);
					} else {
						EmailSendWithAttachment.sendWithAttachment(
								'/api/email/send-file',
								vm.emailTemplate.receiver,
								vm.emailTemplate.title,
								vm.emailTemplate.content, vm.attachmentFile,
								onSendSuccess);
					}
			}
		}

		function onSendSuccess() {
			window.history.back();
		}
	}
})();
