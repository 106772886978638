(function() {
	"use strict";
	angular.module("crmApp")
	.constant("NotificationMessage", NotificationMessage);

  var NotificationMessage = {
    appName: 'Crm App',
    message: 'Hello World',
  };
})();
