(function () {
	'use strict';

	angular
	.module('crmApp')
	.controller('CustomerCantDeleteController', CustomerCantDeleteController);

	CustomerCantDeleteController.$inject = ['$scope', '$uibModalInstance'];
	function CustomerCantDeleteController($scope, $uibModalInstance) {
		var vm = this;
		
		vm.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };
    }
})();