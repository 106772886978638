(function() {
	'use strict';

	angular.module('crmApp').controller('EmailTemplateDetailController',
			EmailTemplateDetailController);

	EmailTemplateDetailController.$inject = [ '$scope', '$state', '$rootScope',
			'$stateParams', 'entity', 'action', 'EmailTemplate',
			'EmailTemplateVariable' ];

	function EmailTemplateDetailController($scope, $state, $rootScope,
			$stateParams, entity, action, EmailTemplate, EmailTemplateVariable) {
		var vm = this;
		var INSERT_OBJECT = {
			RECEIVER : 'receiver',
			TITLE : 'title',
			CONTENT : 'content'
		};
		var VARIABLE_GROUP = {
			CUSTOMER : 'CUSTOMER',
			STAFF : 'STAFF',
			ORDER : 'ORDER'
		};

		vm.insertObject = INSERT_OBJECT.RECEIVER;
		vm.emailTemplate = entity;

		vm.setFocusOnObject = function(object) {
			vm.insertObject = object;
		}

		vm.insertVariable = function() {
			var variable = getVariableString();
			if (vm.insertObject == INSERT_OBJECT.RECEIVER) {
				var input = $('#receiver');
				insertToTextBox(input, variable);
				vm.emailTemplate.receiver = input.val();
			} else if (vm.insertObject == INSERT_OBJECT.TITLE) {
				var input = $('#title');
				insertToTextBox(input, variable);
				vm.emailTemplate.title = input.val();
			} else {
				var editor = $scope.editors['emailContent'];
				insertToRichTextBox(editor, variable);
				vm.emailTemplate.content = editor.getValue();
			}

		}

		// Set initial variable group
		vm.selectedVariableGroup = VARIABLE_GROUP.CUSTOMER;
		vm.loadVariables = loadVariables;
		vm.loadVariables();

		function loadVariables() {
			EmailTemplateVariable.get({
				group : vm.selectedVariableGroup
			}, onLoadVariableSuccess);
		}

		function onLoadVariableSuccess(result) {
			vm.variables = result;

			// Set initial variable
			if (result && result.length > 0) {
				vm.selectedVariable = result[0];
			}
		}

		vm.load = function(id) {
			EmailTemplate.get({
				id : id
			}, function(result) {
				vm.emailTemplate = result;
			});
		};

		var onSaveSuccess = function(result) {
			$scope.$emit('crmApp:emailTemplateUpdate', result);
			$state.go('email-template', null, {
				reload : true
			});
			vm.isSaving = false;
		};

		var onSaveError = function() {
			vm.isSaving = false;
		};

		vm.save = function() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.editForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first()
						|| angular.element('select.ng-invalid').first()
						|| angular.element('option.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else {
				vm.isSaving = true;
				if (vm.emailTemplate.id !== null) {
					EmailTemplate.update(vm.emailTemplate, onSaveSuccess,
							onSaveError);
				} else {
					EmailTemplate.save(vm.emailTemplate, onSaveSuccess,
							onSaveError);
				}
			}

		};

		var unsubscribe = $rootScope.$on('crmApp:emailTemplateUpdate',
				function(event, result) {
					vm.emailTemplate = result;
				});

		function insertToTextBox(input, variable) {
			input.insertAtCaret(variable);
		}

		function insertToRichTextBox(editor, variable) {
			var iframe = editor.composer.iframe;
			var content = iframe.contentWindow || iframe.contentDocument;
			var document = content.document || content;
			editor.focus();
			document.execCommand("insertHTML", false, variable);
		}

		function getVariableString() {
			return vm.selectedVariable.key;
		}

		window.setTimeout(function() {
			// Use setTimeout trick to run this code after directive code
			var editor = $scope.$root.editors['emailContent'];
			editor.on('focus', function() {
				vm.setFocusOnObject(INSERT_OBJECT.CONTENT);
			});
		}, 0);

		$scope.$on('$destroy', unsubscribe);
	}
})();
