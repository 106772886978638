(function() {
    'use strict';

    angular.module('crmApp').controller('CustomerDialogController',
        CustomerDialogController);

    CustomerDialogController.$inject = ['CheckPhone', 'UserUtils',
        'CommonConstants', 'SettingUtils', 'DateFormatService',
        'CustomerCare', 'Principal', 'entity', 'Customer', 'CustomerPhone', '$scope',
        '$state', '$http', 'AlertService'
    ];

    function CustomerDialogController(CheckPhone, UserUtils, CommonConstants,
        SettingUtils, DateFormatService, CustomerCare, Principal, entity,
        Customer, CustomerPhone, $scope, $state, $http, AlertService) {
        var vm = this;

        vm.account = null;
        vm.accountId = null;
        vm.provinces = [];
        vm.clear = clear;

        vm.getAccount = getAccount;
        vm.hasAuthority = hasAuthority;
        vm.getAccount();
        //get account id by login
        function getAccount() {
            vm.account = UserUtils.getAccount();
            vm.accountId = vm.account.id;
        }

        vm.isSave = false;
        vm.customer = entity;
        var POTENTIAL_CUSTOMER_INTERACT_STATE = 'POTENTIAL_CUSTOMER_INTERACT_STATE';
        var CUSTOMER_SERVICE_TYPE = 'CUSTOMER_SERVICE_TYPE';
        var CUSTOMER_SOURCE = 'CUSTOMER_SOURCE';
        var CUSTOMER_GENDER = 'CUSTOMER_GENDER';
        var CUSTOMER_BUSINESS = 'CUSTOMER_BUSINESS';
        var CUSTOMER_TYPE = 'CUSTOMER_TYPE';
        var INTERACT_ROLE = 'INTERACT_ROLE';
        var INTERAC_ROLE_VAL = 2;

        vm.loadAll = loadAll;
        vm.servicies = [];
        vm.sourcies = [];
        vm.genders = [];
        vm.businies = [];
        vm.customerTypes = [];
        vm.interactRoles = [];
        vm.interactionStates = [];
        vm.customerInteractions = [];
        vm.customerInteractionsOrigin = [];

        if(vm.customer && vm.customer.phone && vm.customer.id){
          vm.customerPhoneOrigin = vm.customer.phone;
          $http.get('/api/customer-interaction?customerId=' + vm.customer.id).then(function success(response){
            console.log(response);
            if(response && response.data.length > 0){
              console.log(response.data.length);
              vm.customerInteractions = angular.copy(response.data);
              vm.customerInteractionsOrigin = response.data;
              console.log(response.data);
              console.log(vm.customerInteractions);
            }

            SettingUtils.get({
                'keys': [INTERACT_ROLE]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key == INTERACT_ROLE) {
                        vm.interactRoles.push(value);
                    }
                });

                vm.customerInteractions.push({
                  interactState: null,
                  interactStory: null,
                  interactRole: INTERAC_ROLE_VAL, //1: MKT, 2: Sales
                  interactRoleName: getInteractRoleNameCrm(INTERAC_ROLE_VAL)
                });
            }, function(error) {

            });
          });
        } else {
          SettingUtils.get({
              'keys': [INTERACT_ROLE]
          }, function(success) {
              angular.forEach(success, function(value, key) {
                  if (value.key == INTERACT_ROLE) {
                      vm.interactRoles.push(value);
                  }
              });

              vm.customerInteractions.push({
                interactState: null,
                interactStory: null,
                interactRole: INTERAC_ROLE_VAL, //1: MKT, 2: Sales
                interactRoleName: getInteractRoleNameCrm(INTERAC_ROLE_VAL)
              });
          }, function(error) {

          });
        }

        vm.convertData = convertData;

        vm.cantEditCustomerPhone = function(){
          var result = true;
          if(vm.customer.id){
            if(UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_EDIT_PHONE_NUMBER)){
              result = false;
            }
          } else {
            result = false;
          }

          return result;
        };

        $scope.$on('place_changed', function(e, place) {
            vm.customer.address1Long = place.geometry.location.lat();
            vm.customer.address1Lat = place.geometry.location.lng();
        });

        vm.convertData();
        // getListProvince
         function getListProvince(){
            $http.get('/api/list-provinces').then(function (response) {
                vm.provinces = response.data;
            })
        }
        function convertStrToNumberArray(idArrStr) {
            var idNumArr = [];
            if (idArrStr !== null) {
                var idArr = idArrStr.split(',');

                angular.forEach(idArr, function(value, key) {
                    idNumArr.push(Number(value));
                });
            }
            return idNumArr;
        }

        function convertData() {
            if (vm.customer.sourceId !== null) {
                vm.customer.sourceIdUI = convertStrToNumberArray(vm.customer.sourceId);
            }
            if (vm.customer.serviceTypeId !== null) {
                vm.customer.serviceTypeIdUI = convertStrToNumberArray(vm.customer.serviceTypeId);
            }
            if (vm.customer.birthday !== null) {
                vm.customer.birthday = new Date(vm.customer.birthday);
            }
            if (vm.customer.companyFoundDate !== null) {
                vm.customer.companyFoundDate = new Date(
                    vm.customer.companyFoundDate);
            }
            if (vm.customer.businessId !== null) {
                vm.customer.businessIdUI = convertStrToNumberArray(vm.customer.businessId);
            }
            if (vm.customer.companyBusinessId !== null) {
                vm.customer.companyBusinessIdUI = convertStrToNumberArray(vm.customer.companyBusinessId);
            }
            if (vm.customer.companySourceId !== null) {
                vm.customer.companySourceIdUI = convertStrToNumberArray(vm.customer.companySourceId);
            }
        }

        vm.loadAll();

        vm.statusPhone = false;

        vm.checkNumberPhone = function() {
            if (vm.customer && vm.customer.phone &&
              vm.customer.phone.length >= 10 && vm.customer.phone.length <= 11 &&
              vm.customerPhoneOrigin != vm.customer.phone) {
                CustomerPhone.get({
                    phoneNum: vm.customer.phone
                }).$promise.then(function(customer) {
                    if(customer && customer.id){
											vm.statusPhone = true;
										}
                }, function(){});
            } else {
                vm.statusPhone = false;
            }
        }

				vm.changeNumberPhone = function(){
					vm.statusPhone = false;
				};

        /*vm.check = function() {
        	for (var i = 0; i < vm.phoneNumber.length; i++) {
        		if (vm.customer.phone === vm.phoneNumber[i]) {
        			return true;
        		}
        	}
        	return false;
        }*/

        /*function getAllCustomer() {
        	// check phone number
        	vm.phoneNumber = CheckPhone.checkPhoneAll();
        };*/

        function loadAll() {
            // getAllCustomer();

            SettingUtils.get({
                'keys': [CUSTOMER_SERVICE_TYPE, CUSTOMER_SOURCE,
                    CUSTOMER_GENDER, CUSTOMER_BUSINESS, CUSTOMER_TYPE,POTENTIAL_CUSTOMER_INTERACT_STATE
                ]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === CUSTOMER_SERVICE_TYPE) {
                        vm.servicies.push(value);
                    } else if (value.key === CUSTOMER_SOURCE) {
                        vm.sourcies.push(value);
                    } else if (value.key === CUSTOMER_GENDER) {
                        vm.genders.push(value);
                    } else if (value.key == CUSTOMER_BUSINESS) {
                        vm.businies.push(value);
                    } else if (value.key == CUSTOMER_TYPE) {
                        vm.customerTypes.push(value);
                    } else if(value.key == POTENTIAL_CUSTOMER_INTERACT_STATE){
                        vm.interactionStates.push(value);
                    }
                });
            }, function(error) {

            });

            //
            getListProvince();
        }

        vm.altInputFormats = DateFormatService.getAltInputFormats();
        vm.dateFormat = DateFormatService.getDateFormat();
        vm.dateOptions = {
            startingDay: 1,
             maxDate: new Date()
        };
        vm.dateOptions1 = {
            startingDay: 1
        };

        // function disabled(data) {
        //     var nowDate = new Date();
        //     var date = data.date,
        //         mode = data.mode;
        //     return date > nowDate;
        // }
        vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

        vm.datePickerOpenStatus = function() {
            vm.isCustomerBirthdayOpened = true;
        }

        vm.redirect = function() {
            if (vm.hasAuthority()) {
                vm.redirectPage = true;
                vm.save();
            }
        }

        vm.redirectOrder = function() {
            if (vm.hasAuthority()) {
                vm.redirectOrderPage = true;
                vm.save();
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('crmApp:customerUpdate', result);

            //save customer interaction
            vm.customerInteractionsToSave = [];

            angular.forEach(vm.customerInteractions, function(custInterUI, key) {
              if(custInterUI.interactRole == INTERAC_ROLE_VAL){
                if(!custInterUI.id){
                  custInterUI.customerId = result.id;
                }
                vm.customerInteractionsToSave.push(custInterUI);
              }
            });

            console.log(vm.customerInteractionsToSave.length);
            console.log(vm.customerInteractionsOrigin);

            var hasInSaveLst = false;
            angular.forEach(vm.customerInteractionsOrigin, function(custInterOrg, key) {
              if(custInterOrg.interactRole == INTERAC_ROLE_VAL){
                  hasInSaveLst = false;
                  angular.forEach(vm.customerInteractionsToSave, function(custInterSave, key1) {
                    if(custInterSave.id && custInterSave.interactRole == INTERAC_ROLE_VAL && custInterSave.id == custInterOrg.id){
                      hasInSaveLst = true;
                    }
                  });

                  if(!hasInSaveLst){
                    custInterOrg.deleted = true;
                    vm.customerInteractionsToSave.push(custInterOrg);
                  }
              }
            });
            console.log(vm.customerInteractionsToSave.length);
            console.log(vm.customerInteractionsToSave);

            $http.post('/api/customer-interaction', vm.customerInteractionsToSave).then(function success(){

            });


            vm.isSaving = false;
            if (vm.redirectPage == true) {
              if($state.current.name == "customer.edit-with-phone-number"){
                $state.go('customer-care.detail-with-phone-number', {
                  phoneNum: result.phone
                }, {
                  reload: true
                });
              } else {
                $state.go('customer-care.detail', {
                  id: result.id
                }, {
                  reload: true
                });
              }
            } else if (vm.redirectOrderPage == true) {
                if($state.current.name == "customer.edit-with-phone-number"){
                  $state.go('order.new-with-phone-number', {
                    phoneNum: result.phone
                  }, {
                    reload: true
                  });
                } else {
                  $state.go('order.new', {
                    id: result.id
                  }, {
                    reload: true
                  });
                }
            }else if(vm.isSave){
                $state.go('customer.list', null, {
                    reload: true
                });
            }
        };

        var onSaveError = function() {
            vm.isSaving = false;
        };

        function convertNumberArrayToStr(idArr) {
            var idNumStr = null;
            if (idArr !== undefined && idArr !== null && idArr.length > 0) {
                idNumStr = '';
                angular.forEach(idArr, function(value, key) {
                    idNumStr += value;
                    if (key != (idArr.length - 1)) {
                        idNumStr += ',';
                    }
                });
            }
            return idNumStr;
        }

        var formatDataBeforceSave = function() {
            vm.isSaving = true;
            if (vm.customer.sourceIdUI !== null) {
                vm.customer.sourceId = convertNumberArrayToStr(vm.customer.sourceIdUI);
            }
            if (vm.customer.serviceTypeIdUI !== null) {
                vm.customer.serviceTypeId = convertNumberArrayToStr(vm.customer.serviceTypeIdUI);
            }
            if (vm.customer.businessIdUI !== null) {
                vm.customer.businessId = convertNumberArrayToStr(vm.customer.businessIdUI);
            }
            if (vm.customer.companySourceIdUI !== null) {
                vm.customer.companySourceId = convertNumberArrayToStr(vm.customer.companySourceIdUI);
            }
            if (vm.customer.companyBusinessIdUI !== null) {
                vm.customer.companyBusinessId = convertNumberArrayToStr(vm.customer.companyBusinessIdUI);
            }
        }
        vm.save = function() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.editForm.$invalid) {
                var invalidControl = angular.element('input.ng-invalid')
                    .first() ||
                    angular.element('select.ng-invalid').first() ||
                    angular.element('option.ng-invalid').first();
                if (invalidControl != null) {
                    invalidControl.focus();
                }
            }else if(vm.statusPhone){
               angular.element('#field_phoneNumber').first().focus();
            } else {
                if (vm.hasAuthority()) {
                    formatDataBeforceSave();
                    vm.customerInteractions = getNotNullInteractions(vm.customerInteractions);
                    console.log(vm.customerInteractions);
                    if (vm.customer.id !== undefined) {
                        if(vm.cantEditCustomerPhone() && vm.customerPhoneOrigin != vm.customer.phone){
                          AlertService.error(CommonConstants.HAS_NOT_AUTHORITY_TO_EDIT_CUSTOMER_PHONE);
                          return -1;
                        }
                        Customer.update(vm.customer, onSaveSuccess, onSaveError);
                    } else {
                        vm.isSave = true;
                        Customer.save(vm.customer, onSaveSuccess, onSaveError);
                    }
                }
                $scope.$broadcast('show-errors-reset');
            }

        };

        function hasAuthority() {
            if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ADD)) {
                return true;
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                return false;
            }
        };

        var setRecordCareMission = function(customerId) {
            vm.customerCare = {};
            vm.customerCare.customerId = customerId
            vm.customerCare.staffId = vm.accountId;
            CustomerCare.save(vm.customerCare, onRecordSuccess, onRecordError);
        }

        var onRecordSuccess = function() {}

        var onRecordError = function() {}

        function clear() {
            if ($state.current.name == "customer.edit") {
                $state.go('customer.edit', null, {
                    reload: true
                });
            } else if ($state.current.name == "customer.new") {
                $state.go('customer.new', null, {
                    reload: true
                });
            } else if($state.current.name == "customer.edit-with-phone-number") {
                $state.go('customer.edit-with-phone-number', null, {
                    reload: true
                });
            }
        }

        // vm.plusRow
        function getInteractRoleNameCrm(val){
          var name = '';
          angular.forEach(vm.interactRoles, function(value, key) {
              if(value.value == val){
                name = value.name;
                return 0;
              }
          });
          return name;
        }

       function getNotNullInteractions(interactions){
            var tamp = [];
            angular.forEach(interactions, function(interaction, key) {
                if(interaction.interactState != null || interaction.interactStory){
                    tamp.push(interaction);
                }
            })
            return tamp;
        }

        vm.plusRow = function() {
            var interaction = {
                interactState: null,
                interactStory: null,
                interactRole: INTERAC_ROLE_VAL, //1: MKT, 2: Sales
                interactRoleName: getInteractRoleNameCrm(INTERAC_ROLE_VAL)
            }
            vm.customerInteractions.push(interaction);
        }

        vm.remove = function(index){
           vm.customerInteractions.splice(index, 1);
        }
    }
})();
