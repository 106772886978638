(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('RoleDialogController', RoleDialogController);

    RoleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Role', 'Authority','$http'];

    function RoleDialogController ($scope, $stateParams, $uibModalInstance, entity, Role, Authority, $http) {
        var vm = this;
        vm.role = entity;
        vm.auth = [];
        vm.loadAll = loadAll;
        vm.savePermission = savePermission;
        vm.permissionCheckboxChangeHandler = permissionCheckboxChangeHandler;
        vm.loadAll();
//        vm.loadAll = function(id) {
//            Role.get({id : id}, function(result) {
//                vm.role = result;
//            });
//        };

        function loadAll() {
            $http.get('/api/authorities')
            .success(function(success) {
                vm.authorities = success;
                angular.forEach(vm.authorities, function(authority, key){
                    authority.selected = false;
                    angular.forEach(vm.role.authorities, function(authority1, key1){
                        if(authority.id === authority1.id ){
                            authority.selected = true;
                        }
                    });
                });
         });


        }

        function permissionCheckboxChangeHandler() {

            vm.isSaving = true;
            angular.forEach(vm.authorities, function(authority, key) {
                if (authority.selected) {
                    vm.isSaving = false;
                }
            });
        };

        function savePermission() {
            vm.role.authorities = [];
            var auth = null;
            angular.forEach(vm.authorities, function(authority, key) {
                if (authority.selected) {
                    auth = angular.copy(authority);
                    delete auth.selected;
                    vm.role.authorities.push(auth);
                }
            });
            vm.isSaving = true;
            if (vm.role.id !== null) {
                Role.update(vm.role, onSaveSuccess, onSaveError);
            } else {
                Role.save(vm.role, onSaveSuccess, onSaveError);
            }
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('crmApp:roleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.role.id !== null) {
                Role.update(vm.role, onSaveSuccess, onSaveError);
            } else {
                Role.save(vm.role, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
