/**
 * 
 * @returns
 * @author Quynh
 */
(function() {
    'use strict';

    var historyUpdate = {
        templateUrl: 'app/template/history-update.html',
        controller: historyUpdateComponentController,
        controllerAs: 'vm',
        bindToController:true,
        bindings:{
        	info: '<'
        }
    };
    
    function historyUpdateComponentController(){
    	var vm = this;   
    }
    angular
        .module('crmApp')
        .component('historyUpdate', historyUpdate);
})();
