(function() {
    'use strict';

    angular.module('crmApp').controller('OrderDetailConfirmImportObController',
        OrderDetailConfirmImportObController);

    OrderDetailConfirmImportObController.$inject = ['Order', 'OrderDetail', 'objects', 'SettingUtils', 'DateFormatService', '$rootScope', '$uibModalInstance', 'CommonConstants', '$http'];

    function OrderDetailConfirmImportObController(Order, OrderDetail, objects, SettingUtils, DateFormatService, $rootScope, $uibModalInstance, CommonConstants, $http) {

        var vm = this;
        vm.loadAll = loadAll;
        var MATERIAL_CONFIRM = 'MATERIAL_CONFIRM';
        vm.loadAll();
        vm.id = null;
        vm.objects = [];
        if(objects){
            vm.id = objects.id;
            getobject(objects.orderDetails);
        }

        function getobject(objects){
            angular.forEach(objects, function(object) {
                if(object.productItemType == 2){
                    object.receivePlanDate = new Date(object.receivePlanDate);
                    vm.objects.push(object);
                }
            })
        }
        vm.altInputFormats = DateFormatService.getAltInputFormats();
        vm.dateFormat = DateFormatService.getDateFormat();
        vm.dateOptions = {
            startingDay: 1,
            minDate: new Date()
        };

        vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
        $rootScope.$on('close-modal', function(){
                $uibModalInstance.close();
        });

        function getDataFromSetting() {
            SettingUtils.get({
                'keys': [MATERIAL_CONFIRM]
            }, function(success) {
                vm.materialConfirm = [];
                angular.forEach(success, function(value, key) {
                    if (value.key === MATERIAL_CONFIRM) {
                        vm.materialConfirm.push(value);
                    }
                });

            }, function(error) {});
        }

        function loadAll(){
            getDataFromSetting();
            
        }

       

        vm.save = function(){
            OrderDetail.update(vm.objects, function onsSuccess(data){
                vm.objects = [];
                var a = Order.get({
                    id : vm.id
                }).$promise.then(function(data){
                    getobject(data.orderDetails);
                });
                
            }, function onError(){
                console.log("not save");
            });
        }
    }
})();
