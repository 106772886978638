(function() {
	'use strict';
	angular.module('crmApp').factory('EmailConfiguration', EmailConfiguration);

	EmailConfiguration.$inject = [ '$resource' ];

	function EmailConfiguration($resource) {
		var resourceUrl = 'api/email-configuration/:verify';

		return $resource(resourceUrl, {}, {
			'get' : {
				method : 'GET'
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();
