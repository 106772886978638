(function() {
    'use strict';

    angular
        .module('crmApp')
        .directive('selectPicker', function($timeout) {
            return {
                restrict: 'A',
                replace: true,
                link: function(scope, element, attrs) {
                    if (attrs.selectModel) {
                        scope.$watch(attrs.selectModel, function(newVal, oldVal) {
                            if (newVal && newVal.length > 0) {
                                $timeout(function() {
                                    refresh();
                                }, 50);
                            }
                        }, true);
                    }

                    scope.$watch(attrs.ngModel, function() {
                        refresh();
                    });

                    function refresh() {
                        element.selectpicker('refresh');
                        if(attrs.sizeModel){
                            element.selectpicker({
                                size: attrs.sizeModel
                            });
                        }else{
                            element.selectpicker({
                                size: 10
                            });
                        }
                        
                    }
                }
            };
        });
})();
