(function() {
	'use strict';

	angular.module('crmApp').controller('AdminEmailConfigurationController',
		AdminEmailConfigurationController);

	AdminEmailConfigurationController.$inject = [ '$scope', 'entity', 'AdminEmailConfiguration' ];

	function AdminEmailConfigurationController($scope, entity, AdminEmailConfiguration) {
		var vm = this;
		vm.config = entity;
		vm.save = save;
		
		function save() {
			$scope.$broadcast('show-errors-check-validity');
			if($scope.editForm.$invalid){
				var invalidControl = angular.element('input.ng-invalid').first();
				if(invalidControl.length != 0){
					invalidControl.focus();
				}
			}else{
				AdminEmailConfiguration.update(vm.config, onSaveSuccess);
			}
		}
		
		function onSaveSuccess() {
			alert("Lưu thành công!");
		}
	}
})();
