(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'email-template',
						{
							parent : 'email',
							url : '/email-template?page&sort&search',
							data : {
								pageTitle : 'Quản lý mẫu email'
							},
							views : {
								'content@' : {
									templateUrl : 'app/email/email-template/email-template.html',
									controller : 'EmailTemplateController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ]
							}
						})
				.state(
						'email-template-detail',
						{
							parent : 'email',
							url : '/email-template/{id}',
							data : {
								pageTitle : 'Xem mẫu email'
							},
							views : {
								'content@' : {
									templateUrl : 'app/email/email-template/email-template-detail.html',
									controller : 'EmailTemplateDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'EmailTemplate',
										function($stateParams, EmailTemplate) {
											return EmailTemplate.get({
												id : $stateParams.id
											});
										} ],
								action : function() {
									return 'view';
								}
							}
						})
				.state(
						'email-template-new',
						{
							parent : 'email',
							url : '/email-template/0/create',
							data : {
								pageTitle : 'Tạo mẫu email'
							},
							views : {
								'content@' : {
									templateUrl : 'app/email/email-template/email-template-detail.html',
									controller : 'EmailTemplateDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										name : null
									};
								},
								action : function() {
									return 'create';
								}
							}
						})
				.state(
						'email-template-edit',
						{
							parent : 'email',
							url : 'email-template/{id}/edit',
							data : {
								pageTitle : 'Cập nhật mẫu email'
							},
							views : {
								'content@' : {
									templateUrl : 'app/email/email-template/email-template-detail.html',
									controller : 'EmailTemplateDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'EmailTemplate',
										function($stateParams, EmailTemplate) {
											return EmailTemplate.get({
												id : $stateParams.id
											});
										} ],
								action : function() {
									return 'edit';
								}
							}
						})
				.state(
						'email-template.delete',
						{
							parent : 'email-template',
							url : '/{id}/delete',
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/email/email-template/email-template-delete-dialog.html',
													controller : 'EmailTemplateDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'EmailTemplate',
																function(
																		EmailTemplate) {
																	return EmailTemplate
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(function() {
											$state.go('email-template', null, {
												reload : true
											});
										}, function() {
											$state.go('^');
										});
									} ]
						});
	}
})();
