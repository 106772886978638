/**
 * 
 * @returns
 * @author Quynh
 */
(function() {
    'use strict';

    var historyCustomerCare = {
        templateUrl: 'app/template/history-customer-care.html',
        controller: historyCustomerCareComponentController,
        bindings:{
        	info: '<'
        }
    };
    
    function historyCustomerCareComponentController(){
    	var $ctrl = this;
    
    }
    angular
        .module('crmApp')
        .component('historyCustomerCare', historyCustomerCare);
})();
