(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('GHTKOrderShipStatusService', GHTKOrderShipStatusService);

    GHTKOrderShipStatusService.$inject = [];

    function GHTKOrderShipStatusService() {
        var vm = this;
        
        var StatusId = {
            ORDER_CANCELED: -1,
            NOT_RECEIVED: 1,
            RECEIVERD: 2,
            CAN_NOT_GET_GOODS: 7
        }

        var StatusName = {
            ORDER_CANCELED: "Hủy đơn hàng",
            NOT_RECEIVED: "Chưa tiếp nhận",
            RECEIVERD: "Đã tiếp nhận",
            CAN_NOT_GET_GOODS: "Không lấy được hàng"
        }

        var Class = {
            DANGER : "label label-danger",
            SUCCESS: "label label-success",
            WARNING: "label label-warning"
        }

        vm.getStatusName = function (statusId) {
            var statusName = '';
            
            if (statusId === StatusId.ORDER_CANCELED) {
                statusName = StatusName.ORDER_CANCELED;
            } else if (statusId === StatusId.NOT_RECEIVED) {
                statusName = StatusName.NOT_RECEIVED;
            } else if (statusId === StatusId.RECEIVERD){
                statusName = StatusName.RECEIVERD;
            } else if (statusId === StatusId.CAN_NOT_GET_GOODS) {
                statusName = StatusName.CAN_NOT_GET_GOODS;
            }

            return statusName;
        }

        vm.getClassName = function (statusId) {
            var className = '';
            
            if (statusId === StatusId.ORDER_CANCELED) {
                className = Class.DANGER;
            } else if (statusId === StatusId.NOT_RECEIVED) {
                className = Class.DANGER;
            } else if (statusId === StatusId.RECEIVERD) {
                className = Class.SUCCESS;
            } else if (statusId === StatusId.CAN_NOT_GET_GOODS) {
                className = Class.WARNING;
            }

            return className;
        }
            
    }
}());
