(function() {
	'use strict';

	angular.module('crmApp').controller('EmailController', EmailController);

	// EmailController.$inject = ['Customer', 'CustomerCare', '$scope', 'Principal', 'LoginService', 'pagingParams', 'paginationConstants', 'ParseLinks', '$location', '$anchorScroll'];

	function EmailController() {

	}
})();
