(function() {
	'use strict';

	angular.module('crmApp').controller('CreatePlanCareController',
			CreatePlanCareController);

	CreatePlanCareController.$inject = ['$uibModal', '$scope', 'CareConfiguration', '$state', '$http', 'pagingParams', 'ParseLinks', 'AlertService'];

	function CreatePlanCareController($uibModal, $scope, CareConfiguration, $state, $http, pagingParams, ParseLinks, AlertService) {

		var vm = this;
		vm.loadPage = loadPage;
		vm.transition = transition;
		vm.page = 1;
		vm.predicate = pagingParams.predicate;
		vm.entries = '10';
		vm.showItem = 10;
		vm.showName = "Kế hoạch chăm sóc";
		vm.currentSearch = {};
		vm.loadPage();
		function loadPage() {
		    vm.currentSearch.page = vm.page - 1;
		    vm.currentSearch.size = vm.entries;
            vm.currentSearch.sort = sort();
		    CareConfiguration.query(vm.currentSearch, onSuccess, onError);
    	}

		function transition() {
			vm.loadPage();
		}
		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.carePlans = data;
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}


		vm.addPlanCare = function(carePlan){
			vm.modalInstanAdd = $uibModal.open({
                templateUrl: 'app/product-care/create-plan-care-popup.html',
                size: 'md',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.careConfiguration = carePlan;

                    vm.closePopup = function() {
                       closePopup();
                        $state.go('create-plan-care', null, {
		                    reload: true
		                });
                    };
                    vm.saveConfig = function(){
                    	vm.change = true;
                    	if(vm.careConfiguration.id != undefined)
                    		CareConfiguration.update(vm.careConfiguration, onSaveSuccess, onSaveError);
                    	else
                    		CareConfiguration.save(vm.careConfiguration, onSaveSuccess, onSaveError);
                    }
                    function onSaveSuccess() {
			            vm.isSaving = false;
			             if(vm.change){
			             	 vm.change = true;
			                 $state.go('create-plan-care', null, {
			                    reload: true
			                });
			            }
			        };
			        function onSaveError() {
			            vm.isSaving = false;
			        };
                },
                controllerAs: "vm"
            });
			function closePopup() {
                vm.modalInstanAdd.dismiss('cancel');
            };
		}
		
	}
})();
