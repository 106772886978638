(function() {
    'use strict';

    var jhiAlertError = {
        template: '<div class="alerts" ng-cloak="">' +
            '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
            '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></uib-alert>' +
            '</div>' +
            '</div>',
        controller: jhiAlertErrorController
    };

    angular
        .module('crmApp')
        .component('jhiAlertError', jhiAlertError);

    jhiAlertErrorController.$inject = ['$scope', 'AlertService', '$rootScope'];

    function jhiAlertErrorController($scope, AlertService, $rootScope) {
        var vm = this;

        vm.alerts = [];
        var Code = {
            NOT_AUTHORITY: "2001",
            EMAIL_EXIST: "2002",
            ID_EXIST: "2003",
            CARE_HISTORY_CONTENT_EMPTY: "2004",
            CARE_TYPE_EMPTY: "2005",
            PLANNED_DATE_EMPTY: "2006",
            PHONE_NUMBER_EXIST: "2007",
            PHONE_NUMBER_EMPTY: "2008",
            CUSTOMER_NAME_EMPTY: "2009",
            SERVER_ERROR: "2010",
            PRODUCT_NAME_EMPTY: "2011",
            ORIGIN_EMPTY: "2012",
            LOGIN_NAME_EXIST: "2013",
            ROLE_EMPTY: "2014",
            EMAIL_EMPTY: "2015",
            LOGIN_NAME_EMPTY: "2016",
            PRODUCT_QUANTITY_EMPTY: "2017",
            ORDER_DETAIL_EMPTY: "2018",
            PASSWORD_INCORRECT: "2019",
            PASSWORD_RESET: "2020",
            WAREHOUSE_CODE_EMPTY: "2021",
            WAREHOUSE_NAME_EMPTY: "2022",
            ADDRESS_EMPTY: "2023",
            DESCRIPTION_EMPTY: "2024",
            WAREHOUSE_CODE_EXIST: "2025",
            DO_NOT_DELETE: "2026",
            DO_NOT_DELETE_WAREHOUSE_HAS_PRODUCT: "2027",
            WAREHOUSE_NOT_EXIST: "2028",
            DO_NOT_DELETE_ORDER: "2029",
            GHTK_SHIPMENT_ORDER_ERROR: "2030",
            GHTK_SHIPMENT_ORDER_SUCCESS: '2031',
            PRODUCT_CODE_EMPTY: '2032',
            PRODUCT_CODE_EXIST: '2033',
            ORDER_ID_EXIST: '2034',
            CUSTOMER_ADDRESS_NOT_UPDATE: '2035',
            ORDER_SHIPMENT_PICKMONEY_EMPTY: '2036',
            NOT_ENOUGH_PRODUCT_QUANTITY: '2037',
            ORDER_SHIPMENT_PICKTEL_EMPTY: '2038',
            COMPANY_EMPTY: '2039',
            ORDER_IS_COMPLETE_COLLECTE_MONEY: '2040',
            COLLECTE_MONEY_MORE: '2041',
            INVENTORY_EMPTY: '2042',
            VIETTEL_POST_SERVICE_TYPE_ERROR: '2043',
            VIETTEL_POST_SHIPMENT_ORDER_ERROR: '2044',
            TEAM_NAME_EMPTY: '2045',
            TEAM_TYPE_ID_EMPTY: '2046',
            TEAM_LEADER_ID_EMPTY: '2047',
            DO_NOT_DELETE_TEAM: '2048',
            ORDER_CANCELLED: '2049',
            UPDATE_STATUS_ERROR: '2050',
            QUANTITY_PRODUCT_IN_WAREHOUSE_NOT_ENOUGH: '2051',
            SETTING_NAME_EXIST: '2052',
            CARE_CONFIGURATION_EMPTY: '2053'
        };

        var Message = {
            NOT_AUTHORITY: "Bạn không có quyền để thực hiện chức năng này.",
            EMAIL_EXIST: "Email đã có người sử dụng.",
            ID_EXIST: "Id này đã tồn tại.",
            CARE_HISTORY_CONTENT_EMPTY: "Nội dung báo cáo là bắt buộc.",
            CARE_TYPE_EMPTY: "Hình thức chăm sóc là bắt buộc.",
            PLANNED_DATE_EMPTY: "Ngày thực hiện là bắt buộc.",
            PHONE_NUMBER_EXIST: "Số điện thoại đã sử dụng.",
            PHONE_NUMBER_EMPTY: "Số điện thoại là bắt buộc.",
            CUSTOMER_NAME_EMPTY: "Tên khách hàng là bắt  buộc.",
            SERVER_ERROR: "Có lỗi xảy ra ở server.",
            PRODUCT_NAME_EMPTY: "Tên sản phẩm là bắt buộc.",
            ORIGIN_EMPTY: "Xuất xứ là bắt buộc.",
            LOGIN_NAME_EXIST: "Tên đăng nhập là bắt buộc.",
            ROLE_EMPTY: "Vai trò là bắt buộc.",
            EMAIL_EMPTY: "Tên email là bắt buộc.",
            LOGIN_NAME_EMPTY: "Tên đăng nhập là bắt buộc.",
            PRODUCT_QUANTITY_EMPTY: "Số lượng sản phẩm là bắt buộc.",
            ORDER_DETAIL_EMPTY: "Đơn hàng trống. Vui lòng thêm sản phẩm!",
            PASSWORD_INCORRECT: "Mật khẩu cũ không chính xác.",
            PASSWORD_RESET: "Email này chưa đăng ký.",
            WAREHOUSE_CODE_EMPTY: "Mã kho là bắt buộc.",
            WAREHOUSE_NAME_EMPTY: "Tên kho là bắt buộc.",
            ADDRESS_EMPTY: "Địa chỉ là bắt buộc.",
            DESCRIPTION_EMPTY: "Mô tả là bắt buộc.",
            WAREHOUSE_CODE_EXIST: "Mã kho đã sử dụng.",
            DO_NOT_DELETE: "Không thể xóa khách hàng có đơn hàng.",
            DO_NOT_DELETE_WAREHOUSE_HAS_PRODUCT: "Không thể xóa kho có sản phẩm.",
            WAREHOUSE_NOT_EXIST: "Kho này không còn tồn tại.",
            DO_NOT_DELETE_ORDER: "Không thể xóa Đơn Hàng.",
            GHTK_SHIPMENT_ORDER_ERROR: "Bị lỗi trong quá trình gửi đơn hàng lên GHTK.",
            GHTK_SHIPMENT_ORDER_SUCCESS: "Gửi đơn hàng lên GHTK thành công.",
            PRODUCT_CODE_EMPTY: "Mã sản phẩm là bắt buộc.",
            PRODUCT_CODE_EXIST: "Mã sản phẩm đã sử dụng.",
            ORDER_ID_EXIST: "Mã đơn hàng của bạn đã tồn tại trên hệ thống GHTK.",
            CUSTOMER_ADDRESS_NOT_UPDATE: "Chưa cập nhật địa chỉ của khách hàng.",
            ORDER_SHIPMENT_PICKMONEY_EMPTY: "Tiền thu hộ không được bỏ trống.",
            NOT_ENOUGH_PRODUCT_QUANTITY: "Số lượng sản phẩm trong kho không đủ.",
            ORDER_SHIPMENT_PICKTEL_EMPTY: "Số điện thoại kho không được bỏ trống.",
            COMPANY_EMPTY: "Tên công ty không thể bỏ trống.",
            ORDER_IS_COMPLETE_COLLECTE_MONEY: 'Đơn hàng đã thu đủ tiền.',
            COLLECTE_MONEY_MORE: 'Số tiền thu vượt quá tổng số tiền cần thu.',
            INVENTORY_EMPTY: 'Chưa chọn kho',
            VIETTEL_POST_SERVICE_TYPE_ERROR: 'Bảng giá không áp dụng cho hành trình này!',
            VIETTEL_POST_SHIPMENT_ORDER_ERROR: 'Bị lỗi trong quá trình gửi đơn hàng lên Viettel Post.',
            TEAM_NAME_EMPTY: 'Tên nhóm là bắt buộc.',
            TEAM_TYPE_ID_EMPTY: 'Loại nhóm là bắt buộc.',
            TEAM_LEADER_ID_EMPTY: 'Trưởng nhóm là bắt buộc.',
            DO_NOT_DELETE_TEAM: 'Không thể xóa nhóm có thành viên.',
            ORDER_CANCELLED: 'Vận đơn đã hủy!',
            UPDATE_STATUS_ERROR: 'Có lỗi khi cập nhật trạng thái.',
            QUANTITY_PRODUCT_IN_WAREHOUSE_NOT_ENOUGH: 'Số lượng hàng tồn kho không đủ để điều chuyển.',
            SETTING_NAME_EXIST : 'Nhãn và Từ khóa này đã được sử dụng',
            CARE_CONFIGURATION_EMPTY : 'Vui lòng nhập đủ'
        };

        var Entity = {
            CareHistory: "CareHistory",
            CareMission: "CareMission",
            CarePlan: "CarePlan",
            Customer: "Customer",
            EmailTemplate: "EmailTemplate",
            Order: "Order",
            Payment: "Payment",
            Product: "Product",
            Role: "Role",
            User: "User",
            Warehouse: "Warehouse",
            Team: "Team"
        };

        function getMessageFromErrorCode(errorCode) {
            var message = '';
            if (errorCode === Code.NOT_AUTHORITY) {
                message = Message.NOT_AUTHORITY;
            } else if (errorCode === Code.EMAIL_EXIST) {
                message = Message.EMAIL_EXIST;
            } else if (errorCode === Code.ID_EXIST) {
                message = Message.ID_EXIST;
            } else if (errorCode === Code.CARE_HISTORY_CONTENT_EMPTY) {
                message = Message.CARE_HISTORY_CONTENT_EMPTY;
            } else if (errorCode === Code.CARE_TYPE_EMPTY) {
                message = Message.CARE_TYPE_EMPTY;
            } else if (errorCode === Code.PLANNED_DATE_EMPTY) {
                message = Message.PLANNED_DATE_EMPTY;
            } else if (errorCode === Code.PHONE_NUMBER_EXIST) {
                message = Message.PHONE_NUMBER_EXIST;
            } else if (errorCode === Code.PHONE_NUMBER_EMPTY) {
                message = Message.PHONE_NUMBER_EMPTY;
            } else if (errorCode === Code.CUSTOMER_NAME_EMPTY) {
                message = Message.CUSTOMER_NAME_EMPTY;
            } else if (errorCode === Code.SERVER_ERROR) {
                message = Message.SERVER_ERROR;
            } else if (errorCode === Code.PRODUCT_NAME_EMPTY) {
                message = Message.PRODUCT_NAME_EMPTY;
            } else if (errorCode === Code.ORIGIN_EMPTY) {
                message = Message.ORIGIN_EMPTY;
            } else if (errorCode === Code.LOGIN_NAME_EXIST) {
                message = Message.LOGIN_NAME_EXIST;
            } else if (errorCode === Code.ROLE_EMPTY) {
                message = Message.ROLE_EMPTY;
            } else if (errorCode === Code.EMAIL_EMPTY) {
                message = Message.EMAIL_EMPTY;
            } else if (errorCode === Code.LOGIN_NAME_EMPTY) {
                message = Message.LOGIN_NAME_EMPTY;
            } else if (errorCode === Code.PRODUCT_QUANTITY_EMPTY) {
                message = Message.PRODUCT_QUANTITY_EMPTY;
            } else if (errorCode === Code.ORDER_DETAIL_EMPTY) {
                message = Message.ORDER_DETAIL_EMPTY;
            } else if (errorCode === Code.PASSWORD_INCORRECT) {
                message = Message.PASSWORD_INCORRECT;
            } else if (errorCode === Code.PASSWORD_RESET) {
                message = Message.PASSWORD_RESET;
            } else if (errorCode === Code.WAREHOUSE_CODE_EMPTY) {
                message = Message.WAREHOUSE_CODE_EMPTY;
            } else if (errorCode === Code.WAREHOUSE_NAME_EMPTY) {
                message = Message.WAREHOUSE_NAME_EMPTY;
            } else if (errorCode === Code.ADDRESS_EMPTY) {
                message = Message.ADDRESS_EMPTY;
            } else if (errorCode === Code.DESCRIPTION_EMPTY) {
                message = Message.DESCRIPTION_EMPTY;
            } else if (errorCode === Code.WAREHOUSE_CODE_EXIST) {
                message = Message.WAREHOUSE_CODE_EXIST;
            } else if (errorCode === Code.DO_NOT_DELETE) {
                message = Message.DO_NOT_DELETE;
            } else if (errorCode === Code.DO_NOT_DELETE_WAREHOUSE_HAS_PRODUCT) {
                message = Message.DO_NOT_DELETE_WAREHOUSE_HAS_PRODUCT;
            } else if (errorCode === Code.WAREHOUSE_NOT_EXIST) {
                message = Message.WAREHOUSE_NOT_EXIST;
            } else if (errorCode === Code.DO_NOT_DELETE_ORDER) {
                message = Message.DO_NOT_DELETE_ORDER;
            } else if (errorCode === Code.GHTK_SHIPMENT_ORDER_ERROR) {
                message = Message.GHTK_SHIPMENT_ORDER_ERROR;
            } else if (errorCode === Code.GHTK_SHIPMENT_ORDER_SUCCESS) {
                message = Message.GHTK_SHIPMENT_ORDER_SUCCESS;
            } else if (errorCode === Code.PRODUCT_CODE_EMPTY) {
                message = Message.PRODUCT_CODE_EMPTY;
            } else if (errorCode === Code.PRODUCT_CODE_EXIST) {
                message = Message.PRODUCT_CODE_EXIST;
            } else if (errorCode === Code.ORDER_ID_EXIST) {
                message = Message.ORDER_ID_EXIST;
            } else if (errorCode === Code.CUSTOMER_ADDRESS_NOT_UPDATE) {
                message = Message.CUSTOMER_ADDRESS_NOT_UPDATE
            } else if (errorCode === Code.ORDER_SHIPMENT_PICKMONEY_EMPTY) {
                message = Message.ORDER_SHIPMENT_PICKMONEY_EMPTY;
            } else if (errorCode === Code.NOT_ENOUGH_PRODUCT_QUANTITY) {
                message = Message.NOT_ENOUGH_PRODUCT_QUANTITY;
            } else if (errorCode === Code.ORDER_SHIPMENT_PICKTEL_EMPTY) {
                message = Message.ORDER_SHIPMENT_PICKTEL_EMPTY;
            } else if (errorCode === Code.COMPANY_EMPTY) {
                message = Message.COMPANY_EMPTY;
            } else if (errorCode === Code.ORDER_IS_COMPLETE_COLLECTE_MONEY) {
                message = Message.ORDER_IS_COMPLETE_COLLECTE_MONEY;
            } else if (errorCode === Code.COLLECTE_MONEY_MORE) {
                message = Message.COLLECTE_MONEY_MORE;
            } else if (errorCode === Code.TEAM_NAME_EMPTY) {
                message = Message.TEAM_NAME_EMPTY;
            } else if (errorCode === Code.TEAM_TYPE_ID_EMPTY) {
                message = Message.TEAM_TYPE_ID_EMPTY;
            } else if (errorCode === Code.TEAM_LEADER_ID_EMPTY) {
                message = Message.TEAM_LEADER_ID_EMPTY;
            } else if (errorCode === Code.INVENTORY_EMPTY) {
                message = Message.INVENTORY_EMPTY;
            } else if (errorCode === Code.VIETTEL_POST_SERVICE_TYPE_ERROR) {
                message = Message.VIETTEL_POST_SERVICE_TYPE_ERROR;
            } else if (errorCode === Code.VIETTEL_POST_SHIPMENT_ORDER_ERROR) {
                message = Message.VIETTEL_POST_SHIPMENT_ORDER_ERROR;
            } else if (errorCode === Code.DO_NOT_DELETE_TEAM) {
                message = Message.DO_NOT_DELETE_TEAM;
            } else if (errorCode === Code.ORDER_CANCELLED) {
                message = Message.ORDER_CANCELLED;
            } else if (errorCode === Code.UPDATE_STATUS_ERROR) {
                message = Message.UPDATE_STATUS_ERROR;
            } else if (errorCode === Code.QUANTITY_PRODUCT_IN_WAREHOUSE_NOT_ENOUGH) {
                message = Message.QUANTITY_PRODUCT_IN_WAREHOUSE_NOT_ENOUGH;
            } else if (errorCode === Code.SETTING_NAME_EXIST) {
                message = Message.SETTING_NAME_EXIST;
            } else if (errorCode === Code.CARE_CONFIGURATION_EMPTY) {
                message = Message.CARE_CONFIGURATION_EMPTY;
            }

            return message;
        }

        function convertEntityNameFromEngToVietNamese(entityName) {
            var name = '';
            if (entityName === Entity.CareHistory) {
                name = 'báo cáo';
            } else if (entityName === Entity.CareMission) {
                name = 'chăm sóc khách hàng';
            } else if (entityName === Entity.CarePlan) {
                name = 'lập lịch';
            } else if (entityName === Entity.Customer) {
                name = 'khách hàng';
            } else if (entityName === Entity.EmailTemplate) {
                name = 'mẫu email';
            } else if (entityName === Entity.Order) {
                name = 'đơn hàng';
            } else if (entityName === Entity.Payment) {
                name = 'payment';
            } else if (entityName === Entity.Product) {
                name = 'sản phẩm';
            } else if (entityName === Entity.Role) {
                name = 'vai trò';
            } else if (entityName === Entity.User) {
                name = 'nhân viên';
            } else if (entityName === Entity.Warehouse) {
                name = 'kho';
            } else if (entityName === Entity.Team) {
                name = 'nhóm';
            }

            return name;
        }

        function addErrorAlert(message, key, data) {
            vm.alerts.push(
                AlertService.add({
                        type: 'danger',
                        msg: message,
                        timeout: 5000,
                        toast: AlertService.isToast(),
                        scoped: true
                    },
                    vm.alerts
                )
            );
        }

        var cleanHttpErrorListener = $rootScope.$on('crmApp.httpError', function(event, httpResponse) {
            var i;
            event.stopPropagation();
            switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;

                case 400:
                    var errorHeader = getMessageFromErrorCode(httpResponse.headers('X-crmApp-errorKey'));
                    var entityKey = convertEntityNameFromEngToVietNamese(httpResponse.headers('X-crmApp-params'));
                    if (errorHeader) {
                        var entityName = entityKey;
                        addErrorAlert(errorHeader, errorHeader, {
                            entityName: entityName
                        });
                    } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                        for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                            var fieldError = httpResponse.data.fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            var fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                            addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {
                                fieldName: fieldName
                            });
                        }
                    } else if (httpResponse.data && httpResponse.data.message) {
                        addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                    } else {
                        addErrorAlert(httpResponse.data);
                    }
                    break;

                default:
                    if (httpResponse.data && httpResponse.data.message) {
                        addErrorAlert(httpResponse.data.message);
                    } else {
                        addErrorAlert(angular.toJson(httpResponse));
                    }
            }
        });

        $scope.$on('$destroy', function() {
            if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();
