(function() {
	'use strict';

	angular.module('crmApp').controller('OrderShipmentCheckStatusController', OrderShipmentCheckStatusController);

	OrderShipmentCheckStatusController.$inject = ['entity', '$http', '$uibModalInstance', 'StringUtils', 'GHTKOrderShipStatusService', '$window'];

	function OrderShipmentCheckStatusController(entity, $http, $uibModalInstance, StringUtils, GHTKOrderShipStatusService, $window) {
        var vm = this;
        vm.order = entity;

        if(StringUtils.isNotBlank(vm.order.ghtkId)) {
            $http({
                url : "/api/get-ghtk-ship-order-status-by-ghtk-id",
                method: "GET",
                params: {
                    ghtkId: vm.order.ghtkId
                }
            }).success(function (response) {
                vm.ghtkOrderStatus = response;

                vm.statusName = GHTKOrderShipStatusService.getStatusName(vm.ghtkOrderStatus.order.status);
                vm.className = GHTKOrderShipStatusService.getClassName(vm.ghtkOrderStatus.order.status);
            
            }).error(function (response) {
            
            })    
        }

        vm.ghtkOrderShipCancel = function () {
            var result = ($window.confirm("Huỷ đơn hàng sẽ không thể đăng lên được nữa, Bạn chắc không?"));
            
            if(result) {
                $http({
                    url : "/api/ghtk-ship-order-cancel-by-ghtk-id",
                    method: "POST",
                    params: {
                        ghtkId: vm.order.ghtkId
                    }
                }).success(function (response) {
                    if (response.success) {
                        vm.ghtkOrderStatus.order.status = -1;
                        vm.statusName = GHTKOrderShipStatusService.getStatusName(vm.ghtkOrderStatus.order.status);
                        vm.className = GHTKOrderShipStatusService.getClassName(vm.ghtkOrderStatus.order.status);    
                    }
                }).error(function (response) {
                
                })   


                vm.statusName = GHTKOrderShipStatusService.getStatusName(vm.ghtkOrderStatus.order.status);
                vm.className = GHTKOrderShipStatusService.getClassName(vm.ghtkOrderStatus.order.status);
            }
        }

        vm.showCancel = function () {
            if(vm.ghtkOrderStatus != null || vm.ghtkOrderStatus != undefined)
                return vm.ghtkOrderStatus.order.status === 1;
        }

        vm.close = function() {
			$uibModalInstance.close();
		}
	}
})();
