(function() {
    'use strict';

    angular
        .module('crmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('login', {
            parent: 'account',
            url: '/login',
            data: {
                pageTitle: 'Đăng Nhập'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/login/login.html',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                }
            }
        }).state('forget-password', {
            parent: 'login',
            url: '/forget-password',
            data: {
                pageTitle: 'Đăng Nhập'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/forget-password/forget-password.html',
                    controller: 'ForgetPasswordController',
                    controllerAs: 'vm'
                }
            }
        })
        ;
    }
})();
