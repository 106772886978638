(function() {
	'use strict';
	angular.module('crmApp').factory('CustomerCare', CustomerCare);

	CustomerCare.$inject = [ '$resource', '$state' ];

	function CustomerCare($resource, $state) {
		var resourceUrl = 'api/customer-care/:id';

		var service = $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET'
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data !== null && data !== undefined && data !== '') {
						data = angular.fromJson(data);
					} else {
						$state.go('accessdenied');
					}
					return data;
				}
			},
			'save' : {
				method : 'POST'
			},
			'update' : {
				method : 'PUT'
			},
			'delete' : {
				method : 'DELETE'
			}
		});

		return service;
	}
}());
