(function () {
    'use strict';

    angular
        .module('crmApp')
        .directive('fileUpload', function (
        ) {
            return {               
                link: function (scope, element) {

                    scope.fileNameAvater = 'Chọn ảnh';
                     scope.fileNameCMND = 'Chọn ảnh';
                    element.bind('change', function () {
                        scope.$apply(function () {
                            scope.fileNameAvater = document.getElementById('uploadFileInput1').files[0].name;
                            scope.fileNameCMND = document.getElementById('uploadFileInput2').files[0].name;
                        });
                    });

                    scope.uploadFile = function(){
                        var formData = new FormData();

                        formData.append('file', document.getElementById('uploadFileInput').files[0]);

                        // Add code to submit the formData  
                    };
                }
            };
        });
})();