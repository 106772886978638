(function() {
    'use strict';
    angular
        .module('crmApp')
        .factory('AdminEmailConfiguration', AdminEmailConfiguration);

    AdminEmailConfiguration.$inject = ['$resource'];

    function AdminEmailConfiguration ($resource) {
        var resourceUrl =  'api/admin-email-configuration';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' },
            'update': { method:'PUT' }
        });
    }
})();
