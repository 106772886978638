(function() {
	'use strict';

	angular.module('crmApp').controller('OrderShipmentInfoController',
    OrderShipmentInfoController);

	OrderShipmentInfoController.$inject = ['entity', '$http', '$uibModalInstance'];

	function OrderShipmentInfoController(entity, $http, $uibModalInstance) {
        var vm = this;
        vm.orderShipment = entity;


        vm.confirm = function () {
        	$http.post('/api/ghtk-shipment-order', angular.toJson(vm.orderShipment)).then(onSendSuccess, onSendError);
        }

        function onSendSuccess(response) {
        	$uibModalInstance.close();
        }

        function onSendError(response) {
        	$uibModalInstance.close();
        }

        vm.close = function() {
				$uibModalInstance.close();
			}
	}
})();
