(function() {
	'use strict';

	angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

	stateAbsConfig.$inject = [ '$stateProvider' ];

	function stateAbsConfig($stateProvider) {
		$stateProvider.state('report', {
			url : '/report',
			parent : 'app'
		});
	}
	stateConfig.inject = [ '$stateProvider' ];
	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'report-revenue-achievement',
						{
							parent : 'report',
							url : '/report-revenue-achievement',
							data : {
								authorities : [ 'USER' ],
								pageTitle : 'Báo cao doanh thu'
							},
							views : {
								'content@' : {
									templateUrl : 'app/report/report-revenue-achievement.html',
									controller : 'ReportRevenueAchievementController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								entity : function() {
									return {
										ReportOrder : null
									};
								},
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'report-product',
						{
							parent : 'report',
							url : '/report-product',
							data : {
								authorities : [ 'USER' ],
								pageTitle : 'Báo cao doanh thu theo sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/report/report-product.html',
									controller : 'ReportProductController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								entity : function() {
									return {
										ReportProduct : null
									};
								},
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ]
							}
						})
				.state(
						'report-revenue-achievement.search',
						{
							parent : 'report-revenue-achievement',
							url : '/{search:json}',
							data : {
								authorities : [ 'USER' ],
								pageTitle : 'Báo cao doanh thu'
							},
							views : {
								'content@' : {
									templateUrl : 'app/report/report-revenue-achievement.html',
									controller : 'ReportRevenueAchievementController',
									controllerAs : 'vm'
								}
							},

							resolve : {
								entity : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												ReportOrder : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'report-product.search',
						{
							parent : 'report-product',
							url : '/{search:json}',
							data : {
								authorities : [ 'USER' ],
								pageTitle : 'Báo cao doanh thu theo sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/report/report-product.html',
									controller : 'ReportProductController',
									controllerAs : 'vm'
								}
							},

							resolve : {
								entity : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												ReportProduct : $stateParams.search
											};
										} ],
							}
						}).state('report-stock', {
					parent : 'report',
					url : '/report-stock',
					data : {
						authorities : [ 'USER' ],
						pageTitle : 'Báo cáo tồn kho'
					},
					views : {
						'content@' : {
							templateUrl : 'app/report/report-stock.html',
							controller : 'ReportStockController',
							controllerAs : 'vm'
						}
					},
					resolve : {}
				}).state('report-revenue', {
					parent : 'report',
					url : '/report-revenue',
					data : {
						authorities : [ 'USER' ],
						pageTitle : 'Báo cáo doanh thu'
					},
					views : {
						'content@' : {
							templateUrl : 'app/report/report-revenue.html',
							controller : 'ReportRevenueController',
							controllerAs : 'vm'
						}
					},
					resolve : {}
				}).state('report-liabilities', {
					parent : 'report',
					url : '/report-liabilities',
					data : {
						authorities : [ 'USER' ],
						pageTitle : 'Báo cáo công nợ'
					},
					views : {
						'content@' : {
							templateUrl : 'app/report/report-liabilities.html',
							controller : 'ReportLiabilitiesController',
							controllerAs : 'vm'
						}
					},
					resolve : {}
				})

	}
}());
