'use strict';

angular.module('crmApp').directive('mentionExample', function ($http) {
  return {
    require: 'uiMention',
    link: function link($scope, $element, $attrs, uiMention) {
      /**
       * $mention.findChoices()
       *
       * @param  {regex.exec()} match    The trigger-text regex match object
       * @todo Try to avoid using a regex match object
       * @return {array[choice]|Promise} The list of possible choices
       */
      uiMention.findChoices = function(match) {
        return $http.get('/api/search-user-by-name', { params: { name: match[1] } })
          .then(function(response) {
            return response.data;
          });
      }
    }
  };
});

