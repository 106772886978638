(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('home', {
			parent : 'dashboard',
			url : '/?page&sort&search',
			data : {
				authorities : [ 'USER' ]
			},
			views : {
				'content@' : {
					templateUrl : 'app/dashboard/home/home.html',
					controller : 'HomeController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'lastModifiedDate,desc',
					squash : true
				},
				search : null
			},
			resolve : {
				pagingParams : [
						'$stateParams',
						'PaginationUtil',
						function($stateParams, PaginationUtil) {
							return {
								page : PaginationUtil
										.parsePage($stateParams.page),
								sort : $stateParams.sort,
								predicate : PaginationUtil
										.parsePredicate($stateParams.sort),
								ascending : PaginationUtil
										.parseAscending($stateParams.sort),
								search : $stateParams.search
							};
						} ]
			}
		});
	}
})();
