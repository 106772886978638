(function() {
	'use strict';
	angular.module('crmApp').factory('EmailSend', EmailSend);

	EmailSend.$inject = [ '$resource' ];

	function EmailSend($resource) {
		var resourceUrl = 'api/email/send';

		return $resource(resourceUrl, {}, {
			'send' : {
				method : 'POST'
			},
		});
	}
})();
