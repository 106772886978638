(function() {
    'use strict';

    angular.module('crmApp').controller('OrderDetailConfirmStockDialogController',
        OrderDetailConfirmStockDialogController);

    OrderDetailConfirmStockDialogController.$inject = ['$rootScope', '$uibModalInstance', 'entity', 'CommonConstants', '$http'];

    function OrderDetailConfirmStockDialogController($rootScope, $uibModalInstance, entity, CommonConstants, $http) {

        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.order = entity;
        vm.loadAll();
        vm.PRODUCT_ITEM_TYPE_PRODUCT = CommonConstants.PRODUCT_ITEM_TYPE_PRODUCT;
        vm.PRODUCT_ITEM_TYPE_OBJECT = CommonConstants.PRODUCT_ITEM_TYPE_OBJECT;
        vm.PRODUCT_ITEM_TYPE_WORKER = CommonConstants.PRODUCT_ITEM_TYPE_WORKER;
        vm.PRODUCT_ITEM_TYPE_PROMOTION = CommonConstants.PRODUCT_ITEM_TYPE_PROMOTION;

        function loadAll() {
            vm.loadPage();
        }
        vm.theNumberOfProductGreaterThanInStock = function(data) {
            var theNumberOfProduct = 0;
            theNumberOfProduct = theNumberOfProduct + vm.countTheNumberOfProductBothNormalAndPromotion(data.warehouseId, data.productItemId);
            theNumberOfProduct = theNumberOfProduct - vm.countTheNumberOfProductBothNormalAndPromotionHadBought(data.warehouseId, data.productItemId);

            return data.numberInOrders + theNumberOfProduct - data.numberInStock;
        };
        vm.countTheNumberOfProductBothNormalAndPromotion = function(warehouseId, productId) {
            var count = 0;
            if (vm.order.orderDetails) {
                angular.forEach(vm.order.orderDetails, function(orderDetail) {
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += orderDetail.quantity;
                    }
                });
            }
            return count;
        };
        vm.countTheNumberOfProductBothNormalAndPromotionHadBought = function(warehouseId, productId) {
            var count = 0;
            var orderDetail = null;
            if (vm.order.orderDetails) {
                for (var i = 0; i < vm.order.orderDetails.length; i++) {
                    orderDetail = vm.order.orderDetails[i];
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += orderDetail.quantity;
                    }
                }
            }
            return count;
        };

        function loadPage() {
            vm.productInStock = [];
            vm.stocks = [];
            var orderDetail = null;
            for(var i = 0; i < vm.order.orderDetails; i++){
                orderDetail = vm.order.orderDetails[i];
                if (orderDetail && (orderDetail.productItemType == vm.PRODUCT_ITEM_TYPE_PRODUCT || orderDetail.productItemType == vm.PRODUCT_ITEM_TYPE_PROMOTION)) {
                    if (orderDetail.warehouseId) {
                        orderDetail.numberInStock = 0;
                        orderDetail.numberInOrders = 0;

                        $http({
                            url: '/api/the-number-of-one-product-in-warehouse',
                            method: "GET",
                            params: {
                                warehouseId: orderDetail.warehouseId,
                                productId: orderDetail.productItemId
                            }
                        }).then(function(success) {
                            orderDetail.numberInStock = success.data;
                            var odDetail = null;
                            for (var i = 0; i < vm.order.orderDetails.length; i++) {
                                odDetail = vm.order.orderDetails[i];
                                if (orderDetail.warehouseId == odDetail.warehouseId &&
                                    orderDetail.productItemId == odDetail.productItemId) {
                                    odDetail.numberInStock = orderDetail.numberInStock;
                                }
                            }
                        });

                        $http({
                            url: '/api/get-the-number-of-a-product-ordering',
                            method: "GET",
                            params: {
                                warehouseId: orderDetail.warehouseId,
                                productId: orderDetail.productItemId
                            }
                        }).then(function(success) {
                            orderDetail.numberInOrders = success.data;
                            var odDetail = null;
                            for (var i = 0; i < vm.order.orderDetails.length; i++) {
                                odDetail = vm.order.orderDetails[i];
                                if (orderDetail.warehouseId == odDetail.warehouseId &&
                                    orderDetail.productItemId == odDetail.productItemId) {
                                    odDetail.numberInOrders = orderDetail.numberInOrders;
                                }
                            }
                        });
                    } else {
                        orderDetail.numberInStock = null;
                        orderDetail.numberInOrders = null;
                    }
                }
            };


            $http.get('/api/get-all-stocks')
                .then(function(success) {
                    vm.stocks.push(success.data);
                    angular.forEach(vm.order.productQuantities, function(productQuantities,key) {
                        vm.status = true;
                        angular.forEach(vm.order.orderDetails, function(orderDetails,key1) {
                           angular.forEach(vm.stocks[0], function(stocks) {
                                if (vm.status === true) {
                                    if (productQuantities.productItemId === orderDetails.productItemId && orderDetails.warehouseId === stocks.id && vm.order.orderDetails[key1].productItemType == vm.order.productQuantities[key].productItemType) {
                                       vm.productInStock.push(angular.extend(productQuantities, orderDetails, stocks));
                                       vm.status = false;
                                    }
                                }
                            })
                        })
                    })
                });

        }
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
         $rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
        vm.save = function() {

        }
    }
})();
