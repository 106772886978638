(function() {
    'use strict';

    angular.module('crmApp').controller('StockConfirmProductController',
        StockConfirmProductController);

    StockConfirmProductController.$inject = ['$resource', '$uibModal', '$http', '$scope'];

    function StockConfirmProductController($resource, $uibModal, $http, $scope) {
        var vm = this;
        vm.reverse = false;
        vm.transition = transition;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "sản phẩm";
        vm.showItem = 10;
        vm.totalItems = null;
        vm.idCheckeds = [];
        vm.loadAll = loadAll;
        vm.loadAll();
        // loadAll
        function loadAll(){
            $http.get('/api/get-waitting-confirm-transfer-product')
            .then(function(success) {
                vm.transferProducts = success.data;
                vm.isSelectedAll = false;
             });
        }

        // transition
        function transition() {

        }

        //
        vm.isNotSelected = true;
        vm.checkProduct = function() {
            vm.idCheckeds = [];
            angular.forEach(vm.transferProducts, function(transferProduct) {
                if (!!transferProduct.selected) {
                    vm.idCheckeds.push(transferProduct.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.isSelectedAll = false;
            } else if (vm.idCheckeds.length === vm.transferProducts.length) {
                vm.isSelectedAll = true;
            } else {
                vm.isNotSelected = false;
                vm.isSelectedAll = false;
            }
        }

        vm.selectedAll = function() {
            var i = 0;
            if (vm.isSelectedAll) {
                vm.isSelectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.isSelectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.transferProducts, function(transferProduct) {
                i++;
                if (i <= vm.entries)
                    transferProduct.selected = vm.isSelectedAll;
            });
            vm.checkProduct();
        }

        vm.getQuantityOfThisProductInWarehouse = function(productWarehouses, warehouse_id, product_id){
          var quantity = 0;
          if(productWarehouses && productWarehouses.length > 0){
            for(var i = 0; i < productWarehouses.length; i++){
              if(productWarehouses[i].warehouseId == warehouse_id && productWarehouses[i].productId == product_id){
                quantity = productWarehouses[i].quantity;
                break;
              }
            }
          }
          return quantity;
        }

        function getTransferGroup(){
          var transferGroups = [];
          angular.forEach(vm.transferProducts, function(transferProduct) {
              if (!!transferProduct.selected) {
                  transferGroups.push(transferProduct.tranferGroup);
              }
          });
          return transferGroups;
        }

        // confirm
        vm.confirm = function(){
            vm.modalInstanceChange = $uibModal.open({
                template: '<confirm-component  message="vm.title" content="vm.content" close="vm.closePopup()" save="vm.save()"></confirm-component>',
                size: 'md',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.title = "THÔNG BÁO XÁC NHẬN";
                    vm.content = "Bạn có chắc chắn xác nhận yêu cầu này không? ";
                    vm.closePopup = function() {
                        close();
                    };
                    vm.save = function(){
                      var transferGroups = getTransferGroup();
                      var ConfirmTransferProduct = $resource('api/confirm-transfer-product');
                      ConfirmTransferProduct.get({transferGroups: transferGroups},
                      function success(success){
                        loadAll();
                      }, function error(error){
                      });
                      close();
                    };
                },
                controllerAs:"vm"
            });

            function close(){
                vm.modalInstanceChange.dismiss('cancel');
            }
        }

        // not confirm
        vm.notConfirm = function(){
            vm.modalInstance = $uibModal.open({
                template: '<confirm-component  message="vm.title" content="vm.content" close="vm.closePopup()" save="vm.save()"></confirm-component>',
                size: 'md',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.title = "THÔNG BÁO HỦY XÁC NHẬN";
                    vm.content = "Bạn có chắc chắn xác nhận hủy yêu cầu này không? ";
                    vm.closePopup = function() {
                        close();
                    };
                    vm.save = function(){
                      var transferGroups = getTransferGroup();
                      var CancelConfirm = $resource('api/cancel-confirm-transfer-product');
                      CancelConfirm.get({transferGroups: transferGroups},
                      function success(success){
                        loadAll();
                      }, function error(error){
                      });
                      close();
                    };
                },
                controllerAs:"vm"
            });

            function close(){
                vm.modalInstance.dismiss('cancel');
            }
        }
    } // end controller
})();
