/*sidebar*/
    $(document).ready(function () {
        //change icon chevron
        $("#panel1").click(function() {
            $("#arow1").toggleClass("fa-chevron-left");
            $("#arow1").toggleClass("fa-chevron-down");
        });
        $("#panel2").click(function() {
            $("#arow2").toggleClass("fa-chevron-left");
            $("#arow2").toggleClass("fa-chevron-down");
        });
        $("#panel3").click(function() {
            $("#arow3").toggleClass("fa-chevron-left");
            $("#arow3").toggleClass("fa-chevron-down");
        });
        $("#panel4").click(function() {
            $("#arow4").toggleClass("fa-chevron-left");
            $("#arow4").toggleClass("fa-chevron-down");
        });
        $("#panel5").click(function() {
            $("#arow5").toggleClass("fa-chevron-left");
            $("#arow5").toggleClass("fa-chevron-down");
        });
        $("#panel6").click(function() {
            $("#arow6").toggleClass("fa-chevron-left");
            $("#arow6").toggleClass("fa-chevron-down");
        });
        $("#panel7").click(function() {
            $("#arow7").toggleClass("fa-chevron-left");
            $("#arow7").toggleClass("fa-chevron-down");
        });
        $("#panel8").click(function() {
            $("#arow8").toggleClass("fa-chevron-left");
            $("#arow8").toggleClass("fa-chevron-down");
        });
    });
    $(document).ready(function () {
        $('.sidebar').tooltip();
    });