(function() {
    'use strict';

    angular
        .module('crmApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService'];

    function notificationInterceptor($q, AlertService) {
        var service = {
            response: response
        };

        var Code = {
            CREATE_SUCCESS: "1001",
            UPDATE_SUCCESS: "1002",
            DELETE_SUCCESS: "1003",
            GHTK_SHIPMENT_ORDER_SUCCESS: "1004",
            VIETTEL_POST_SHIPMENT_ORDER_SUCCESS: "1005",
            ORDER_CANCELLED_SUCCESS: "1006"
        };

        var Message = {
            GHTK_SHIPMENT_ORDER_SUCCESS: "Đơn hàng đã được chuyển lên GHTK",
            VIETTEL_POST_SHIPMENT_ORDER_SUCCESS: "Đơn hàng đã được chuyển lên Viettel Post",
            ORDER_CANCELLED_SUCCESS: "Đã hủy vận đơn"
        }

        var Entity = {
            CareHistory: "CareHistory",
            CareMission: "CareMission",
            CarePlan: "CarePlan",
            Customer: "Customer",
            EmailTemplate: "EmailTemplate",
            Order: "Order",
            Payment: "Payment",
            Product: "Product",
            Role: "Role",
            User: "User",
            Team: "Team",
            Warehouse: "Warehouse"
        };


        function getMessageFromCode(code){
            var message = '';
            if (code === Code.GHTK_SHIPMENT_ORDER_SUCCESS) {
                message = Message.GHTK_SHIPMENT_ORDER_SUCCESS;
            } else if (code === Code.VIETTEL_POST_SHIPMENT_ORDER_SUCCESS) {
                message = Message.VIETTEL_POST_SHIPMENT_ORDER_SUCCESS;
            } else if (code === Code.ORDER_CANCELLED_SUCCESS) {
                message = Message.ORDER_CANCELLED_SUCCESS;
            }
            return message;
        }

        return service;

        function createAlertMessageForEntityCreation(action, entityName) {
            var alert = '';
            if (action === Code.CREATE_SUCCESS) {
                alert += 'Lưu ';
            } else if (action === Code.UPDATE_SUCCESS) {
                alert += 'Cập nhật ';
            } else if (action === Code.DELETE_SUCCESS) {
                alert += 'Xóa ';
            }

            if (entityName === Entity.CareHistory) {
                alert += 'báo cáo';
            } else if (entityName === Entity.CareMission) {
                alert += 'chăm sóc khách hàng';
            } else if (entityName === Entity.CarePlan) {
                alert += 'lập lịch';
            } else if (entityName === Entity.Customer) {
                alert += 'khách hàng';
            } else if (entityName === Entity.EmailTemplate) {
                alert += 'mẫu email';
            } else if (entityName === Entity.Order) {
                alert += 'đơn hàng';
            } else if (entityName === Entity.Payment) {
                alert += 'thanh toán';
            } else if (entityName === Entity.Product) {
                alert += 'sản phẩm';
            } else if (entityName === Entity.Role) {
                alert += 'vai trò';
            } else if (entityName === Entity.User) {
                alert += 'nhân viên';
            } else if (entityName === Entity.Team) {
                alert += 'nhóm';
            } else if (entityName === Entity.Warehouse) {
                alert += 'kho';
            }

            alert += ' thành công';

            return alert;
        }

        function response(response) {
            var alertKey = response.headers('X-crmApp-alert');
            var action = response.headers('X-crmApp-action');
            var entityName = response.headers('X-crmApp-entityName');
            var params = response.headers('X-crmApp-params');

            if(action === Code.GHTK_SHIPMENT_ORDER_SUCCESS || action === Code.VIETTEL_POST_SHIPMENT_ORDER_SUCCESS || action === Code.ORDER_CANCELLED_SUCCESS) {
                var alert = getMessageFromCode(action);
                AlertService.success(alert, {
                    param: response.headers('X-crmApp-params')
                });
            }

            if (angular.isString(action) && angular.isString(entityName)) {
                var alert = createAlertMessageForEntityCreation(action, entityName);
                if (action === Code.UPDATE_SUCCESS) {
                  AlertService.info(alert, {
                    param: response.headers('X-crmApp-params')
                  });
                } else {
                  AlertService.success(alert, {
                    param: response.headers('X-crmApp-params')
                  });
                }
            }
            return response;
        }
    }
})();
