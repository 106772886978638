(function() {
    'use strict';

    var jhiItemCount = {
        template: '<div class="info">' +
                    'Hiển thị {{$ctrl.queryCount == 0 ? 0 : (($ctrl.page -1) * $ctrl.showItem) == 0 ? 1 :(($ctrl.page-1) * $ctrl.showItem) + 1}}- ' +
                    '{{($ctrl.page * $ctrl.showItem) < $ctrl.queryCount ? ($ctrl.page * $ctrl.showItem) : $ctrl.queryCount}} ' +
                    'trong {{$ctrl.queryCount}} {{$ctrl.showName}}.' +
                '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            showName: '<name',
            showItem: '<show'
        }
    };
    angular
        .module('crmApp')
        .component('jhiItemCount', jhiItemCount);
})();
