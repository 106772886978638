(function() {
	'use strict';

	angular.module('crmApp').controller('ReportRevenueController',
			ReportRevenueController);

	ReportRevenueController.$inject = [ 'SettingUtils', '$scope', '$resource',
			'DateFormatService', '$window', '$http', '$state', '$uibModal'];

	function ReportRevenueController(SettingUtils, $scope, $resource,
			DateFormatService, $window, $http, $state, $uibModal) {
		var vm = this;
		vm.reportRevenueCO = {};
		vm.reportRevenueCurrentClone = {};

		vm.products = [];
		vm.stocksSelect = [];
		vm.users = [];
		vm.teams = [];
		vm.typeReports = [
			{value:1, name: "Doanh Thu Theo Mặt Hàng"},
			{value:2, name: "Doanh Thu Theo Nhân Viên Bán Hàng"},
			{value:3, name: "Doanh Thu Hàng Trả"},
			{value:4, name: "Chi Tiết Doanh Thu"}
		];
		vm.quarters = [
			{value:1, name: "Quý 1"},
			{value:2, name: "Quý 2"},
			{value:3, name: "Quý 3"},
			{value:4, name: "Quý 4"}
		];

		vm.months = [
			{value:1, name: "Tháng 1"},
			{value:2, name: "Tháng 2"},
			{value:3, name: "Tháng 3"},
			{value:4, name: "Tháng 4"},
			{value:5, name: "Tháng 5"},
			{value:6, name: "Tháng 6"},
			{value:7, name: "Tháng 7"},
			{value:8, name: "Tháng 8"},
			{value:9, name: "Tháng 9"},
			{value:10, name: "Tháng 10"},
			{value:11, name: "Tháng 11"},
			{value:12, name: "Tháng 12"}
		]
		vm.orderStatus = [];
		vm.loadAll = loadAll;
		vm.loadAll();
		function loadAll() {
			configDateTime();
			getProductCode();
			vm.value = 1;
			hideShow('block', 'none', 'none', 'block','none', 'none', 'none', 'block', 'none', 'block');
			showFilter();
			showHide(vm.filter);
			vm.hideDate = true;
		}
		//
		vm.required = false;

		vm.reportRevenueStaff = function(){
			vm.reportRevenueCurrentClone = angular.copy(vm.reportRevenueCO);
			vm.revenueStaffReports = [];
			var year = vm.reportRevenueCO.year ? vm.reportRevenueCO.year.getFullYear() : null;
			$http({
					url: "/api/report-revenue-for-staff",
					method: "GET",
					params: {
							dateFrom: vm.reportRevenueCO.dateFrom,
							dateTo: vm.reportRevenueCO.dateTo,
							staffIds: vm.reportRevenueCO.staffIds,
							month: vm.reportRevenueCO.month,
							quarter: vm.reportRevenueCO.quarter,
							year: year
					}
			}).then(function (response) {
				vm.revenueStaffReports = response.data;
				vm.data = vm.revenueStaffReports;
			});
		};

		vm.reportRevenueDetail = function(){
			vm.reportRevenueCurrentClone = angular.copy(vm.reportRevenueCO);
			vm.revenueDetailReports = [];
			var year = vm.reportRevenueCO.year ? vm.reportRevenueCO.year.getFullYear() : null;
			$http({
					url: "/api/report-revenue-detail",
					method: "GET",
					params: {
							orderStatuses: vm.reportRevenueCO.orderStatuses,
							teamId: vm.reportRevenueCO.teamId,
							dateFrom: vm.reportRevenueCO.dateFrom,
							dateTo: vm.reportRevenueCO.dateTo,
							staffIds: vm.reportRevenueCO.staffIds,
							productIds: vm.reportRevenueCO.productIds,
							month: vm.reportRevenueCO.month,
							quarter: vm.reportRevenueCO.quarter,
							year: year
					}
			}).then(function (response) {
				vm.revenueDetailReports = response.data;
				vm.data = vm.revenueDetailReports;
			});
		};

		vm.reportRevenueProductRef = function(){
			vm.reportRevenueCurrentClone = angular.copy(vm.reportRevenueCO);
			vm.RefundItems = [];
			var year = vm.reportRevenueCO.year ? vm.reportRevenueCO.year.getFullYear() : null;
			$http({
					url: "/api/report-revenue-for-refund-item",
					method: "GET",
					params: {
							productIds: vm.reportRevenueCO.productIds,
							month: vm.reportRevenueCO.month,
							quarter: vm.reportRevenueCO.quarter,
							year: year
					}
			}).then(function (response) {
				vm.RefundItems = response.data;
				vm.data = vm.RefundItems;
			});
		};

		vm.reportRevenueProduct = function(){
			vm.reportRevenueCurrentClone = angular.copy(vm.reportRevenueCO);
			vm.revenueReports = [];
			var year = vm.reportRevenueCO.year ? vm.reportRevenueCO.year.getFullYear() : null;
			$http({
					url: "/api/report-revenue-for-product",
					method: "GET",
					params: {
							productIds: vm.reportRevenueCO.productIds,
							month: vm.reportRevenueCO.month,
							quarter: vm.reportRevenueCO.quarter,
							year: year
					}
			}).then(function (response) {
				vm.revenueReports = response.data;
				vm.data = vm.revenueReports;
			});
		};

		vm.report = function(){
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.searchForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else{
				if(vm.value == 1){
					vm.reportRevenueProduct();
				} else if(vm.value == 2){
					vm.reportRevenueStaff();
				} else if(vm.value == 3){
					vm.reportRevenueProductRef();
				} else if(vm.value == 4){
					vm.reportRevenueDetail();
				}
			}
		};

		function addParameterToUrl(parameter, paramName, paramValue){
			if(paramValue){
				if(parameter.length == 0){
					parameter += '?';
				} else {
					parameter += '&';
				}
				parameter += (paramName + '='+ paramValue);
			}
			return parameter;
		}

		function isNull(val){
			if(val === null || val === undefined){
				return true;
			} else {
				return false;
			}
		}

		vm.exportExcelRevenueProduct = function(){
			var year = vm.reportRevenueCurrentClone.year ? vm.reportRevenueCurrentClone.year.getFullYear() : null;
			var parameter = "";
			parameter = addParameterToUrl(parameter, "productIds", vm.reportRevenueCurrentClone.productIds);
			parameter = addParameterToUrl(parameter, "month", vm.reportRevenueCurrentClone.month);
			parameter = addParameterToUrl(parameter, "quarter", vm.reportRevenueCurrentClone.quarter);
			parameter = addParameterToUrl(parameter, "year", year);

			$window.open("public/export-excel-revenue-for-product" + parameter);
		};

		vm.exportExcelRevenueStaff = function(){
			var year = vm.reportRevenueCurrentClone.year ? vm.reportRevenueCurrentClone.year.getFullYear() : null;
			var dateFrom = isNull(vm.reportRevenueCurrentClone.dateFrom) ? vm.reportRevenueCurrentClone.dateFrom : vm.reportRevenueCurrentClone.dateFrom.toISOString();
			var dateTo = isNull(vm.reportRevenueCurrentClone.dateTo) ? vm.reportRevenueCurrentClone.dateTo : vm.reportRevenueCurrentClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "staffIds", vm.reportRevenueCurrentClone.staffIds);
			parameter = addParameterToUrl(parameter, "month", vm.reportRevenueCurrentClone.month);
			parameter = addParameterToUrl(parameter, "quarter", vm.reportRevenueCurrentClone.quarter);
			parameter = addParameterToUrl(parameter, "year", year);

			$window.open("public/export-excel-revenue-for-staff" + parameter);
		};

		vm.exportExcelRevenueProductRef = function(){
			var year = vm.reportRevenueCurrentClone.year ? vm.reportRevenueCurrentClone.year.getFullYear() : null;
			var parameter = "";
			parameter = addParameterToUrl(parameter, "productIds", vm.reportRevenueCurrentClone.productIds);
			parameter = addParameterToUrl(parameter, "month", vm.reportRevenueCurrentClone.month);
			parameter = addParameterToUrl(parameter, "quarter", vm.reportRevenueCurrentClone.quarter);
			parameter = addParameterToUrl(parameter, "year", year);

			$window.open("public/export-excel-revenue-for-refund-item" + parameter);
		};

		vm.exportExcelRevenueDetail = function(){
			var year = vm.reportRevenueCurrentClone.year ? vm.reportRevenueCurrentClone.year.getFullYear() : null;
			var dateFrom = isNull(vm.reportRevenueCurrentClone.dateFrom) ? vm.reportRevenueCurrentClone.dateFrom : vm.reportRevenueCurrentClone.dateFrom.toISOString();
			var dateTo = isNull(vm.reportRevenueCurrentClone.dateTo) ? vm.reportRevenueCurrentClone.dateTo : vm.reportRevenueCurrentClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "orderStatuses", vm.reportRevenueCurrentClone.orderStatuses);
			parameter = addParameterToUrl(parameter, "teamId", vm.reportRevenueCurrentClone.teamId);
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "staffIds", vm.reportRevenueCurrentClone.staffIds);
			parameter = addParameterToUrl(parameter, "productIds", vm.reportRevenueCurrentClone.productIds);
			parameter = addParameterToUrl(parameter, "month", vm.reportRevenueCurrentClone.month);
			parameter = addParameterToUrl(parameter, "quarter", vm.reportRevenueCurrentClone.quarter);
			parameter = addParameterToUrl(parameter, "year", year);

			$window.open("public/export-excel-revenue-detail" + parameter);
		};

		vm.excel = function(){
			if(vm.value == 1){
				vm.exportExcelRevenueProduct();
			} else if(vm.value == 2){
				vm.exportExcelRevenueStaff();
			} else if(vm.value == 3){
				vm.exportExcelRevenueProductRef();
			} else if(vm.value == 4){
				vm.exportExcelRevenueDetail();
			}
		};

		// print
		vm.print = function() {
			$window.print();
		}
		//

		function showHide(filter){
				if(filter == 2 ){
					vm.hideShowDate = false;
					vm.hideShowMonth = true;
					vm.hideShowQuarter = false;
					vm.hideDate = true;
				}else if(filter == 3){
					vm.hideShowDate = false;
					vm.hideShowMonth = false;
					vm.hideShowQuarter = true;
					vm.hideDate = true;
				}else if(filter == 1){
					vm.hideShowDate = true;
					vm.hideShowMonth = false;
					vm.hideShowQuarter = false;
					vm.hideDate = false;
				}else if(filter == null){
					vm.hideShowDate = false;
					vm.hideShowMonth = false;
					vm.hideShowQuarter = false;
					vm.hideDate = false;
				}
		}
		vm.changeFilter = function(filter){
			vm.reportRevenueCO = {};
			vm.reportRevenueCurrentClone = {};

			showHide(filter);
			$scope.$broadcast('show-errors-reset');
		}
		function angularElementChild(value1, value2, value3, value4, value5) {
			angular.element(".hidden-year").css({
				display : value1
			});
			angular.element(".hidden-quarter").css({
				display : value2
			});
			angular.element(".hidden-from-date").css({
				display : value3
			});
			angular.element(".hidden-to-date").css({
				display : value4
			});
			angular.element(".hidden-month").css({
				display : value5
			});

		}

		function hideShow(value1, value2, value3, value4, value5, value6, value7, value8, value9, value10){
			angular.element(".condition-filter").css({
				display : value1
			});
			angular.element(".condition-group").css({
				display : value2
			});
			angular.element(".condition-user").css({
				display : value3
			});
			angular.element(".condition-product").css({
				display : value4
			});
			angular.element(".condition-order").css({
				display : value5
			});
			angular.element(".condition-dateto").css({
				display : value6
			});
			angular.element(".condition-datefrom").css({
				display : value7
			});
			angular.element(".condition-month").css({
				display : value8
			});
			angular.element(".condition-quarter").css({
				display : value9
			});
			angular.element(".condition-year").css({
				display : value10
			});
		}
		//

		// get product
		function getProductCode() {
			$http.get('/api/get-all-product').success(function(success) {
				if (success && success.length > 0) {
					vm.products = success;
				}
			});

			$http.get('/api/get-all-stocks').then(function(success) {
				vm.stocksSelect = success.data;
			}).$promise;

			$http.get("/api/users-sales", {}).then(function(response) {
				vm.users = response.data;
			});

			var ORDER_STATUS = 'ORDER_STATUS';
			SettingUtils.get({
				'keys' : [ ORDER_STATUS ]
			}, function(success) {

				angular.forEach(success, function(value, key) {
					if (value.key === ORDER_STATUS) {
						vm.orderStatus = success;
					}
				});
			}, function(error) {
			});

			// get team
			$http({
				url : "/api/get-all-team",
				method : "GET",
			}).success(function(response) {
				vm.teams = response;
			}).error(function(response) {

			})
		}

		vm.selectedTeams = function(){
			vm.users = [];
			if (vm.reportRevenueCO.teamId && !isNaN(vm.reportRevenueCO.teamId) && angular.isNumber(+vm.reportRevenueCO.teamId)) {
				var StaffTeam = $resource('api/get-member-of-a-team');
				StaffTeam.query({teamId: vm.reportRevenueCO.teamId}, function success(response){
					var users = [];
					for(var i = 0; i < response.length; i++){
						users.push(response[i].staff);
					}
					vm.users = users;
				}, function error(error){

				});
    	} else {
				$http.get("/api/users-sales", {}).then(function(response) {
					vm.users = response.data;
				});
			}
		};

		// config date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.altInputFormatsDate = 'dd';
			vm.dateFormatDate = 'dd';

			vm.altInputFormatsMonth = 'MM';
			vm.dateFormatMonth = 'MM';

			vm.altInputFormatsMonthYear = 'M!/yyyy';
			vm.dateFormatMonthYear = 'MM/yyyy';

			vm.altInputFormatsYear = 'yyyy';
			vm.dateFormatYear = 'yyyy';

			// vm.dateOptions = DateFormatService.getDateOptions();

			vm.dateOptionsDate = {
				formatYear : 'dd',
				startingDay : 1,
				minMode : 'day',

			};


			vm.dateOptionsMonth = {
				formatYear : 'MM',
				startingDay : 1,
				minMode : 'month',

			};

			vm.dateOptionsMonthYear = {
				formatYear : 'MM/yyyy',
				minMode : 'month'
			};

			vm.dateOptionsYear = {
				formatYear : 'yyyy',
				startingDay : 1,
				minMode : 'year',

			};
			vm.openDateFrom1 = function() {
				vm.isDateFromOpened1 = true;
			}

			vm.openDateFrom2 = function() {
				vm.isDateFromOpened2 = true;
			}

			vm.openDateFrom3 = function() {
				vm.isDateFromOpened3 = true;
			}

			vm.openDateFrom4 = function() {
				vm.isDateFromOpened4 = true;
			}
		}

		function showFilter(){
			if(vm.value == 1 || vm.value == 3 || vm.value == null){
				vm.filter = 2;
				vm.filters = [
					{value:2, name: "Theo Tháng"},
					{value:3, name: "Theo Quý"}
				];
			}else{
				vm.filter = 1;
				vm.filters = [
					{value:1, name: "Theo Ngày"},
					{value:2, name: "Theo Tháng"},
					{value:3, name: "Theo Quý"}
				];
			}
		}
		//
		function resetValue(){
			vm.reportRevenueCO.month = "";
			vm.reportRevenueCO.year = "";
			vm.reportRevenueCO.dateTo = "";
			vm.reportRevenueCO.dateFrom = "";
			vm.reportRevenueCO.productIds = [];
			vm.reportRevenueCO.orderStatuses = [];
			vm.reportRevenueCO.staffIds = [];
			vm.reportRevenueCO.teamIds  = [];
		}

		vm.displayUI = function(value) {
			vm.disableButton();
			vm.revenueStaffReports = [];
			vm.revenueDetailReports = [];
			vm.RefundItems = [];
			vm.revenueReports = [];
			vm.data = [];

			vm.filter = [];
			resetValue();
			if (value === 1 || value === null) {
				showFilter();
				vm.hideDate = true;
				hideShow('block', 'none', 'none', 'block','none', 'none', 'none', 'block', 'none', 'block');
			} else if (value === 2) {
				$http.get("/api/users-sales", {}).then(function(response) {
					vm.users = response.data;
				});
				showFilter();

				vm.hideDate = false;
				hideShow('block', 'none', 'block', 'none','none', 'block', 'block', 'none', 'none', 'none');
			} else if (value === 3) {
				vm.hideDate = true;
				showFilter();
				hideShow('block', 'none', 'none', 'block','none', 'none', 'none', 'block', 'none', 'block');
				hideShow('block', 'none', 'none', 'block','none', 'none', 'none', 'block', 'none', 'block');
			} else if (value === 4) {
				$http.get("/api/users-sales", {}).then(function(response) {
					vm.users = response.data;
				});
				showFilter();
				vm.hideDate = true;
				hideShow('block', 'block', 'block', 'block','block', 'block', 'block', 'none', 'none', 'none');
			}
			 showHide(vm.filter);
			$scope.$broadcast('show-errors-reset');
			vm.reportRevenueCO = {};
			vm.reportRevenueCurrentClone = {};
		}

		vm.displayProductName = function(products, productCode) {
			var result = '';
			if (products !== null && products !== undefined
					&& products.length > 0) {
				angular.forEach(products, function(product, key) {
					angular.forEach(productCode, function(code, key) {
						if (product.productCode == code) {
							result += product.productName;
							if (key != (products.length - 1)) {
								result += ', ';
							}
						}
					})
				});
			}
			return result;

		}

		vm.displayStaff = function(staffs, staffId) {
			var result = '';
			if (staffs !== null && staffs !== undefined
					&& staffs.length > 0) {
				angular.forEach(staffs, function(staff, key) {
					angular.forEach(staffId, function(id, key) {
						if (staff.id == id) {
							result += staff.name;
							if (key != (staff.length - 1)) {
								result += ', ';
							}
						}
					})
				});
			}
			return result;

		}

		vm.disableButton = function(){
			if(vm.data === undefined || vm.data.length == 0){
				return true;
			}else{
				return false;
			}

		}
	}
})();
