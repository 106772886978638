(function() {
	'use strict';
	angular.module('crmApp').controller('CustomerCareDetailController',
			CustomerCareDetailController);
	CustomerCareDetailController.inject = ['$uibModal','$location', '$anchorScroll',
			'$timeout', 'UserUtils', 'CommonConstants', 'AlertService',
			'SettingUtils, ', 'DateFormatService', '$scope', 'User',
			'CustomerCare', 'CareHistory', 'CarePlan', '$stateParams', '$http',
			'entity', '$state', 'idCarePlan', '$q' ];

	function CustomerCareDetailController($uibModal, $location, $anchorScroll, $timeout,
			UserUtils, CommonConstants, AlertService, SettingUtils,
			DateFormatService, $scope, User, CustomerCare, CareHistory,
			CarePlan, $stateParams, $http, entity, $state, idCarePlan, $q) {
		var CUSTOMER_SERVICE_TYPE = 'CUSTOMER_SERVICE_TYPE';
		var CUSTOMER_SOURCE = 'CUSTOMER_SOURCE';
		var CUSTOMER_GENDER = 'CUSTOMER_GENDER';
		var CARE_MISSION_STATUS = 'CARE_MISSION_STATUS';
		var CARE_MISSION_RESULT = 'CARE_MISSION_RESULT';
		var CARE_TYPE = 'CARE_TYPE';
		var CARE_PLAN_SEVERITY = 'CARE_PLAN_SEVERITY';
		var CARE_HISTORY_CLOSED_STATUS = 'CARE_HISTORY_CLOSED_STATUS';
		var CUSTOMER_BUSINESS = 'CUSTOMER_BUSINESS';

		var vm = this;
		vm.account = UserUtils.getAccount();
		vm.loginName = vm.account.login;
		vm.$state = $state;

		vm.scrollToCare = scrollToCare;
		vm.showCarePlan = showCarePlan;
		function showCarePlan(careHistoryDetail) {
			vm.modalInstance = $uibModal.open({
				templateUrl: 'app/template/customer-care-detail-plan.html',
				backdrop: 'static',
				scope: $scope,
				controller: function(){
					vm.clearPlan = function(){
						vm.modalInstance.dismiss('cancel');
					}
					vm.careHistoryDetail = careHistoryDetail;
				}
			});
		}
		function scrollToCare(closedCareHistoryId) {
			$location.hash(closedCareHistoryId);
			$anchorScroll();
		}

		vm.status = false;
		vm.statusError = false;
		// vm.carePlanType = 0;
		vm.loadAll = loadAll;
		vm.hasAuthority = hasAuthority;
		vm.customers = entity;
		vm.isUserLoginCreateThisCustomer = vm.customers.createdBy === vm.loginName;
		vm.isCustomerAll = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ALL);
		vm.hasOrderAddAuthority = UserUtils.hasAuthorityName(CommonConstants.ORDER_ADD);
		vm.altInputFormats = DateFormatService.getAltInputFormats();
		vm.dateFormat = DateFormatService.getDateFormat();
		vm.dateOptions = DateFormatService.getDateOptions();

		vm.careHistory = {
			careMissionId : null,
			carePlanId : null,
			nextCarePlanId : null,
			plannedDate : null,
			actionDate : null,
			closeStatusId : null,
			careTypeId : null
		};

		vm.carePlan = {
			staffId : null,
			memo : null,
			severityId : null,
			careType : null,
			plannedDate : null

		};
		vm.careHistoryInCarePlan = [];
		vm.carePlanForEditInCarePlan = [];
		vm.isCloseCareMissionInPlan = {};

		vm.count = 0;
		vm.countChat = 0;
		vm.countEmail = 0;
		vm.countPhone = 0;
		vm.countShake = 0;
		vm.countGift = 0;

		vm.caretypies = [];
		vm.servicies = [];
		vm.sourcies = [];
		vm.genders = [];
		vm.statuses = [];
		vm.severities = [];
		vm.severitys = [];
		vm.closeStatus = [];
		vm.businies = [];

		vm.getAllCustomer = getAllCustomer;

		//vm.setDisable = setDisable;
		// close all when
		vm.showReport = [];
		vm.showReportFunc = function(index, carePlan, carePlanId) {
			//show/hide report or edit care plan
			vm.showReport[index] = !vm.showReport[index];
			vm.showEditReport[index] = false;

			vm.careHistoryInCarePlan[index] = {};
			vm.careHistoryInCarePlan[index].carePlanId = carePlan.id;
			vm.careHistoryInCarePlan[index].careTypeId = carePlan.careTypeId;

			vm.carePlanForEditInCarePlan[index] = angular.copy(carePlan);
			vm.carePlanForEditInCarePlan[index].plannedDate = new Date(
					vm.carePlanForEditInCarePlan[index].plannedDate);
		}

		vm.showEditReport = [];
		vm.showEditReportFunc = function(index, carePlan) {
			//show/hide report or edit care plan
			vm.showEditReport[index] = !vm.showEditReport[index];
			vm.showReport[index] = false;

			vm.carePlanForEditInCarePlan[index] = angular.copy(carePlan);
			vm.carePlanForEditInCarePlan[index].plannedDate = new Date(
					vm.carePlanForEditInCarePlan[index].plannedDate);
		}

		// open datepicker
		vm.openAssignedDate = function() {
			vm.isAssignedDateOpened = true;
		}
		// open datepicker
		vm.openCompleteDate = function() {
			vm.isCompleteDateOpened = true;
		}
		// open datepicker
		vm.openDateFrom = function() {
			vm.isDateFromOpened = true;
		}
		vm.deleteHistoryPlan = function(carePlanId){
			vm.modalInstanceObject = $uibModal.open({
				templateUrl: 'app/customer-care/customer-care-detail-history-plan-delete-dialog.html',
				controller: 'CustomerCarePlanDeleteDialogController',
				controllerAs: 'vm',
				backdrop: 'static',
				size: 'md',
				resolve : {
					carePlanId : function() {
						return carePlanId;
					},
					entity: function(){
						return entity;
					}
				}
			});
		}
		// open datepicker
		vm.isPlanDatedOfCarePlanEditOpen = [];
		vm.openPlanDatedOfCarePlanEdit = function(index) {
			vm.isPlanDatedOfCarePlanEditOpen[index] = true;
		}

		vm.growableOptions = {
			displayText : 'Tìm kiếm khách hàng',
			emptyListText : 'Không tìm thấy khách hàng nào',
			emptySearchResultText : 'Không tìm thấy "$0"',
			searchDelay : 0
		}
		vm.loadAll();
		// get http request care misstion id
		vm.statusPlan = false;
		function getCarePlanHttp(statusPlan) {
			var defer = $q.defer();
			$http.get(
					'/api/get-care-plan-with-care-mission-id?id='
							+ vm.careMissionId).success(function(success) {
				vm.carePlans = success;

				// update care STATUS
				var keys = Object.keys(vm.carePlans);
				defer.resolve(keys.length);
				//end update care STATUS
				vm.carePlansClone = angular.copy(vm.carePlans);
				for (var i = 0; i < vm.carePlans.length; i++) {
					if (vm.carePlans[i].careType != null) {
						vm.count += 1;
						switch (vm.carePlans[i].careType.value) {
						case 1:
							vm.countPhone += 1;
							break;
						case 2:
							vm.countShake += 1;
							break;
						case 3:
							vm.countChat += 1;
							break;
						case 4:
							vm.countGift += 1;
							break;
						case 5:
							vm.countEmail += 1;
							break;
						}
					}
				}

			});
			// update care STATUS
			vm.statusPlan = statusPlan;
			if (vm.statusPlan === true) {
				defer.promise.then(function(value) {
					if (vm.careMission.careStatusId !== 3) {
						if (value !== 0) {
							vm.careMission.careStatusId = 2;
							CustomerCare.update(vm.careMission);
						} else {
							vm.careMission.careStatusId = 1;
							CustomerCare.update(vm.careMission);
						}
					}
				}, function(error) {
					// This is called when error occurs.
				})
				vm.statusPlan = false;
			}
			// end update care STATUS
		}
		;
		// get http request care misstion id
		function getCareHistoryHttp() {
			$http.get(
					'/api/get-care-history-with-care-mission-id?id='
							+ vm.careMissionId).success(function(success) {
				vm.careHistories = success;
			});
		}
		;

		// get http request care misstion id
		function getOrderHttp() {
			$http.get('/api/orders-with-customer-id?id=' + vm.customers.id)
					.then(function(success) {
						vm.oderCustomers = success;
					});
		}
		;

		function loadAll() {
			if (vm.customers) {
				if (vm.customers.careMissions) {
					vm.careMission = vm.customers.careMissions[0];
					vm.careMissionId = vm.customers.careMissions[0].id;
					vm.careMissionStatus = vm.customers.careMissions[0].careStatusId;
				}

				if (vm.customers.birthday) {
					vm.customers.birthday = new Date(vm.customers.birthday);
				}
				if (vm.careMission.assignedDate) {
					vm.careMission.assignedDate = new Date(
							vm.careMission.assignedDate);
				}
				if (vm.careMission.closedDate) {
					vm.careMission.closedDate = new Date(
							vm.careMission.closedDate);
				}
				if (vm.customers.companyFoundDate) {
					vm.customers.companyFoundDate = new Date(
							vm.customers.companyFoundDate);
				}
			}

			if (vm.careMissionId) {
				getCarePlanHttp();
				getCareHistoryHttp();
				getOrderHttp();
			}

			SettingUtils.get({
				'keys' : [ CARE_MISSION_STATUS, CARE_MISSION_RESULT,
						CUSTOMER_SERVICE_TYPE, CUSTOMER_SOURCE,
						CUSTOMER_GENDER, CARE_TYPE, CARE_PLAN_SEVERITY,
						CARE_HISTORY_CLOSED_STATUS, CUSTOMER_BUSINESS ]
			}, function(success) {
				vm.caretypies = [];
				vm.servicies = [];
				vm.sourcies = [];
				vm.genders = [];
				vm.statuses = [];
				vm.severities = [];
				vm.severitys = [];
				vm.closeStatus = [];
				vm.businies = [];

				angular.forEach(success, function(value, key) {
					if (value.key === CARE_MISSION_STATUS) {
						vm.statuses.push(value);
					} else if (value.key === CARE_MISSION_RESULT) {
						vm.severities.push(value);
					} else if (value.key === CUSTOMER_SERVICE_TYPE) {
						vm.servicies.push(value);
					} else if (value.key === CUSTOMER_SOURCE) {
						vm.sourcies.push(value);
					} else if (value.key === CUSTOMER_GENDER) {
						vm.genders.push(value);
					} else if (value.key === CARE_TYPE) {
						vm.caretypies.push(value);
					} else if (value.key === CARE_PLAN_SEVERITY) {
						vm.severitys.push(value);
					} else if (value.key === CARE_HISTORY_CLOSED_STATUS) {
						vm.closeStatus.push(value);
					} else if (value.key === CUSTOMER_BUSINESS) {
						vm.businies.push(value);
					}
				});
			}, function(error) {
			});
		}
		;

		vm.formatDataForAutocomplete = function(data) {
			var result = [];
			var obj = null;
			angular.forEach(data, function(value, key) {
				obj = {};
				obj.key = value.id;
				obj.val = value.name + ' (' + value.phone + ')';
				obj.content = value;
				result.push(obj);
			});
			return result;
		};

		vm.onType = function(searchStr) {
			var searchPhone = angular.isNumber(+searchStr);
			$http.get(
					'/api/search-customer-either-name-or-phone?searchValue='
							+ searchStr + '&searchPhone=' + searchPhone)
					.success(onSuccess);

			function sort() {
				return [];
			}

			function onSuccess(data, headers) {
				vm.customerListData = vm.formatDataForAutocomplete(data);
			}

			function onError(error) {
			}
		};

		vm.onSelect = function(customer) {
			vm.selectedCustomer = customer;
			vm.customerListData = [];
			vm.customerStr = '';

			vm.customers = customer;
			vm.loadAll();
		};

		vm.getAllCustomerForAutocomplete = function() {
			$http.get('/api/get-all-customer').success(onSuccess);

			function onSuccess(data, headers) {
				vm.customerListData = vm.formatDataForAutocomplete(data);
			}
		};
		vm.changeToInforCareTab = function() {
			$scope.$broadcast('show-errors-reset');
			vm.statusError = false;
		};
		vm.changeToCareReportTab = function() {
			$scope.$broadcast('show-errors-reset');
			vm.statusError = false;
		};
		vm.changeToCarePlanTab = function() {
			$scope.$broadcast('show-errors-reset');
			vm.statusError = false;
		};

		vm.selectCareType = function(careType) {
			vm.careType = careType;
		}

		vm.saveCarePlanEdit = function(index) {
			if (vm.hasAuthority()) {
				CarePlan.update(vm.carePlanForEditInCarePlan[index],
						onSaveSuccess, onSaveError);
				$scope.$broadcast('show-errors-reset');
			}
		}

		vm.saveCarePlan = function(formPlan) {
			$scope.$broadcast('show-errors-check-validity');
			$scope.$broadcast('show-errors-check-validity-html5');
			// angular.element(".html5").addClass('has-error');
			vm.statusError = false;
			var invalid3 = angular.element('.input-valid.ng-invalid');
			var invalid4 = angular.element('wyshtml5.ng-invalid');
			var carePlanMemoEditor = $scope.$root.editors['carePlanMemo'];
			if (formPlan.$invalid) {
				if (invalid4.length !== 0) {
					vm.statusError = true;
					carePlanMemoEditor.focus();
				}
				if (invalid3.length !== 0) {
					invalid3.first().focus();
				}
			} else {
				if (vm.hasAuthority()) {
					vm.carePlan.staffId = vm.account.id; //vm.customers.careMissions[0].staff.id;
					vm.carePlan.careMissionId = vm.customers.careMissions[0].id;
					vm.carePlan.careMission = vm.customers.careMissions[0];
					// vm.carePlan.staff = vm.customers.careMissions[0].staff;
					vm.carePlan.plannedDate = DateFormatService
							.getFormatDateTime(vm.carePlan.plannedDate, null);

					if (vm.careMission.id !== null) {
						CarePlan
								.update(vm.carePlan, onSaveSuccess, onSaveError);

					} else {

						CarePlan.save(vm.carePlan, onSaveSuccess, onSaveError);

					}

				}
				$scope.$broadcast('show-errors-reset');
				$scope.$broadcast('show-errors-reset-html5');
			}
		}
		var onSaveSuccess = function(result) {
			getCarePlanHttp();
			vm.carePlan.plannedDate = null;
			vm.carePlan.careTypeId = null;
			vm.carePlan.severityId = null;
			vm.carePlan.closeStatusId = null;
			vm.statusPlan = true;
			getCarePlanHttp(vm.statusPlan);
			$state.reload();
			$scope.$broadcast('set_null');
		};

		var onSaveError = function() {

		};

		vm.clickFinishCareMission = function() {
			if (vm.isCloseCareMission) {
				vm.careHistory.closeStatusId = 1;
			} else {
				vm.careHistory.closeStatusId = 0;
			}
		};
		vm.saveHistory = function(formCare) {
			angular.element(".rm-error").removeClass('remove-error');
			var invalid1 = angular.element('.detail-input.ng-invalid');
			var invalid2 = angular.element('wyshtml5.ng-invalid');
			var reportedContentEditor = $scope.$root.editors['careHistoryToolbar'];

			vm.statusError = false;
			if (formCare.$invalid) {
				$scope.$broadcast('show-errors-check-validity');
				$scope.$broadcast('show-errors-check-validity-html5');
				if (invalid2.length !== 0) {
					vm.statusError = true;
					reportedContentEditor.focus();
				}
				if (invalid1.length != 0) {
					invalid1.first().focus();
				}
			} else {
				if (vm.hasAuthority()) {

					if (vm.careMission && vm.careMission.id) {
						vm.careHistory.careMissionId = vm.careMission.id;
						vm.careHistory.actionDate = new Date();
						vm.careHistory.staffId = vm.account.id;

						if (vm.isCloseCareMission) {
							vm.careHistory.closeStatusId = 1;
							vm.careMissionStatus = 3;
						}
						CareHistory.save(vm.careHistory, onSaveSuccessHistory,
								onSaveErrorHistory);

					}
					$scope.$broadcast('show-errors-reset');
					angular.element(".rm-error").addClass('remove-error');
					$scope.$broadcast('show-errors-reset-html5');
				}
			}
		};

		vm.saveCareMission = function(saveTitleForm) {

			if (saveTitleForm.$invalid) {
				$scope.$broadcast('show-errors-check-validity');
				var invalidControl = angular.element('input.ng-invalid');
				if (invalidControl.length !== 0) {
					invalidControl.focus();
				}
			} else if (vm.hasAuthority()) {
				if (vm.careMission) {
					if (vm.careMission.id !== null) {
						CustomerCare.update(vm.careMission, function(success) {
							vm.careMission = success;
							if (vm.careMission.assignedDate) {
								vm.careMission.assignedDate = new Date(
										vm.careMission.assignedDate);
							}
							if (vm.careMission.closedDate) {
								vm.careMission.closedDate = new Date(
										vm.careMission.closedDate);
							}
						}, function(error) {

						});
					}
				}
				$scope.$broadcast('show-errors-reset');
			}
		};

		var onSaveSuccessHistory = function(result) {
			vm.careHistory = {};
			vm.careHistory.reportedContent = '';
			if (vm.isCloseCareMission && vm.careMission) {
				vm.isCloseCareMission = false;
				if (vm.careMission.id !== null) {
					vm.careMission.closedCareHistoryId = result.id;
					vm.careMission.closedDate = new Date();
					vm.careMission.careStatusId = 3; //FIXME
					if (result.closeStatusId == 1) {
						vm.careMission.careResultId = 1;
					}
					CustomerCare.update(vm.careMission, function(success) {
						vm.careMission = success;
						if (vm.careMission.assignedDate) {
							vm.careMission.assignedDate = new Date(
									vm.careMission.assignedDate);
						}
						if (vm.careMission.closedDate) {
							vm.careMission.closedDate = new Date(
									vm.careMission.closedDate);
						}

					}, function(error) {

					});
				}
			}

			getCareHistoryHttp();
			vm.carePlan.plannedDate = null;
			vm.carePlan.careTypeId = null;
			vm.carePlan.severityId = null;
			$scope.$broadcast('set_null');

			// vm.careHistory.reportedContent = null;
		};

		vm.clickFinishCareMissionInCarePlan = function(index) {
			if (vm.isCloseCareMissionInPlan
					&& vm.isCloseCareMissionInPlan[index]) {
				vm.careHistoryInCarePlan[index].closeStatusId = 1;
			}
		}

		vm.saveHistoryInCarePlan = function(index) {
			if (vm.hasAuthority()) {
				if (vm.customers && vm.customers.careMissions[0]
						&& vm.customers.careMissions[0].id) {
					vm.careHistoryInCarePlan[index].careMissionId = vm.customers.careMissions[0].id;
					vm.careHistoryInCarePlan[index].actionDate = new Date();
					vm.careHistoryInCarePlan[index].staffId = vm.account.id;
					if (vm.isCloseCareMissionInPlan
							&& vm.isCloseCareMissionInPlan[index]) {
						vm.careHistoryInCarePlan[index].closeStatusId = 1;
					}

					//if report care history in Plan mode has close status is close then care plan is also close
					if (vm.careHistoryInCarePlan[index].closeStatusId == 1) {
						vm.carePlanForEditInCarePlan[index].closeStatusId = 1;
						CarePlan.update(vm.carePlanForEditInCarePlan[index],
								onSaveSuccess, onSaveError);
					}

					CareHistory.save(vm.careHistoryInCarePlan[index], function(
							result) {
						vm.careHistoryInCarePlan[index] = {};
						vm.careHistoryInCarePlan[index].reportedContent = '';

						if (vm.isCloseCareMissionInPlan
								&& vm.isCloseCareMissionInPlan[index]
								&& vm.careMission) {
							if (vm.careMission.id !== null) {
								vm.careMission.closedCareHistoryId = result.id;
								vm.careMission.closedDate = new Date();
								vm.careMission.careStatusId = 3; //FIXME
								if (result.closeStatusId == 1) {
									vm.careMission.careResultId = 1;
								}
								CustomerCare.update(vm.careMission, function(
										success) {
									vm.careMission = success;
									if (vm.careMission.assignedDate) {
										vm.careMission.assignedDate = new Date(
												vm.careMission.assignedDate);
									}
									if (vm.careMission.closedDate) {
										vm.careMission.closedDate = new Date(
												vm.careMission.closedDate);
									}
								}, function(error) {

								});
							}
						}
						getCareHistoryHttp();
					}, onSaveErrorHistory);
				}
			}
		};

		var onSaveErrorHistory = function(error) {

		};

		function getAllCustomer() {
			$http.get('/api/get-all-customer').success(onSuccess);

			function onSuccess(data, headers) {
				vm.customerListData = data;
			}
		}

		function isPhoneNumber(phone) {
			if (phone.length < 10)
				return false;

			var pattern = /^[0-9]*$/;
			var isPhoneNumber = phone.match(pattern);

			if (isPhoneNumber)
				return true;
			return false;
		}

		vm.refreshCustomerByPhone = function(phone) {
			if (!isNotBlank(phone)) {
				vm.customerListData = null;
				return;
			}
			var isPhone = isPhoneNumber(phone);
			return $http({
				url : '/api/search-customer-by-phone',
				method : 'GET',
				params : {
					phone : phone,
					isPhone : isPhone
				}
			}).then(function(response) {
				vm.customerListData = response.data;
			});
		}
		vm.refreshCustomer = function(customer) {
			if (customer.id) {
				if(customer.createdBy === vm.loginName || vm.isCustomerAll){
					$state.go('customer-care.detail', {id : customer.id }, { reload : true });
				}else{
					$state.go('customer-care.detail-with-phone-number', {phoneNum: customer.phone}, { reload : true });
				}
			}
		}

		vm.displaySettingListName = function(settings) {
			var result = '';
			if (settings !== null && settings !== undefined
					&& settings.length > 0) {
				angular.forEach(settings, function(setting, key) {
					result += setting.name;
					if (key != (settings.length - 1)) {
						result += ', ';
					}
				});
			}
			return result;
		};

		function hasAuthority() {
			if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE)) {
				return true;
			} else {
				AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
				return false;
			}
		}
		;
		function isNotBlank(argument) {
			if (argument == null || argument == undefined || argument == "") {
				return false;
			}
			return true;
		}

		vm.showName = function (customer) {
			alert(customer.phone);
		}
	}
}());
