(function() {
        'use strict';

        angular.module('crmApp').controller('StockCreateUpdateController',
            StockCreateUpdateController);

        StockCreateUpdateController.$inject = ['entity', '$scope', 'Stock', '$http','UserUtils','$state','SettingUtils', 'CommonConstants'];

        function StockCreateUpdateController(entity, $scope, Stock, $http, UserUtils, $state, SettingUtils, CommonConstants) {

            var vm = this;
            vm.Stock = entity;
            if(vm.Stock){
                vm.userStockId = vm.Stock.stockkeeperId;
            }
            vm.hasAuthority = hasAuthority;
            vm.loadAll = loadAll();
            vm.userStock = [];
            vm.stockType = [];
            // save Stock
            $scope.$on('place_changed', function(e, place) {
                vm.Stock.addressLong = place.geometry.location.lat();
                vm.Stock.addressLat = place.geometry.location.lng();
            });

            vm.save = function() {
                $scope.$broadcast('show-errors-check-validity');
                if ($scope.stockForm.$invalid) {
                    var invalidControl = angular.element('.form-control.ng-invalid');
                    if (invalidControl != null) {
                        invalidControl.first().focus();
                    }
                } else {
                    if (vm.userStockId) {
                        vm.Stock.stockkeeperId = vm.userStockId;
                    }
                    if (vm.stockTypeId) {
                        vm.Stock.stockType = vm.stockTypeId;
                    }
                    if (vm.hasAuthority()) {
                        vm.Stock.deleted = false;
                        vm.isSave = true;
                        if (vm.Stock.id != undefined) {
                            Stock.update(vm.Stock, onSaveSuccess, onSaveError);
                        } else {
                            vm.change = true;
                            Stock.save(vm.Stock, onSaveSuccess, onSaveError);
                        }
                    }
                }
            }

          function getUserStockHttp() {
            $http.get('/api/users-stock').success(function(success) {
                if(success && success.length > 0){
                  vm.userStock = success;
                }
            });
            if (vm.Stock.stockType) {
                vm.stockTypeId = vm.Stock.stockType;
            }
        };

        function loadAll() {
            var STOCK_TYPE = 'STOCK_TYPE';
            SettingUtils.get({
                'keys': [STOCK_TYPE]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === STOCK_TYPE) {
                        vm.stockType.push(value);
                    }
                });
            }, function(error) {});
            getUserStockHttp();
        }

        function onSaveSuccess() {
            vm.isSaving = false;
             if(vm.change){
                 $state.go('list-stock', null, {
                    reload: true
                });
            }
            //$uibModalInstance.close(true);

        };

        var onSaveError = function() {
            vm.isSaving = false;
        };

        function hasAuthority() {
            if (UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_ADD) || UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_EDIT)) {
                return true;
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                return false;
            }
        }
    }
})();
