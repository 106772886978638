(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('email-sent', {
			parent : 'email',
			url : '/email-sent?page&sort&search',
			data : {
				pageTitle : 'Quản lý email đã gửi'
			},
			views : {
				'content@' : {
					templateUrl : 'app/email/email-sent/email-sent.html',
					controller : 'EmailSentController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search : null
			},
			resolve : {
				pagingParams : [
						'$stateParams',
						'PaginationUtil',
						function($stateParams, PaginationUtil) {
							return {
								page : PaginationUtil
										.parsePage($stateParams.page),
								sort : $stateParams.sort,
								predicate : PaginationUtil
										.parsePredicate($stateParams.sort),
								ascending : PaginationUtil
										.parseAscending($stateParams.sort),
								search : $stateParams.search
							};
						} ]
			}
		})
	}
})();
