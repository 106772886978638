(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('CheckPhone', CheckPhone);

    CheckPhone.$inject = ['StringUtils','$http'];

    function CheckPhone(StringUtils, $http) {
        var vm = this;
        
        vm.phoneNumber = [];
        vm.checkPhoneAll = function() {
             $http.get('/api/users').success(function(success) {
                vm.userPhone = success;
                for (var i = 0; i <= vm.userPhone.length; i++) {
                     if(vm.userPhone[i] !== undefined && vm.userPhone[i].phone !== null){
                        vm.phoneNumber.push(vm.userPhone[i].phone);
                    }
                     
                }
             });
            return vm.phoneNumber;
        }

        vm.isPhoneNumber = function (phone) {
            if(!StringUtils.isNotBlank(phone))
                return false;

            if (phone.length < 10)
                return false;

            var pattern = /^[0-9]*$/;
            var isPhoneNumber = phone.match(pattern);

            if (isPhoneNumber)
                return true;
            return false;
        }

        //end function
    }
}());
