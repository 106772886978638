        (function() {
    'use strict';

    angular
    .module('crmApp')
    .controller('ForgetPasswordController', ForgetPasswordController);

    ForgetPasswordController.$inject = ['$scope', 'Auth'];

    function ForgetPasswordController ($scope, Auth) {
    	var vm = this;
    	vm.forgetPassword = function(){
    		$scope.$broadcast('show-errors-check-validity');
            if($scope.forgetPasswordForm.$invalid){
                angular.element('input.ng-invalid').first().focus();
            }else{
                Auth.resetPasswordInit(vm.email).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    //vm.error = 'ERROR';
                });
            }
        
            
    	}
    }
})();
