(function() {
    'use strict';

    angular.module('crmApp').controller('StockMultiDeleteController',
        StockMultiDeleteController);

    StockMultiDeleteController.$inject = ['$rootScope', '$resource', '$state', '$uibModalInstance', 'UserUtils',
        'AlertService', 'CommonConstants', 'entity', 'Stock', '$http'
    ];

    function StockMultiDeleteController($rootScope, $resource, $state, $uibModalInstance, UserUtils,
        AlertService, CommonConstants, entity, Stock, $http) {
        var vm = this;

        vm.clear = clear;
        vm.idCheckeds = entity;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        $rootScope.$on('close-modal', function(){
            clear();
        });
        vm.confirmDelete = function() {
            if (vm.idCheckeds != null) {
                if (UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_DELETE)) {
                  var StockMultiDelete = $resource('api/stock-multi-delete');
                  StockMultiDelete.delete({ids: vm.idCheckeds}, onSuccess, onError);


                    // Stock.delete({
                    //     id: vm.idCheckeds
                    // }, onSuccess, onError);
                } else {
                    AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                    $uibModalInstance.dismiss('cancel');
                }
            }
        };

        function onSuccess() {
            $state.reload();
            $uibModalInstance.close(true);
        }

        function onError() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
