(function() {
    'use strict';
    var Autocomplete = {
        templateUrl: 'app/template/autocomplete.html',
        controller: AutocompleteController,
        bindings: {
            searchParam: '=ngModel',
            autocompletePlaceholder: '@',
            onType: '&',
            clickCaretDownButton: '&',
            onSelect: '&',
            data: '<',
            minLength: '<',
            nameAutocomplete: '@'
        }
    };

    AutocompleteController.inject = ['$scope', '$timeout', '$element'];

    function AutocompleteController($scope, $timeout, $element) {
        var $ctrl = this;
        // the index of the suggestions that's currently selected
        $ctrl.selectedIndex = 0;
        $ctrl.isProcessing = false;
        $ctrl.isSelect = false;

        // selecting a suggestion with RIGHT ARROW or ENTER
        $ctrl.select = function(suggestion) {
            $ctrl.isSelect = true;
            $ctrl.isClickCaretDown = false;
            if (suggestion) {
                if ($ctrl.onSelect) {
                    $ctrl.onSelect({
                        selectedObj: suggestion
                    });
                }
            }
            // $ctrl.searchParam = '';
            $ctrl.isProcessing = false;
            $ctrl.selectedIndex = -1;
            $ctrl.data = [];
        };

        $ctrl.clickCaretDownButtonInDirective = function() {
            // $ctrl.isClickCaretDown = true;
            if ($ctrl.clickCaretDownButton) {
                $ctrl.clickCaretDownButton();
            }
        };

        $scope.$watch('$ctrl.searchParam', function(newValue, oldValue) {
            if (oldValue === newValue /* || (!oldValue && $ctrl.initLock)*/ ) {
                return;
            }

            // function thats passed to on-type attribute gets executed
            if (!$ctrl.isSelect && $ctrl.onType && $ctrl.searchParam && $ctrl.searchParam.length >= $ctrl.minLength) {
                $ctrl.isProcessing = true;
                //$timeout(function(){
                $ctrl.onType({
                    searchStr: $ctrl.searchParam
                });
                // }, 500);
            } else {
                $ctrl.data = [];
            }
            $ctrl.isSelect = false;
        });

        //$postLink
        $ctrl.$postLink = function() {
            var ulElement = $element.find('ul');
            // $(window).click(function() {
            // $ctrl.isClickCaretDown = false;
            // });
            // ulElement.on('click', function(event) {
            //   $ctrl.isClickCaretDown = true;
            //   event.stopPropagation();
            // });
            // $element.find('span').on('click', function(event) {
            //   $ctrl.isClickCaretDown = true;
            //   event.stopPropagation();
            // });

            var key = {
                left: 37,
                up: 38,
                right: 39,
                down: 40,
                enter: 13,
                esc: 27,
                tab: 9
            };
            var index = -1;

            $element.on('keydown', function(e) {
                var keycode = e.keyCode || e.which;
                var l = $element.find('ul li').length;
                // this allows submitting forms by pressing Enter in the autocompleted field
                if (l == 0) return;

                switch (keycode) {
                    case key.up:
                        index = $ctrl.selectedIndex - 1;
                        if (index < -1) {
                            index = l - 1;
                        } else if (index >= l) {
                            index = -1;
                            $ctrl.selectedIndex = index;
                            break;
                        }
                        $ctrl.selectedIndex = index;
                        $scope.$digest();
                        break;
                    case key.down:

                        index = $ctrl.selectedIndex + 1;
                        if (index < -1) {
                            index = l - 1;
                        } else if (index >= l) {
                            index = -1;
                            $ctrl.selectedIndex = index;
                            $scope.$digest();
                            break;
                        }
                        $ctrl.selectedIndex = index;
                        $scope.$digest();
                        break;
                    case key.left:
                        break;
                    case key.right:
                    case key.enter:
                    case key.tab:
                        index = $ctrl.selectedIndex;
                        // scope.preSelectOff();
                        if (index !== -1) {
                            $ctrl.select($($element.find('ul li')[index]).attr('objectData'));
                            if (keycode == key.enter) {
                                e.preventDefault();
                            }
                        } else {
                            if (keycode == key.enter) {
                                $ctrl.select();
                            }
                        }

                        $ctrl.isProcessing = false;
                        $ctrl.selectedIndex = -1;

                        $scope.$digest();
                        break;
                    case key.esc:
                        break;
                    default:
                        return;
                }
            });
        };
    };
    angular.module('crmApp').component('autocomplete', Autocomplete);
}());
