(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('GHTKService', GHTKService);

        GHTKService.$inject = ['$http'];
        function GHTKService($http){
        	var vm = this;

        	vm.cancel = function (order) {
        		if(order.ghtkId){
        			$http({
	        			url: "/api/ghtk-ship-order-cancel-by-ghtk-id",
	        			method: "POST",
	        			params: {
	        				ghtkId: order.ghtkId
	        			}
	        		}).success(function (response) {
	        		}).error(function (response) {
	        		});	
        		}
        	}
        }

 }());