(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state(
			'admin-email-configuration',
			{
				parent : 'admin',
				url : '/admin-email',
				data : {
					authorities : [ 'ADMIN' ],
					pageTitle : 'Cấu hình email'
				},
				views : {
					'content@' : {
						templateUrl : 'app/admin/admin-email-configuration/admin-email-configuration.html',
						controller : 'AdminEmailConfigurationController',
						controllerAs : 'vm'
					}
				},
				resolve : {
					entity: ['AdminEmailConfiguration', function(AdminEmailConfiguration) {
						return AdminEmailConfiguration.get();
					}]
				}
			})
	}
})();
