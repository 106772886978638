(function() {
	'use strict';

	angular.module('crmApp').controller('UserManagementController',
			UserManagementController);

	UserManagementController.$inject = ['AlertService','$uibModal', '$state', 'Principal', 'User',
			'ParseLinks', 'paginationConstants', 'pagingParams', '$scope',
			'SettingUtils' ];

	function UserManagementController(AlertService, $uibModal, $state, Principal, User, ParseLinks,
			paginationConstants, pagingParams, $scope, SettingUtils) {
		var vm = this;

		vm.loadAll = loadAll;
		vm.predicate = pagingParams.predicate;
		vm.loadPage = loadPage;
		vm.reverse = false;
		vm.transition = transition;
		vm.currentAccount = null;
		vm.languages = null;
		vm.links = null;
		vm.searchCriteriaHandler = searchCriteriaHandler;
		vm.clear = clear;
		vm.entries = '10';
		vm.currentSearch = null;
		vm.currentCheck;
		vm.page = 1;
		vm.setActive = setActive;
		vm.totalItems = null;
		vm.users = [];
		vm.loginCheckeds = null;

		var STAFF_LEVEL = 'STAFF_LEVEL';
		vm.staff_levels = [];

		vm.loadAll();
		vm.isNotSelected = true;
		vm.checkAll = false;

		vm.showName = "nhân viên";

		Principal.identity().then(function(account) {
			vm.currentAccount = account;
		});
		var j;
		vm.checkAll = function() {
			var i = 0;
			if (vm.selectedAll) {
				vm.selectedAll = true;
				vm.isNotSelected = false;
			} else {
				vm.selectedAll = false;
				vm.isNotSelected = true;
			}
			angular.forEach(vm.users, function(user) {
				i++;
				if (i <= vm.entries)
					user.selected = vm.selectedAll;
			});
			vm.checkUser();
		};

		vm.deleteUser = function(id){
			vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                controller: 'UserManagementDeleteController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve : {
					entity : function() {
						return id;
					}
				}

            });
		}
		vm.refresh = function() {
			// body...
			$state.go('user-management', null, {
				reload : true
			});
		}
		vm.checkUser = function() {
			vm.loginCheckeds = [];
			angular.forEach(vm.users, function(user) {
				if (!!user.selected) {
					vm.loginCheckeds.push(user.id);
				}
			})
			if (vm.loginCheckeds.length === 0) {
				vm.isNotSelected = true;
			} else {
				vm.isNotSelected = false;
			}

		}
		vm.displayRole = function (roles) {
			var result = '';
			if(roles !== null && roles !== undefined
					&& roles.length > 0){
				angular.forEach(roles, function(role, key) {
					result += role.name;
					if (key != (roles.length - 1)) {
						result += ', ';
					}
				});

			}
			return result;
		}

		// vm.showEntries = function() {
		// 	vm.loadAll();
		// }

		SettingUtils.get({
			'keys' : [ STAFF_LEVEL ]
		}, function(success) {
			angular.forEach(success, function(value, key) {
				if (value.key === STAFF_LEVEL) {
					vm.staff_levels.push(value);
				}
			});
			// angular.element('#levelId').selectpicker('refresh');
		}, function(error) {
		});

		function loadAll() {
			vm.loadPage();
		}

		function loadPage(isSearchWithConditionBefore) {
			if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                User.query(vm.currentSearch, onSuccess, onError);
            } else{
            	if (!vm.UserCO) {
					vm.UserCO = {};
				}
				vm.UserCO.page = vm.page - 1;
				vm.UserCO.size = vm.entries;
				vm.UserCO.sort = sort();
				vm.currentSearch = angular.copy(vm.UserCO);
				vm.showItem = vm.entries;
				User.query(vm.currentSearch, onSuccess, onError);
            }

			if (!vm.UserCO) {
				vm.UserCO = {};
			}

			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}

			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.users = data;

			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		// select item

		function setActive(user, isActivated) {
			user.activated = isActivated;
			User.update(user, function() {
				vm.loadAll();
				vm.clear();
			});
		}
		vm.showItem = 10;

		function transition() {
			if (vm.currentSearch) {
				vm.loadPage(true);
			}
		}

		function searchCriteriaHandler() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.searchForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first()
						|| angular.element('select.ng-invalid').first()
						|| angular.element('option.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else {
				vm.page = 1;
				vm.predicate = 'id';
				vm.reverse = false;
				vm.loadAll();
			}
		}
		;

		function clear() {
			vm.user = {
				id : null,
				login : null,
				name : null,
				email : null,
				activated : null,
				langKey : null,
				createdBy : null,
				createdDate : null,
				lastModifiedBy : null,
				lastModifiedDate : null,
				resetDate : null,
				resetKey : null,
				authorities : null,
				phone : null,
				phone2 : null,
				birthdate : null,
				sex : null,
				address : null,
				addressLong : null,
				addressLat : null,
				memo : null
			};
			vm.editForm.$setPristine();
			vm.editForm.$setUntouched();
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			// vm.searchCriteria = null;
			vm.loadAll();
		}
	}
})();
