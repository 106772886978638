(function() {
    'use strict';

    angular.module('crmApp').controller('StockTransferProductController',
        StockTransferProductController);

    StockTransferProductController.$inject = ['$uibModal', '$scope', '$state', '$resource', 'ProductWarehouse', 'AlertService', 'ParseLinks', '$http', 'entity', '$uibModalInstance', 'WarehousesId', 'pagingParams', 'Principal'];

    function StockTransferProductController($uibModal, $scope, $state, $resource, ProductWarehouse, AlertService, ParseLinks, $http, entity, $uibModalInstance, WarehousesId, pagingParams, Principal) {
        var vm = this;
        vm.reverse = false;
        vm.transition = transition;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "sản phẩm";
        vm.showItem = 10;
        vm.totalItems = null;
        vm.stocksSelect = [];
        vm.search = search;
        vm.currentSearch = {};
        vm.loadPage = loadPage;
        vm.loadAll = loadAll;
        vm.ProductWarehouseDetail = [];
        vm.idCheckeds = [];
        vm.changePage = changePage;
        vm.hasAnyAuthority = Principal.hasAnyAuthority;

        function loadAll() {
            $http.get('/api/get-all-stocks').then(function(success) {
                vm.stocks = success.data;
                angular.forEach(vm.stocks, function(stock) {
                    if (stock.id !== WarehousesId) {
                        vm.stocksSelect.push(stock);
                    }
                })
            }).$promise;
            loadPage(false);
        }
        vm.loadAll();
        function getPage(){
            if(vm.page > 1){
                vm.page = vm.page  - 1;
            }else{
                vm.page = vm.page  + 1;
            }
        }

        vm.displaySendWattingConfirmTransferProduct = function(){
          return !vm.hasAnyAuthority(['WAREHOUSE_GOODS_ISSUE']) && vm.hasAnyAuthority(['WAREHOUSE_GOODS_ISSUE_SEND_REQUEST']);
        };

        // transfer
        vm.messageZero = "";
        vm.transfer = function(isAddProductWhenChangePage) {
            // body...
            $scope.$broadcast('show-errors-check-validity');
            if($scope.stockForm.$invalid){
                var invalidControl = angular.element('.form-control.ng-invalid');
                    if (invalidControl != null) {
                        invalidControl.first().focus();
                    }
                if(vm.change){
                    getPage();
                }
            }else{
                vm.messageZero = "";
                vm.productWarehousesToSave = [];
                //code add
                var check = true;
                angular.forEach(vm.ProductWarehouseDetail, function(ProductWarehouse) {
                    if (!!ProductWarehouse.selected) {
                        vm.productWarehousesToSave.push(ProductWarehouse);
                        if(Number(ProductWarehouse.transferQuantity) > Number(ProductWarehouse.quantity) || Number(ProductWarehouse.transferQuantity) === 0){
                            vm.messageZero = "Số lượng điều chuyển không được lớn hơn số lượng tồn kho hoặc bằng không.";
                            check = false;
                            return false;
                        }
                    }
                });

                if (vm.productWarehousesToSave.length > 0 && check) {
                    vm.pageStatus = false;
                    var TransferProductResource = null;
                    if(vm.displaySendWattingConfirmTransferProduct()){
                      TransferProductResource = $resource('api/accountant-send-waitting-confirm-transfer-product');
                    } else{
                      TransferProductResource = $resource('api/stock-transfer-product');
                    }
                    if(isAddProductWhenChangePage){
                    //Add product when press Confirm Save when change page
                          TransferProductResource.save(vm.getProductWarehousesForSave(), function success(success){
                            vm.transition();
                          }, function error(error){
                            AlertService.error(error.data.message);
                          });
                    } else {
                        //Add product when press Add on popup
                          TransferProductResource.save(vm.getProductWarehousesForSave(), function success(success){
                            $uibModalInstance.dismiss('cancel');
                          }, function error(error){
                            AlertService.error(error.data.message);
                          });
                    }
                } else if(!check){
                    vm.messageZero = "Số lượng điều chuyển không được lớn hơn số lượng tồn kho hoặc bằng không.";
                     vm.pageStatus = true;
                }else{
                    vm.messageZero = "Vui lòng chọn sản phẩm điều chuyển!";
                }

            }

        }

        vm.getProductWarehousesForSave = function() {
            var productTransfers = [];
            var productTransfer = null;
            angular.forEach(vm.idCheckeds, function(id) {
              angular.forEach(vm.ProductWarehouseDetail, function(productWarehouseDetail) {
                  if (productWarehouseDetail.id == id) {
                      productTransfer = {};
                      productTransfer.warehouseFromId = WarehousesId;
                      productTransfer.warehouseToId = vm.warehouseId;
                      productTransfer.productId = id;
                      productTransfer.quantity = productWarehouseDetail.transferQuantity;
                      productTransfers.push(productTransfer);
                      return false;
                  }
              });
          });

            return productTransfers;
        };
        vm.change = false;
        function changePage() {
            vm.change = true;
            if (vm.currentSearch && vm.currentSearch.page != vm.page - 1) {
                var productWarehousesForSave = vm.getProductWarehousesForSave();
                if (productWarehousesForSave.length > 0) {
                    vm.modalInstanceProduct = $uibModal.open({
                        template: '<popup-component title="vm.title" content="vm.content" save-change="vm.saveWhenPageChange()" dont-save-change="vm.dontSaveAndPageChange()" close="vm.closePopup()"></popup-component>',
                        size: 'md',
                        scope: $scope,
                        controller: function() {
                            vm.title = "THÔNG BÁO CHUYỂN TRANG";
                            vm.content = "Bạn có muốn lưu trước khi chuyển trang không";
                            vm.closePopup = function() {
                                //FIXME check truong hop nhay nhieu page
                                vm.modalInstanceProduct.dismiss('cancel');
                                getPage();
                            };
                            vm.saveWhenPageChange = function() {
                                //save and change page
                                vm.transfer(true);
                                if(vm.pageStatus){
                                    getPage();
                                }
                                vm.modalInstanceProduct.dismiss('cancel');
                            };
                            vm.dontSaveAndPageChange = function(){
                                vm.modalInstanceProduct.dismiss('cancel');
                                vm.transition();
                            };
                        }
                    });
                } else {
                    vm.transition();
                }
            } else {
                vm.transition();
            }
        }
        // load Page
        function loadPage(isSearchWithConditionBefore) {
            vm.ProductWarehouseDetail = [];
            if (!vm.productWarehouseCO) {
                vm.productWarehouseCO = {};
            }
            if (isSearchWithConditionBefore) {
                //search with condition before
                vm.productWarehouseCO.warehouseId = WarehousesId;
                // vm.productWarehouseCO.productCode = null;
                getQuery();
            } else {
                if (WarehousesId) {
                    vm.productWarehouseCO.warehouseId = WarehousesId;
                    getQuery();
                }
            }
        };

        function getQuery() {
            vm.productWarehouseCO.page = vm.page - 1;
            vm.productWarehouseCO.size = vm.entries;
            vm.productWarehouseCO.sort = sort();
            vm.showItem = vm.entries;
            ProductWarehouse.query(vm.productWarehouseCO, onSuccess, onError);
        }

        function transition() {
            vm.messageZero = "";
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id,desc');
            }
            return result;
        }


        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.productWarehouses = data;

            vm.productWarehousesClone = angular.copy(vm.productWarehouses);
            angular.forEach(vm.productWarehouses, function(value, key) {
                vm.ProductWarehouseDetail.push({
                    id: value.product.id,
                    productCode: value.product.productCode,
                    name: value.product.productName,
                    quantity: value.quantity,
                    decimalQuantity: value.product.decimalQuantity,
                    hasOrdered: 0,
                    approved: 0,
                    sold: 0,
                    transferQuantity: 0
                });
            });
            getProductWareHouse();
        }

        function getProductWareHouse() {
            angular.forEach(vm.ProductWarehouseDetail, function(value, key) {
                angular.forEach(vm.orderQuantity, function(value1, key1) {
                    if ((value1.productId === value.id && value1.status === 1) || (value1.productId === value.id && value1.status === 2)) {
                        vm.ProductWarehouseDetail[key].hasOrdered += value1.quantity;
                        vm.ProductWarehouseDetail[key].approved += value1.quantity;
                    }
                    if ((value1.productId === value.id && value1.status === 3) || (value1.productId === value.id && value1.status === 4) || (value1.productId === value.id && value1.status === 5)) {
                        vm.ProductWarehouseDetail[key].approved += value1.quantity;
                    }
                    if (value1.productId === value.id && value1.status === 6) {
                        vm.ProductWarehouseDetail[key].sold += value1.quantity;
                    }
                });
            });

        };

        function search() {
            if (!vm.productWarehouseCO) {
                vm.productWarehouseCO = {};
            }
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.loadPage(true);
        };

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.clear = function() {
            $state.go('stock.watch', {id: WarehousesId}, {
                reload: true
            });
            $uibModalInstance.dismiss('cancel');
        }
        //
        vm.isNotSelected = true;
        vm.checkProduct = function() {
            vm.idCheckeds = [];
            angular.forEach(vm.ProductWarehouseDetail, function(ProductWarehouse) {
                if (!!ProductWarehouse.selected) {
                    vm.idCheckeds.push(ProductWarehouse.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.isSelectedAll = false;
            } else if (vm.idCheckeds.length === vm.ProductWarehouseDetail.length) {
                vm.isSelectedAll = true;
            } else {
                vm.isNotSelected = false;
                vm.isSelectedAll = false;
            }
        }

        vm.selectedAll = function() {
            var i = 0;
            if (vm.isSelectedAll) {
                vm.isSelectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.isSelectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.ProductWarehouseDetail, function(ProductWarehouse) {
                i++;
                if (i <= vm.entries)
                    ProductWarehouse.selected = vm.isSelectedAll;
            });
            vm.checkProduct();
        }
    } // end controller
})();
