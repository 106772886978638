(function() {
	'use strict';

	angular.module('crmApp').controller('ProductDeleteController',
			ProductDeleteController);

	ProductDeleteController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'UserUtils',
			'AlertService', 'CommonConstants', 'entity', 'Product', '$http' ];

	function ProductDeleteController($rootScope, $state, $uibModalInstance, UserUtils,
			AlertService, CommonConstants, entity, Product, $http) {
		var vm = this;

		vm.clear = clear;
		vm.idCheckeds = entity;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		$rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
		vm.confirmDelete = function() {
			if (vm.idCheckeds != "") {
				if ( UserUtils.hasAuthorityName(CommonConstants.PRODUCT_DELETE)) {
					$http.get('/api/products-delete?id=' + vm.idCheckeds)
							.success(function(success) {
								$state.reload();
								$uibModalInstance.close(true);
							});
				} else {
					AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
					$uibModalInstance.dismiss('cancel');
				}
			}
		};
	}
})();
