(function() {
    'use strict';

    angular
    .module('crmApp')
    .config(stateAbsConfig)
    .config(stateConfig);

    stateAbsConfig.$inject = ['$stateProvider'];

    function stateAbsConfig($stateProvider) {
        $stateProvider.state('customer-potential', {
            url: '/customer-potential',
            abstract: true,
            parent: 'app'
        });
    }

    stateConfig.$inject = ['$stateProvider', 'CommonConstants'];

    function stateConfig($stateProvider, CommonConstants) {
      $stateProvider
      .state('customer-potential.list', {
          parent: 'customer-potential',
          url: '/list?page&sort&search',
          data: {
              authorities: [CommonConstants.CUSTOMER_VIEW],
              pageTitle: 'Danh sách khách hàng tiềm năng'
          },
          views: {
              'content@': {
                  templateUrl: 'app/customers-potential/customers-potential.html',
                  controller: 'CustomerPotentialController',
                  controllerAs: 'vm'
              }
          },
          params: {
              page: {
                  value: '1',
                  squash: true
              },
              sort: {
                  value: 'id,asc',
                  squash: true
              },
              search: null
          },
          resolve: {
              pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                  return {
                      page: PaginationUtil.parsePage($stateParams.page),
                      sort: $stateParams.sort,
                      predicate: PaginationUtil.parsePredicate($stateParams.sort),
                      ascending: PaginationUtil.parseAscending($stateParams.sort),
                      search: $stateParams.search
                  };
              }],
          }
      })
    }

})();
