(function() {
    'use strict';

    angular.module('crmApp').controller(
        'CustomerCareUpdatePlanDialogController',
        CustomerCareUpdatePlanDialogController);

    CustomerCareUpdatePlanDialogController.inject = ['$scope', 'UserUtils',
        'CommonConstants', 'AlertService', 'SettingUtils',
        'DateFormatService', '$uibModalInstance', 'CarePlan', '$http',
        'idCarePlan', '$rootScope'
    ];

    function CustomerCareUpdatePlanDialogController($scope, UserUtils,
        CommonConstants, AlertService, SettingUtils, DateFormatService,
        $uibModalInstance, CarePlan, $http, idCarePlan, $rootScope) {
        var vm = this;
        vm.hasAuthority = hasAuthority;
        vm.entity = idCarePlan;
        vm.careMissionStatus = null;
        var CARE_PLAN_SEVERITY = "CARE_PLAN_SEVERITY";
        var CARE_TYPE = "CARE_TYPE";
        var CARE_PLAN_CLOSED_STATUS = "CARE_PLAN_CLOSED_STATUS";
        vm.dateFormat = DateFormatService.getDateFormat();
        vm.dateOptions = DateFormatService.getDateOptions();

        // open datepicker
        // open datepicker
        vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

        vm.severitys = [];
        vm.caretypies = [];
        vm.statuses = [];
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function getCareHistoryHttp() {
            $http
                .get('/api/care-plan/' + vm.entity)
                .success(
                    function(success) {
                        vm.carePlanForEditInCarePlan = success;
                        if (vm.carePlanForEditInCarePlan.careMission.careStatusId === 3) {
                            vm.careMissionStatus = 3
                        }
                        if (vm.carePlanForEditInCarePlan.closeStatusId == null) {
                            vm.carePlanForEditInCarePlan.closeStatusId = 0;
                        }
                        vm.carePlanForEditInCarePlan.plannedDate = new Date(
                            vm.carePlanForEditInCarePlan.plannedDate)
                    });
        };
        vm.status = false;
        vm.saveCarePlanEdit = function(index, updatePlan) {
            $scope.$broadcast('show-errors-check-validity');
            if (updatePlan.$invalid) {
                vm.status = true;
                var invalidControl = angular.element('input.ng-invalid')
                    .first();
                if (invalidControl.length != 0) {
                    invalidControl.focus();
                }
            } else {
                if (vm.hasAuthority()) {
                    CarePlan.update(vm.carePlanForEditInCarePlan,
                        onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;

            $uibModalInstance.close(true);

        };
        $rootScope.$on('close-modal', function(){
             $uibModalInstance.close(true);
        });
        var onSaveError = function() {
            vm.isSaving = false;
        };

        getCareHistoryHttp();

        SettingUtils.get({
            'keys': [CARE_PLAN_SEVERITY, CARE_TYPE, CARE_PLAN_CLOSED_STATUS]
        }, function(success) {
            vm.closeStatus = [];

            angular.forEach(success, function(value, key) {
                if (value.key === CARE_TYPE) {
                    vm.caretypies.push(value);
                } else if (value.key === CARE_PLAN_SEVERITY) {
                    vm.severitys.push(value);
                } else if (value.key === CARE_PLAN_CLOSED_STATUS) {
                    vm.statuses.push(value);
                }

            });
        }, function(error) {

        });

        function hasAuthority() {
            if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE)) {
                return true;
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                return false;
            }
        };
    };
}());
