(function() {
	'use strict';

	angular.module('crmApp')
	.controller('CustomerCareDeleteDialogController', CustomerCareDeleteDialogController);

	CustomerCareDeleteDialogController.inject = ['UserUtils', 'CommonConstants', 'AlertService', '$uibModalInstance', 'CarePlan', 'carePlanId'];

	function CustomerCareDeleteDialogController(UserUtils, CommonConstants, AlertService, $uibModalInstance, CarePlan, carePlanId) {
		var vm = this;

		vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

		vm.delete = function() {
			if(UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE)){
				CarePlan.delete({
					id: carePlanId
				},
				function() {
					$uibModalInstance.close(true);
				});
			} else {
				AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
				$uibModalInstance.dismiss('cancel');
			}
		};
	};
}());
