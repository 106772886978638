(function() {
	'use strict';

	angular.module('crmApp').controller('StockHistoryController',
			StockHistoryController);

	StockHistoryController.$inject = ['DateFormatService', 'pagingParams'];

	function StockHistoryController(DateFormatService, pagingParams) {

		var vm = this;
		vm.loadAll = loadAll;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "lịch sử";
        vm.showItem = 10;
        vm.totalItems = null;

		// date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();
		}
		vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

        vm.datePickerOpenStatus = function() {
            vm.isOrderDateOpened = true;
        }
		// load page
		function loadPage() {

		}
		// load all
		function loadAll() {
			vm.loadPage();
			configDateTime();
		}

		function transition() {
        }
	}
})();