(function() {
    'use strict';

    angular.module('crmApp').controller('ListStockController',
        ListStockController);

    ListStockController.$inject = ['$state', '$scope', '$uibModal', 'CommonConstants', 'pagingParams', 'UserUtils', 'Stock', 'AlertService', 'ParseLinks', '$http'];

    function ListStockController($state, $scope, $uibModal, CommonConstants, pagingParams, UserUtils, Stock, AlertService, ParseLinks, $http) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.loadAll = loadAll;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.clear = clear;
        vm.page = 1;
        vm.searchWarehouseHandler = searchWarehouseHandler;
        vm.idCheckeds = null;
        vm.entries = '10';
        vm.showItem = 10;
        vm.isNotSelected = true;
        vm.selectedAll = false;
        vm.currentSearch = null;
        vm.totalItems = null;
        vm.showName = "kho";
        vm.userStock = [];
        // function delete stock
        vm.deleteStock = function(WarehouseId) {
            vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/stock/stock-delete-dialog.html',
                controller: 'StockDeleteController',
                controllerAs: 'vm',
                size: 'md',
                 backdrop: 'static',
                resolve: {
                    entity: function() {
                        return WarehouseId;
                    }
                }
            });
        };

        vm.deleteMultiStock = function() {
						var warehouseIds = [];
						angular.forEach(vm.Warehouses, function(Warehouses) {
								if (Warehouses.selected) {
										warehouseIds.push(Warehouses.id);
								}
						})


						if(warehouseIds.length > 0) {
							vm.modalInstanceObject = $uibModal.open({
							    templateUrl: 'app/stock/stock-multi-delete-dialog.html',
							    controller: 'StockMultiDeleteController',
							    controllerAs: 'vm',
                                 backdrop: 'static',
							    size: 'md',
									resolve: {
											entity: function() {
												return warehouseIds;
											}
	                }
							});
						}
        };

        // load page
        function loadPage(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                Stock.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.warehouseCO) {
                    vm.warehouseCO = {};
                }
                vm.warehouseCO.page = vm.page - 1;
                vm.warehouseCO.size = vm.entries;
                vm.warehouseCO.sort = sort();
                vm.currentSearch = angular.copy(vm.warehouseCO);
                vm.showItem = vm.entries;
                Stock.query(vm.currentSearch, onSuccess, onError);
            }
        }

        function loadAll() {
            getUserStockHttp();
            vm.loadPage();
        }
        vm.loadAll();
        // sort
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        //
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.Warehouses = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
        // transition
        function transition() {
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function clear() {

        }
        // get user stock
        function getUserStockHttp() {
            $http.get('/api/users-stock').success(function(success) {
                if (success && success.length > 0) {
                    vm.userStock = success;
                    // angular.element('#stockkeeper').selectpicker('refresh');
                }
            });
        };
        // search
        function searchWarehouseHandler() {
            if ($scope.stockSearchForm.$invalid) {
                var invalidControl = angular.element('.form-control.ng-invalid');
                if (invalidControl != null) {
                    invalidControl.first().focus();
                }
            } else {
                vm.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                vm.loadPage();
            }
        }
        vm.checkAll = function() {
            var i = 0;
            if (vm.selectedAll) {
                vm.selectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.selectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.Warehouses, function(Warehouses) {
                i++;
                if (i <= vm.entries)
                    Warehouses.selected = vm.selectedAll;
            });
            vm.checkWarehouse();
        }
        vm.checkWarehouse = function() {
            vm.idCheckeds = [];

            angular.forEach(vm.Warehouses, function(Warehouses) {
                if (!!Warehouses.selected) {
                    vm.idCheckeds.push(Warehouses.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.selectedAll = false;
            } else if (vm.idCheckeds.length === vm.Warehouses.length) {
                vm.selectedAll = true;
            } else {
                vm.isNotSelected = false;
                vm.selectedAll = false;
            }
        }
        // Status Stock
        vm.hasAuthorityWarehouseViewGoodsReceipt = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_VIEW_GOODS_RECEIPT);
        }
        vm.hasAuthorityWarehouseDelete = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_DELETE);
        }
        vm.hasAuthorityWarehouseViewGoodsIssue = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_VIEW_GOODS_ISSUE);
        }
        vm.hasAuthorityWarehouseAdd = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_ADD);
        }
        vm.hasAuthorityWarehouseGoodsReceipt = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_GOODS_RECEIPT);
        }
        vm.hasAuthorityWarehouseGoodsIssue = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_GOODS_ISSUE);
        }
        vm.hasAuthorityWarehouseEdit = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_EDIT);
        }
        vm.hasAuthorityWarehouseView = function() {
            return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_VIEW);
        }

        // vm.refresh
        vm.refresh = function() {
            $state.go('list-stock', null, {
                reload: true
            });
        }
    }
})();
