(function() {
	'use strict';
	angular.module('crmApp').controller('UserManagementPermissionControler',
			UserManagementPermissionControler);

	UserManagementPermissionControler.$inject = [ 'User', 'entity',
			'authority', 'role', 'AlertService', '$state' ];

	function UserManagementPermissionControler(User, entity, authority, role,
			AlertService, $state) {
		var vm = this;
		vm.user = entity;
		vm.roles = role;
		vm.authorities = authority;

		vm.savePermission = savePermission;
		vm.loadAll = loadAll;
		vm.changeRoleTemplateHandler = changeRoleTemplateHandler;
		vm.selectedName = '';
		vm.isSaving = false;
		vm.permissionCheckboxChangeHandler = permissionCheckboxChangeHandler;
		vm.loadAll();

		function loadAll() {
			angular.forEach(vm.authorities, function(authority, key) {
				authority.selected = false;
				angular.forEach(vm.user.auths, function(authority1, key1) {
					if (authority.id === authority1.id) {
						authority.selected = true;
					}
				});
			});
		}
		;

		function changeRoleTemplateHandler() {

			if (vm.selectedName != null) {
				vm.isSaving = false;
				vm.authorities = angular.copy(authority);
				angular.forEach(vm.authorities, function(authority, key) {
					authority.selected = false;
				});
				angular.forEach(vm.authorities, function(authority, key) {
					angular.forEach(vm.selectedName.authorities, function(
							authority1, key1) {
						if (authority.id === authority1.id) {
							authority.selected = true;
						}
					});

				});
			} else {
				vm.isSaving = false;
				vm.authorities = angular.copy(authority);
				angular.forEach(vm.authorities, function(authority, key) {

					authority.selected = false;
					angular.forEach(vm.user.auths, function(authority1, key1) {
						if (authority.id === authority1.id) {
							authority.selected = true;
						}
					});
				});
			}
		}
		;

		function permissionCheckboxChangeHandler() {

			vm.isSaving = true;
			angular.forEach(vm.authorities, function(authority, key) {
				if (authority.selected) {
					vm.isSaving = false;
				}
			});
		}
		;

		function onSaveSuccess(result) {
			vm.isSaving = false;
			//$uibModalInstance.close(result);
			$state.go('user-management', null, {
				reload : true
			});
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function savePermission() {
			vm.user.auths = [];

			var auth = null;
			angular.forEach(vm.authorities, function(authority, key) {
				if (authority.selected) {
					auth = angular.copy(authority);
					delete auth.selected;
					vm.user.auths.push(auth);
				}
			});

			vm.isSaving = true;
			if (vm.user.id !== null) {
				User.update(vm.user, onSaveSuccess, onSaveError);
			} else {
				vm.user.langKey = 'en';
				User.save(vm.user, onSaveSuccess, onSaveError);
			}
		}
		;
	}
	;
}());
