(function() {
	'use strict';

	angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

	stateAbsConfig.$inject = [ '$stateProvider' ];

	function stateAbsConfig($stateProvider) {
		$stateProvider.state('product', {
			url : '/product',
			parent : 'app'
		});
	}

	stateConfig.inject = [ '$stateProvider', 'CommonConstants' ];

	function stateConfig($stateProvider, CommonConstants) {
		$stateProvider
				.state(
						'list-product',
						{
							parent : 'product',
							url : '/list-product',
							data : {
								authorities : [],
								pageTitle : 'Danh sách sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/product/product.html',
									controller : 'ListProductController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'product.new',
						{
							parent : 'product',
							url : '/create',
							data : {
								authorities : [
										CommonConstants.PRODUCT_ADD ],
								pageTitle : 'Tạo mới sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/product/product-create-update.html',
									controller : 'ProductCreateUpdateController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										id : null,
										productCode : null,
										productName : null,
										retailPrice : null,
										wholesalePrice : null,
										promotionPrice : null,
										discountLevelOne : null,
										discountLevelTwo : null,
										discountLevelThree : null,
										memo : null,
										description : null,
										origin : null,
										productImg : null,
										deleted : false,
										productTypeName : null,
										unitOfAccount : null,
										size : null,
										guaranteePeriod: 0
									};
								}
							}
						})
				.state(
						'product.edit',
						{
							parent : 'product',
							url : '/{id}/edit',
							data : {
								authorities : [
										CommonConstants.PRODUCT_EDIT ],
								pageTitle : 'Cập nhật sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/product/product-create-update.html',
									controller : 'ProductCreateUpdateController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'Product',
										function($stateParams, Product) {
											return Product.get({
												id : $stateParams.id
											}).$promise;
										} ]

							}
						})
				.state(
						'product.detail',
						{
							parent : 'product',
							url : '/{id}',
							data : {
								authorities : [],
								pageTitle : 'Chi tiết sản phẩm'
							},
							views : {
								'content@' : {
									templateUrl : 'app/product/product-detail.html',
									controller : 'ProductDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'Product',
										function($stateParams, Product) {
											return Product.get({
												id : $stateParams.id
											}).$promise;
										} ]

							}
						})
				;
	}
}());
