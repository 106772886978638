(function () {
	'use strict';
	
	var wyshtml5 = {
		templateUrl: 'app/template/wyshtml5.html',
		controller: wyshtml5Controller,
		bindings: {
			model: '=ngModel',
			idToolbar: '@idToolbar',
			disabled: '@disabled'
		}
	};
	wyshtml5Controller.inject = ['$scope', '$element'];

	function wyshtml5Controller($scope, $element, $attrs) {
		var $ctrl = this;
	}
	
	angular.module('crmApp').component('wyshtml5', wyshtml5);
})();