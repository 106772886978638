(function() {
	'use strict';
	angular.module('crmApp').factory('Customer', Customer);

	Customer.$inject = [ '$resource', '$state' ];

	function Customer($resource, $state) {
		var resourceUrl = 'api/customers/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data !== null && data !== undefined && data !== '') {
						data = angular.fromJson(data);
					} else {
						$state.go('accessdenied');
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();
