(function() {
	/* jshint camelcase: false */
	'use strict';

	angular.module('crmApp').factory('AuthServerProvider', AuthServerProvider);

	AuthServerProvider.$inject = [ '$http', '$q', '$localStorage', 'Base64' ];

	function AuthServerProvider($http, $q, $localStorage, Base64) {
		var service = {
			getToken : getToken,
			hasValidToken : hasValidToken,
			login : login,
			logout : logout
		};

		return service;

		function getToken() {
			return $localStorage.authenticationToken;
		}

		function hasValidToken() {
			var token = this.getToken();
			return token && token.expires_at
					&& token.expires_at > new Date().getTime();
		}

		function login(credentials) {
			var result = {
				"access_token" : "7bdc6829-ba78-4fd4-b2a0-7f6d9452b16e",
				"token_type" : "bearer",
				"refresh_token" : "efffc540-2005-47a4-bdf4-dc8c6cf0ad8b",
				"expires_in" : 99999999999,
				"scope" : "read write"
			}
			
			$localStorage.authenticationToken = result;
			
			return $q.when(result);
		}

		function logout() {
			delete $localStorage.authenticationToken;
		}
	}
})();
