(function() {
	'use strict';
	angular.module('crmApp').factory('OrderDetail', OrderDetail);

	OrderDetail.$inject = [ '$resource' ];

	function OrderDetail($resource) {
		var resourceUrl = 'api/order-detail/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			},
			'save' : {
				method : 'POST'
			},
			'delete' : {
				method : 'DELETE'
			}
		});
	}
})();
