(function() {
	'use strict';

	angular.module('crmApp').controller('CustomerDetailController',
			CustomerDetailController);

	CustomerDetailController.$inject = [ '$sce', '$scope', '$http',
			'$rootScope', 'entity', 'Customer', 'UserUtils', '$state', 'CommonConstants' ];

	function CustomerDetailController($sce, $scope, $http, $rootScope,
			entity, Customer, UserUtils, $state, CommonConstants) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.getOrderByCustomerById = getOrderByCustomerById;
		vm.getDistricts = getDistricts;
		vm.account = UserUtils.getAccount();
		vm.customers = entity;
		vm.$state = $state;
        vm.loadAll();
        function loadAll() {
          vm.getOrderByCustomerById();
          vm.getDistricts();
        }
		if (vm.customers.birthday) {
			vm.customers.birthday = new Date(vm.customers.birthday);
		}
		if (vm.customers.companyFoundDate) {
			vm.customers.companyFoundDate = new Date(
					vm.customers.companyFoundDate);
		}

		vm.isUserLoginCreateThisCustomer = vm.account.login == vm.customers.createdBy;
		vm.isCustomerAll = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ALL);
		vm.isOrderAll = UserUtils.hasAuthorityName(CommonConstants.ORDER_ALL);

		var unsubscribe = $rootScope.$on('crmApp:customerUpdate', function(
				event, result) {
			vm.customers = result;
		});
		function getOrderByCustomerById() {
			$http.get('/api/orders-with-customer-id?id=' + vm.customers.id)
				.then(function(success) {
					vm.oderCustomers = success.data;
				});
		};
		function getDistricts() {
            if(vm.customers.deliveryProvinceId){
                $http.get('/api/list-districts', {params: { provinceId : vm.customers.deliveryProvinceId}}).then(function (response) {
                    vm.districts = response.data;
                })
            }
        }
		vm.displaySettingListName = function(settings) {
			var result = '';
			if (settings !== null && settings !== undefined
					&& settings.length > 0) {
				angular.forEach(settings, function(setting, key) {
					result += setting.name;
					if (key != (settings.length - 1)) {
						result += ', ';
					}
				});
			}
			return result;
		};

		$scope.$on('$destroy', unsubscribe);

	}
})();
