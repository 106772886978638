(function() {
    'use strict';
    angular
    .module('crmApp')
    .controller('ProductCreateUpdateController', ProductCreateUpdateController);
    ProductCreateUpdateController.$inject = ['$uibModal', 'UserUtils', 'CommonConstants', 'AlertService', 'SettingUtils', 'Product', 'UploadFileService', '$scope', 'entity', '$http', '$state', '$window', '$resource'];

    function ProductCreateUpdateController($uibModal, UserUtils, CommonConstants, AlertService, SettingUtils, Product, UploadFileService, $scope, entity, $http, $state, $window, $resource) {
        var vm = this;
        vm.Product = entity;
        vm.isSave = false;
        vm.loadAll = loadAll;
        vm.hasAuthority = hasAuthority;
        vm.getCareConfiguration = getCareConfiguration;
        vm.getProductCare = getProductCare;
        // upload image
        vm.isUploadFileInProgress = false;
        vm.product_types = [];
        var PRODUCT_TYPE = 'PRODUCT_TYPE';
        vm.uploadFileNameList = [];
        vm.careConfigurations = [];
        vm.careConfId = [];
        vm.productCareDTOs = [];
        vm.productCareCurrent = [];
        var fd = new FormData();

        vm.loadAll();

        vm.clear = clear;

        function clear() {
            if ($state.current.name == "product.edit") {
                $state.go('product.edit', null, {
                    reload: true
                });
            } else if ($state.current.name == "product.new") {
                $state.go('product.new', null, {
                    reload: true
                });
            }
        }

        function loadAll() {
            SettingUtils.get({
                'keys': [PRODUCT_TYPE]
            },
            function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === PRODUCT_TYPE) {
                        vm.product_types.push(value);
                    }
                });
            },
            function(error) {

            }
            );
            vm.getCareConfiguration();
            if(vm.Product.id !=  null){
                vm.getProductCare();
            }

        }
        function getCareConfiguration() {
                $http.get('/api/get-all-care-configuration').success(function(success) {
                    vm.careConfigurations = success;
                });
        }
        function getProductCare() {
            vm.careConfId = [];
            $http.get('/api/product-care/' + vm.Product.id).success(function(success) {
                angular.forEach(success, function(value, key) {
                    vm.careConfId.push(value.careConfId);
                });
                vm.careConfIdCurrent = angular.copy(success);
            });
        }
        vm.error = false;
        vm.importExcel = function(){
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/product/product-create-update-excel.html',
                 backdrop: 'static',
                 scope: $scope,
                controller: function() {
                  var fdExcelProducts = new FormData();
                  $scope.fileExcelSelected = function(element) {
                    vm.isUploadFileInProgress = true;
                    // if not exits file return
                    // if file more than 32MB then return
                    if (!element.files[0] || (element.files[0].size/1024/1024 > 32)) {
                        return;
                    }

                    // delete file in FormData if exist
                    if (fdExcelProducts.has("file")) {
                        fdExcelProducts.delete("file");
                    }
                    // validate file name
                    var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
                    var validateFileName = fileName + new Date().getTime();
                    var extension = getExtensionFile(element.files[0].name);
                    var finalFileName = validateFileName + "." + extension;

                    fdExcelProducts.append("file", element.files[0]);
                    fdExcelProducts.set("file", element.files[0], finalFileName);
                  };

                   vm.saveExcel = function(){
                     UploadFileService.uploadOneFile('/api/import-excel-products', fdExcelProducts, function(success, status) {
                        $state.go('list-product', null, {
                             reload: true
                         });
                         vm.isUploadFileInProgress = false;
                     }, function(error, status) {
                         vm.isUploadFileInProgress = false;
                     });
                   }

                   vm.clodePopup = function(){
                        vm.modalInstance.dismiss('cancel');
                   }
                }
            });
        }

        vm.change = false;
        vm.edit = false;
        vm.save = function() {
           $scope.$broadcast('show-errors-check-validity');
           if($scope.createUpdateProduct.$invalid){
                var invalidControl = angular.element('input.ng-invalid').first() || angular.element('select.ng-invalid').first() || angular.element('option.ng-invalid').first();
                if(invalidControl != null){
                    invalidControl.focus();
                }
            }else{
                if(vm.hasAuthority()){
                    vm.Product.deleted = false;
                    vm.isSave = true;
                    // Fix upload product image 20190719
                    if(vm.myImage){
                    	uploadFile();
                    }   
                    // End fix upload product image 20190719
                    if (vm.Product.id != undefined) {
                      vm.edit = true;
                      Product.update(vm.Product, onSaveSuccess, onSaveError);
                  } else {
                    vm.change = true;
                      Product.save(vm.Product, onSaveSuccess, onSaveError);
                  }
              }
               $scope.$broadcast('show-errors-reset');
          }

      };

      function onSaveSuccess(result) {
            //new
            angular.forEach(vm.careConfId, function(value, key){
                var checkHasId = false;
                angular.forEach(vm.careConfIdCurrent, function(careConfIdCurrent_Value, careConfIdCurrent_Key){
                    if(careConfIdCurrent_Value.careConfId == value){
                      checkHasId = true;
                    }
                });

                if(!checkHasId){
                  vm.productCareDTOs.push({careConfId: value, productId: result.id, deleted: false});
                }
            });

            //delete
            angular.forEach(vm.careConfIdCurrent, function(value, key) {
                var checkHasId = false;
                angular.forEach(vm.careConfId, function(careConfId_value, careConfId_key){
                    if(value.careConfId == careConfId_value){
                      checkHasId = true;
                    }
                });

                if(!checkHasId){
                  vm.productCareDTOs.push({id: value.id ,careConfId: value.careConfId, productId: value.productId, deleted: true});
                }
            });

            var productCareResource = $resource('api/product-care');
            productCareResource.save(vm.productCareDTOs,function success(success){
              vm.getProductCare();
              vm.productCareDTOs = [];
            }, function error(){
              vm.productCareDTOs = [];
            });

            // delete file upload before but no save in system
            if (vm.uploadFileNameList.length > 1) {
                var index = vm.uploadFileNameList.indexOf(vm.Product.productImg);
                if (index != -1) {
                    vm.uploadFileNameList.splice(index, 1);
                    deleteUploadFileButNoSave(vm.uploadFileNameList);
                }
            }
            if(vm.edit){
                $state.go('product.edit', result.id, {
                    reload: true
                });
            }
            $scope.$emit('crmApp:productUpdate', result);
            if(vm.change){
                 $state.go('list-product', null, {
                    reload: true
                });
            }
            vm.isSaving = false;
        };

        function onSaveError() {
            vm.isSaving = false;
        };

        function isImage(fileType) {
            if (fileType.indexOf("image") !== -1) return true;
            return false;
        }

        function getExtensionFile(fileName) {
            return fileName.split('.').pop();
        }

        /*$scope.fileSelected = function(element) {
            vm.isUploadFileInProgress = true;
            // if not exits file return
            // if file more than 32MB then return
            if (!element.files[0] || (element.files[0].size/1024/1024 > 32)) {
                return;
            }
            // if not image return
            if(!isImage(element.files[0].type)){
                return;
            }

            // delete file in FormData if exist
            if (fd.has("file")) {
                fd.delete("file");
            }
            // validate file name
            var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
            var validateFileName = fileName + new Date().getTime();
            var extension = getExtensionFile(element.files[0].name);
            var finalFileName = validateFileName + "." + extension;

            fd.append("file", element.files[0]);
            fd.set("file", element.files[0], finalFileName);

            UploadFileService.uploadOneFile('/api/save-product-image', fd, function(success, status) {
                vm.Product.productImg = finalFileName;
                vm.isUploadFileInProgress = false;
                vm.uploadFileNameList.push(finalFileName);
            }, function(error, status) {
                vm.isUploadFileInProgress = false;
            });
        }*/
        
        // Fix upload product image 20190719
        // resize image
        vm.myImage='';
        vm.myCroppedImage='';

        var handleFileSelect=function(evt) {
          var file=evt.currentTarget.files[0];
          var reader = new FileReader();
          reader.onload = function (evt) {
            $scope.$apply(function($scope){
              vm.myImage=evt.target.result;
            });
          };
          reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change',handleFileSelect);
        // end  resize image   
        
        function uploadFile(){
            // Split the base64 string in data and contentType
            var block = vm.myCroppedImage.split(";");
            // Get the content type of the image
            var contentType = block[0].split(":")[1];// In this case "image/png"
            //get the type of the image
            var type = contentType.split("/")[1];// In this case "image/png"
            // get the real base64 content of the file
            var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

            var blob = b64toBlob(realData, contentType);

             // validate file name
            var validateFileName = new Date().getTime();
            var finalFileName = "resize-img-" + validateFileName + "." + type;

            vm.Product.productImg = finalFileName;

            fd.append("file", blob);
            fd.set("file", blob, finalFileName);

            UploadFileService.uploadOneFile('/api/save-product-image', fd, function(success, status) {
                // body...
                vm.isUploadFileInProgress = false;
            }, function(error, status) {
                vm.isUploadFileInProgress = false;
            });
        }
        function b64toBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                var byteCharacters = atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

              var blob = new Blob(byteArrays, {type: contentType});
              return blob;
        }
      // End fix upload product image 20190719
        
        function deleteUploadFileButNoSave(fileNames) {
            $http({
                method: 'GET',
                url: '/api/delete-product-image',
                params: {
                    fileNames: fileNames
                }
            });
        };

        function hasAuthority(){
          if(UserUtils.hasAuthorityName(CommonConstants.PRODUCT_ADD) || UserUtils.hasAuthorityName(CommonConstants.PRODUCT_EDIT)){
            return true;
        } else {
            AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
            return false;
        }


    };
}
})();
