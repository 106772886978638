(function() {
	'use strict';

	angular.module('crmApp').controller('StockDeleteController',
			StockDeleteController);

	StockDeleteController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'UserUtils',
			'AlertService', 'CommonConstants', 'entity', 'Stock', '$http' ];

	function StockDeleteController($rootScope, $state, $uibModalInstance, UserUtils,
			AlertService, CommonConstants, entity, Stock, $http) {
		var vm = this;

		vm.clear = clear;
		vm.idCheckeds = entity;
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		$rootScope.$on('close-modal', function(){
            clear();
        });
		vm.confirmDelete = function() {
			if (vm.idCheckeds != "") {
				if (UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_DELETE)) {
					Stock.delete({id: vm.idCheckeds}, onSuccess, onError);
				}else{
					AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
					$uibModalInstance.dismiss('cancel');
				}
			}
		};
		function onSuccess(){
			$state.reload();
			$uibModalInstance.close(true);
		}
		function onError(){
			$uibModalInstance.dismiss('cancel');
		}
	}
})();
