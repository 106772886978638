(function() {
    'use strict';
    angular
    .module('crmApp').directive('checkFileSize', function() {
        return {
            require: 'ngModel',
            link: function(scope, elem, attr, ngModel) {
                $(elem).parent().siblings('div.file-size-validation').hide();
                $(elem).bind('change', function() {
                    if (this.files[0] && this.files[0].size/1024/1024 > 32) {
                      $(elem).parent().siblings('div.file-size-validation').show();
                    } else {
                      $(elem).parent().siblings('div.file-size-validation').hide();
                    }
                });
            }
        }
    });
}());
