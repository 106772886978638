(function() {
	'use strict';

	angular.module('crmApp').controller('ReportRevenueAchievementController',
			ReportRevenueAchievementController);

	ReportRevenueAchievementController.$inject = [ '$q', 'CommonConstants',
			'Principal', '$resource', '$scope', '$window', '$http',
			'SettingUtils', 'pagingParams', 'DateFormatService', '$state',
			'entity','$uibModal', '$localStorage'];

	function ReportRevenueAchievementController($q, CommonConstants, Principal,
			$resource, $scope, $window, $http, SettingUtils, pagingParams,
			DateFormatService, $state, entity, $uibModal, $localStorage) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.entity = entity;
		vm.loadPage = loadPage;
		vm.reverse = false;
		vm.search = search;
		vm.clear = clear;
		vm.datePickerOpenStatus = {};
		vm.currentSearch = null;
		vm.teams = [];
		vm.orderStatus = [];
		vm.users = [];
		vm.ReportOrderCO = {};
		vm.ReportProductCO = {};
		vm.reportOrders = null;
		vm.nowDate = new Date();
		vm.OrderStatusDirective = [];
		vm.loadAll();

		function loadAll() {
			if (vm.entity.ReportOrder != null) {
				vm.searchOrder = JSON.parse(atob(vm.entity.ReportOrder));
				vm.ReportOrderCO.teamId = vm.searchOrder.teamId;
				vm.ReportOrderCO.staffId = vm.searchOrder.staffId;
				if (vm.searchOrder.dateFrom != null) {
					vm.ReportOrderCO.dateFrom = new Date(
							vm.searchOrder.dateFrom);
				}
				if (vm.searchOrder.dateTo != null) {
					vm.ReportOrderCO.dateTo = new Date(vm.searchOrder.dateTo);
				}
				vm.ReportOrderCO.orderStatus = vm.searchOrder.orderStatus;
				vm.searchOrderClone = angular.copy(vm.searchOrder);
				var ReportOrder = $resource('api/report-order');
				ReportOrder.query(vm.searchOrder,
						function success(reportOrders) {
							vm.reportOrders = reportOrders;
							vm.totalRealRevenue = vm
									.getTotalRevenue(reportOrders);
							vm.totalOrderPrice = vm
									.getTotalOrderPrice(reportOrders);
						}, function error() {

						});
			}
			vm.loadPage();
			configDateTime();
		} //
		function loadPage() {
			var ORDER_STATUS = 'ORDER_STATUS';
			SettingUtils.get({
				'keys' : [ ORDER_STATUS ]
			}, function(success) {

				angular.forEach(success, function(value, key) {
					if (value.key === ORDER_STATUS) {
						vm.orderStatus = success;
					}
				});
			}, function(error) {
			});

			// get team
			$http({
				url : "/api/get-all-team",
				method : "GET",
			}).success(function(response) {
				vm.teams = response;
			}).error(function(response) {

			})

			//get user
			loadUsers();
		}

		function addParameterToUrl(parameter, paramName, paramValue){
			if(paramValue){
				if(parameter.length == 0){
					parameter += '?';
				} else {
					parameter += '&';
				}
				parameter += (paramName + '='+ paramValue);
			}
			return parameter;
		}

		function isNull(val){
			if(val === null || val === undefined){
				return true;
			} else {
				return false;
			}
		}

		vm.excel = function(){
			var parameter = "";
			var dateFrom = isNull(vm.searchOrderClone.dateFrom) ? vm.searchOrderClone.dateFrom : new Date(vm.searchOrderClone.dateFrom).toISOString();
			var dateTo = isNull(vm.searchOrderClone.dateTo) ? vm.searchOrderClone.dateTo : new Date(vm.searchOrderClone.dateTo).toISOString();
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "orderStatus", vm.searchOrderClone.orderStatus);
			parameter = addParameterToUrl(parameter, "staffId", vm.searchOrderClone.staffId);
			parameter = addParameterToUrl(parameter, "teamId", vm.searchOrderClone.teamId);
			parameter = addParameterToUrl(parameter, "currentLogin", $localStorage.account.login);
			$window.open("public/export-excel-order" + parameter);
		};

		// print
		vm.print = function() {
			$window.print();
		}

		vm.openCalendar = function(date) {
			vm.datePickerOpenStatus[date] = true;
		};
		vm.typeReportTable = 1;
		vm.changeTable = function(typeReport) {
			if (typeReport == 1) {
				vm.typeReportTable = 1;
			} else if (typeReport == 2) {
				vm.typeReportTable = 2;
			} else if (typeReport == 3) {
				vm.typeReportTable = 3;
			}
		}
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.openDateFrom1 = function() {
				vm.isDateFromOpened1 = true;
			}

			vm.openDateFrom2 = function() {
				vm.isDateFromOpened2 = true;
			}
		}
		function transition() {
		}

		function loadUsers() {
			$http.get("/api/users-sales", {}).then(function(response) {
				vm.users = response.data;
			});
		}
		function getAccount() {
			Principal.identity().then(function(account) {
				vm.account = account;
			});
		}
		function search() {

			$scope.$broadcast('show-errors-check-validity');
			if ($scope.reportForm.$invalid) {
				var invalidControl = angular
						.element('.form-control.ng-invalid');
				if (invalidControl != null) {
					invalidControl.first().focus();
				}
			} else {
				if (vm.ReportOrderCO.dateFrom) {
					vm.ReportOrderCO.dateFrom = new Date(
							vm.ReportOrderCO.dateFrom);
				}
				if (vm.ReportOrderCO.dateTo) {
					vm.ReportOrderCO.dateTo = new Date(vm.ReportOrderCO.dateTo);
				}
				$state.go('report-revenue-achievement.search', {
					search : btoa(JSON.stringify(vm.ReportOrderCO))
				});
			}
		}

		function clear() {
		}
		// total order price
		vm.totalOrderPrice = 0;
		vm.getTotalOrderPrice = function(reportOrders) {
			var totalOrderPrice = 0;
			angular.forEach(reportOrders, function(reportOrder) {
				totalOrderPrice += reportOrder.totalPayAmount;
			})
			return totalOrderPrice;
		}

		// total real revenue
		vm.totalRealRevenue = 0;
		vm.getTotalRevenue = function(reportOrders) {
			var totalRealRevenue = 0;
			angular.forEach(reportOrders, function(reportOrder) {
				totalRealRevenue += reportOrder.realRevenue;
			});
			return totalRealRevenue;
		}

		vm.disableButton = function(){
			if(vm.reportOrders === undefined || vm.reportOrders == null){
				return true;
			}else{
				return false;
			}

		}

	}
})();
