(function() {
    'use strict';
    /* globals SockJS, Stomp */

    angular
        .module('crmApp')
        .factory('CommentTrackerService', CommentTrackerService);

    CommentTrackerService.$inject = ['$rootScope', '$window', '$cookies', '$http', '$q', '$localStorage'];

    function CommentTrackerService ($rootScope, $window, $cookies, $http, $q, $localStorage) {
        var service = {
            unsubscribeCommentSocket: unsubscribeCommentSocket,
            unsubscribeOrderChangeSocket: unsubscribeOrderChangeSocket
        };

        return service;

        function unsubscribeCommentSocket(){
          $rootScope.$broadcast('unsubscribe-comment-socket');
        }
        function unsubscribeOrderChangeSocket() {
            $rootScope.$broadcast('unsubscribe-order-change-socket');
        }

    }
})();
