(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'user-management',
						{
							parent : 'admin',
							url : '/user-management',
							data : {
								pageTitle : 'Nhân viên'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management.html',
									controller : 'UserManagementController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								},
								search : null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'user-management-detail',
						{
							parent : 'admin',
							url : '/user/:login',
							data : {
								pageTitle : 'Chi tiết nhân viên'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-detail.html',
									controller : 'UserManagementDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ 'User', '$stateParams',
										function(User, $stateParams) {
											return User.get({
												login : $stateParams.login
											}).$promise;
										} ],
								roles : [ 'Role', function(Role) {
									return Role.query({}).$promise;
								} ]
							},
							onEnter : function($state, entity) {
								if (entity.id == null) {
									$state.go('accessdenied');
								}
							}
						})
				.state(
						'user-management.new',
						{
							parent : 'user-management',
							url : '/new',
							data : {
								authorities : [ 'USER_ADD' ],
								pageTitle : 'Tạo mới nhân viên'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-dialog.html',
									controller : 'UserManagementDialogController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										id : null,
										login : null,
										name : null,
										email : null,
										activated : true,
										langKey : null,
										createdBy : null,
										createdDate : null,
										lastModifiedBy : null,
										lastModifiedDate : null,
										resetDate : null,
										resetKey : null,
										authorities : null,
										phone : null,
										phone2 : null,
										birthdate : null,
										genderId : null,
										address : null,
										addressLong : null,
										addressLat : null,
										memo : null,
										avatar : null,
										receiveEmail : true
									};
								},
								roles : [ 'Role', function(Role) {
									return Role.query({}).$promise;
								} ]
							}
						})
				.state(
						'user-management.edit',
						{
							parent : 'user-management',
							url : '/{login}/edit',
							data : {
								// authorities : [ 'USER_EDIT' ],
								pageTitle : 'Cập nhật nhân viên'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-dialog.html',
									controller : 'UserManagementDialogController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ 'User', '$stateParams',
										function(User, $stateParams) {
											return User.get({
												login : $stateParams.login
											}).$promise;
										} ],
								roles : [ 'Role', function(Role) {
									return Role.query({}).$promise;
								} ]
							},
							onEnter : function($state, entity) {
								if (entity.id == null) {
									$state.go('accessdenied');
								}
							}
						})
				.state(
						'user-management.permission',
						{
							parent : 'user-management',
							url : '/{login}/permission',
							data : {
								authorities : [ 'USER_ADD' ],
								pageTitle : 'Phân quyền'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-permission-dialog.html',
									controller : 'UserManagementPermissionControler',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ 'User', '$stateParams',
										function(User, $stateParams) {
											return User.get({
												login : $stateParams.login
											}).$promise;
										} ],
								authority : [ 'Authority', function(Authority) {
									return Authority.query({}).$promise;
								} ],
								role : [ 'Role', function(Role) {
									return Role.query({}).$promise;
								} ]
							}
						})
				;
	}
})();
