(function() {
    'use strict';

    angular.module('crmApp').controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$location', '$state', 'Auth', 'Principal',
        'ENV', 'LoginService', 'Utils'
    ];

    function NavbarController($location, $state, Auth, Principal, ENV,
        LoginService, Utils) {
        var vm = this;

        vm.login = login;
        vm.logout = logout;
        vm.$state = $state;

        vm.getAccount = getAccount

        loadAdminCRMScript();

        function login() {
            LoginService.open();
        }

        function logout() {
            LoginService.open();
            Auth.logout();
        }

        // Load adminCRMScript after html is completely fetching
        // This is not a good approach -> need to refactor later
        function loadAdminCRMScript() {
             var src = "content/js/admincrm-custom.js";
             var script = document.createElement('script');
             script.setAttribute('src', src);
             document.body.appendChild(script);
         }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account && vm.account.name === null) {
                    vm.account.name = vm.account.login;
                }
            });
        }
    }
})();
