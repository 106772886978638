(function() {
    'use strict';
    angular
        .module('crmApp')
        .factory('Utils', Utils);

    Utils.$inject = [];

    function Utils () {
    	return {
    		toggleClassName: function(element, className) {
    			if (element.hasClass(className)) {
    				element.removeClass(className);
    			} else {
    				element.addClass(className);
    			}
    		}
    	}
    }
})();
