(function() {
    'use strict';
    var informationComponent = {
        templateUrl: 'app/template/popup-config.html',
        controller: configInformationComponentController,
        bindings: {
            message:"<",
            save:"&",
            close: "&",
            model:"<",
            show:"<",
            editMode:"<"
        }
    };

    configInformationComponentController.inject = ['$uibModal', '$scope'];

    function configInformationComponentController($uibModal, $scope) {
        var $ctrl = this;
        $ctrl.saveConfig = function (configForm) {
            $scope.$broadcast('show-errors-check-validity');
            if(configForm.$invalid){
                var invalidControl = angular.element('input.ng-invalid').first();
                if(invalidControl.length != 0){
                    invalidControl.focus();
                }
            }else{
                $ctrl.save();
                $scope.$emit('crmApp:addInformationSuccess', $ctrl.model);
            }

        }

        $ctrl.closePopup = function () {
            $ctrl.close();
        }
    }

    angular.module('crmApp').component('informationComponent', informationComponent);
})();
