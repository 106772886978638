(function() {
    'use strict';
    angular.module('crmApp').factory('ConfigurationSetting', ConfigurationSetting);

    ConfigurationSetting.$inject = [ '$resource' ];

    function ConfigurationSetting($resource) {
        var resourceUrl = '/api/setting';

        return $resource(resourceUrl, {}, {
            'get' : {
                method : 'GET',
                isArray: true
            },
            'delete' : {
                method : 'DELETE'
            },
            'query': {
                method: 'GET',
                isArray: true,
                params: {fromDate: null, toDate: null}
            },
            'save' : {
                method : 'POST',
                params: {id: '@id',key: '@key', name: '@name', value: '@value'}
            },
            'update' : {
                method : 'PUT',
                params: {id: '@id',key: '@key', name: '@name', value: '@value'}
            }

        });
    }
})();
