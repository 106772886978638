(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('EmailTemplateDeleteController',EmailTemplateDeleteController);

    EmailTemplateDeleteController.$inject = ['$uibModalInstance', 'entity', 'EmailTemplate'];

    function EmailTemplateDeleteController($uibModalInstance, entity, EmailTemplate) {
        var vm = this;
        vm.emailTemplate = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EmailTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
