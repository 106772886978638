(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('HeaderbarController', HeaderbarController);

    HeaderbarController.$inject = ['$timeout', '$http', '$resource', '$rootScope', '$window', '$scope', '$location', '$state', '$localStorage', 'Utils', 'Principal', 'LoginService', 'Auth', 'CommonConstants'];

    function HeaderbarController($timeout, $http, $resource, $rootScope, $window, $scope, $location, $state, $localStorage, Utils, Principal, LoginService, Auth, CommonConstants) {
        var vm = this;
        vm.getAccount = getAccount;
        vm.logout = logout;
        vm.reloadRole = reloadRole;

        getAccount();
        addClasses();
        // get number mess
        vm.numberMessageNotRead = 0;
        vm.numberMessage = 0;
        vm.numberOrderDetail = 0;
        vm.notifications = [];
        vm.displayMessage = true;
        vm.click = false;

        function numbermessage(notifications) {
            vm.numberMessage = 0;
            vm.numberMessageNotRead = 0;

            if(notifications){
              vm.numberMessage = notifications.length;
            }
            angular.forEach(notifications, function(notify) {
                if (notify.read == false) {
                    vm.numberMessageNotRead += 1;
                }
            });
            if (vm.numberMessageNotRead <= 0) {
                vm.displayMessage = true;
            } else {
                vm.displayMessage = false;
            }
        }
        // get mess
        function getMessage() {
            var Notification = $resource('api/notification');
            Notification.query({}, function success(staffNotificationList) {
                if (staffNotificationList == null || staffNotificationList.length <= 0) {
                    vm.displayMessage = true;
                    vm.notifications = [];
                } else {
                    vm.notifications = staffNotificationList;
                }
                numbermessage(vm.notifications);
            }, function error() {

            });
        }

        function getOrderDetailCare() {
            var Notification = $resource('api/order-detail-care');
            Notification.query({}, function success(orderDetailCareList) {
                if (orderDetailCareList == null || orderDetailCareList.length <= 0) {
                    vm.displayMessage = true;
                    vm.orderDetailCare = [];
                } else {
                    vm.orderDetailCares = orderDetailCareList;
                }
                if(vm.orderDetailCares)
                    vm.numberOrderDetail = vm.orderDetailCares.length;
            }, function error() {

            });
        }

        vm.clickMessage = function() {
            vm.click = true;
            vm.displayMessage = true;
            // update read
            getMessage();
            $timeout(function() {
                $http.get('/api/mark-read-notification').then(function(success) {})
                vm.numberMessageNotRead = 0;
                vm.displayMessage = true;
            }, 1000);
        }

        vm.clickOrderDetailCare = function() {
            vm.click = true;
            // update read
            getOrderDetailCare();
            $timeout(function() {
                $http.get('/api/mark-read-order-detail-care').then(function(success) {})
                vm.numberMessageNotRead = 0;
                vm.displayMessage = true;
            }, 1000);
        }

        $rootScope.$on("send-message-header", function(event, args) {
            $timeout(function() {
              getMessage();
            }, 100);
        });

        function getAccount() {
            getMessage();
            getOrderDetailCare();
            Principal.identity().then(function(account) {
                if (account) {
                    vm.account = account;
                    $localStorage.account = vm.account;

                    if (vm.account.name === null) {
                        vm.account.name = vm.account.login;
                    }

                    if ($localStorage.currentRoleId) {
                        vm.currentRoleId = $localStorage.currentRoleId;
                        angular.forEach(vm.account.roles, function(role, key) {
                            if (role.id == vm.currentRoleId) {
                                vm.nameRole = role.name;
                            }
                        });
                    } else {
                        //Select Director role first
                        angular.forEach(vm.account.roles, function(role, key) {
                            if (role.name == CommonConstants.Director) {
                                vm.currentRoleId = role.id;
                                vm.nameRole = role.name;
                                return false;
                            }
                        });

                        //Select role different Admin first
                        if (!vm.currentRoleId) {
                            if (vm.account.roles.length > 1) {
                                angular.forEach(vm.account.roles, function(role, key) {
                                    if (role.name != CommonConstants.Admin) {
                                        vm.currentRoleId = role.id;
                                        vm.nameRole = role.name;
                                        return false;
                                    }
                                });
                            } else {
                                vm.currentRoleId = vm.account.roles[0].id;
                                vm.nameRole = vm.account.roles[0].name;
                            }
                        }
                        $localStorage.currentRoleId = vm.currentRoleId;
                    }

                    //Load data in dashboard after get staff info
                    $scope.$emit('crmApp:has.account.info', null);
                }
            });
        }

        function addClasses() {
            // Add wrapper classes by script for keeping the UI clean in the beginning
            $('#bodyWrapper').addClass('wrapper-background');
            $('#contentWrapper').addClass('content-wrapper-background');
            $('#footerWrapper').removeClass('hidden');
        }

        function logout() {
            LoginService.open();
            Auth.logout();
            // $window.localStorage.clear();
        }

        function reloadRole(id) {
            $localStorage.currentRoleId = id;
            vm.currentRoleId = id;
            window.location.reload();
        }
    }
})();
