(function() {
	'use strict';

	angular.module('crmApp').controller('ListProductController',
			ListProductController);

	ListProductController.$inject = ['$uibModal', '$state', 'UserUtils', 'CommonConstants',
			'SettingUtils', 'Product', 'ParseLinks', 'AlertService',
			'pagingParams', 'paginationConstants', '$http' ];

	function ListProductController($uibModal, $state, UserUtils, CommonConstants,
			SettingUtils, Product, ParseLinks, AlertService, pagingParams,
			paginationConstants, $http) {

		var vm = this;
		vm.initialize = initialize;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = false;
		vm.transition = transition;
		vm.searchProductHandler = searchProductHandler;
		vm.clear = clear;
		vm.currentCheck;
		vm.page = 1;
		vm.product_types = [];
		var PRODUCT_TYPE = 'PRODUCT_TYPE';

		vm.idCheckeds = null;
		 vm.currentSearch = null;
		vm.entries = '10';
		vm.showItem = 10;
		vm.showName = "sản phẩm";
		vm.isNotSelected = true;
		vm.checkAll = false;
		vm.totalItems = null;
		// add title pagination

		vm.initialize();

		vm.hasAuthorityWarehouseGoodsReceipt = function(){
			return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_GOODS_RECEIPT);
		}

		function initialize() {
			vm.loadPage();
			SettingUtils.get({
				'keys' : [ PRODUCT_TYPE ]
			}, function(success) {
				angular.forEach(success, function(value, key) {
					if (value.key === PRODUCT_TYPE) {
						vm.product_types.push(value);
					}
				});

				// angular.element('#productTypeId').selectpicker('refresh');
			}, function(error) {
			});
		}
		vm.toQuote = function() {
			// body...
			$state.go('order.new', {
				toQuote : "toQuote"
			}, {
				reload : true
			});
		}
		vm.deleteProduct = function(id){
			vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/product/product-delete-dialog.html',
                controller: 'ProductDeleteController',
                controllerAs: 'vm',
                 backdrop: 'static',
                size: 'md',
                resolve : {
					entity : function() {
						return id;
					}
				}
            });
		}
		// vm.changeColor = function(index){
		// 	var arr = angular.element(".tr");
		// 	var el = angular.element("#"+index);
		// 	arr.removeClass('show-color');
		// 	el.addClass('show-color');
		// }
		vm.checkAll = function() {
			var i = 0;
			if (vm.selectedAll) {
				vm.selectedAll = true;
				vm.isNotSelected = false;
			} else {
				vm.selectedAll = false;
				vm.isNotSelected = true;
			}
			angular.forEach(vm.Products, function(Product) {
				i++;
				if (i <= vm.entries)
					Product.selected = vm.selectedAll;
			});
			vm.checkProduct();
		}

		vm.checkProduct = function() {
			vm.idCheckeds = [];

			angular.forEach(vm.Products, function(Product) {
				if (!!Product.selected) {
					vm.idCheckeds.push(Product.id);
				}
			})
			if (vm.idCheckeds.length === 0) {
				vm.isNotSelected = true;
				vm.selectedAll = false;
			} else if (vm.idCheckeds.length === vm.Products.length) {
				vm.selectedAll = true;
			} else {
				vm.isNotSelected = false;
				vm.selectedAll = false;
			}
		}

		vm.showEntries = function() {
			vm.loadPage();
		}

		function loadPage(isSearchWithConditionBefore) {
			if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                Product.query(vm.currentSearch, onSuccess, onError);
            } else{
            	if (!vm.productCO) {
					vm.productCO = {};
				}
				vm.productCO.page = vm.page - 1;
				vm.productCO.size = vm.entries;
				vm.productCO.sort = sort();
				vm.currentSearch = angular.copy(vm.productCO);
				vm.showItem = vm.entries;
				Product.query(vm.currentSearch, onSuccess, onError);
            }
		}

		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function onSuccess(data, headers) {
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count');
			vm.queryCount = vm.totalItems;
			vm.Products = data;
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		function transition() {
			if (vm.currentSearch) {
				vm.loadPage(true);
			}
		}

		function searchProductHandler() {
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.loadPage();
		}

		function clear() {
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			vm.searchCustomer = null;
			vm.loadPage();
		}
		vm.refresh = function() {
			// body...
			$state.go('list-product', null, {
				reload : true
			});
		}
	}
})();
