(function() {
    'use strict';

    angular.module('crmApp')
        .controller('CustomerCarePlanDeleteDialogController', CustomerCarePlanDeleteDialogController);

    CustomerCarePlanDeleteDialogController.inject = ['$rootScope', '$state','UserUtils', 'entity', 'CommonConstants', 'AlertService', '$uibModalInstance', 'CarePlan', 'carePlanId', '$http' ,'CustomerCare','$q'];

    function CustomerCarePlanDeleteDialogController($rootScope, $state, UserUtils, entity, CommonConstants, AlertService, $uibModalInstance, CarePlan, carePlanId, $http, CustomerCare, $q) {
        var vm = this;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.careMission = entity.careMissions[0];
        function getCarePlanHttp() {
            var defer = $q.defer();
            $http.get(
                    '/api/get-care-plan-with-care-mission-id?id='
                            + entity.id).success(function(success) {
                vm.carePlans = success;
                // update care STATUS
                var keys = Object.keys(vm.carePlans);
                defer.resolve(keys.length);
                // end update care STATUS

            });
            // update care STATUS
                defer.promise.then(function(value) {
                       if (value === 0) {
                            vm.careMission.careStatusId = 1;
                            CustomerCare.update(vm.careMission);
                        }
                }, function(error) {
                    // This is called when error occurs.
                })
            // end update care STATUS
        };

        vm.delete = function() {
            if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE)) {
                CarePlan.delete({
                        id: carePlanId
                    },
                    function() {
                        getCarePlanHttp();
                        $state.reload();
                        $uibModalInstance.close(true);
                    });
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                $uibModalInstance.dismiss('cancel');
            }
        };
         $rootScope.$on('close-modal', function(){
             $uibModalInstance.close(true);
        });
    };
}());
