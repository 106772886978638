(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateAbsConfig.$inject = ['$stateProvider'];

	function stateAbsConfig($stateProvider) {
		$stateProvider.state('notification', {
			url: '/notification',
			parent: 'app'
		});
	}
	stateConfig.inject = ['$stateProvider', 'CommonConstants'];

	function stateConfig($stateProvider, CommonConstants) {
		$stateProvider
		.state(
			'notification-order-status',
			{
				parent : 'app',
				url : '/notifications-order-status',
				data : {
					authorities : [ 'USER' ],
					pageTitle : 'Danh sách thông báo'
				},
				views : {
					'content@' : {
						templateUrl : 'app/notification/notification-order-status.html',
						controller : 'NotificationOrderStatusController',
						controllerAs : 'vm'
					}
				},resolve : {
					
				}
			})
		.state(
			'notification-care-product',
			{
				parent : 'app',
				url : '/notifications-care-product',
				data : {
					authorities : [ 'USER' ],
					pageTitle : 'Danh sách thông báo chăm sóc'
				},
				views : {
					'content@' : {
						templateUrl : 'app/notification/notification-care-product-customer.html',
						controller : 'NotificationCareProductController',
						controllerAs : 'vm'
					}
				},resolve : {
					
				}
			})
	}
})();
