(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'email-configuration',
						{
							parent : 'email',
							url : '/user-email',
							data : {
								authorities : [ 'USER' ],
								pageTitle : 'Cấu hình email'
							},
							views : {
								'content@' : {
									templateUrl : 'app/email/email-configuration/email-configuration.html',
									controller : 'EmailConfigurationController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ 'EmailConfiguration',
										function(EmailConfiguration) {
											return EmailConfiguration.get();
										} ]
							}
						})
	}
})();
