(function() {
    'use strict';
    var historyOrdertTemplate = {
        templateUrl: 'app/template/history-order.html',
        controller: historyOrderTemplateController,
        bindings: {
            id: '<',
            orders: '<'
        }
    };

    historyOrderTemplateController.inject = ['$scope', '$element', '$uibModal','$location','$anchorScroll','$http'];

    function historyOrderTemplateController( $scope, $element, $uibModal, $location, $anchorScroll,$http) {
        var $ctrl = this;
        
        $http({
                url: '/api/orders-aud/'+$ctrl.id,
                method: 'GET'
            }).then(function(response) {
                $ctrl.orderAud = response.data.orderAud;    
             });


        $ctrl.showBoxHistory = function() {
            angular.element(".show-history-order").css({
                display: 'block'
            });
            angular.element(".box-history").css({
                display: 'none'
            });
            $location.hash('show-history-order');
            $anchorScroll();
        };

        $ctrl.closeHistory = function() {
            angular.element(".box-history").css({
                display: 'block'
            });
            angular.element(".show-history-order").css({
                display: 'none'
            });
        };

    }
    angular.module('crmApp').component('historyOrdertTemplate', historyOrdertTemplate);
})();
