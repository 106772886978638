(function() {
    'use strict';
    var templateQuantity = {
        template:  '<div class="input-group-qty pull-left" show-errors> ' +
        '<span class="pull-left"> </span>' +
        '<input next-on-enter  required type="text" name="$ctrl.myIndex" ng-model="$ctrl.myModel"' +
        '  min="1"  ng-blur="$ctrl.inputNumber($ctrl.myIndex);" ' +
        '  ng-value="1" ng-class="$ctrl.ngClass" class="$ctrl.myClass input-number input--wd input-qty pull-left" value="1">' +
        '<span class="pull-left btn-number-container">' +
        '<button type="button" class="btn btn-number btn-number--plus"  ng-click="$ctrl.plusNumber($ctrl.myIndex)" data-type="plus" data-field="myIndex"> + </button>' +
        '<button type="button" class="btn btn-number btn-number--minus" ng-click="$ctrl.minusNumber($ctrl.myIndex)" data-type="minus" data-field="myIndex"> – </button>' +
        '</span></div>',
        controller: templateQuantityController,
        bindings: {
            myClass: '=',
            myModel:'=',
            myIndex:'<',
            ngClass: '<',
            conditionEnter: '<'
        }
    };

    templateQuantityController.inject = ['$scope'];

    function templateQuantityController($scope) {
        var $ctrl = this;
         $ctrl.minusNumber = function(myIndex) {
            if ($ctrl.myModel >= 1) {
                $ctrl.myModel =  parseFloat($ctrl.myModel) - 1;
            }
        }
        // check '.'
        $scope.$watch('$ctrl.myModel', function(newValue, oldValue){
            var n = 0;
            for (var i = 0; i < newValue.length ; i++) {
                if(newValue[i] === "."){
                    n++;
                }
            }
            if(n >= 2){
                $ctrl.myModel = oldValue;
            }
        }, true);
        $ctrl.inputNumber = function(myIndex) {
            if (($ctrl.myModel !== null && $ctrl.myModel !== undefined)) {
                var hasDataBefore = false;
                if ($ctrl.myModel && $ctrl.myModel.length > 0) {
                    hasDataBefore = true;
                }
                // if ($ctrl.myModel.length > 0) {
                //     $ctrl.myModel = $ctrl.myModel.toString().replace(/[^0-9-]+/, '');
                // }

                if ($ctrl.myModel.length > 0 && $ctrl.conditionEnter == false) {
                    $ctrl.myModel = $ctrl.myModel.toString().replace(/[^0-9-]+/, '');
                }else{
                    $ctrl.myModel = $ctrl.myModel.toString().replace(/[^\d|\-+|\.+]/g, '');
                }

                if (hasDataBefore && ($ctrl.myModel === null || $ctrl.myModel === undefined || $ctrl.myModel === '')) {
                    $ctrl.myModel = 1;

                }
                if (hasDataBefore && $ctrl.myModel.toString() === "0") {
                    $ctrl.myModel= 1;
                }
            }
            if($ctrl.myModel === undefined){
                $ctrl.myModel= 1;
            }
        }
        $ctrl.plusNumber = function(myIndex) {
            $ctrl.myModel = parseFloat($ctrl.myModel) + 1;
            
        }
    }
    angular.module('crmApp').component('templateQuantity', templateQuantity);
})();

