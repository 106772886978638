(function() {
	'use strict';
	angular.module('crmApp').factory('EmailTemplateProcess',
			EmailTemplateProcess);

	EmailTemplateProcess.$inject = [ '$resource' ];

	function EmailTemplateProcess($resource) {
		var resourceUrl = 'api/email-templates/process/:id';

		return $resource(resourceUrl, {}, {
			'get' : {
				method : 'GET'
			}
		});
	}
})();
