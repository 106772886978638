(function() {
    'use strict';
    var popupComponent = {
        templateUrl: 'app/template/popup.html',
        controller: popupComponentController,
        bindings: {
            title:"<",
            content:"<",
            saveChange:"&",
            dontSaveChange: "&",
            close: "&"
        }
    };

    popupComponentController.inject = ['$uibModal', '$scope'];

    function popupComponentController($uibModal, $scope) { 
        var $ctrl = this;
        // cancel
        $ctrl.closePopup = function(){
            $ctrl.close();

        }

        $ctrl.saveWhenPageChange = function(){
            $ctrl.saveChange();
        }

        $ctrl.dontSaveAndPageChange = function(){
            $ctrl.dontSaveChange();
        }
        
    }
    angular.module('crmApp').component('popupComponent', popupComponent);
})();
