(function() {
    'use strict';

    angular
        .module('crmApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$state'];

    function LoginService ($state) {
        var service = {
            open: open,
            openMainPage: openMainPage
        };

        return service;

        function open () {
        	window.location = "login.html";
        }
        
        function openMainPage() {
        	window.location = "/#";
        }
    }
})();
