(function() {
	'use strict';

	angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

	stateAbsConfig.$inject = [ '$stateProvider' ];

	function stateAbsConfig($stateProvider) {
		$stateProvider.state('email', {
			url : '/email',
			parent : 'app'
		});
	}
	stateConfig.inject = [ '$stateProvider' ];
	function stateConfig($stateProvider) {
		$stateProvider.state('email-box', {
			parent : 'email',
			url : '/box-email',
			data : {
				authorities : [ 'USER' ],
				pageTitle : 'Hộp thư'
			},
			views : {
				'content@' : {
					templateUrl : 'app/email/emails.html',
					controller : 'EmailController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search : null
			}
		}).state(
				'compose-email',
				{
					parent : 'email',
					url : '/compose-email/{id}',
					data : {
						authorities : [ 'USER' ],
						pageTitle : 'Soạn thư'
					},
					views : {
						'content@' : {
							templateUrl : 'app/email/compose-email.html',
							controller : 'ComposeEmailController',
							controllerAs : 'vm'
						}
					},
					params : {
						page : {
							value : '1',
							squash : true
						},
						sort : {
							value : 'id,asc',
							squash : true
						},
						search : null
					},
					resolve : {
						entity : function() {
							return {
								from : null,
								to : null,
								subject : null,
								content : null,
								isMultipart : false,
								isHtml : false
							}
						},
						orderInfo : [ 'Order', '$stateParams',
								function(Order, $stateParams) {
									if ($stateParams.id) {
										return Order.get({
											id : $stateParams.id
										}).$promise;

									} else {
										return null;
									}

								} ]

					}
				}).state('read-email', {
			parent : 'email',
			url : '/read-email',
			data : {
				authorities : [ 'USER' ]
			},
			views : {
				'content@' : {
					templateUrl : 'app/email/read-email.html',
					pageTitle : 'Xem thư'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search : null
			}
		})
	}
}());
