(function() {
	'use strict';

	angular.module('crmApp').controller('EmailTemplateController',
			EmailTemplateController);

	EmailTemplateController.$inject = [ '$scope', '$state', 'EmailTemplate',
			'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants' ];

	function EmailTemplateController($scope, $state, EmailTemplate, ParseLinks,
			AlertService, pagingParams, paginationConstants) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.loadAll();
		vm.entries = '10';
		vm.showName = "mẫu email";
		function loadAll() {
			EmailTemplate.query({
				page : pagingParams.page - 1,
				size : vm.entries,
				sort : sort()
			}, onSuccess, onError);
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.emailTemplates = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}
		vm.showItem = 10;
		function transition() {
			vm.entries;
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
			vm.showItem = vm.entries;
		}
	}
})();
