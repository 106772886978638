(function() {
	'use strict';

	angular
	.module('crmApp')
	.directive('wysiwyg', wysiwyg);
	wysiwyg.$inject = ['$timeout', '$document'];
	function wysiwyg($timeout, $document) {
		return {
			restrict: "A",
			require: "ngModel",
			template: "<textarea style='width: 100%;height:100%' placeholder=''></textarea>",
			replace: true,
			scope: false,
			link: function (scope, element, attrs, controller) {
				var styleSheets,
				synchronize, editor,
				wysihtml5ParserRules = {
					tags: {
						strong: {}, b: {}, i: {}, em: {}, br: {}, p: {},
						div: {}, span: {}, ul: {}, ol: {}, li: {},
						h1: {}, h2: {}, 
						a: {
							set_attributes: {
								target: "_blank",
								rel:    "nofollow"
							},
							check_attributes: {
								href:   "url"
							}
						}
					}
				};

				styleSheets = _($document[0].styleSheets)
				.filter(function(ss) { return ss.href; })
				.pluck('href').value();

				editor = new wysihtml5.Editor(element[0], {
					toolbar: attrs.wysiwygToolbar,
					parserRules: wysihtml5ParserRules,
					useLineBreaks: false,
					stylesheets: styleSheets
				});

				synchronize = function() {
					controller.$setViewValue(editor.getValue());
					scope.$apply();
				};

				editor.on('redo:composer', synchronize);
				editor.on('undo:composer', synchronize);
				editor.on('paste', synchronize);
				editor.on('aftercommand:composer', synchronize);
				editor.on('change', synchronize);

				editor.on('load', function() {
					wysihtml5.dom.observe(
						editor.currentView.iframe.contentDocument.body, 
						['keyup'], synchronize);
				});

				scope.$watch(attrs.ngModel, function(newValue) {
					if (newValue && (newValue !== editor.getValue())) {
						element.html(newValue);
						editor.setValue(newValue);
					}
				});
				scope.$on('set_null', function () {
                    $timeout(function () {
                        editor.setValue('');
                    }, 0);
                });

                scope.$on("set_wysihtml5_disable", function () {
                	editor.disable();
                })

				
				// Pass editor object to scope for retrieving at controller
				scope.$root.editors = scope.$root.editors || {};
				scope.$root.editors[attrs.id || attrs.wysiwygToolbar] = editor;
			}
		};
	};
})();
