(function() {
    'use strict';
    var settingComponent = {
        templateUrl: 'app/template/popup-setting.html',
        controller: configSettingComponentController,
        bindings: {
            message:"<",
            save:"&",
            close: "&",
            model:"<",
            name:"<",
            key:"<",
            value:"<"
        }
    };

    configSettingComponentController.inject = ['$uibModal', '$scope'];

    function configSettingComponentController($uibModal, $scope) { 
        var $ctrl = this;
        $ctrl.saveConfig = function (settingForm) {
            $scope.$broadcast('show-errors-check-validity');
            if(settingForm.$invalid){
                var invalidControl = angular.element('input.ng-invalid').first();
                if(invalidControl.length != 0){
                    invalidControl.focus();
                }
            }else{
                $ctrl.save();
                $scope.$emit('crmApp:addSettingSuccess', $ctrl.model);
            }
            
        }

        $ctrl.closePopup = function () {
            $ctrl.close();
        }
    }

    angular.module('crmApp').component('settingComponent', settingComponent);
})();
