(function() {
	'use strict';

	angular.module('crmApp').controller('CreatePlanProductCareController',
			CreatePlanProductCareController);

	CreatePlanProductCareController.$inject = ['careMissionId', 'orderDetailIds', '$scope', '$uibModalInstance', '$state', 'UserUtils', 'DateFormatService',
			'SettingUtils', 'ParseLinks', 'AlertService', 'paginationConstants', '$http', '$localStorage', 'CarePlan' ];

	function CreatePlanProductCareController(careMissionId, orderDetailIds, $scope, $uibModalInstance, $state, UserUtils, DateFormatService,
			SettingUtils, ParseLinks, AlertService,
			paginationConstants, $http, $localStorage, CarePlan) {
		var vm = this;
		vm.account = $localStorage.account;
		vm.carePlan = {};
		vm.carePlan.staffId = vm.account.id;
		vm.carePlan.careMissionId = careMissionId;
		vm.carePlan.orderDetailIds = orderDetailIds;
		var loadAll = loadAll;
		var CARE_TYPE = 'CARE_TYPE';
		
		loadAll();
		// load all
		function loadAll(){
			configDateTime();
			vm.carePlan.plannedDate = new Date();
			SettingUtils.get({
				'keys' : [CARE_TYPE]
			}, function(success) {
				vm.caretypies = [];
				angular.forEach(success, function(value, key) {
					if (value.key === CARE_TYPE) {
						vm.caretypies.push(value);
					}
				});
			}, function(error) {
			});
		}
		// date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.openDateFrom = function() {
				vm.isDateFromOpened = true;
			}
		}
		
		//
		vm.clear = function(){
			 $uibModalInstance.dismiss('cancel');
		}
		//
		vm.save = function(){

			

			angular.element(".rm-error").removeClass('remove-error');
			var invalid1 = angular.element('.ng-invalid');
			var reportedContentEditor = $scope.$root.editors['careHistoryToolbar'];

			vm.statusError = false;
			if ($scope.formProductCare.$invalid) {
				$scope.$broadcast('show-errors-check-validity');
				$scope.$broadcast('show-errors-check-validity-html5');
				if (invalid1.length != 0) {
					invalid1.first().focus();
					vm.statusError = true;
					reportedContentEditor.focus();
				}
			} else {
			CarePlan.save(vm.carePlan, function success(reponse){
				$uibModalInstance.dismiss('cancel');
				$scope.$emit('crmApp:save-success-care-plan-for-product-care');
			}, function error(){
			});
				$scope.$broadcast('show-errors-reset');
				angular.element(".rm-error").addClass('remove-error');
				$scope.$broadcast('show-errors-reset-html5');
			}
		}
	}
})();
