(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['UserUtils', '$uibModal', 'CommonConstants', 'Order', 'Product', '$rootScope', 'Customer', 'CustomerCare', '$scope', '$localStorage', 'Principal', 'LoginService', 'pagingParams', 'paginationConstants', 'ParseLinks', '$location', '$anchorScroll', 'AlertService', '$http','$timeout'];

    function HomeController(UserUtils, $uibModal, CommonConstants, Order, Product, $rootScope, Customer, CustomerCare, $scope, $localStorage, Principal, LoginService, pagingParams, paginationConstants, ParseLinks, $location, $anchorScroll, AlertService, $http,$timeout) {
        var vm = this;
        var ROLE = {
            MANAGER: 1,
            SALES: 2,
            ACCOUNTANT: 3
        }

        vm.pagingParams = pagingParams;
        // vm.deleteOrder = function(id){
        //      vm.modalInstanceObject = $uibModal.open({
        //         templateUrl: 'app/order/order-delete-dialog.html',
        //         controller: 'OrderDeleteController',
        //         controllerAs: 'vm',
        //         size: 'md',
        //         resolve : {
        //             entity : function() {
        //                 return id;
        //             }
        //         }
        //     });
        // }
        vm.deleteProduct = function(id){
            vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/product/product-delete-dialog.html',
                controller: 'ProductDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve : {
                    entity : function() {
                        return id;
                    }
                }
            });
        }

        //FIXME
        vm.revenueTarget = 50000000;

        vm.displaySettingListName = function(settings) {
            var result = '';
            if (settings !== null && settings !== undefined
                    && settings.length > 0) {
                angular.forEach(settings, function(setting, key) {
                    result += setting.name;
                    if (key != (settings.length - 1)) {
                        result += ', ';
                    }
                });
            }
            return result;
        };
        vm.login = LoginService.open;

        vm.scrollToTODO = scrollToTODO;
        vm.getHomePageByCurrentRole = getHomePageByCurrentRole;

        vm.isCurrentAdminRole = function() {
            return getCurrentRoleName() == CommonConstants.Admin;
        };

        vm.isCurrentDirectorRole = function() {
            return getCurrentRoleName() == CommonConstants.Director;
        };

        vm.isCurrentSalesRole = function() {
            return getCurrentRoleName() == CommonConstants.Sales
                || getCurrentRoleName() == CommonConstants.SalesManager
                || getCurrentRoleName() == CommonConstants.SalesLeader;
        };
        vm.isCurrentSalesManagerRole = function() {
            return getCurrentRoleName() == CommonConstants.SalesManager;
        }
       vm.isCurrentSaleLeaderRole = function () {
           return getCurrentRoleName() == CommonConstants.SalesLeader;
       }
        vm.isCurrentAccountantRole = function() {
            return getCurrentRoleName() == CommonConstants.Accountant;
        };

        vm.isCurrentStockRole = function() {
            return getCurrentRoleName() == CommonConstants.Stock;
        };

        vm.isCurrentProductManagerRole = function() {
            return getCurrentRoleName() == CommonConstants.Product_Manager;
        };

        function getCurrentRoleName() {
            var roleName = null;
            angular.forEach($localStorage.account.roles, function(role, key) {
                if (role.id == $localStorage.currentRoleId) {
                    roleName = role.name;
                    return false;
                }
            });
            return roleName;
        }
        vm.basicUsage = vm.basicUsage;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.loadCareMission = loadCareMission;
        vm.loadProduct = loadProduct;
        // customer
        vm.transition = transition;
        vm.searchCustomerHandler = searchCustomerHandler;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.page = 1;
        vm.entries = "5";
        vm.showName = "khách hàng";

        // caremission
        vm.predicateCareMission = pagingParams.predicate;
        vm.pageCareMission = 1;
        vm.entriesCareMission = "5";
        vm.reverseCareMission = false;
        vm.transitionCareMission = transitionCareMission;
        vm.searchCareMissionHandler = searchCareMissionHandler;

        // product
        vm.predicateProduct = pagingParams.predicate;
        vm.showProduct = "sản phẩm";
        vm.pageProduct = 1;
        vm.entriesProduct = "5";
        vm.reverseProduct = false;
        vm.transitionProduct = transitionProduct;
        vm.searchProductHandler = searchProductHandler;
        var status = null;


        if($localStorage && $localStorage.currentRoleId){
          // if has currentRoleId before then get data for dashboard
          // else $rootScope.$on('crmApp:has.account.info')...
          vm.account = UserUtils.getAccount();
          vm.loginName = vm.account.login;
          vm.isCustomerAll = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ALL);
          vm.loadAll();
        } else {
          // get home data when has account info in $localStorage
          var unsubscribe = $rootScope.$on('crmApp:has.account.info', function(event, result) {
            vm.account = UserUtils.getAccount();
            vm.loginName = vm.account.login;
            vm.isCustomerAll = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ALL);
            vm.loadAll();
          });

          $scope.$on('$destroy', unsubscribe);
        }

        function loadAll() {
            vm.loadPage();
            totalSales();
            if (vm.isCurrentStockRole() == false) {
              vm.loadCareMission();
            }

            if(vm.isCurrentDirectorRole() || vm.isCurrentSalesRole() || vm.isCurrentSaleLeaderRole()){
              $http.get('/api/get-revenue').success(function(success) {
                  vm.revenueData = success;
              });
            }
        }
        function totalSales(){
            vm.totalSales = -1;
            vm.totalOrder = -1;
            vm.usersSales = 0;
            $http.get('/api/report-order-total-revenue').success(function(result) {
            		vm.totalSales += result.totalSales;
            		vm.totalOrder += result.totalOrder;
            });
            
            //  Tổng số nhân viên sales
            $http.get('/api/users-sales').success(function(success) {
                angular.forEach(success, function(value, key) {
                    vm.usersSales += 1;
                });
            });
        }
        function scrollToTODO() {
            // $("html, body").animate({ scrollTop: $(document).height() },
            // "slow");
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash('to_do_list');

            // call $anchorScroll()
            $anchorScroll();
        }
        // List order

        // List product
        function loadProduct() {
            if (!vm.productCO) {
                vm.productCO = {};
            }

            vm.productCO.page = vm.pageProduct - 1;
            vm.productCO.size = vm.entriesProduct;
            vm.productCO.sort = sort();
            Product.query(vm.productCO, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverseProduct ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'lastModifiedDate') {
                    result.push('lastModifiedDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsProduct = headers('X-Total-Count');
                vm.queryCountProduct = vm.totalItemsProduct;
                vm.Products = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function searchProductHandler() {
            vm.pageProduct = 1;
            vm.predicate = 'lastModifiedDate';
            vm.reverseProduct = false;
            vm.loadProduct();
        }

        function transitionProduct() {
            vm.loadProduct();
            vm.showItemProduct = vm.entriesProduct;
        }
        // List customer
        function loadPage() {
            if (!vm.customerCO) {
                    vm.customerCO = {};
                }
            vm.customerCO.isPhone = false;
            vm.customerCO.page = vm.page - 1;
            vm.customerCO.size = vm.entries;
            vm.customerCO.sort = sort();
            vm.currentSearch = angular.copy(vm.customerCO);
            vm.showItem = vm.entries;
            Customer.query(vm.currentSearch, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'lastModifiedDate') {
                    result.push('lastModifiedDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customers = data;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }


        function loadCareMission() {
            CustomerCare.get({
                dataSearch: vm.dataSearchCareMission,
                page: vm.pageCareMission - 1,
                size: vm.entriesCareMission,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateCareMission + ',' +
                    (vm.reverseCareMission ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'lastModifiedDate') {
                    result.push('lastModifiedDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsCareMission = headers('X-Total-Count');
                vm.queryCountCareMission = vm.totalItems;
                vm.careMissions = data.content;
            }

            function onError(error) {}

        }
        vm.showItem = 5;

        function transition() {
            vm.entries;
            vm.loadPage();
            vm.selectedAll = false;
            vm.showItem = vm.entries;
        }

        function transitionCareMission() {
            vm.loadCareMission();
            vm.loadProduct();
            vm.loadOrder();
        }

        function searchCustomerHandler(keyEvent) {
            if (keyEvent.which === 13) {
                vm.page = 1;
                vm.predicate = 'lastModifiedDate';
                vm.reverse = false;
                vm.loadAll();
            }
        }

        function searchCareMissionHandler(keyEvent) {
            if (keyEvent.which === 13) {
                vm.pageCareMission = 1;
                vm.predicateCareMission = 'lastModifiedDate';
                vm.reverseCareMission = false;
                vm.loadCareMission();
            }
        }


        function clear() {
            vm.page = 1;
            vm.predicate = 'lastModifiedDate';
            vm.reverse = false;
            vm.searchCustomer = null;
            vm.loadAll();
        }

        function getHomePageByCurrentRole() {
            var currentRoleId = $localStorage.currentRoleId;

            if (currentRoleId == ROLE.MANAGER) {
                return "app/dashboard/home/home-sales.html";
            } else if (currentRoleId == ROLE.SALES) {
                return "app/dashboard/home/home-sales.html";
            } else if (currentRoleId == ROLE.ACCOUNTANT) {
                return "app/dashboard/home/home-account.html";
            } else {
                return "app/dashboard/home/home-blank.html";
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
            // vm.isSearchCriteria = false;
        }
    }
})();
