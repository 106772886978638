(function() {
	'use strict';

	angular.module('crmApp').controller('ReportLiabilitiesController',
			ReportLiabilitiesController);

	ReportLiabilitiesController.$inject = [ '$scope', '$resource',
			'DateFormatService', '$window', '$http', '$state', 'SettingUtils','$uibModal' ];

	function ReportLiabilitiesController($scope, $resource, DateFormatService,
			$window, $http, $state, SettingUtils, $uibModal) {
		var vm = this;
		vm.reportInventoryCO = {};
		vm.deliverMethods = [];
		vm.customers = [];
		var DELIVER_METHOD = 'DELIVER_METHOD';
		vm.typeReports = [
			{value:1, name: "Công Nợ Vận Chuyển"}
			// ,
			// {value:2, name: "Công Nợ Khách Hàng"}
		];
		vm.shippingStatus = [
			{value:1, name: "Trong hạn"},
			{value:2, name: "Quá hạn"},
			{value:3, name: "Hoàn thành"}
		];

		vm.loadAll = loadAll;
		vm.loadAll();
		vm.reportStockListDelivery = reportStockListDelivery;
		
		function loadAll() {
			vm.value = 1;
			configDateTime();
			$http.get('/api/get-all-customer').success(function(success) {
				if (success && success.length > 0) {
					vm.customers = success;
				}
			});
			SettingUtils.get({
				'keys' : [ DELIVER_METHOD ]
			}, function(success) {
				angular.forEach(success, function(value, key) {
					if (value.key === DELIVER_METHOD) {
						vm.deliverMethods.push(value);
					}
				});
			}, function(error) {
			});
		}
		// Cong no van chuyen
		function reportStockListDelivery(){
			$scope.$broadcast('show-errors-check-validity');
			if (vm.searchForm.$invalid) {
				var invalidControl = angular.element('.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else{
				var config = {
					params: vm.reportInventoryCO,
					headers : {'Accept' : 'application/json'}
				};
				$http.get('/api/report-liabilities-delivery',config).success(function(success) {
					vm.reportLiabilitiesDeliverys = success;
					vm.data = vm.reportLiabilitiesDeliverys;
				});
			}
		}

		// print
		vm.print = function() {
			$window.print();
		}
		// config date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.openDateFrom1 = function() {
				vm.isDateFromOpened1 = true;
			}

			vm.openDateFrom2 = function() {
				vm.isDateFromOpened2 = true;
			}
		}
		//
		vm.displayUI = function(value) {
			vm.data = [];
			
		}


		// 
		vm.displayName = function(arrs, model) {
			var result = '';
			if (arrs !== null && arrs !== undefined
					&& arrs.length > 0) {
				angular.forEach(arrs, function(arr, key) {
					angular.forEach(model, function(code, key) {
						if (arr.value == code) {
							result += arr.name;
							if (key != (arrs.length - 1)) {
								result += ', ';
							}
						}
					})
				});
			}
			return result;

		}

		vm.disableButton = function(){
			if(vm.data === undefined || vm.data.length == 0){
				return true;
			}else{
				return false;
			}
			
		}
		
	}
})();
