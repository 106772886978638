(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('ViettelPostService', ViettelPostService);

        ViettelPostService.$inject = ['$uibModal', '$localStorage', '$http'];
        function ViettelPostService($uibModal, $localStorage, $http){
        	var vm = this;

        	vm.cancel = function (order) {
        		if($localStorage.viettelPostAccount == null || $localStorage.viettelPostAccount == undefined) {
	                vm.modalInstantLogin = $uibModal.open({
	                    templateUrl: 'app/order-shipment/viettel-post/login-dialog.html',
	                    controller: 'ViettelPostLoginController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: function() {
	                            return order;
	                        }
	                    }
	                })
	            }else{
	                $http({
	                    url: "/api/cancel-viettel-post",
	                    method: "POST",
	                    params: {
	                        token: $localStorage.viettelPostAccount.tokenKey,
	                        viettelPostId: order.viettelPostId,
	                    }
	                }).success(function (response) {
	                }).error(function (response) {
	                })
	            }
        	}
        }

 }());