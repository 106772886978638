(function() {
	'use strict';

	angular.module('crmApp').controller('OrderController', OrderController);

	OrderController.$inject = ['CommonConstants', 'UserUtils','$uibModal','$rootScope', '$scope', '$state', '$http',
			'Order', 'ParseLinks', 'AlertService', 'pagingParams',
			'paginationConstants', 'Principal', 'User', 'SettingUtils',
			'DateFormatService', 'CheckPhone'];

	function OrderController( CommonConstants, UserUtils, $uibModal, $rootScope, $scope, $state, $http, Order,
			ParseLinks, AlertService, pagingParams, paginationConstants,
			Principal, User, SettingUtils, DateFormatService, CheckPhone) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.loadPage = loadPage;
		vm.searchOrderHandler = searchOrderHandler;
		vm.predicate = pagingParams.predicate;
		vm.reverse = false;
		vm.transition = transition;
		vm.search = search;
		vm.clear = clear;
		vm.idCheckeds = null;
		vm.checkAll = false;
		vm.isNotSelected = true;
		vm.page = 1;
		vm.entries = '10';
		vm.totalItems = null;
		vm.showName = "đơn hàng"
		vm.datePickerOpenStatus = {};
		vm.currentSearch = null;
		vm.datePickerOpenStatus.ngayOrder = false;
		vm.searchOrderUseCriteria = searchOrderUseCriteria;

		
		// dashboard pass status order to show list order
		if($state.params.status){
			if (!vm.orderCO) {
					vm.orderCO = {};
				}
			vm.orderCO.status = parseInt($state.params.status);
			vm.loadAll();
		}else{
			vm.loadAll();
		}
		vm.checkAll = function() {
			var i = 0;
			if (vm.selectedAll) {
				vm.selectedAll = true;
				vm.isNotSelected = false;
			} else {
				vm.selectedAll = false;
				vm.isNotSelected = true;
			}
			angular.forEach(vm.Orders, function(Order) {
				i++;
				if (i <= vm.entries)
					Order.selected = vm.selectedAll;
			});
			vm.checkOrder();
		}
		vm.hasAuthorityWarehouseGoodsIssue = function(){
			return UserUtils.hasAuthorityName(CommonConstants.WAREHOUSE_GOODS_ISSUE);
		}
		// to good issue
		vm.toGoodIssue = function(idOrder){
            $state.go('good-issue', {
				idOrder: idOrder
			});
		}
		vm.checkOrder = function() {
			vm.idCheckeds = [];

			angular.forEach(vm.Orders, function(Order) {
				if (!!Order.selected) {
					vm.idCheckeds.push(Order.id);
				}
			})
			if (vm.idCheckeds.length === 0) {
				vm.isNotSelected = true;
				vm.selectedAll = false;
			} else if (vm.idCheckeds.length === vm.Orders.length) {
				vm.selectedAll = true;
			} else {
				vm.isNotSelected = false;
				vm.selectedAll = false;
			}
		}

		vm.showEntries = function() {
			vm.loadAll();
		}

		vm.openCalendar = function(date) {
			vm.datePickerOpenStatus[date] = true;
		};
		vm.refresh = function() {
			// body...
			$state.go('order', null, {
				reload : true
			});
		}

		function loadAll() {
			vm.loadPage();
			getDataFromSetting();
			configDateTime();
		}
		vm.orderInWritingStatus = function(status) {
            return status == CommonConstants.ORDER_WRITING;
        }
        vm.orderInPendingStatus = function(status) {
            return status == CommonConstants.ORDER_PENDING;
        }
        vm.orderInWaitingDeliveryAndCollectionStatus = function(status) {
            return status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION;
        }
        vm.orderInWaitingDeliveryStatus = function(status) {
            return status == CommonConstants.ORDER_WAITING_DELIVERY;
        }
        vm.orderInWaitingCollectStatus = function(status) {
            return status == CommonConstants.ORDER_WAITING_COLLECTION;
        }
        vm.orderInCompletedStatus = function(status) {
            return status == CommonConstants.ORDER_COMPLETED;
        }
        vm.orderInWaitingConfirmStatus = function(status){
        	return status == CommonConstants.ORDER_WAITING_CONFIRM;
        }
        vm.orderInMissingPaymentStatus = function(status){
        	return status == CommonConstants.ORDER_MISSING_PAYMENT;
        }
        vm.orderInMissingDeliveryStatus = function(status){
        	return status == CommonConstants.ORDER_MISSING_DELIVERY;
        }
        vm.orderInWaitingCancelStatus = function (status) {
        	return status == CommonConstants.ORDER_CANCEL || status == CommonConstants.ORDER_REQUEST_CANCEL;
        }
        vm.orderInWaitingGoodIssue = function(status){
        	return status == 12;
        }
        // status show hidden
        vm.notProcessStatus = function(status){
            return status == 1;
        }
        vm.processingStatus = function(status){
            return status == 2;
        }
        vm.packedStatus = function(status){
            return status == 3;
        }
        vm.sendedToDeliveryStatus = function(status){
            return status == 4;
        }
        vm.receivedStatus = function(status){
        	return status == 6;
        }
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.openDateFrom = function() {
				vm.isDateFromOpened = true;
			}
		}

		vm.changeColor = function(index){
			var arr = angular.element(".tr");
			var el = angular.element("#"+index);
			arr.removeClass('show-color');
			el.addClass('show-color');
		}
		function getDataFromSetting() {
			var ORDER_CATEGORY = 'ORDER_CATEGORY';
			var DELIVER_STATE = 'DELIVER_STATE';
			var ORDER_STATUS = 'ORDER_STATUS';
			SettingUtils.get({
				'keys' : [ ORDER_CATEGORY, DELIVER_STATE, ORDER_STATUS ]
			}, function(success) {
				vm.orderCategories = [];
				vm.deliverStaties = [];
				vm.orderStatus = [];

				angular.forEach(success, function(value, key) {
					if (value.key === ORDER_CATEGORY) {
						vm.orderCategories.push(value);
					} else if (value.key === DELIVER_STATE) {
						vm.deliverStaties.push(value);
					} else if (value.key === ORDER_STATUS) {
						vm.orderStatus.push(value);
					}
				});

				// angular.element('#status').selectpicker('refresh');
				// angular.element('#category').selectpicker('refresh');
			}, function(error) {
			});
		}

		function loadPage(isSearchWithConditionBefore) {
			if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                Order.query(vm.currentSearch, onSuccess, onError);
            } else{
            	if (!vm.orderCO) {
					vm.orderCO = {};
				}

				vm.orderCO.phoneNum = CheckPhone.isPhoneNumber(vm.orderCO.customerPhone);
				vm.orderCO.page = vm.page - 1;
				vm.orderCO.size = vm.entries;
				vm.orderCO.sort = sort();
				vm.currentSearch = angular.copy(vm.orderCO);
				vm.showItem = vm.entries;
				Order.query(vm.currentSearch, onSuccess, onError);
            }

			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'lastModifiedDate') {
					result.push('lastModifiedDate,desc');
				}
				return result;
			}

			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.Orders = data;
				//vm.isSearchCriteria = false;
			}
			function onError(error) {
				AlertService.error(error.data.message);
				//vm.isSearchCriteria = false;
			}
		}

		vm.getPaymentAmount = function(values){
			vm.PaymentAmount = 0;
			if(values.length > 0){
				angular.forEach(values, function(value) {
					vm.PaymentAmount += value.paymentAmount;

				})
			}
			return vm.PaymentAmount;
		}
		vm.showItem = 10;

		function transition() {
			if (vm.currentSearch) {
				vm.loadPage(true);
			}
		}

		function search() {
			vm.page = 1;
			vm.predicate = 'lastModifiedDate';
			vm.reverse = false;
			vm.loadAll();
		}
		;

		function clear() {
			vm.page = 1;
			vm.predicate = 'lastModifiedDate';
			vm.reverse = false;
			vm.searchCriteria = null;
			vm.loadAll();
		}
		;

		function searchOrderHandler(keyEvent) {
			if (keyEvent.which === 13) {
				vm.page = 1;
				vm.predicate = 'lastModifiedDate';
				vm.reverse = false;
				vm.loadAll();
			}
		}

		vm.deleteOrder = function(id){
			 vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/order/order-delete-dialog.html',
                controller: 'OrderDeleteController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve : {
					entity : function() {
						return id;
					}
				}
            });
		}
		function searchOrderUseCriteria() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.searchForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first()
						|| angular.element('select.ng-invalid').first()
						|| angular.element('option.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else {
				vm.page = 1;
				vm.predicate = 'id';
				vm.reverse = false;
				vm.loadAll();
			}
		}
		
	}
})();
