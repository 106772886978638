(function() {
    'use strict';
    var chatTemplate = {
        templateUrl: 'app/template/chat.html',
        controller: chatTemplateController,
        bindings: {
            id: '<',
            hasNewComment: '<',
            comments: '<',
            sendComment: '&'
        }
    };

    chatTemplateController.inject = ['$localStorage', '$window', '$compile', '$http', 'UserUtils', '$scope', '$element', '$uibModal', 'Comment'];

    function chatTemplateController($localStorage, $window, $compile, $http, UserUtils, $scope, $element, $attrs, $uibModal, Comment) {
        var $ctrl = this;
        $ctrl.account = UserUtils.getAccount();
        $ctrl.accountId = $ctrl.account.id;
        $ctrl.sendCommentRealTime = function() {
            var newComment = {};
            newComment.content = $ctrl.content;
            $ctrl.content = '';

            $ctrl.sendComment({
                comment: newComment
            });
        }
        $ctrl.showHistoryChat = function(comment) {
            $ctrl.modalInstance = $uibModal.open({
                templateUrl: 'app/order/order-detail-history-edit-chat-dialog.html',
                scope: $scope,
                controller: function() {
                    $ctrl.audits = comment.commentAudits;
                    $ctrl.closeHistory = function() {
                        $ctrl.modalInstance.dismiss('cancel');
                    }
                }
            });
        }

        $scope.$watch('$ctrl.comments.length', function() {
          if($ctrl.comments){
            if($ctrl.hasNewComment){
              $ctrl.showBoxChat();
            }
          }
        });

        $ctrl.showBoxChat = function() {
            angular.element("#chat-order").css({
                display: 'block'
            });
            angular.element(".showCol3").addClass('col-sm-3');
            angular.element(".showCol9").addClass('col-sm-9');
            angular.element(".box-chat").css({
                display: 'none'
            });
        };

        $ctrl.close = function() {
            angular.element(".box-chat").css({
                display: 'block'
            });
            angular.element("#chat-order").css({
                display: 'none'
            });
            angular.element(".showCol3").removeClass('col-sm-3');
            angular.element(".showCol9").removeClass('col-sm-9');
        };
        $ctrl.number = -1;
        $ctrl.showEdit = function(comment) {
            var divEdit = "#edit-comment" + comment.id;
            var divSaveEdit = "#save-edit-comment" + comment.id;

            angular.element(divEdit).removeClass('hidden');
            angular.element(divSaveEdit).removeClass('hidden');
        }

        $ctrl.save = function(comment) {
            var divEdit = "#edit-comment" + comment.id;
            var divSaveEdit = "#save-edit-comment" + comment.id;

            angular.element(divEdit).addClass('hidden');
            angular.element(divSaveEdit).addClass('hidden');

            $ctrl.sendComment({
                comment: comment
            });
        }

        $ctrl.isNotBlank = function(data) {
            if (data == null || data.trim() == "" || data == undefined) {
                return false;
            }
            return true;
        }
    }
    angular.module('crmApp').component('chatTemplate', chatTemplate);
})();
