$(document).ready(function () {
	//Show modal remove
	$('#openBtn').click(function () {
		$('#myModal').modal({
			show: true
		})
	});

});
//tooltip
$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();   
});
//Show password
$(document).ready(function () {
	$("#showhide").click(function () {
		if ($(this).data('val') == "1") {
			$("#pwd").prop('type', 'text');
			$("#eye").attr("class", "glyphicon glyphicon-eye-close");
			$(this).data('val', '0');
		} else {
			$("#pwd").prop('type', 'password');
			$("#eye").attr("class", "glyphicon glyphicon-eye-open");
			$(this).data('val', '1');
		}
	});
});

$(document).ready(function () {
	$("#remove").click(function () {
		$("#uname").val('');
	});

});



//Orders add/delete row
$(document).ready(function () {
	var i = 1;
	$("#add_row").click(function () {
		$('#addr' + i).html("<td>" + (i + 1) + "</td><td><input name='item" + i + "' type='text' placeholder='Hạng mục' class='form-control input-md'  /> </td><td><input  name='size" + i + "' type='text' placeholder='Kích thước'  class='form-control input-md'></td><td><select name='dvt" + i + "' class='form-control input-md'><option>Bộ</option><option>Cái</option><option>M2</option></select></td><td><input  name='sl" + i + "' type='text' placeholder='Số lượng'  class='form-control input-md'></td><td><input  name='price" + i + "' type='text' placeholder='Đơn giá'  class='form-control input-md'></td><td><input  name='money" + i + "' type='text' placeholder='Thành tiền'  class='form-control input-md'></td><td><input  name='memo" + i + "' type='text' placeholder='Ghi chú'  class='form-control input-md'></td>");

		$('#tab_logic').append('<tr id="addr' + (i + 1) + '"></tr>');
		i++;
	});
	$("#delete_row").click(function () {
		if (i > 1) {
			$("#addr" + (i - 1)).html('');
			i--;
		}
	});

});

$.fn.extend({
    insertAtCaret: function(myValue) {
        if (this.prop('selectionStart') || this.prop('selectionStart') == '0') {
            var startPos = this.prop('selectionStart');
            var endPos = this.prop('selectionEnd');
            this.val(this.val().substring(0, startPos) + myValue + this.val().substring(endPos, this.val().length));
            this.focus();
            this.prop('selectionStart', startPos + myValue.length);
            this.prop('selectionEnd', startPos + myValue.length);
        } else {
            this.val(myValue);
            this.focus();
        }
    }
});


