(function() {
    'use strict';

    angular.module('crmApp').controller('CreateProductCareController',
        CreateProductCareController);

    CreateProductCareController.$inject = ['$uibModal', '$state', 'CommonConstants',
        '$http', '$rootScope', '$scope', '$resource'
    ];

    function CreateProductCareController($uibModal, $state, CommonConstants,
        $http, $rootScope, $scope, $resource) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.idCheckeds = null;
        vm.productCareDetail = null;
        vm.orderDetailHasProductSelecteds = null;
        vm.loadAll();
        var unsubscribe = $rootScope.$on('crmApp:save-success-care-plan-for-product-care', function(event) {
              getCarePlanForProductCare(vm.order.id);
            });

        var unsubscribeReportCare = $rootScope.$on('crmApp:save-success-report-care-for-product-care', function(event, carePlanId) {
          reloadCareHistoriesOfOneCarePlan(carePlanId);
            });

        $scope.$on('$destroy', function () {
            unsubscribe();
            unsubscribeReportCare();
        });

        //
        vm.detailReport = {
            content: '',
            templateUrl: 'create-product-care-watch-report.html',
            title: 'NỘI DUNG BÁO CÁO'
          };

        function loadAll() {
        	var productCareInOnePlan = $resource('api/product-care-in-one-plan', {}, {

        	});
            var orderCode = $state.params.order;
            var careCodes = [];
            careCodes.push($state.params.care);

            productCareInOnePlan.get({'orderCode': orderCode, 'careConfigurationCodes': careCodes}, function success(response){
              vm.productCareDetail = response;
              reloadCareHistoriesOfOneCarePlan(vm.productCareDetail.carePlan.id);
            }, function error(err){

            });

        }

        function getCareMissionByOrderId(orderId){
          $http.get('/api/care-mission-by-order-id?orderId=' + orderId)
                  .success(function(response) {
                     vm.careMission = response;
                  });
        };

        function getCarePlanForProductCare(orderId){
            $http.get('/api/care-plan-for-product-care?orderId=' + orderId)
                    .success(function(response) {
                       vm.productCares = response;
                    });
        };

        function reloadCareHistoriesOfOneCarePlan(carePlanId){
          $http.get('/api/get-care-history-by-care-plan-id?carePlanId=' + carePlanId)
                  .success(function(response) {
                    vm.productCares = response;
                  });
        }
        // show detail history report
        vm.watchDetail = function(careHistories){
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/product-care/create-report-detail-product-care.html',
                scope: $scope,
                 size: 'lg',
                backdrop: 'static',
                controller: function() {
                    vm.careHistories = careHistories;
                    vm.clear = function(){
                        close();
                    }
                }
            })

            function close(){
                vm.modalInstance.dismiss('cancel');
            }
        }
        // create report care
        vm.reportCare = function(careProduct) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/product-care/create-report-product-care.html',
                controller: 'CreateReportProductCareController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                  careProduct:[
                    function(){
                        return careProduct;
                    }
                  ]
                }
            });
        }
        // vm.displayProduct = function(products){
        //     var result = '';
        //     if (products !== null && products !== undefined
        //             && products.length > 0) {
        //         angular.forEach(products, function(product, key) {
        //             result += product.orderDetail.product.productName;
        //             if (key != (products.length - 1)) {
        //                 result += ', ';
        //             }
        //         });
        //     }
        //     return result;
        // }
        // vm.checkAll = function() {
        // 	if (vm.selectedAll) {
        // 		vm.selectedAll = true;
        // 		vm.isNotSelected = false;
        // 	} else {
        // 		vm.selectedAll = false;
        // 		vm.isNotSelected = true;
        // 	}
        // 	angular.forEach(vm.orderDetailHasProducts, function(orderDetail) {
        // 		orderDetail.selected = vm.selectedAll;
        // 	});
        // 	vm.checkItem();
        // }

        // vm.checkItem = function() {
        // 	vm.idCheckeds = [];
        // 	vm.orderDetailHasProductSelecteds = [];

        // 	angular.forEach(vm.orderDetailHasProducts, function(orderDetail) {
        // 		if (!!orderDetail.selected) {
        // 			vm.idCheckeds.push(orderDetail.id);
        // 			vm.orderDetailHasProductSelecteds.push(orderDetail);
        // 		}
        // 	});

        // 	if (vm.idCheckeds.length === 0) {
        // 		vm.isNotSelected = true;
        // 		vm.selectedAll = false;
        // 	} else if (vm.idCheckeds.length === vm.orderDetailHasProducts.length) {
        // 		vm.selectedAll = true;
        // 	} else {
        // 		vm.isNotSelected = false;
        // 		vm.selectedAll = false;
        // 	}
        // }
    }
})();
