(function() {
    'use strict';

    angular.module('crmApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('department', {
                parent: 'admin',
                url: '/department',
                data: {
                    authorities: ['TEAM_VIEW'],
                    pageTitle: 'Nhóm'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/department/department.html',
                        controller: 'DepartmentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil
                                    .parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil
                                    .parsePredicate($stateParams.sort),
                                ascending: PaginationUtil
                                    .parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }
                    ]
                }
            }).state(
                'department.new', {
                    parent: 'department',
                    url: '/new',
                    data: {
                        authorities: ['TEAM_ADD'],
                        pageTitle: 'Tạo mới nhóm'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/admin/department/department-create-update.html',
                            controller: 'DepartmentCreateUpdateController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: function() {
                            return {};
                        }
                    }
                })
            .state(
                'department.edit', {
                    parent: 'department',
                    url: '/{id}/edit',
                    data: {
                        pageTitle: 'Cập nhật thông tin nhóm'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/admin/department/department-create-update.html',
                            controller: 'DepartmentCreateUpdateController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: ['$stateParams', 'Team',
                            function($stateParams, Team) {
                                return Team.get({
                                    id: $stateParams.id
                                }).$promise;
                            }
                        ]
                    }
                })
            .state(
                'department.detail', {
                    parent: 'department',
                    url: '/{id}/detail',
                    data: {
                        pageTitle: 'Thông tin nhóm'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/admin/department/department-detail.html',
                            controller: 'DepartmentDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        pagingParams: [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    predicate: PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search: $stateParams.search
                                };
                            }
                        ],
                        teamId: ['$stateParams',
                            function($stateParams) {
                                return $stateParams.id;
                            }
                        ]
                    }
                })
    }
})();
