(function () {
	'use strict';

	angular
	.module('crmApp')
	.controller('OrderCreateUpdateObjectDialogController', OrderCreateUpdateObjectDialogController);

	OrderCreateUpdateObjectDialogController.$inject = ['DateFormatService', '$rootScope', '$scope', '$uibModalInstance', 'UploadFileService'];
	function OrderCreateUpdateObjectDialogController(DateFormatService, $rootScope, $scope, $uibModalInstance, UploadFileService) {
		var vm = this;

		vm.objects = [];
		vm.reset = reset;

		// upload file
		vm.isUploadFileInProgress = false;
		var fd = new FormData();
		vm.uploadFileNameList = [];
		// flag validation
		vm.validation = false;

		vm.saveObject = function () {
			$scope.$broadcast('show-errors-check-validity');
			if($scope.addObject.$invalid){
				var invalidControl = angular.element('input.ng-invalid').first() || angular.element('select.ng-invalid').first() || angular.element('option.ng-invalid').first();
				if(invalidControl != null){
					invalidControl.focus();
				}

			}else{
				var object = {
					selected : true,
					productCode : 'vt',
					productName : vm.productName,
					productImg : vm.productImg,
					origin : vm.origin,
					size : vm.size,
					unitOfAccount : vm.unitOfAccount,
					retailPrice : vm.retailPrice,
					receivePlanDate: vm.receivePlanDate,
					memo : vm.memo
				}
				vm.objects.push(object);
				vm.selectedAll = true;
				vm.reset();
				$scope.$broadcast('show-errors-reset');
			}
		}
		console.log("obj");

		function reset() {
			vm.productName = null,
			vm.productImg = null,
			vm.origin = null,
			vm.size = null,
			vm.unitOfAccount = null,
			vm.receivePlanDate = null,
			vm.retailPrice = "",
			vm.memo = null
		}

        vm.altInputFormats = DateFormatService.getAltInputFormats();
        vm.dateFormat = DateFormatService.getDateFormat();
        vm.dateOptions = {
            startingDay: 1,
            minDate: new Date()
        };

        vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

		vm.addObject = function () {
			if (vm.uploadFileNameList.length > 1) {
				var index = vm.uploadFileNameList.indexOf(vm.Product.productImg);
				if (index != -1) {
					vm.uploadFileNameList.splice(index, 1);
					deleteUploadFileButNoSave(vm.uploadFileNameList);
				}
			}
			var newObjects = [];
			if(vm.objects.length === 0){
				vm.displayError = "Chưa có vật tư để thêm. Vui lòng chọn một vật tư.";
			}else{
				angular.forEach(vm.objects, function (object) {
					if(object.selected) {
						newObjects.push(object);
					}
				})
				vm.objects = newObjects;
				$uibModalInstance.close(vm.objects);
			}

		}
		$rootScope.$on('close-modal', function(){
	        $uibModalInstance.close();
	    });
		function isImage(fileType) {
			if (fileType.indexOf("image") !== -1) return true;
			return false;
		}

		function getExtensionFile(fileName) {
			return fileName.split('.').pop();
		}

		$scope.fileSelected = function(element) {
			vm.isUploadFileInProgress = true;
            // if not exits file return
            // if file more than 32MB then return
            if (!element.files[0] || (element.files[0].size/1024/1024 > 32)) {
            	return;
            }
            // if not image return
            if(!isImage(element.files[0].type)){
            	return;
            }

            // delete file in FormData if exist
            if (fd.has("file")) {
            	fd.delete("file");
            }
            // validate file name
            var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
            var validateFileName = fileName + new Date().getTime();
            var extension = getExtensionFile(element.files[0].name);
            var finalFileName = validateFileName + "." + extension;

            fd.append("file", element.files[0]);
            fd.set("file", element.files[0], finalFileName);


            UploadFileService.uploadOneFile('/api/save-product-image', fd, function(success, status) {
            	vm.productImg = finalFileName;
            	vm.isUploadFileInProgress = false;
            	vm.uploadFileNameList.push(finalFileName);
            }, function(error, status) {
            	vm.isUploadFileInProgress = false;
            });
        }

        function deleteUploadFileButNoSave(fileNames) {
        	$http({
        		method: 'GET',
        		url: '/api/delete-product-image',
        		params: {
        			fileNames: fileNames
        		}
        	});
        };

        vm.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };

    }
})();
