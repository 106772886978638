(function() {
	'use strict';

	angular.module('crmApp').controller('OrderDetailDeleteController',
			OrderDetailDeleteController);

	OrderDetailDeleteController.$inject = ['$rootScope', 'UserUtils', 'AlertService',
			'CommonConstants', '$uibModalInstance', '$http', '$state',
			'entity', '$scope' ];

	function OrderDetailDeleteController($rootScope, UserUtils, AlertService,
			CommonConstants, $uibModalInstance, $http, $state, entity, $scope) {
		var vm = this;

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
		vm.confirmDelete = function() {
			if (UserUtils.hasAuthorityName(CommonConstants.ORDER_ADD)) {
				$scope.$emit('crmApp:orderCreateUpdateDeleteItem', entity);
				$uibModalInstance.close(true);
			} else {
				AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
				$uibModalInstance.dismiss('cancel');
			}
		};
	}
})();
