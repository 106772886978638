(function() {
	'use strict';

	angular.module('crmApp').factory('Account', Account);

	Account.$inject = [ '$resource', '$q', '$localStorage' ];

	function Account($resource, $q, $localStorage) {
		var service = {
			get : function() {
				if ($localStorage.authenticationToken) {
					return {
						$promise : $q.when({
							data: {
								"login" : "admin",
								"password" : null,
								"name" : "Administrator",
								"email" : "admin@localhost",
								"activated" : true,
								"langKey" : "en",
								"auths" : [ {
									"id" : 1,
									"name" : "ADMIN",
									"description" : "A default framework permission"
								}, {
									"id" : 2,
									"name" : "USER",
									"description" : "A default framework permission"
								} ],
								"authorities" : [ "ADMIN", "USER" ]
							}}),
						$resolve: true
					}
				} else {
					return {
						$promise: $q(function(resolve, reject) {
							reject();
						}),
						$resolve: false
					};
				}
			}
		}

		return service;
	}
})();
