(function() {
    'use strict';
    var tableOrder = {
        templateUrl: 'app/template/table-order.html',
        controller: tableOrderController,
        bindings: {
            message: "@",
            status: "<",
            pagingParams: "<",
            showMoney: "<",
            showDetail: "<",
            showEdit: "<",
            showDelete: "<"
        }
    };

    tableOrderController.inject = ['$rootScope', '$state', '$localStorage', 'AlertService', '$uibModal', 'CommonConstants', 'Order', 'Principal', 'paginationConstants', 'ParseLinks', '$http'];

    function tableOrderController($rootScope, $state, $localStorage, AlertService, $uibModal, CommonConstants, Order, Principal, paginationConstants, ParseLinks, $http) {

        var $ctrl = this;
        $ctrl.loadAll = loadAll;
        $ctrl.predicate = $ctrl.pagingParams.predicate;
        $ctrl.loadPage = loadPage;
        $ctrl.reverse = false;
        $ctrl.transition = transition;
        $ctrl.links = null;
        $ctrl.entries = '5';
        $ctrl.currentSearch = null;
        $ctrl.page = 1;
        $ctrl.totalItems = null;
        $ctrl.loadAll();
        $ctrl.orders = [];
        $ctrl.showName = "đơn hàng";
        $ctrl.isCurrentAdminRole = isCurrentAdminRole;
        $ctrl.isCurrentDirectorRole = isCurrentDirectorRole;
        $ctrl.isCurrentSalesRole = isCurrentSalesRole;
        $ctrl.isCurrentSalesManagerRole = isCurrentSalesManagerRole;
        $ctrl.isCurrentAccountantRole = isCurrentAccountantRole;
        $ctrl.isCurrentStockRole = isCurrentStockRole;
        $ctrl.isCurrentProductManagerRole = isCurrentProductManagerRole;
        $ctrl.isCurrentSaleLeaderRole = isCurrentSaleLeaderRole;

        $ctrl.getMessage = function(){
          return $ctrl.message;
        };

         // status show hidden
        $ctrl.notProcessStatus = function(status){
            return status == 1;
        }
        $ctrl.processingStatus = function(status){
            return status == 2;
        }
        $ctrl.packedStatus = function(status){
            return status == 3;
        }
        $ctrl.sendedToDeliveryStatus = function(status){
            return status == 4;
        }
        // go to list order status
        $ctrl.goListOrderStatus = function(status){
            $state.go('order',{status: status});
        }
        // use in component
        function isCurrentSaleLeaderRole() {
            return getCurrentRoleName() == CommonConstants.SalesLeader;
        }

        function isCurrentAdminRole() {
            return getCurrentRoleName() == CommonConstants.Admin;
        };
        //
        function isCurrentDirectorRole() {
            return getCurrentRoleName() == CommonConstants.Director;
        };
        //
        function isCurrentSalesRole() {
            return getCurrentRoleName() == CommonConstants.Sales ||
                getCurrentRoleName() == CommonConstants.SalesManager;
        };
        //
        function isCurrentSalesManagerRole() {
            return getCurrentRoleName() == CommonConstants.SalesManager;
        }
        //
        function isCurrentAccountantRole() {
            return getCurrentRoleName() == CommonConstants.Accountant;
        };
        //
        function isCurrentStockRole() {
            return getCurrentRoleName() == CommonConstants.Stock;
        };
        //
        function isCurrentProductManagerRole() {
            return getCurrentRoleName() == CommonConstants.Product_Manager;
        };
        // end use in component
        //
        function getCurrentRoleName() {
            var roleName = null;
            angular.forEach($localStorage.account.roles, function(role, key) {
                if (role.id == $localStorage.currentRoleId) {
                    roleName = role.name;
                    return false;
                }
            });
            return roleName;
        }

        // function delete
        $ctrl.deleteOrder = function(id) {
            $ctrl.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/order/order-delete-dialog.html',
                controller: 'OrderDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function() {
                        return id;
                    }
                }
            });
        }
        // function load all
        function loadAll() {
            $ctrl.loadPage($ctrl.status);
        }
        // function load page follow status
        function loadPage(status) {
            if (!$ctrl.orderCO) {
                $ctrl.orderCO = {};
            }
            if (status == null) {
                if (isCurrentDirectorRole()) {
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WRITING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_PENDING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_COMPLETED, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_CONFIRM, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                } else if (isCurrentSalesManagerRole()) {
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WRITING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_PENDING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_CONFIRM, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                } else if (isCurrentSalesRole() || isCurrentSaleLeaderRole()) {
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WRITING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_PENDING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                } else if (isCurrentAccountantRole()) {
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_PENDING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                } else if (isCurrentStockRole()) {
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                    splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
                }
            } else if (status == CommonConstants.ORDER_WRITING && (isCurrentDirectorRole() || isCurrentSalesRole() || isCurrentSaleLeaderRole() || isCurrentAccountantRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_WRITING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_PENDING && (isCurrentDirectorRole() || isCurrentSalesRole() || isCurrentAccountantRole() || isCurrentSaleLeaderRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_PENDING, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_WAITING_DELIVERY && (isCurrentDirectorRole() || isCurrentStockRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_WAITING_COLLECTION && (isCurrentDirectorRole() || isCurrentAccountantRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION && (isCurrentDirectorRole() || isCurrentAccountantRole() || isCurrentStockRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_COMPLETED && (isCurrentDirectorRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_COMPLETED, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_WAITING_CONFIRM && (isCurrentDirectorRole() || isCurrentSalesManagerRole() || isCurrentSalesRole() || isCurrentSaleLeaderRole())) {
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_WAITING_CONFIRM, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_MISSING_DELIVERY && (isCurrentDirectorRole() || isCurrentSalesRole() || isCurrentStockRole() || isCurrentSaleLeaderRole())) {
                $ctrl.orderCO.isGetOrderMissingDelivery = "true";
                splitOrder($ctrl.orderCO, null, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_MISSING_PAYMENT && (isCurrentDirectorRole() || isCurrentSalesRole() || isCurrentAccountantRole() || isCurrentSaleLeaderRole())) {
                $ctrl.orderCO.isGetOrderMissingPayment = "true";
                splitOrder($ctrl.orderCO, null, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            } else if (status == CommonConstants.ORDER_REQUEST_CANCEL && (isCurrentAccountantRole() || isCurrentStockRole())) {
                // if (orderCO.status == CommonConstants.ORDER_REQUEST_CANCEL) {
                    $ctrl.orderCO.searchFollowCurrentRole = true;

                    if (isCurrentAccountantRole()) {
                        $ctrl.orderCO.accountantRole = true;
                        $ctrl.orderCO.goodsIssue = false;
                    } else if (isCurrentStockRole()) {
                        $ctrl.orderCO.stockRole = true;
                        $ctrl.orderCO.goodsIssue = true;
                    }
                // }
                splitOrder($ctrl.orderCO, CommonConstants.ORDER_REQUEST_CANCEL, $ctrl.page, $ctrl.entries, onSuccess, $ctrl.predicate, $ctrl.reverse);
            }

            function splitOrder(orderCO, status, page, entries, onSuccess, predicate, reverse) {
				orderCO = {};
                orderCO.page = page - 1;
                orderCO.size = entries;
                orderCO.sort = sort(predicate, reverse);
                orderCO.status = status;
                Order.query(orderCO, onSuccess, onError);
            }

            function sort(predicate, reverse) {
                var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
                if (predicate !== 'lastModifiedDate') {
                    result.push('lastModifiedDate,desc');
                }
                return result;
            }
           
            function onSuccess(data, headers) {
                $ctrl.links = ParseLinks.parse(headers('link'));
                $ctrl.totalItems = headers('X-Total-Count');
                $ctrl.queryCount = $ctrl.totalItems;
                $ctrl.orders = data;
                // $rootScope.$broadcast('show-hidden-table',{show:$ctrl.orders.length,status:$ctrl.status });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        // function trantition ng-change
        $ctrl.showItem = 5;
        $ctrl.getPaymentAmount = function(values){
            $ctrl.PaymentAmount = 0;
            if(values.length > 0){
                angular.forEach(values, function(value) {
                    $ctrl.PaymentAmount += value.paymentAmount;

                })
            }
            return $ctrl.PaymentAmount;
        }
        function transition(status, entries) {
            $ctrl.loadPage(status);
            if (status == 0 || status == 1 || status == 2 || status == 3 || status == 4 || status == 5 || status == 6 || status == 7 || status == 8 || status == 9) {
                $ctrl.showItem = entries;
            }

        }
    }
    angular.module('crmApp').component('tableOrder', tableOrder);
})();
