(function() {
	'use strict';

	angular.module('crmApp').controller('EmailConfigurationController',
			EmailConfigurationController);

	EmailConfigurationController.$inject = [ '$scope', 'entity',
			'EmailConfiguration' ];

	function EmailConfigurationController($scope, entity, EmailConfiguration) {
		var vm = this;
		vm.isUser = false; // disable admin controls
		vm.config = entity;
		vm.save = save;
		vm.verify = verify;

		function save() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.configEmailForm.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first();
				if (invalidControl.length != 0) {
					invalidControl.focus();
				}
			} else {
				EmailConfiguration.update(vm.config, onSaveSuccess);
			}
		}

		function verify() {
			EmailConfiguration.update({
				'verify' : 'verify'
			}, vm.config, onVerifySuccess);
		}

		function onSaveSuccess() {
			alert("Lưu thành công!");
		}

		function onVerifySuccess() {
			alert("Kiểm tra thành công!");
		}
	}
})();
