(function() {
    'use strict';

    angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

    stateAbsConfig.$inject = ['$stateProvider'];

    function stateAbsConfig($stateProvider) {
        $stateProvider.state('product-care', {
            url: '/product-care',
            parent: 'app'
        });
    }

    stateConfig.inject = ['$stateProvider', 'CommonConstants'];

    function stateConfig($stateProvider, CommonConstants) {
        $stateProvider
            .state(
                'list-product-care', {
                    parent: 'product-care',
                    url: '/list-product-care',
                    data: {
                        authorities: [],
                        pageTitle: 'Danh sách sản phẩm chăm sóc'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/product-care/product-care.html',
                            controller: 'ProductCareController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    predicate: PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search: $stateParams.search
                                };
                            }
                        ],
                    }
                })
            .state(
                'create-product-care', {
                    parent: 'product-care',
                    url: '/create-product-care/{order}/{care}',
                    data: {
                        authorities: [],
                        pageTitle: 'Kế hoạch chăm sóc sản phẩm'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/product-care/create-product-care.html',
                            controller: 'CreateProductCareController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                        
                    },
                    resolve: {
                        order: [ '$stateParams',
                            function($stateParams) {
                                return $stateParams.order;
                            } ],
                        care:[ '$stateParams',
                            function($stateParams) {
                                return $stateParams.care;
                            } ]
                    }
                })
            .state(
                'create-plan-care', {
                    parent: 'product-care',
                    url: '/create-plan-care',
                    data: {
                        authorities: [],
                        pageTitle: 'Tạo kế hoạch chăm sóc'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/product-care/create-plan-care.html',
                            controller: 'CreatePlanCareController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    predicate: PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search: $stateParams.search
                                };
                            }
                        ],
                    }
                });
    }
}());
