(function() {
	'use strict';

	angular.module('crmApp').controller('OrderDetailEditPaymentController',
			OrderDetailEditPaymentController);

	OrderDetailEditPaymentController.$inject = ['$rootScope', 'Order', 'entity', '$scope',
			'DateFormatService', 'SettingUtils', '$uibModalInstance' ];

	function OrderDetailEditPaymentController($rootScope, Order, entity, $scope,
			DateFormatService, SettingUtils, $uibModalInstance) {
		var vm = this;
		var temp = null;
		if (entity.paymentDueDate) {
			temp = new Date(entity.paymentDueDate);
		}
		vm.order = entity;
		vm.loadAll = loadAll;
		var PAYMENT_TYPE = 'PAYMENT_TYPE';
		vm.loadAll();
		function getDataFromSetting() {
			SettingUtils.get({
				'keys' : [ PAYMENT_TYPE ]
			}, function(success) {
				vm.paymentTypies = [];
				angular.forEach(success, function(value, key) {
					if (value.key === PAYMENT_TYPE) {
						vm.paymentTypies.push(value);
					}
				});
			}, function(error) {
			});
		}

		function loadAll() {
			configDateTime();
			getDataFromSetting();
		}
		function configDateTime() {
			if (vm.order.paymentDueDate) {
				vm.order.paymentDueDate = new Date(vm.order.paymentDueDate);
			}
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = {
				startingDay : 1,
				dateDisabled : disabled
			};
			function disabled(data) {
				var nowDate = new Date();
				nowDate = nowDate.setDate(nowDate.getDate() - 1);
				var date = data.date, mode = data.mode;
				return date < nowDate;
			}
			vm.openDateFrom = function() {
				vm.isDateFromOpened = true;
			}

			vm.datePickerOpenStatus = function() {
				vm.isOrderDateOpened = true;
			}
		}
		vm.savePayment = function() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.editPayment.$invalid) {
				var invalidControl = angular.element('input.ng-invalid')
						.first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else {
				Order.update(vm.order, onUpdateSuccess, onUpdateError);
				$scope.$broadcast('show-errors-reset');
			}
		}

		var onUpdateSuccess = function(response) {
			$scope.$emit('crmApp:orderUpdate', response);
			$uibModalInstance.close(null);
		}

		var onUpdateError = function(response) {
		}

		vm.close = function() {
			$uibModalInstance.close(temp);
		}
		$rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
	}
})();
