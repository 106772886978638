(function() {
    'use strict';

    angular.module('crmApp').controller('ProductCareController',
        ProductCareController);

    ProductCareController.$inject = ['$uibModal', '$state', 'UserUtils', 'CommonConstants',
        'SettingUtils', 'Product', 'ParseLinks', 'AlertService',
        'pagingParams', 'paginationConstants', '$http', 'CheckPhone', '$scope', '$resource'
    ];

    function ProductCareController($uibModal, $state, UserUtils, CommonConstants,
        SettingUtils, Product, ParseLinks, AlertService, pagingParams,
        paginationConstants, $http, CheckPhone, $scope, $resource) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.searchOrderHandler = searchOrderHandler;
        vm.predicate = 'lastModifiedDate';
        vm.reverse = false;
        vm.transition = transition;
        vm.page = 1;
        vm.entries = '10';
        vm.totalItems = null;
        vm.showItem = 10;
        vm.showName = "đơn hàng"
        vm.currentSearch = null;
        vm.searchOrderUseCriteria = searchOrderUseCriteria;

        loadAll();

        function loadAll() {
            getDataFromSetting();
            getCareConfiguration();
            vm.loadPage(false);
        }

        /*vm.initAllProductCare = function() {
            $http.get('/api/init-all-product-care').success(function(success) {
            });
        }*/

        //
        vm.careConfigurations = [];
        function getCareConfiguration() {
                $http.get('/api/get-all-care-configuration').success(function(success) {
                    vm.careConfigurations = success;
                });
        }
        function getDataFromSetting() {
            var ORDER_CATEGORY = 'ORDER_CATEGORY';
            SettingUtils.get({
                'keys': [ORDER_CATEGORY]
            }, function(success) {
                vm.orderCategories = [];
                angular.forEach(success, function(value, key) {
                  //Khong tinh DH tra(order refund)
                    if(value.value != 4){
                      vm.orderCategories.push(value);
                    }
                });
            }, function(error) {});
        }

        function transition() {
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function searchOrderHandler(keyEvent) {
            if (keyEvent.which === 13) {
                vm.page = 1;
                vm.predicate = 'lastModifiedDate';
                vm.reverse = false;
                vm.loadPage(false);
            }
        }

        // vm.detailCare = function(orderCode, careCode){
        //     $state.go('create-product-care', {order:orderCode, care: careCode});
        // }

        function loadPage(isSearchWithConditionBefore) {
            var ProductCare = $resource('api/orders-for-product-care');
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                ProductCare.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.productCarePlanCO) {
                    vm.productCarePlanCO = {};
                }

                vm.productCarePlanCO.phoneNum = CheckPhone.isPhoneNumber(vm.productCarePlanCO.customerPhone);
                vm.productCarePlanCO.page = vm.page - 1;
                vm.productCarePlanCO.size = vm.entries;
                vm.productCarePlanCO.sort = sort();
                vm.currentSearch = angular.copy(vm.productCarePlanCO);
                vm.showItem = vm.entries;
                ProductCare.query(vm.currentSearch, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'lastModifiedDate') {
                    result.push('lastModifiedDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.ProductCares = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function searchOrderUseCriteria() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.searchForm.$invalid) {
                var invalidControl = angular.element('input.ng-invalid')
                    .first() ||
                    angular.element('select.ng-invalid').first() ||
                    angular.element('option.ng-invalid').first();
                if (invalidControl != null) {
                    invalidControl.focus();
                }
            } else {
                vm.page = 1;
                vm.predicate = 'lastModifiedDate';
                vm.reverse = false;
                vm.loadPage(false);
            }
        }
    }
})();
