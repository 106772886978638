(function() {
    'use strict';

    angular.module('crmApp').controller('DepartmentController',
        DepartmentController);

    DepartmentController.$inject = ['$rootScope', 'AlertService', '$uibModal', 'pagingParams', '$scope', '$state', '$http', 'SettingUtils', 'Team', 'ParseLinks'];

    function DepartmentController($rootScope, AlertService, $uibModal, pagingParams, $scope, $state, $http, SettingUtils, Team, ParseLinks) {
        var vm = this;
        var TEAM_TYPE = 'TEAM_TYPE';
        vm.userSalesLeaders = [];
        vm.teamTypes = [];

        vm.loadAll = loadAll;
        vm.loadAll();

        vm.search = search;
        vm.loadPage = loadPage;

        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.currentSearch = null;
        vm.transition = transition;
        vm.entries = '10';
        vm.showItem = 10;
        vm.isNotSelected = true;
        vm.selectedAll = false;

        vm.page = 1;
        vm.totalItems = null;
        vm.showName = "nhóm";

        vm.checkAll = false;
        vm.idCheckeds = null;
        // check all
        vm.checkAll = function() {
            var i = 0;
            if (vm.selectedAll) {
                vm.selectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.selectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.teams, function(team) {
                i++;
                if (i <= vm.entries)
                    team.selected = vm.selectedAll;
            });
            vm.checkTeam();
        };
        // check box
        vm.checkTeam = function() {
            vm.idCheckeds = [];
            angular.forEach(vm.teams, function(team) {
                if (!!team.selected) {
                    vm.idCheckeds.push(team.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.selectedAll = false;
            } else if (vm.idCheckeds.length === vm.teams.length) {
                vm.selectedAll = true;
            } else {
                vm.isNotSelected = false;
                vm.selectedAll = false;
            }

        }
        // function load all
        function loadAll() {
           getUserSalesLeaderHttp();
           getDataFromSetting();
           search();
       }
				// function search
				function search() {
					vm.page = 1;
					vm.predicate = 'id';
					vm.reverse = false;
					loadPage();
				};

				function loadPage(isSearchWithConditionBefore){
					if (vm.currentSearch && isSearchWithConditionBefore) {
							//search with condition before
							vm.currentSearch.page = vm.page - 1;
							vm.currentSearch.sort = sort();
							Team.query(vm.currentSearch, onSuccess, onError);
                       } else {
                         if (!vm.teamCO) {
                           vm.teamCO = {};
                       }
                       vm.teamCO.page = vm.page - 1;
                       vm.teamCO.size = vm.entries;
                       vm.teamCO.sort = sort();
                       vm.currentSearch = angular.copy(vm.teamCO);
                       vm.showItem = vm.entries;
                       Team.query(vm.currentSearch, onSuccess, onError);
                   }
               };

				// transition
                function transition() {
            // if (vm.currentSearch) {
                loadPage(true);
            // }
        }

				// sort
                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }
        //
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.teams = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        // function delete department
        vm.deleteTeam = function(teamId) {
            vm.modalInstance = $uibModal.open({
                template: '<popup-delete-component  title="vm.title" content="vm.content" close="vm.close()" delete="vm.delete()"></popup-delete-component>',
                size: 'md',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.title = "Xác nhận xóa thông tin nhóm";
                    vm.content = "Bạn có chắc chắn muốn xóa thông tin nhóm này? ";
                    //
                    vm.close = function() {
                        closePopup();
                    }
                    // code delete
                    vm.delete = function() {
                        $http.get('/api/team-delete?id=' + teamId)
                                .success(function(success) {
                                   $state.go("department", {}, {reload: true});
                                });
                        closePopup();
                    }
                },
                controllerAs: "vm"
            });

            function closePopup() {
                vm.modalInstance.dismiss('cancel');
                $rootScope.$on('close-modal', function(){
                    vm.modalInstance.dismiss('cancel');
                });
            }
        }
        
         // function multi delete department
        vm.deleteMultiTeam = function() {
            var teamIds = [];
            angular.forEach(vm.teams, function(team) {
                    if (team.selected) {
                        teamIds.push(team.id);
                    }
            })
            if(teamIds.length > 0){
                vm.modalInstanceTeam = $uibModal.open({
                    template: '<popup-delete-component  title="vm.title" content="vm.content" close="vm.close()" delete="vm.delete()"></popup-delete-component>',
                    size: 'md',
                     backdrop: 'static',
                    scope: $scope,
                    controller: function() {
                        var vm = this;
                        vm.title = "Xác nhận xóa thông tin nhóm";
                        vm.content = "Bạn có chắc chắn muốn xóa những thông tin nhóm này? ";
                        //
                        vm.close = function() {
                            closePopup();
                        }
                        // code delete
                        vm.delete = function() {
                              $http.get('/api/team-delete?id=' + teamIds)
                                .success(function(success) {
                                   $state.go("department", {}, {reload: true});
                                });
                                closePopup();
                        }
                    },
                    controllerAs: "vm"
                });
            }


            function closePopup() {
                vm.modalInstanceTeam.dismiss('cancel');
            }
        }

        // get user sales leader
        function getUserSalesLeaderHttp() {
            $http.get('/api/users-sales-leader').success(function(success) {
                if (success && success.length > 0) {
                    vm.userSalesLeaders = success;
                }
            });
        };

        function getDataFromSetting() {
            SettingUtils.get({
                'keys': [TEAM_TYPE]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === TEAM_TYPE) {
                        vm.teamTypes.push(value);
                    }
                });

                /*if (vm.teamTypes.length == 1) {
                    if (!vm.team) {
                        vm.team = {};
                    }
                    vm.team.teamTypeId = vm.teamTypes[0].value;
                }*/
            }, function(error) {});
        };


    } // end controller
})();
