(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('HeaderService', HeaderService);

    HeaderService.$inject = [];

    function HeaderService() {
        var vm = this;
        var Code = {
            CREATE_SUCCESS: "1001",
            UPDATE_SUCCESS: "1002",
            DELETE_SUCCESS: "1003"
        };

        var Entity = {
            CareHistory: "CareHistory",
            CareMission: "CareMission",
            CarePlan: "CarePlan",
            Customer: "Customer",
            EmailTemplate: "EmailTemplate",
            Order: "Order",
            Payment: "Payment",
            Product: "Product",
            Role: "Role",
            User: "User",
            GeneralSetting: "GeneralSetting"
        };

        vm.createAlertMessageForEntityCreation = function(action, entityName) {
            var alert = '';
            if (action === Code.CREATE_SUCCESS) {
                alert += 'Lưu ';
            } else if (action === Code.UPDATE_SUCCESS) {
                alert += 'Cập nhật ';
            } else if (action === Code.DELETE_SUCCESS) {
                alert += 'Xóa ';
            }

            if (entityName === Entity.CareHistory) {
                alert += 'báo cáo';
            } else if (entityName === Entity.CareMission) {
                alert += 'chăm sóc khách hàng';
            } else if (entityName === Entity.CarePlan) {
                alert += 'lập lịch';
            } else if (entityName === Entity.Customer) {
                alert += 'khách hàng';
            } else if (entityName === Entity.EmailTemplate) {
                alert += 'mẫu email';
            } else if (entityName === Entity.Order) {
                alert += 'đơn hàng';
            } else if (entityName === Entity.Payment) {
                alert += 'thanh toán';
            } else if (entityName === Entity.Product) {
                alert += 'sản phẩm';
            } else if (entityName === Entity.Role) {
                alert += 'vai trò';
            } else if (entityName === Entity.User) {
                alert += 'nhân viên';
            } else if (entityName === Entity.GeneralSetting) {
                alert += 'cấu hình';
            }

            alert += ' thành công';

            return alert;
        }

        //end function
    }
}());
