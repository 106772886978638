(function() {
	'use strict';

	angular.module('crmApp').controller('StockWatchGoodIssueController',
			StockWatchGoodIssueController);

	StockWatchGoodIssueController.$inject = ['$state'];

	function StockWatchGoodIssueController($state) {

		var vm = this;
		
	}
})();