(function() {
	'use strict';

	EmailSendWithAttachment.$inject = [ '$http' ];

	function EmailSendWithAttachment($http) {
		var service = this;
		service.sendWithAttachment = function(uploadUrl, mailTo, subject,
				content, files, successFunc) {
			var fd = new FormData();

			//Take the first selected file
			fd.append("mailTo", mailTo);
			fd.append("subject", subject);
			fd.append("content", content);

			if (files) {
				for (var i = 0; i < files.length; i++) {
					fd.append("file", files[i]);
				}
			}

			$http.post(uploadUrl, fd, {
				withCredentials : true,
				headers : {
					'Content-Type' : undefined
				},
				transformRequest : angular.identity
			}).success(successFunc).error(function(data, status) {
			});
		};
	}

	angular.module('crmApp').service('EmailSendWithAttachment',
			EmailSendWithAttachment);
}());
