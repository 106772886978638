(function () {
	'use strict';

	angular
	.module('crmApp')
	.controller('OrderCreateUpdateWorkerDialogController', OrderCreateUpdateWorkerDialogController);

	OrderCreateUpdateWorkerDialogController.$inject = ['$rootScope','$scope', '$uibModalInstance', 'UploadFileService'];
	function OrderCreateUpdateWorkerDialogController($rootScope, $scope, $uibModalInstance, UploadFileService) {
		var vm = this;

		vm.workers = [];
		vm.reset = reset;

		// upload file
		vm.isUploadFileInProgress = false;
		var fd = new FormData();
		vm.uploadFileNameList = [];
		// flag validation
		vm.validation = true;

		vm.saveWorker = function () {
			$scope.$broadcast('show-errors-check-validity');
			if($scope.addWorker.$invalid){
				var invalidControl = angular.element('input.ng-invalid').first() || angular.element('select.ng-invalid').first() || angular.element('option.ng-invalid').first();
				if(invalidControl != null){
					invalidControl.focus();
				}
			}else{
				var worker = {
					selected : true,
					productCode : 'nc',
					productName : vm.productName,
					productImg : vm.productImg,
					unitOfAccount : vm.unitOfAccount,
					retailPrice : vm.retailPrice,
					memo : vm.memo
				}
				vm.workers.push(worker);
				vm.selectedAll = true;
				vm.reset();
				$scope.$broadcast('show-errors-reset');
			}
		}

		function reset() {
			vm.productName = null,
			vm.productImg = null,
			vm.unitOfAccount = null,
			vm.retailPrice = "",
			vm.memo = null
		}

		vm.addWorker = function () {
			var newWorkers = [];
			if(vm.workers.length === 0){
				vm.displayError = "Chưa có nhân công để thêm. Vui lòng chọn một nhân công.";
			}else{
				angular.forEach(vm.workers, function (worker) {
					if(worker.selected) {
						newWorkers.push(worker);
					}
				})
				vm.workers = newWorkers;
				$uibModalInstance.close(vm.workers);
			}
			
		}

		function isImage(fileType) {
			if (fileType.indexOf("image") !== -1) return true;
			return false;
		}

		function getExtensionFile(fileName) {
			return fileName.split('.').pop();
		}

		$scope.fileSelected = function(element) {
			vm.isUploadFileInProgress = true;
            // if not exits file return
            // if file more than 32MB then return
            if (!element.files[0] || (element.files[0].size/1024/1024 > 32)) {
            	return;
            }
            // if not image return
            if(!isImage(element.files[0].type)){
            	return;
            }

            // delete file in FormData if exist
            if (fd.has("file")) {
            	fd.delete("file");
            }
            // validate file name
            var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
            var validateFileName = fileName + new Date().getTime();
            var extension = getExtensionFile(element.files[0].name);
            var finalFileName = validateFileName + "." + extension;

            fd.append("file", element.files[0]);
            fd.set("file", element.files[0], finalFileName);


            UploadFileService.uploadOneFile('/api/save-product-image', fd, function(success, status) {
            	vm.productImg = finalFileName;
            	vm.isUploadFileInProgress = false;
            	vm.uploadFileNameList.push(finalFileName);
            }, function(error, status) {
            	vm.isUploadFileInProgress = false;
            });
        }

        function deleteUploadFileButNoSave(fileNames) {
        	$http({
        		method: 'GET',
        		url: '/api/delete-product-image',
        		params: {
        			fileNames: fileNames
        		}
        	});
        };

        vm.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };
         $rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
    }
})();