(function() {
	'use strict';
	angular.module('crmApp').factory('CustomerPhone', CustomerPhone);

	CustomerPhone.$inject = [ '$resource', '$state' ];

	function CustomerPhone($resource, $state) {
		var resourceUrl = 'api/customers/phone/:phoneNum';

		return $resource(resourceUrl, {}, {
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data !== null && data !== undefined && data !== '') {
						data = angular.fromJson(data);
					}
					return data;
				},
				params:{
	        phoneNum:'@phoneNum'
		    }
			}
		});
	}
})();
