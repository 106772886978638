(function() {
    'use strict';
    var popupDeleteComponent = {
        templateUrl: 'app/template/popup-delete.html',
        controller: popupDeleteComponentController,
        bindings: {
            title:"<",
            content:"<",
            close:"&",
            delete:"&"
        }
    };

    popupDeleteComponentController.inject = [];

    function popupDeleteComponentController() { 
        var $ctrl = this;
        // function close popup
        $ctrl.clodePopup = function(){
            $ctrl.close();
        }
         // function confirm delete
        $ctrl.deleteInform = function(){
            $ctrl.delete();
        }
    }
    angular.module('crmApp').component('popupDeleteComponent', popupDeleteComponent);
})();
