(function() {
	'use strict';

	angular.module('crmApp').controller('UserManagementDetailController',
			UserManagementDetailController);

	UserManagementDetailController.$inject = [ '$http', '$stateParams', 'User',
			'roles', 'entity' ];

	function UserManagementDetailController($http, $stateParams, User, roles,
			entity) {
		var vm = this;

		// vm.load = load;
		vm.user = entity;

		// vm.load($stateParams.login);
		vm.roles = roles;

		// function load(login) {
		// User.get({
		// login: login
		// }, function(result) {
		// vm.user = result;
		// });
		// }

	}
})();
