(function() {
	'use strict';
	angular.module('crmApp').factory('EmailSent', EmailSent);

	EmailSent.$inject = [ '$resource' ];

	function EmailSent($resource) {
		var resourceUrl = 'api/email-sents/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});
	}
})();
