(function() {
	'use strict';

	angular.module('crmApp').controller('ToDoController', ToDoController);

	ToDoController.$inject = [ '$scope' ];

	function ToDoController($scope) {
		var vm = this;
	}
})();
