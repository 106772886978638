(function() {
    'use strict';

    angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

    stateAbsConfig.$inject = ['$stateProvider'];

    function stateAbsConfig($stateProvider) {
        $stateProvider.state('customer-care', {
            url: '/customer-care',
            parent: 'app',
            abstract: true,
        });
    }

    stateConfig.inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('customer-care.detail', {
                parent: 'customer-care',
                url: '/customer-care-detail/{id}',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Chăm sóc khách hàng'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/customer-care/customer-care-detail.html',
                        controller: 'CustomerCareDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    entity: ['CustomerCare', '$stateParams', function(CustomerCare, $stateParams) {
                        if ($stateParams.id) {
                            return CustomerCare.get({
                                id: $stateParams.id
                            }).$promise;

                        } else {
                            return null;
                        }
                    }],
                    idCarePlan: function() {
                        return null;
                    }
                }
            })
            .state('customer-care.detail-with-phone-number', {
                    parent: 'customer-care',
                    url: '/customer-care-detail/phone/{phoneNum}',
                    data: {
                        authorities: ['CUSTOMER_CARE'],
                        pageTitle: 'Chăm sóc khách hàng'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/customer-care/customer-care-detail.html',
                            controller: 'CustomerCareDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        entity: ['CustomerCarePhone', '$stateParams', function(CustomerCarePhone, $stateParams) {
                            if ($stateParams.phoneNum) {
                                return CustomerCarePhone.get({
                                    phoneNum: $stateParams.phoneNum
                                }).$promise;

                            } else {
                                return null;
                            }
                        }],
                        idCarePlan: function() {
                            return null;
                        }
                    }
                })
            .state('customer-care.list', {
                parent: 'customer-care',
                url: '/customer-care-list',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Danh sách khách hàng chăm sóc'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/customer-care/customer-cares.html',
                        controller: 'CustomerCareController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams : [
                    '$stateParams',
                    'PaginationUtil',
                    function($stateParams, PaginationUtil) {
                        return {
                            page : PaginationUtil
                                    .parsePage($stateParams.page),
                            sort : $stateParams.sort,
                            predicate : PaginationUtil
                                    .parsePredicate($stateParams.sort),
                            ascending : PaginationUtil
                                    .parseAscending($stateParams.sort),
                            search : $stateParams.search
                        };
                    } ]
                }
            })
            .state('customer-care.delete', {
                parent: 'customer-care.detail',
                url: '/{id}/delete',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Xóa khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-dialog.html',
                        controller: 'CustomerCareDeleteDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'md',
                        resolve: {

                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('customer-care-detail-update', {
                parent: 'customer-care.detail',
                url: '/{idCarePlan}/update',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Cập nhật thông tin chăm sóc khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-update-plan-dialog.html',
                        controller: 'CustomerCareUpdatePlanDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            idCarePlan: function() {
                                return $stateParams.idCarePlan;
                            },
                            entity: function() {
                                return null;
                            }
                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('customer-care-detail-update-with-phone-number', {
                parent: 'customer-care.detail-with-phone-number',
                url: '/{idCarePlan}/update',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Cập nhật thông tin chăm sóc khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-update-plan-dialog.html',
                        controller: 'CustomerCareUpdatePlanDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            idCarePlan: function() {
                                return $stateParams.idCarePlan;
                            },
                            entity: function() {
                                return null;
                            }
                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail-with-phone-number', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('customer-care-detail-report', {
                parent: 'customer-care.detail',
                url: '/{idCarePlan}/report',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Báo cáo chăm sóc khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-report-plan-dialog.html',
                        controller: 'CustomerCareReportPlanDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            idCarePlan: function() {
                                return $stateParams.idCarePlan;
                            },
                            entity: function() {
                                return null;
                            }
                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('customer-care-detail-report-with-phone-number', {
                parent: 'customer-care.detail-with-phone-number',
                url: '/{idCarePlan}/report',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Báo cáo chăm sóc khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-report-plan-dialog.html',
                        controller: 'CustomerCareReportPlanDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            idCarePlan: function() {
                                return $stateParams.idCarePlan;
                            },
                            entity: function() {
                                return null;
                            }
                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail-with-phone-number', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('history-plan-delete-dialog-delete', {
                parent: 'customer-care.detail',
                url: '/{idCarePlan}/delete',
                data: {
                    authorities: ['CUSTOMER_CARE'],
                    pageTitle: 'Xóa lịch sử kế hoạch chăm sóc khách hàng'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/customer-care/customer-care-detail-history-plan-delete-dialog.html',
                        controller: 'CustomerCarePlanDeleteDialogController',
                        controllerAs: 'vm',
                         backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['CustomerCare', '$stateParams', function(CustomerCare, $stateParams) {
                                if ($stateParams.id) {
                                    return CustomerCare.get({
                                        id: $stateParams.id
                                    }).$promise;

                                } else {
                                    return null;
                                }
                            }],
                            carePlanId: function() {
                                return $stateParams.idCarePlan;
                            }
                        }
                    }).result.then(function() {
                        $state.go('customer-care.detail', null, {
                            reload: true,
                            notify: true,
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
}());
