(function() {
    'use strict';

    angular
    .module('crmApp')
    .directive('isDate', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {
            function validate(value) {

                // var d = Date.parse(value);
                var d = new Date(value);
                if (angular.isDate(value)) { // d.valueOf() could also work
                    ngModel.$setValidity('valid', false);
                } else {
                    ngModel.$setValidity('valid', true);
                }
                // it is a date
                // if (isNaN(d)) { // d.valueOf() could also work
                //     ngModel.$setValidity('valid', false);
                // } else {
                //     ngModel.$setValidity('valid', true);
                // }


            }

            scope.$watch(function () {
                return ngModel.$viewValue;
            }, validate);
        }
    };
});
})();
