(function() {
    'use strict';

    angular
        .module('crmApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (!(response.status === 401 && (response.data === '' 
            	|| (response.data.path !== undefined && response.data.path.indexOf('/api/account') === 0 )))) {
            	if (response.data && response.data.error && response.data.error == 'invalid_token') {
            		// do nothing
            	} else {
            		$rootScope.$emit('crmApp.httpError', response);
            	}
            }
            return $q.reject(response);
        }
    }
})();
