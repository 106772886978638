(function() {
    'use strict';

    angular.module('crmApp').controller('DepartmentCreateUpdateController',
        DepartmentCreateUpdateController);

    DepartmentCreateUpdateController.$inject = ['entity', '$scope', '$state', '$http', 'SettingUtils', 'Team'];

    function DepartmentCreateUpdateController(entity, $scope, $state, $http, SettingUtils, Team) {
        var vm = this;
        var TEAM_TYPE = 'TEAM_TYPE';
        vm.change = false;
        vm.team = entity;

        vm.userSalesLeaders = [];
        vm.teamTypes = [];

        vm.initialization = initialization;
        vm.initialization();
        // function load data first
        function initialization() {
            getUserSalesLeaderHttp();
            getDataFromSetting();
        }

        // get user sales leader
        function getUserSalesLeaderHttp() {
            $http.get('/api/users-sales-leader').success(function(success) {
                if (success && success.length > 0) {
                    vm.userSalesLeaders = success;
                }
            });
        };
        // get data from setting
        function getDataFromSetting() {
            SettingUtils.get({
                'keys': [TEAM_TYPE]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === TEAM_TYPE) {
                        vm.teamTypes.push(value);
                    }
                });

                if (vm.teamTypes.length == 1) {
                    if (!vm.team) {
                        vm.team = {};
                    }
                    vm.team.teamTypeId = vm.teamTypes[0].value;
                }
            }, function(error) {});
        };
        // function save department
        vm.save = function() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.departmentForm.$invalid) {
                var invalidControl = angular.element('.form-control.ng-invalid').first();
                if(invalidControl.length != 0){
                    invalidControl.focus();
                }
            } else {
                if (vm.team.id != undefined) {
                    Team.update(vm.team, onSaveSuccess, onSaveError);
                } else {
                    vm.change = true;
                    Team.save(vm.team, onSaveSuccess, onSaveError);
                }
            }
        };
        //  function onSaveSuccess
        function onSaveSuccess(success) {
            if (vm.change) {
                $state.go('department', null, {
                    reload: true
                });
            }
        };
        // function onSaveError
        function onSaveError() {

        };
    } //end controller
})();
