(function() {
    'use strict';

    angular
    .module('crmApp')
    .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$scope','Auth', 'Principal'];

    function PasswordController ($scope, Auth, Principal) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
           $scope.$broadcast('show-errors-check-validity');
           if($scope.form.$invalid){
            angular.element('input.ng-invalid').first().focus();
        }else{
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword({oldPassword : vm.oldPassword, password: vm.password}).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    //vm.error = 'ERROR';
                });
            }
        }

    }
}
})();
