(function() {
	'use strict';

	angular.module('crmApp').controller('NotificationCareProductController',
		NotificationCareProductController);

	NotificationCareProductController.$inject = ['$scope', '$resource', '$http'];

	function NotificationCareProductController($scope, $resource, $http) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.getAllOrderDetailcare = getAllOrderDetailcare;
		function loadAll(){
			vm.getAllOrderDetailcare();
		}
		function getAllOrderDetailcare(){
			$http.get('/api/get-all-order-detail-care').then(function(success) {
	            vm.orderDetailCares = success.data;
	        });
		}
		vm.loadAll();
		vm.limit = 10;
		vm.loadMore = function(){
			var increamented = vm.limit + 10;
      		vm.limit = increamented > vm.orderDetailCares.length ? vm.orderDetailCares.length : increamented;
		}
	}
})();