(function() {
	'use strict';

	angular.module('crmApp').controller('UserManagementDeleteController',
			UserManagementDeleteController);

	UserManagementDeleteController.$inject = ['$rootScope', '$state', 'UserUtils', 'AlertService',
			'CommonConstants', '$uibModalInstance', 'entity', 'User', '$http' ];

	function UserManagementDeleteController($rootScope, $state, UserUtils, AlertService,
			CommonConstants, $uibModalInstance, entity, User, $http) {
		var vm = this;
		vm.clear = clear;
		vm.loginCheckeds = entity;
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		$rootScope.$on('close-modal', function(){
            clear();
        });
		vm.confirmDelete = function() {
			if (vm.loginCheckeds != "") {
				if (UserUtils.hasAuthorityName(CommonConstants.USER_DELETE)) {
					$http.get('/api/users-delete?id=' + vm.loginCheckeds)
							.success(function(success) {
								$state.reload();
								$uibModalInstance.close(true);
							});
				} else {
					AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
					$uibModalInstance.dismiss('cancel');
				}
			}
		};

	}
})();
