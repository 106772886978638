(function() {
	'use strict';

	angular.module('crmApp').controller('CheckStatusViettelPostController', CheckStatusViettelPostController);

	CheckStatusViettelPostController.$inject = ['entity', '$localStorage', '$http', '$uibModalInstance'];

	function CheckStatusViettelPostController(entity, $localStorage, $http, $uibModalInstance) {
        var vm = this;
        vm.order = entity;
        vm.viettelPostStatus = {};

        vm.updateStatus = function () {
            var token = $localStorage.viettelPostAccount.tokenKey;
            vm.viettelPostStatus.ORDER_NUMBER = vm.order.viettelPostId;
            $http({
                url: "/api/update-viettel-post-status",
                method: "POST",
                params: {
                        token: token
                    },
                data: vm.viettelPostStatus
            }).success(function (response) {
                $uibModalInstance.close();
            }).error(function (response) {
                $uibModalInstance.close();
            })
        }

        vm.close = function() {
			$uibModalInstance.close();
		}
	}
})();
