(function() {
	'use strict';

	angular.module('crmApp').controller('ProductDetailController',
			ProductDetailController);

	ProductDetailController.$inject = [ '$scope', '$http', '$rootScope',
			'$stateParams', 'entity', 'Product' ];

	function ProductDetailController($scope, $http, $rootScope, $stateParams,
			entity, Product) {
		var vm = this;

		vm.products = entity;
		vm.load = load;

		vm.load();

		function load() {
		}
		;

		var unsubscribe = $rootScope.$on('crmApp:productUpdate', function(
				event, result) {
			vm.Product = result;
		});

		$scope.$on('$destroy', unsubscribe);
	}
})();
