(function() {
    'use strict';

    angular.module('crmApp').controller('InformationConfigurationController',
        InformationConfigurationController);

    InformationConfigurationController.$inject = ['$state', '$rootScope', '$uibModal', '$scope', 'InformationConfiguration', 'entity', 'AlertService'];

    function InformationConfigurationController($state, $rootScope, $uibModal, $scope, InformationConfiguration, entity, AlertService) {
        var vm = this;
        vm.information = entity;
        vm.result = [];
        $rootScope.$on('crmApp:addInformationSuccess', function(event, info) {
            saveValue(info);
        });
        vm.addInformation = function() {
            // body...
            vm.modalInstanAdd = $uibModal.open({
                template: '<information-component close="vm.close()" message="vm.message" model="vm.information" show="vm.showInput" save="vm.save()" edit-mode="false"></information-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.information = "";
                    vm.showInput = true;
                    vm.message = "THÊM THÔNG TIN CẤU HÌNH";

                    vm.save = function() {
                        // code save
                        closePopupAdd();
                    }

                    vm.close = function() {
                        closePopupAdd();
                    }
                },
                controllerAs: "vm"
            });

            function closePopupAdd() {
                vm.modalInstanAdd.close();

            }
        }

        vm.editInformation = function(information) {

            vm.modalInstanEdit = $uibModal.open({
                template: '<information-component close="vm.close()" message="vm.message" model="vm.information" show="vm.showInput" save="vm.save()" edit-mode="true"></information-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.information = information;
                    vm.showInput = true;
                    vm.message = "CHỈNH SỬA THÔNG TIN CẤU HÌNH";

                    vm.close = function() {
                        closePopupEdit();
                    }

                    vm.save = function() {
                        // code save
                        closePopupEdit();
                    }

                },
                controllerAs: "vm"
            });

            function closePopupEdit() {
                vm.modalInstanEdit.close();
            }
        }

        function saveValue(value) {
						if(!value.id){
							var check = false;
							var infor = null;
							for(var i = 0; i < vm.information.length; i++){
								infor = vm.information[i];
								if(infor.key == value.key && infor.group == value.group){
									check = true;
									break;
								}
							}
							if(check){
								AlertService.error("Từ Khóa và Nhóm bị trùng.");
								return;
							}
						}
						vm.result = [];
						vm.result.push(value);
            InformationConfiguration.update(vm.result, onSaveSuccess, onError);
        }

        function onSaveSuccess() {
            vm.result = [];
            InformationConfiguration.get({}, function(success) {
                vm.information = success;
            }, function(error) {})
        }

        function onError(error) {
            vm.result = [];
            AlertService.error(error.data.message);
        }
    }
})();
