(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('StringUtils', StringUtils);

    StringUtils.$inject = [];

    function StringUtils() {
        var vm = this;
        
        vm.isNotBlank = function (string) {
            if (string == null || string == undefined || string.trim() == "") {
                return false;
            }
            return true;
        }
            
    }
}());
