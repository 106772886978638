(function() {
    'use strict';

    angular.module('cacheBusterHtml', []).config(["$provide", function($provide) {
        /*return $provide.decorator("$http", ["$delegate", "CommonConstants", function($delegate, CommonConstants) {
            var get = $delegate.get;
            $delegate.get = function(url, config) {
                if(url.indexOf('app/') != -1 && !~url.indexOf('template/')){
                    // app {project.version}/ to the beginning of the url
                    url = (CommonConstants.CURRENT_VERSION + "/" + url);
                }
                return get(url, config);
            };
            return $delegate;
        }]);*/
    }]);
}());
