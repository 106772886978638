(function() {
	'use strict';

	angular.module('crmApp').controller('OrderDeleteController',
			OrderDeleteController);

	OrderDeleteController.$inject = [ 'UserUtils', 'AlertService',
				'CommonConstants', '$uibModalInstance', 'entity', '$http', 'Order', '$state', '$rootScope' ];


	function OrderDeleteController(UserUtils, AlertService, CommonConstants,
				$uibModalInstance, entity, $http, Order, $state, $rootScope) {
		var vm = this;


		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		vm.idCheckeds = entity;
		$rootScope.$on('close-modal', function(){
	        vm.clear();
	    });
		vm.confirmDelete = function() {
			if (vm.Order != "") {
				if (UserUtils.hasAuthorityName(CommonConstants.ORDER_DELETE)) {
					$http.get('/api/orders-delete?id=' + vm.idCheckeds)
							.success(function(success) {
								$state.reload();
							});
					$uibModalInstance.close(true);
				} else {
					AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
					$uibModalInstance.dismiss('cancel');
				}
			}
		};

	}
})();
