(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('ConvertMoneyToWordService', ConvertMoneyToWordService);

        ConvertMoneyToWordService.$inject = [];
        function ConvertMoneyToWordService(){
        	var vm = this;

        	var dictionnaryNumbers = ['', 'Một ', 'Hai ', 'Ba ', 'Bốn ', 'Năm ', 'Sáu ', 'Bảy ', 'Tám ', 'Chín '];
	        var dictionnaryUnits = ['', 'Tỷ', 'Triệu', 'Nghìn', 'Đồng'];

	        vm.readNumber3Digits = function (num, readFull) {
				readFull = true;
	            if ((num = num.toString()).length > 3) return '';
	            var unitsHundreds = parseInt(num / 100);
	            var unitsTens = parseInt((num % 100) / 10);
	            var units = num % 10;
	            var result = "";
	            var resultUH = "";
	            var resultUT = "";
	            var resultU = "";

	            // Xử lý hàng trăm
	            if (unitsHundreds != 0) {
	                resultUH += dictionnaryNumbers[unitsHundreds] + " Trăm ";
	            }
	            // xử lý hàng chục
	            if (unitsTens != 0) {
	                resultUT = dictionnaryNumbers[unitsTens] + " Mươi ";
	            }

	            if (unitsTens == 0 && unitsHundreds != 0) resultUT = " Linh ";
	            if (unitsTens == 1) resultUT = " Mười ";

	            // xử lỹ hàng đơn vị
	            resultU += dictionnaryNumbers[units];
	            if (unitsTens > 1 && units == 1) resultU = " Mốt ";
	            if (unitsTens > 0 && units == 5) resultU = " Lăm ";
	            if (unitsTens == 0 && units == 0) {
	                resultU = "";
	                resultUT = "";
	            }

	            if (units == 0) {
	                resultU = "";
	            }

	            if (readFull == false) {
	                if (unitsHundreds == 0) resultUH = "";
	                if (unitsHundreds == 0 && unitsTens == 0) resultUT = "";
	            }

	            result += resultUH + resultUT + resultU;

	            return result;
	        }

	        vm.readNumber12Digits = function (number) {
	            if ((number = number.toString()).length > 12) return 'overflow';
	            var n = ('000000000000' + number).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/);
	            var index;
	            var keepGoing = true;
	            var readFull;
	            var result = [];
	            vm.endResult = "";
	            angular.forEach(n, function(value, key) {
	                if (keepGoing) {
	                    if (value !== "000") {
	                        index = key;
	                        keepGoing = false;
	                    }
	                }
	            });
	            angular.forEach(n, function(value, key) {
	                if (key >= index) {
	                    readFull = true;
	                    if (key == index) readFull = false;
	                    if (value !== "000") {
	                        result[key] = vm.readNumber3Digits(value, readFull) + " " + dictionnaryUnits[key];
	                    }
	                }
	            });
	            vm.endResult += result.join(" ");
	            if (vm.endResult.search("Đồng") === -1) {
	                vm.endResult += " Đồng ";
	            }
	            return " ( " + vm.endResult.replace(/\s+/g, ' ') + " ) ";
	        }
        }

 }());