(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('InforGeneral', InforGeneral);

        InforGeneral.$inject = ['$http'];
        function InforGeneral($http){
        	var vm = this;
        	vm.load = load;



			function load(){
				$http({
                url: '/api/general-setting',
	                method: 'GET'
	            }).then(function(response) {
	                vm.information = response.data;
	    //             angular.forEach(vm.information, function(value, key){
					//     if(value.key === vm.companyName.key)
					//         vm.companyName.value = value.value;
					//     if(value.key === vm.companyAddress.key)
					//         vm.companyAddress.value = value.value;
					//     if(value.key === vm.companyWebsite.key)
					//         vm.companyWebsite.value = value.value;
					//     if(value.key === vm.companyPhone.key)
					//         vm.companyPhone.value = value.value;
					//     if(value.key === vm.companyEmail.key)
					//         vm.companyEmail.value = value.value;
					//     if(value.key === vm.companyTaxCode.key)
					//         vm.companyTaxCode.value = value.value;

					// });
	            });
			}

        }

 }());
