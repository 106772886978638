(function () {
	'use strict';

	angular
	.module('crmApp')
	.controller('CustomerDeleteController', CustomerDeleteController);

	CustomerDeleteController.$inject = ['$scope', '$uibModalInstance'];
	function CustomerDeleteController($scope, $uibModalInstance) {
		var vm = this;

		vm.clear = function() {
        	$uibModalInstance.close(false);
        };

        vm.confirmDelete = function () {
        	$uibModalInstance.close(true);
        }
    }
})();
