(function() {
    'use strict';

    angular
        .module('crmApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'cacheBusterHtml',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'angular-loading-bar',
            'ui.toggle',
            'ui.select',
            'ui.swiper',
            // 'angular-growl',
            'ui.mention',
            'ui.multiselect',
            'ngImgCrop',
            'angular-web-notification'//,
            // 'scrollable-table'
        ])
        .config(currencyProvider)
        .run(run);

    currencyProvider.$inject = ['$provide'];
    function currencyProvider($provide){
        $provide.decorator('$locale', ['$delegate', function ($delegate) {
            $delegate.NUMBER_FORMATS.DECIMAL_SEP = '.';
            $delegate.NUMBER_FORMATS.GROUP_SEP = ',';
            return $delegate;
        }]);
    }
    run.$inject = ['stateHandler', '$rootScope', '$location'];

    function run(stateHandler, $rootScope, $location) {
        stateHandler.initialize();
        $rootScope.$on('$locationChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            $rootScope.actualLocation = $location.path();
        });

       $rootScope.$watch(function () {return $location.path()}, function (newLocation, oldLocation) {
            if($rootScope.actualLocation === newLocation) {
                $rootScope.$broadcast('close-modal');
            }
        });
    }
})();
