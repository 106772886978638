(function() {
    'use strict';
    var ddo = {
     template:'<div class="input-group-qty pull-left" show-errors>' +
         '<span class="pull-left"> </span>' +
         '<input next-on-enter  required type="text" name="vm.myIndex" ng-model="vm.myModel"' +
        ' min="1"  ng-blur="vm.inputNumber(vm.myIndex);" ' +
        ' ng-value="1" ng-class="vm.ngClass" class="vm.myClass input-number input--wd input-qty pull-left" value="1">' +
        ' <span class="pull-left btn-number-container">' +
        ' <button type="button" class="btn btn-number btn-number--plus"  ng-click="vm.plusNumber(vm.myIndex)" data-type="plus" data-field="myIndex"> + </button>' +
        ' <button type="button" class="btn btn-number btn-number--minus" ng-click="vm.minusNumber(vm.myIndex)" data-type="minus" data-field="myIndex"> – </button>' +
        ' </span></div>',
     scope: {
      myClass: '=',
            myModel:'=',
            myIndex:'<',
            ngClass: '<',
            conditionEnter: '<'
    },
     controller: templateQuantityController,
     controllerAs: 'vm',
     bindToController: true,
     link: DecimalPlacesDirectiveLink
 };

 return ddo;


 templateQuantityController.inject = [];

 function templateQuantityController() {
    var vm = this;
    vm.minusNumber = function(myIndex) {
        if (vm.myModel >= 1) {
            vm.myModel =  parseFloat(vm.myModel) - 1;
        }
    }

    vm.inputNumber = function(myIndex) {
        if ((vm.myModel !== null && vm.myModel !== undefined)) {
            var hasDataBefore = false;
            if (vm.myModel && vm.myModel.length > 0) {
                hasDataBefore = true;
            }
                // if (vm.myModel.length > 0) {
                //     vm.myModel = vm.myModel.toString().replace(/[^0-9-]+/, '');
                // }

                if (vm.myModel.length > 0 && vm.conditionEnter == false) {
                    vm.myModel = vm.myModel.toString().replace(/[^0-9-]+/, '');
                }else{
                   vm.myModel = vm.myModel.toString().replace(/[^\d|\-+|\.+]/g, '');
               }

               if (hasDataBefore && (vm.myModel === null || vm.myModel === undefined || vm.myModel === '')) {
                vm.myModel = 1;

            }
            if (hasDataBefore && vm.myModel.toString() === "0") {
                vm.myModel= 1;
            }
        }
        if(vm.myModel === undefined){
            vm.myModel= 1;
        }
    }
    vm.plusNumber = function(myIndex) {
        vm.myModel = parseFloat(vm.myModel) + 1;

    }
}

function DecimalPlacesDirectiveLink(scope,ele,attrs){
     ele.bind('keypress',function(e){
                var newVal=$(this).val()+(e.charCode!==0?String.fromCharCode(e.charCode):'');
                if($(this).val().search(/(.*)\.[0-9][0-9]/)===0 && newVal.length>$(this).val().length){
                    e.preventDefault();
                }
            });
}
angular.module('crmApp').directive('templateQuantity', templateQuantity);
})();

