(function() {
	'use strict';

	angular.module('crmApp').controller('ConfigurationSettingController',
		ConfigurationSettingController);

	ConfigurationSettingController.$inject = ['$rootScope', '$uibModal', '$scope', 'ConfigurationSetting', 'AlertService', '$state', '$http', 'pagingParams', 'ParseLinks'];

	function ConfigurationSettingController($rootScope, $uibModal, $scope, ConfigurationSetting, AlertService, $state, $http, pagingParams, ParseLinks) {
		var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = true;
        vm.page = 1;
        vm.entries = '10';
        vm.transition = transition;
        vm.searchSettingUseCriteria = searchSettingUseCriteria;
        vm.results = [{
            key: null
        }];
        vm.loadAll = loadAll;
        vm.loadAll();

        function loadAll(){
            vm.loadPage();
            $http.get('/api/setting-all').then(function (response) {
               var i = 0;
                angular.forEach(response.data, function(setting) {
                    if (setting.key !== vm.results[i].key){
                        vm.results.push({key:setting.key});
                        i++;
                    }
                })
            });
        }


        function loadPage(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore ) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                ConfigurationSetting.query(vm.currentSearch, onSuccess, onError);
            } else{
                if (!vm.settingCO) {
                    vm.settingCO = {};
                }
                vm.settingCO.page = vm.page - 1;
                vm.settingCO.size = vm.entries;
                vm.settingCO.sort = sort();
                vm.currentSearch = angular.copy(vm.settingCO);
                vm.showItem = vm.entries;
                ConfigurationSetting.query(vm.currentSearch, onSuccess, onError);
            }

           function sort() {
                var result = [ vm.predicate + ','
                        + (vm.reverse ? 'asc' : 'desc') ];
                if (vm.predicate !== 'value') {
                    result.push('value,asc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.setting = data;
                //vm.isSearchCritersettingia = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
                //vm.isSearchCriteria = false;
            }
        }
        function transition() {
             if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function searchSettingUseCriteria() {
            vm.loadPage(true);
        }
		// add settings
		vm.addSettings = function(){
			// add 1 setting
			vm.modalInstanAdd = $uibModal.open({
                 template: '<setting-component close="vm.close()" name="vm.showInputName" key="vm.showInputKey" message="vm.message" model="vm.information" show="vm.showInput" save="vm.save()"></setting-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.setting = {
                        name: null,
                        key:null
                    };
                    vm.information = "";
                    vm.showInputKey = true;
                    vm.showInputName = true;
                    vm.message = "THÊM THÔNG TIN CẤU HÌNH";

                    vm.save = function () {
                    	// code save
                        vm.status = true;
                    	$rootScope.$on('crmApp:addSettingSuccess', function(event, info) {
                            if (vm.status === true) {
                                saveValue(info);
                                vm.status = false;
                            }
                    	})

                    }
                    function saveValue(value){
                        ConfigurationSetting.save(value, onSaveSuccess,onError);
                    }

                    function onSaveSuccess() {
                        vm.success = 'OK';
                        $state.reload();
                    }
                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                    vm.close = function () {
                    	closePopupAdd();
                    }
                },
                controllerAs: "vm"
            });

            function closePopupAdd() {
            	vm.modalInstanAdd.close();
            }
		}

		// add a setting
		vm.addSetting = function(){
			vm.modalInstanAddA = $uibModal.open({
                 template: '<setting-component close="vm.close()" message="vm.message" model="vm.information" name="vm.showInputName" key="vm.showInputKey" save="vm.save()"></setting-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.information = "";
                    vm.showInputKey = false;
                    vm.showInputName = false;
                    vm.message = "THÊM GIÁ TRỊ";

                    vm.save = function () {
                    	// code save

                    	closePopupAddA();
                    }

                    vm.close = function () {
                    	closePopupAddA();
                    }
                },
                controllerAs: "vm"
            });

            function closePopupAddA() {
            	vm.modalInstanAddA.close();
            }
		}
		// update setting
		vm.updateSetting = function(setting){
			vm.modalInstanUpdate = $uibModal.open({
                 template: '<setting-component close="vm.close()" message="vm.message" model="vm.setting" name="vm.showInputName" key="vm.showInputKey" save="vm.save()"></setting-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.setting = setting;
                    vm.showInputKey = false;
                    vm.showInputName = true;
                    vm.showInputValue = true;
                    vm.message = "CẬP NHẬT THÔNG TIN CẤU HÌNH";

                    vm.save = function () {
                    	 vm.status = true;
                        $rootScope.$on('crmApp:addSettingSuccess', function(event, info) {
                            if (vm.status === true) {
                                info.id = setting.id;
                                info.name = setting.name;
                                info.value = setting.value;
                                saveValue(info);
                                vm.status = false;
                            }
                        })
                    }
                     function saveValue(value){
                        ConfigurationSetting.update(value, onSaveSuccess,onError);
                    }

                    function onSaveSuccess() {
                        vm.success = 'OK';
                        $state.reload();
                    }
                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                    vm.close = function () {
                    	closePopupAddA();
                         $state.reload();
                    }
                },
                controllerAs: "vm"
            });

            function closePopupAddA() {
            	vm.modalInstanUpdate.close();
            }
		}

        // delete
        vm.deleteSetting = function(setting){
            vm.modalInstance = $uibModal.open({
                template: '<popup-delete-component  message="vm.message" model="vm.setting" content="vm.content" close="vm.close()" delete="vm.delete()"></popup-delete-component>',
                size: 'md',
                backdrop: 'static',
                scope: $scope,
                controller: function() {
                    var vm = this;
                    vm.message = "Xác nhận xóa thông tin";
                    vm.content = "Bạn có chắc chắn muốn xóa thông tin này không? ";
                     vm.setting = setting;
                    //
                    vm.close = function() {
                        closePopup();
                    }
                    // code delete
                    vm.delete = function() {
                        deleteSetting(vm.setting);
                    }
                    function deleteSetting(value){
                        ConfigurationSetting.delete(value, onSaveSuccess,onError);
                    }

                    function onSaveSuccess() {
                        vm.success = 'OK';
                        $state.reload();
                    }
                    function onError(error) {
                        AlertService.error(error.data.message);
                    }

                },
                controllerAs: "vm"
            });

            function closePopup() {
                vm.modalInstance.dismiss('cancel');
                $rootScope.$on('close-modal', function(){
                    vm.modalInstance.dismiss('cancel');
                });
            }
        }

	}
})();
