(function() {
    'use strict';
    angular.module('crmApp').controller('CustomerCareController', CustomerCareController);

    CustomerCareController.inject = ['$localStorage', '$rootScope', 'pagingParams', '$scope', '$state', 'SettingUtils',
        'User', 'CustomerCare', '$http', '$filter', 'AlertService',
        'ParseLinks', 'UserUtils', 'CommonConstants'
    ];

    function CustomerCareController($localStorage, $rootScope, pagingParams, $scope, $state, SettingUtils, User,
        CustomerCare, $http, $filter, AlertService, ParseLinks, UserUtils, CommonConstants) {
        var vm = this;

        var CARE_MISSION_STATUS = 'CARE_MISSION_STATUS';
        var CARE_MISSION_PRIORITY = 'CARE_MISSION_PRIORITY';
        var CUSTOMER_SOURCE = 'CUSTOMER_SOURCE';
        //pagging
        vm.reverse = false;
        vm.transition = transition;
        vm.clear = clear;
        vm.page = 1;
        vm.entries = '10';
        vm.predicate = pagingParams.predicate;
        vm.showName = "khách hàng chăm sóc";
        vm.currentSearch = null;

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;

        vm.customerListData = [];
        vm.autocompletePlaceholder = "Khách Hàng";
        vm.autocompleteStaffPlaceholder = "Nhân Viên";
        vm.hasCustomerCareAuthority = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE);

        vm.statuses = [];
        vm.priorities = [];
        vm.sourcies = [];

        vm.selectedCustomer = null;
        vm.selectedStaff = null;

        vm.searchCustomerHandler = searchCustomerHandler;
        vm.altInputFormats = ['d!/M!/yyyy']
        vm.dateFormat = 'dd/MM/yyyy';
        vm.dateOptions = {
            startingDay: 1
        };
        var isPhone = false;
        vm.openDateFrom = function() {
            vm.isDateFromOpened = true;
        }

        vm.openDateTo = function() {
            vm.isDateToOpened = true;
        }

        vm.toQuote = function() {
            // body...
            $state.go('order.new', {
                toQuote: "toQuote"
            }, {
                reload: true
            });
        }

        vm.loadAll();

        function loadPage(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                CustomerCare.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.CareMissionCO) {
                    vm.CareMissionCO = {};
                }
                vm.CareMissionCO.page = vm.page - 1;

                vm.CareMissionCO.isPhone = isPhone;
                vm.CareMissionCO.size = vm.entries;
                vm.CareMissionCO.sort = sort();
                vm.currentSearch = angular.copy(vm.CareMissionCO);
                vm.showItem = vm.entries;
                CustomerCare.query(vm.currentSearch, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.careMissions = data.content;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        vm.showItem = 10;

        function transition() {
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.careMission = null;
            vm.loadPage();
        };

        function searchCustomerHandler() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.searchForm.$invalid) {
                var invalidControl = angular.element('input.ng-invalid')
                    .first() ||
                    angular.element('select.ng-invalid').first() ||
                    angular.element('option.ng-invalid').first();
                if (invalidControl != null) {
                    invalidControl.focus();
                }
            } else {
                vm.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                vm.loadPage();
            }
        }

        function getCurrentAccount() {
            vm.account = UserUtils.getAccount();
            vm.loginName = vm.account.login;
        }

        function loadAll() {

            if ($localStorage && $localStorage.currentRoleId) {
                getCurrentAccount();
            } else {
                var unsubscribe = $rootScope.$on('crmApp:has.account.info', function(event, result) {
                    getCurrentAccount();
                });

                $scope.$on('$destroy', unsubscribe);
            }

            SettingUtils.get({
                'keys': [CARE_MISSION_PRIORITY, CARE_MISSION_STATUS,
                    CUSTOMER_SOURCE
                ]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === CARE_MISSION_STATUS) {
                        vm.statuses.push(value);
                    } else if (value.key === CARE_MISSION_PRIORITY) {
                        vm.priorities.push(value);
                    } else if (value.key === CUSTOMER_SOURCE) {
                        vm.sourcies.push(value);
                    }
                });

                // angular.element('#sourceId').selectpicker('refresh');
                // angular.element('#careStatusId').selectpicker('refresh');
            }, function(error) {

            });
            vm.loadPage();

        };

        vm.search = function() {
            // vm.careMission = {};
            var dateTo = null;
            if (vm.dateTo) {
                dateTo = $filter('date')(vm.dateTo, "dd/MM/yyyy");
            }
            var timeTo = null;
            if (vm.timeTo) {
                timeTo = $filter('date')(vm.timeTo, "HH:mm:ss");
            }
            var dateFrom = null;
            if (vm.dateFrom) {
                dateFrom = $filter('date')(vm.dateFrom, "dd/MM/yyyy");
            }
            var timeFrom = null;
            if (vm.timeFrom) {
                timeFrom = $filter('date')(vm.timeFrom, "HH:mm:ss");
            }
            if (vm.selectedStaff != undefined && vm.selectedStaff &&
                vm.selectedStaff.id) {
                vm.careMission.staffId = vm.selectedStaff.id;
            } else {
                vm.staffStr = '';
            }

            if (vm.selectedCustomer != undefined && vm.selectedCustomer &&
                vm.selectedCustomer.id) {
                vm.careMission.customerId = vm.selectedCustomer.id;
            } else {
                vm.customerStr = '';
            }

            if (vm.careMission == null || vm.careMission == undefined) {
                vm.careMission = {};
            }

            vm.dateFrom = formatDateTime(vm.dateFrom, vm.timeFrom);
            vm.dateTo = formatDateTime(vm.dateTo, vm.timeTo);

            if (vm.dateFrom) {
                vm.careMission.assignedDate = vm.dateFrom;
            }

            if (vm.dateTo) {
                vm.careMission.closedDate = vm.dateTo;
            }

            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.loadPage();
        };

        vm.displaySettingListName = function(settings) {
            var result = '';
            if (settings !== null && settings !== undefined &&
                settings.length > 0) {
                angular.forEach(settings, function(setting, key) {
                    result += setting.name;
                    if (key != (settings.length - 1)) {
                        result += ', ';
                    }
                });
            }
            return result;
        };

        function isPhoneNumber(phone) {
            if (phone.length < 10)
                return false;

            var pattern = /^[0-9]*$/;
            var isPhoneNumber = phone.match(pattern);

            if (isPhoneNumber)
                return true;
            return false;
        }

        vm.CareMissionInNewStatus = function(status) {
            return status == 1;
        }
        vm.CareMissionInCaringStatus = function(status) {
            return status == 2;
        }
        vm.CareMissionInFinishStatus = function(status) {
            return status == 3;
        }
    };
}());
