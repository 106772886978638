(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state(
			'information-configuration',
			{
				parent : 'admin',
				url : '/information-configuration',
				data : {
					authorities : [ 'ADMIN' ],
					pageTitle : 'Cấu hình thông tin'
				},
				views : {
					'content@' : {
						templateUrl : 'app/admin/information-configuration/information-configuration.html',
						controller : 'InformationConfigurationController',
						controllerAs : 'vm'
					}
				},resolve : {
					entity: ['InformationConfiguration', function(InformationConfiguration) {
						return InformationConfiguration.get().$promise
					}]
				}
			})
	}
})();
