(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('PermissionUserController', PermissionUserController);

    PermissionUserController.$inject = ['$scope', '$state', 'Role', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$http'];

    function PermissionUserController ($scope, $state, Role, ParseLinks, AlertService, pagingParams, paginationConstants, $http) {
        var vm = this;
         vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.user = [];
        vm.permissionUser = [];
        vm.loadAll();

        function loadAll () {
            Role.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            var i = 0;
            $http.get('/api/users').success(function(success) {
                vm.user = success;
                $http.get('/api/authorities').success(function(success) {
                    vm.authoritys = success;
                    var i = 0;
                    angular.forEach(vm.authoritys, function(authoritys, key){
                        vm.permissionUser.push({id: authoritys.id, permissionName: authoritys.name, userName : ''});
                        angular.forEach(vm.user, function(user, key){
                            if(user.authorities.indexOf(authoritys.name) >= 0){
                                vm.permissionUser[i].userName += user.name + ',';
                            }
                        });
                        i++;
                    });
                });
            });
            vm.page = pagingParams.page;

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.roles = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
