(function() {
	'use strict';
	angular.module('crmApp').factory('EmailTemplateVariable',
			EmailTemplateVariable);

	EmailTemplateVariable.$inject = [ '$resource' ];

	function EmailTemplateVariable($resource) {
		var resourceUrl = 'api/email-templates/variables/:group';

		return $resource(resourceUrl, {}, {
			'get' : {
				method : 'GET',
				isArray : true
			}
		});
	}
})();
