(function() {
    'use strict';

    angular
    .module('crmApp')
    .controller('OrderCreateUpdateController', OrderCreateUpdateController);

    OrderCreateUpdateController.$inject = ['$timeout','$rootScope', '$q', 'ConvertMoneyToWordService','$resource', '$anchorScroll', '$location',
    'Principal', 'UserUtils', 'AlertService', 'CommonConstants', '$filter', '$uibModal',
    '$scope', 'Order', 'entity', '$stateParams', '$http', 'DateFormatService', '$state',
    'UploadFileService', 'OrderDetail', 'Payment', 'customer', 'SettingUtils', '$window',
    'CustomerPhone', 'Product'];

    function OrderCreateUpdateController($timeout,
      $rootScope, $q, ConvertMoneyToWordService, $resource, $anchorScroll, $location,
      Principal, UserUtils, AlertService, CommonConstants, $filter, $uibModal,
      $scope, Order, entity, $stateParams, $http, DateFormatService, $state,
      UploadFileService, OrderDetail, Payment, customer, SettingUtils, $window,
      CustomerPhone, Product) {
        var vm = this;
        vm.order = entity;

        vm.isOrderRefund = function(){
            return vm.order.category == 4;
        }

        //create new refund order
        if(vm.order && (vm.order.refOrderId == null || vm.order.refOrderId == undefined) && $state.current.name == "order.refund"){
            vm.order.category = 4;
            vm.order.refOrderId = vm.order.id;
            vm.order.id = null;

            if(vm.order.orderDetails){
              var orderDetailTmp = null;
              for(var i = 0; i < vm.order.orderDetails.length; i++){
                orderDetailTmp = vm.order.orderDetails[i];
                orderDetailTmp.refOrderDetailId = orderDetailTmp.id;
                orderDetailTmp.id = null;
                orderDetailTmp.refOrderDetail = angular.copy(orderDetailTmp);

              }
            }
        } else if(vm.order && vm.order.refOrderId && vm.isOrderRefund() && $state.current.name == "order.edit"){
          $http.get('/api/order-detail-by-id?id=' + vm.order.refOrderId)
    			.success(function(success) {
    				var orderDetailLst = success;
            var orderDetailTmp = null;
            if(orderDetailLst && orderDetailLst.length > 0){
              for(var i = 0; i < vm.order.orderDetails.length; i++){
                orderDetailTmp = vm.order.orderDetails[i];
                for(var j = 0; j < orderDetailLst.length; j++){
                  if(orderDetailTmp.refOrderDetailId == orderDetailLst[j].id){
                    orderDetailTmp.refOrderDetail = angular.copy(orderDetailLst[j]);
                    break;
                  }
                }
              }
            }
    			});
        }

        vm.hasAnyAuthority = Principal.hasAnyAuthority;
        vm.account = null;
        vm.accountId = null;
        vm.onSelect = onSelect;
        vm.getAccount = getAccount;
        vm.initOrderInfo = initOrderInfo;
        vm.readMoneyToWord = readMoneyToWord;
        // vm.readNumber12Digits = readNumber12Digits;
        vm.getAccount();

        // upload image
        vm.isUploadFileInProgress = false;
        vm.uploadFileNameList = [];
        vm.getDistricts = getDistricts;
        vm.getWards = getWards;

        vm.loadAll = loadAll;
        vm.products = [];
        vm.payment = {};
        vm.stocks = [];
        vm.mainStock = null;
        vm.provinces = [];
        vm.districts = [];
        vm.wards = [];
        var ORDER_CATEGORY = 'ORDER_CATEGORY';
        var PAYMENT_TYPE = 'PAYMENT_TYPE';
        var DELIVER_TYPE = 'DELIVER_TYPE';
        var DELIVER_METHOD = 'DELIVER_METHOD';
        var DELIVER_STATE = 'DELIVER_STATE';
        var PRODUCT_ITEM_TYPE = 'PRODUCT_ITEM_TYPE';
        var ORDER_PHASE = 'ORDER_PHASE';
        var ORDER_STATUS = 'ORDER_STATUS';
        var PRICE_TYPE = 'PRICE_TYPE';
        var SHIP_FEE_TYPE = 'SHIP_FEE_TYPE';
        var VAT_RATES = 'VAT_RATES';

        vm.PRODUCT_ITEM_TYPE_PRODUCT = CommonConstants.PRODUCT_ITEM_TYPE_PRODUCT;
        vm.PRODUCT_ITEM_TYPE_OBJECT = CommonConstants.PRODUCT_ITEM_TYPE_OBJECT;
        vm.PRODUCT_ITEM_TYPE_WORKER = CommonConstants.PRODUCT_ITEM_TYPE_WORKER;
        vm.PRODUCT_ITEM_TYPE_PROMOTION = CommonConstants.PRODUCT_ITEM_TYPE_PROMOTION;

        var fd = new FormData();
        var isSaveSuccess = true;

        vm.flag = (vm.order.phase === CommonConstants.ORDER_WRITING);
        vm.loadAll();

        vm.clear = clear;
        vm.statusDate = false;
        vm.redirectPrintPage = false;
        vm.redirectEmailPage = false;
        function clear() {
            if ($state.current.name == "order.edit") {
                $state.go('order.edit', null, {
                    reload: true
                });
            } else if ($state.current.name == "order.new") {
                $state.go('order.new', null, {
                    reload: true
                });
            }
        }
        // check state
        vm.orderRefundStatus = function(){
            if($state.current.name == "order.refund" || vm.order.category == 4){
                vm.order.category = 4;
                return true;
            }
        }

        vm.OrderVatRate = function() {
            if (vm.order.vateRateStatus !== null) {
                if (vm.order.vatRateStatus === 0 || vm.order.vatRateStatus === 2) {
                    vm.order.vatRate = 0;
                } else if (vm.order.vatRateStatus === 1){
                    vm.order.vatRate = 10;
                }
            }
        }

        vm.chooseMoneyStatus = function(free){
            if(free != 1){
                vm.order.deliveryCost = 0;
            }
        }
        function initOrderInfo() {
            //if item is product or promotion then fetch product quantity to order detail
            //use for calculator the number of product had bought
            vm.orderDetailsClone = angular.copy(vm.order.orderDetails);

            if (vm.order && vm.order.productQuantities && vm.order.productQuantities.length > 0) {
                var odDetail = null;
                var proQuantity = null;
                for (var i = 0; i < vm.order.productQuantities.length; i++) {
                    proQuantity = vm.order.productQuantities[i];
                    for (var j = 0; j < vm.order.orderDetails.length; j++) {
                        odDetail = vm.order.orderDetails[j];
                        if (proQuantity.warehouseId == odDetail.warehouseId &&
                            proQuantity.productItemId == odDetail.productItemId &&
                            proQuantity.productItemType == odDetail.productItemType) {
                            odDetail.numberInStock = proQuantity.numberInStock;
                        odDetail.numberInOrders = proQuantity.numberInOrders;
                    }
                }
            }
        }
    };

        // end reset
        function loadAll() {
            if (!isNotBlank(vm.order.orderDetails)) {
                //empty order
                vm.order.orderDetails = [];
            } else {
                //edit order
                vm.initOrderInfo();
            }
            if ($state.current.name == "order.new" || $state.current.name == "order.new-with-phone-number") {
            	//Add orderDate 20190731
                vm.order.orderDate = new Date();
                //End orderDate 20190731
                vm.order.deliveryDate = new Date();
                vm.order.paymentDueDate = new Date();
                vm.statusDate = true;
            }
            if (vm.order.deliveryProvinceId){
                vm.getDistricts();
            }
            if (vm.order.deliveryDistrictId){
                vm.getWards();
            }

            convertData();
            getDataFromSetting();
            getListProvince();
            configDateTime();
            getAccountant();
            formatDate(vm.order.orderDetails);
            $http.get('/api/get-all-stocks')
            .then(function(success) {
                vm.stocks = success.data;
                for (var i = 0; i < vm.stocks.length; i++) {
                    if (vm.stocks[i].stockType == 2) {
                        vm.mainStock = vm.stocks[i];
                        break;
                    }
                }
                    // angular.element('.warehouse-select').selectpicker('refresh');
                });
        }

        vm.changeStock = function(orderDetail) {
            if (orderDetail && (orderDetail.productItemType == vm.PRODUCT_ITEM_TYPE_PRODUCT || orderDetail.productItemType == vm.PRODUCT_ITEM_TYPE_PROMOTION)) {
                if (orderDetail.warehouseId) {
                    orderDetail.numberInStock = 0;
                    orderDetail.numberInOrders = 0;

                    $http({
                        url: '/api/the-number-of-one-product-in-warehouse',
                        method: "GET",
                        params: {
                            warehouseId: orderDetail.warehouseId,
                            productId: orderDetail.productItemId
                        }
                    }).then(function(success) {
                        orderDetail.numberInStock = success.data;
                        var odDetail = null;
                        for (var i = 0; i < vm.order.orderDetails.length; i++) {
                            odDetail = vm.order.orderDetails[i];
                            if (orderDetail.warehouseId == odDetail.warehouseId &&
                                orderDetail.productItemId == odDetail.productItemId) {
                                odDetail.numberInStock = orderDetail.numberInStock;
                        }
                    }
                });

                    $http({
                        url: '/api/get-the-number-of-a-product-ordering',
                        method: "GET",
                        params: {
                            warehouseId: orderDetail.warehouseId,
                            productId: orderDetail.productItemId
                        }
                    }).then(function(success) {
                        orderDetail.numberInOrders = success.data;
                        var odDetail = null;
                        for (var i = 0; i < vm.order.orderDetails.length; i++) {
                            odDetail = vm.order.orderDetails[i];
                            if (orderDetail.warehouseId == odDetail.warehouseId &&
                                orderDetail.productItemId == odDetail.productItemId) {
                                odDetail.numberInOrders = orderDetail.numberInOrders;
                        }
                    }
                });
                } else {
                    orderDetail.numberInStock = null;
                    orderDetail.numberInOrders = null;
                }
            }
        };
        vm.flag = false;
        vm.changePriceType = function(orderDetail) {
            if (orderDetail) {
            	var product = null;

            	if (orderDetail.product) {
            		product = orderDetail.product;
            	} else {
            		product = Product.get({id: orderDetail.productItemId}).$promise;
            	}

            	$q.when(product, function(response) {
            		orderDetail.product = response;

            		if (orderDetail.priceType == CommonConstants.PRICE_TYPE.RETAIL) {
            			orderDetail.price = orderDetail.product.retailPrice;
                         vm.flag = false;
            		} else if (orderDetail.priceType == CommonConstants.PRICE_TYPE.WHOLESALE) {
            			orderDetail.price = orderDetail.product.wholesalePrice;
                         vm.flag = false;
            		} else if (orderDetail.priceType == CommonConstants.PRICE_TYPE.AGENT) {
            			orderDetail.price = orderDetail.product.agentPrice;
                        vm.flag = false;
            		} else if (orderDetail.priceType == CommonConstants.PRICE_TYPE.PROMOTION) {
            			orderDetail.price = orderDetail.product.promotionPrice;
                        vm.flag = true;
            		}

            		if (!orderDetail.price) orderDetail.price = 0;
            	});
            }
        };

        function isNotBlank(argument) {
            if (argument == null || argument == undefined || argument == "") {
                return false;
            }
            return true;
        }

        function convertData() {
            if (vm.order.orderDate) {
                vm.order.orderDate = new Date(vm.order.orderDate);
            }
            if (vm.order.deliveryDate) {
                vm.order.deliveryDate = new Date(vm.order.deliveryDate);
            }
            if (vm.order.paymentDueDate) {
                vm.order.paymentDueDate = new Date(vm.order.paymentDueDate);
            }
            if (customer != null) {
                vm.order.customer = customer;
                vm.onSelect(customer);
            }

        }

        function getListProvince(){
            $http.get('/api/list-provinces', {}).then(function (response) {
                vm.provinces = response.data;
            })
        }

        function getAccountant(){
            $http.get('/api/users-accountants', {}).then(function (response) {
                vm.accountants = response.data;
            })
        }
        function getDataFromSetting() {
            SettingUtils.get({
                'keys': [ORDER_STATUS, ORDER_CATEGORY, PAYMENT_TYPE, DELIVER_TYPE, DELIVER_METHOD,
                DELIVER_STATE, PRODUCT_ITEM_TYPE, ORDER_PHASE, PRICE_TYPE, SHIP_FEE_TYPE, VAT_RATES]
            }, function(success) {
                vm.orderCategories = [];
                vm.paymentTypies = [];
                vm.deliverTypies = [];
                vm.deliverMethods = [];
                vm.deliverStaties = [];
                vm.productItemTypes = [];
                vm.orderPhase = [];
                vm.orderStatus = [];
                vm.priceTypes = [];
                vm.shipFeeTypes = [];
                vm.vatRates = [];
                angular.forEach(success, function(value, key) {
                    if (value.key === ORDER_CATEGORY) {
                        if($state.current.name == "order.refund" || vm.order.category === 4){
                            vm.orderCategories.push(value);
                        }else{
                            if(value.value != 4){
                                vm.orderCategories.push(value);
                            }
                        }
                    } else if (value.key === PAYMENT_TYPE) {
                        vm.paymentTypies.push(value);
                    } else if (value.key === DELIVER_TYPE) {
                        vm.deliverTypies.push(value);
                    } else if (value.key === DELIVER_METHOD) {
                        vm.deliverMethods.push(value);
                    } else if (value.key === DELIVER_STATE) {
                        vm.deliverStaties.push(value);
                    } else if (value.key === PRODUCT_ITEM_TYPE) {
                        vm.productItemTypes.push(value);
                    } else if (value.key === ORDER_PHASE) {
                        vm.orderPhase.push(value);
                        // Set default value for vm.order.phase
                        vm.order.phase = vm.order.phase || vm.orderPhase[0].value;
                    } else if (value.key === ORDER_STATUS) {
                        vm.orderStatus.push(value);
                    } else if (value.key === PRICE_TYPE) {
                        vm.priceTypes.push(value);
                    } else if (value.key === SHIP_FEE_TYPE) {
                        vm.shipFeeTypes.push(value);
                    } else if (value.key === VAT_RATES) {
                        vm.vatRates.push(value);
                    }
                });

            }, function(error) {});
        }

        function configDateTime() {
            vm.isDateFromOpened = [];
            vm.altInputFormats = DateFormatService.getAltInputFormats();
            vm.dateFormat = DateFormatService.getDateFormat();
            vm.dateOptions = {
                startingDay: 1,
                minDate: new Date()
            };
            
            //Add orderDate 20190731
            vm.orderDateOptions = {
                    startingDay: 1,
                    minDate: 0
            };
            //End orderDate 20190731
            
            vm.openDateFrom = function(index) {
                vm.isDateFromOpened[index] = true;
            }
            vm.openDateFrom1 = function() {
                vm.isDateFromOpened1 = true;
            }
            vm.openDateFrom2 = function() {
                vm.isDateFromOpened2 = true;
            }

            vm.datePickerOpenStatus1 = function() {
                vm.isOrderDateOpened1 = true;
            }
            vm.datePickerOpenStatus2 = function() {
                vm.isOrderDateOpened2 = true;
            }
			//Add orderDate 20190731
            vm.datePickerOpenStatus3 = function() {
                vm.isOrderDateOpened3 = true;
            }
			//End orderDate 20190731
        }

        // check a string is Phone Numer => true
        function isPhoneNumber(phone) {
            if (phone.length < 10) return false;

            var pattern = /^[0-9]*$/;
            var isPhoneNumber = phone.match(pattern);

            if (isPhoneNumber) return true;
            return false;
        }
        // refresh function for autocomplete

        vm.refreshCustomerByName = function(name) {
            if (!isNotBlank(name)) {
                vm.customerListData = null;
                return;
            }
            return $http({
                url: '/api/search-customer-by-name',
                method: 'GET',
                params: {
                    name: name,
                }
            }).then(function(response) {
                vm.customerListData = response.data;
            });
        }

        vm.refreshCustomerByPhone = function(phone) {
            if (!isNotBlank(phone)) {
                vm.customerListData = null;
                return;
            }
            var isPhone = isPhoneNumber(phone);
            return $http({
                url: '/api/search-customer-by-phone',
                method: 'GET',
                params: {
                    phone: phone,
                    isPhone: isPhone
                }
            }).then(function(response) {
                vm.customerListData = response.data;
            });
        }

        // config and save order
        vm.tableStatus = "";
        vm.validationStatus = "";
        function getAccount() {
            vm.account = UserUtils.getAccount();
            vm.accountId = vm.account.id;
        }
        vm.save = function(nextAction) {
            vm.changeStatus = false;
            vm.moneyStatus = false;
            vm.quantityStatus = false;
            vm.refMoneyStatus = false;
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.createUpdateForm.$invalid) {
                var invalidSelect = angular.element('select.ng-invalid').first();
                var invalidInput = angular.element('input.ng-invalid').first();
                var invalidUi = angular.element('.ui-select-container.ng-invalid').first();
                if (invalidUi.length != 0) {
                    angular.element('.ui-select-toggle').first().focus();
                    angular.element(".add-class").addClass('message-validation');
                    angular.element(".add-class").removeClass('help-block');
                } else if (invalidInput.length != 0) {
                    invalidInput.focus();
                } else if (invalidSelect.length != 0) {
                    invalidSelect.focus();
                    angular.element('.bs-placeholder').focus();
                }
            } else if (vm.order.orderDetails.length == 0) {
                vm.tableStatus = "Đơn hàng trống. Vui lòng thêm sản phẩm!";
                $location.hash('message-validation');
                $anchorScroll();
            }
            else{

             angular.forEach(vm.order.orderDetails, function(orderDetail) {
                if(orderDetail.priceType === undefined || orderDetail.priceType === null || orderDetail.priceType === ""){
                    vm.changeStatus = true;
                    return;
                }
                // order refund validation
                if($state.current.name == "order.refund" && orderDetail.quantity > orderDetail.refOrderDetail.quantity){
                    vm.quantityStatus = true;
                }else if(orderDetail.refOrderDetail != null && orderDetail.quantity > orderDetail.refOrderDetail.quantity){
                    vm.quantityStatus = true;
                }

                if($state.current.name == "order.refund" && orderDetail.totalPrice > orderDetail.refOrderDetail.totalPrice){
                    vm.refMoneyStatus = true;
                }else if(orderDetail.refOrderDetail != null && orderDetail.totalPrice > orderDetail.refOrderDetail.totalPrice){
                    vm.quantityStatus = true;
                }
            });
             if(vm.changeStatus){
                vm.tableStatus = "Vui lòng chọn loại giá cho sản phẩm!";
                $location.hash('message-validation');
                $anchorScroll();
            }else{
                vm.tableStatus = "";
                    if (UserUtils.hasAuthorityName(CommonConstants.ORDER_EDIT) ||
                      UserUtils.hasAuthorityName(CommonConstants.ORDER_ADD)) {
                        vm.order.customerId = vm.order.customer.id;

						//Add orderDate 20190731
                        vm.orderDateStatus = "";
						//End orderDate 20190731
                        vm.deliveryDateStatus = "";
                        vm.paymentDueDateStatus = "";
                        if (isNotBlank(vm.order.id)) { // update order
                            // config and save OrderDetail in Order
                            angular.forEach(vm.order.orderDetails, function(orderDetail) {
                                orderDetail.orderId = vm.order.id;
                                if(orderDetail.totalPrice <  0 || orderDetail.price < 0){
                                    vm.moneyStatus = true;
                                }
                            });
                            var onSave = nextAction ? (nextAction == 'view' ? onSaveSuccessThenView : onSaveSuccessThenConfirm) :
                                onSaveSuccess;
                                if(vm.moneyStatus){
                                    vm.tableStatus = "Tiền không được âm. vui lòng nhập lại!";
                                }else if(vm.order.category == 4){
                                    if(vm.quantityStatus){
                                    vm.tableStatus = "Số lượng trả đã vượt quá!";
                                    }else if(vm.refMoneyStatus){
                                        vm.tableStatus = "Số lượng tiền trả đã vượt quá!";
                                    }else{
                                        Order.update(vm.order, onSave, onSaveError);
                                    }
                                }
                                else{
                                    Order.update(vm.order, onSave, onSaveError);
                                }

                        } else {
                            // Order in status WRITING
                            // validation date
                            if ($state.current.name != "order.refund" && (validationDate(vm.order.deliveryDate) || validationDate(vm.order.paymentDueDate))) {
                                if (validationDate(vm.order.deliveryDate)) {
                                    vm.deliveryDateStatus = "Ngày giao hàng không được nhỏ hơn ngày hiện tại.";
                                }
                                if (validationDate(vm.order.paymentDueDate)) {
                                    vm.paymentDueDateStatus = "Ngày hoàn tất thanh toán không được nhỏ hơn ngày hiện tại";
                                }

                            }
                             else if(vm.moneyStatus){
                                 vm.tableStatus = "Tiền không được âm. vui lòng nhập lại!";
                            }
                            else {
                                // order refund validation
                                if(vm.quantityStatus){
                                    vm.tableStatus = "Số lượng trả đã vượt quá!";
                                }else if(vm.refMoneyStatus){
                                    vm.tableStatus = "Số lượng tiền trả đã vượt quá!";
                                }else{
                                    vm.order.status = 1;
                                    vm.order.staffId = vm.accountId;
                                    vm.change = true;
                                    Order.save(vm.order, onSaveSuccess, onSaveError);
                                }
                            }
                        }

                    } else {
                        AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                        $uibModalInstance.dismiss('cancel');
                    }
            }

        }

    }

    function validationDate(date) {
        var nowFormat = $filter('date')(new Date(), 'yyyy-MM-dd');
        var dateFormat = $filter('date')(date, 'yyyy-MM-dd');
        if (dateFormat < nowFormat) {
            return true;
        }
    }
        // direct print page
        vm.redirectPrint = function() {
            vm.redirectPrintPage = true;
            // create new pdf file
            vm.order.createNewPdfFile = true;
            vm.save();
        }
        // direct email page
        vm.redirectEmail = function() {
            vm.redirectEmailPage = true;
            // create new pdf file
            vm.order.createNewPdfFile = true;
            vm.save();
        }
        vm.saveOrder = function() {
            // create new pdf file
            vm.order.createNewPdfFile = true;
            vm.save();
        }
        vm.createOrder = function() {
            // new order in phase order. 0: quotation, 1:order
            vm.order.phase = 1;
            vm.save('view');
        }
        vm.salesWaitConfirm = function() {
            vm.save('waiting_confirm');
        }
        // upload file
        function getExtensionFile(fileName) {
            return fileName.split('.').pop();
        }
        // upload File
        $scope.uploadFile = function(element) {
            vm.isUploadFileInProgress = true;
            // if not exits file return
            // if file more than 32MB then return
            if (!element.files[0] || (element.files[0].size / 1024 / 1024 > 32)) {
                return;
            }

            // validate file name
            var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
            var validateFileName = fileName + new Date().getTime();
            var extension = getExtensionFile(element.files[0].name);
            var finalFileName = validateFileName + "." + extension;

            fd.append("file", element.files[0]);
            fd.set("file", element.files[0], finalFileName);

            UploadFileService.uploadOneFile('/api/save-quotation-order-attachment', fd, function(success, status) {
                vm.order.attachDocument = finalFileName;
                vm.isUploadFileInProgress = false;
                vm.uploadFileNameList.push(finalFileName);
            }, function(error, status) {
                vm.isUploadFileInProgress = false;
            });
        }

        // DELETE FILE ORDER
        function deleteUploadFileButNoSave(fileNames) {
            $http({
                method: 'GET',
                url: '/api/delete-quotation-order-attachment',
                params: {
                    fileNames: fileNames
                }
            });
        };
        // end upload file
        function formatDate(orderDetails){
            angular.forEach(orderDetails, function(or) {
                or.receivePlanDate = new Date(or.receivePlanDate);
            })
        }
        // onSaveSuccess
        function onSaveSuccess(order) {
            formatDate(order.orderDetails);
            if (vm.change) {
              $state.go('order.edit', {
                  id: order.id
                }, {
                  reload: true
                });
                // $state.go('order', null, {
                //     reload: true
                // });
            }

            if (vm.uploadFileNameList.length > 1) {
                var index = vm.uploadFileNameList.indexOf(vm.order.attachDocument);
                if (index != -1) {
                    vm.uploadFileNameList.splice(index, 1);
                    deleteUploadFileButNoSave(vm.uploadFileNameList);
                }
            }

            // update data after save
            vm.order = order;

            vm.initOrderInfo();
            convertData();

            if (isSaveSuccess) {
                if (vm.redirectPrintPage == true) {
                    vm.redirectPrintPage = false;
                    $window.open("public/pdf/open/" + order.id + "/" + order.orderReport);
                } else if (vm.redirectEmailPage == true) {
                    $state.go('order.email', {
                        id: order.id,
                    }, {
                        reload: true
                    });
                    vm.redirectEmailPage = false;
                }

            }
        }
        // onSaveError
        function onSaveError(error) {
            isSaveSuccess = false;
        }

        function onSaveSuccessThenView(order) {
            onSaveSuccess(order);
            $state.go('order-detail', {
                id: order.id,
                action: 'view'
            }, {reload:true});
        }

        function onSaveSuccessThenConfirm(order) {
            onSaveSuccess(order);
            $state.go('order-detail', {
                id: order.id,
                action: 'waiting_confirm'
            }, {reload:true});
        }

        // get total price
        vm.getTotalPrice = function() {
            var totalPrice = 0;
            angular.forEach(vm.order.orderDetails, function(orderDetails) {
                if (orderDetails.product != null) {
                    if (isNotBlank(orderDetails.quantity)) {
                        totalPrice += parseFloat(orderDetails.totalPrice);
                    }

                }
            })
            if (vm.order.vatRate) {
                totalPrice = totalPrice + vm.order.vatRate * totalPrice / 100
            }

            if (vm.order.deliveryCost) {
                totalPrice = totalPrice + Number(vm.order.deliveryCost);
            }
            return totalPrice;
        }
        // get price worker
        vm.getPriceWorker = function() {
            var totalPrice = 0;
            angular.forEach(vm.order.orderDetails, function(orderDetails) {
                if (orderDetails.product != null && orderDetails.productItemType == 3) {
                    if (isNotBlank(orderDetails.quantity) != 0 && isNotBlank(orderDetails.price) != 0) {
                        totalPrice += parseFloat(orderDetails.quantity) * parseFloat(orderDetails.price);
                    }
                }

            })
            return totalPrice;
        }
        // get price object
        vm.getPriceObject = function() {
            var totalPrice = 0;
            angular.forEach(vm.order.orderDetails, function(orderDetails) {
                if (orderDetails.product != null && orderDetails.productItemType == 2) {
                    if (isNotBlank(orderDetails.quantity) != 0 && isNotBlank(orderDetails.price) != 0) {
                        totalPrice += parseFloat(orderDetails.quantity) * parseFloat(orderDetails.price);
                    }
                }

            })
            return totalPrice;
        }
        // get price product
        vm.getPriceProduct = function() {
            var totalPrice = 0;
            angular.forEach(vm.order.orderDetails, function(orderDetails) {
                if (orderDetails.product != null && orderDetails.productItemType == 1) {
                    if (isNotBlank(orderDetails.quantity) != 0 && isNotBlank(orderDetails.price) != 0) {
                        totalPrice += parseFloat(orderDetails.quantity) * parseFloat(orderDetails.price);
                    }
                }

            })
            return totalPrice;
        }

        vm.theNumberOfProductGreaterThanInStock = function(data) {
            var theNumberOfProduct = 0;
            theNumberOfProduct = theNumberOfProduct + vm.countTheNumberOfProductBothNormalAndPromotion(data.warehouseId, data.productItemId);
            theNumberOfProduct = theNumberOfProduct - vm.countTheNumberOfProductBothNormalAndPromotionHadBought(data.warehouseId, data.productItemId);

            return data.numberInOrders + theNumberOfProduct - data.numberInStock;
        };

        vm.countTheNumberOfProductBothNormalAndPromotion = function(warehouseId, productId) {
            var count = 0;
            if (vm.order.orderDetails) {
                angular.forEach(vm.order.orderDetails, function(orderDetail) {
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += Number(orderDetail.quantity);
                    }
                });
            }
            return count;
        };

        vm.countTheNumberOfProductBothNormalAndPromotionHadBought = function(warehouseId, productId) {
            //SL SP da mua truoc do, dung khi da len dh, xong chinh sua SL SP
            var count = 0;
            var orderDetail = null;
            if (vm.orderDetailsClone) {
                for (var i = 0; i < vm.orderDetailsClone.length; i++) {
                    orderDetail = vm.orderDetailsClone[i];
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += Number(orderDetail.quantity);
                    }
                }
            }
            return count;
        };

        // delete item product
        vm.deleteItem = function(index) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/order/order-detail-delete-dialog.html',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    vm.confirmDelete = function() {
                        vm.order.orderDetails.splice(index, 1);
                        vm.modalInstance.close(true);
                    };

                    vm.close = function() {
                        vm.modalInstance.dismiss('cancel');
                    }
                }
            });
        };
        // show dialog product
        vm.showDialogProduct = function(productItemType) {
            vm.tableStatus = "";
            vm.modalInstanceProduct = $uibModal.open({
                url: '/?page&sort&search',
                templateUrl: 'app/order/order-create-update-product-dialog.html',
                size: 'lg',
                 backdrop: 'static',
                controller: 'OrderCreateUpdateProductDialogController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            });

            // get products from dialog product
            var unsubscribe = $rootScope.$on('crmApp:addProductSuccess', function(event, products) {
                angular.forEach(products, function(product) {

                    var orderDetail = {
                        productItemId: product.id,
                        productItemType: productItemType.value,
                        unitOfAccount: '',
                        quantity: 1,
                        priceType: CommonConstants.PRICE_TYPE.RETAIL,
                        price: product.retailPrice,
                        totalPrice: null,
                        memo: null,
                        warehouseId: vm.mainStock ? vm.mainStock.id : null,
                        discountAmount: 0,
                        product: product
                    };

                    orderDetail.product = product;
                    if (!vm.checkAProductSameItemTypeHasInOrderDetails(orderDetail)) {
                        vm.changeStock(orderDetail);
                        vm.order.orderDetails.push(orderDetail);
                    }
                })
            });
            $scope.$on('$destroy', unsubscribe);
        }

        vm.checkAProductSameItemTypeHasInOrderDetails = function(orderDetail) {
            var check = false;
            angular.forEach(vm.order.orderDetails, function(od) {
                if (orderDetail.productItemId == od.productItemId &&
                    orderDetail.productItemType == od.productItemType) {
                    check = true;
                return false;
            }
        });
            return check;
        };

        vm.getMaxReceivePlanDate = function(){
            var result = null;
            angular.forEach(vm.order.orderDetails, function(object) {
                var tamp = new Date(object.receivePlanDate);
                if(tamp > result){
                    result = tamp;
                }
            })
            return result;
        }
        vm.showObject = function(){
            var result = false;
            angular.forEach(vm.order.orderDetails, function(order) {
                if(order.productItemType == 2){
                    result =  true;
                }
            })
            return result;
        }
        vm.objectProduct = null;
        // show dialog object
        vm.showDialogObject = function(productItemType) {
            vm.tableStatus = "";
            vm.modalInstanceObject = $uibModal.open({
                templateUrl: 'app/order/order-create-update-object-dialog.html',
                controller: 'OrderCreateUpdateObjectDialogController',
                controllerAs: 'vm',
                 backdrop: 'static',
                size: 'lg',
                scope: $scope
            });
            // get products from dialog product
            vm.modalInstanceObject.result.then(function(objects) {
                getAccountant();
                vm.objectProduct = objects;
                angular.forEach(objects, function(object) {
                    var orderDetail = {
                        productItemId: null,
                        productItemType: productItemType.value,
                        unitOfAccount: null,
                        quantity: 1,
                        priceType: CommonConstants.PRICE_TYPE.RETAIL,
                        price: object.retailPrice,
                        totalPrice: null,
                        receivePlanDate: new Date(object.receivePlanDate),
                        memo: object.memo,
                    };
                    orderDetail.product = object;
                    vm.order.orderDetails.push(orderDetail);
                })
            }, function(error) {});
        }

        // show dialog worker
        vm.showDialogWorker = function(productItemType) {
            vm.tableStatus = "";
            vm.modalInstanceWorker = $uibModal.open({
                templateUrl: 'app/order/order-create-update-worker-dialog.html',
                controller: 'OrderCreateUpdateWorkerDialogController',
                controllerAs: 'vm',
                 backdrop: 'static',
                size: 'lg',
            });
            // get worker from dialog worker
            vm.modalInstanceWorker.result.then(function(workers) {
                angular.forEach(workers, function(worker) {
                    var orderDetail = {
                        productItemId: null,
                        productItemType: productItemType.value,
                        unitOfAccount: null,
                        quantity: 1,
                        priceType: CommonConstants.PRICE_TYPE.RETAIL,
                        price: worker.retailPrice,
                        totalPrice: null,
                        memo: null
                    };
                    orderDetail.product = worker;
                    vm.order.orderDetails.push(orderDetail);
                })
            }, function(error) {});
        }
        // show dialog promotions
        vm.showDialogPromotions = function(productItemType) {
            vm.tableStatus = "";
            vm.modalInstancePromotions = $uibModal.open({
                templateUrl: 'app/order/order-create-update-promotions-dialog.html',
                controller: 'OrderCreateUpdatePromotionDialogController',
                controllerAs: 'vm',
                 backdrop: 'static',
                size: 'lg',
                windowClass: 'app-modal-window',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                }
            });
            // get products from dialog product
            vm.nameValidation = 0;
            var unsubscribe = $rootScope.$on('crmApp:addProductPromotionSuccess', function(event, promotions) {
             angular.forEach(promotions, function(promotion) {
                vm.nameValidation = vm.nameValidation +1;
                var orderDetail = {
                    productItemId: promotion.id,
                    productItemType: productItemType.value,
                    unitOfAccount: '',
                    quantity: 1,
                    price: promotion.retailPrice,
                    priceType: CommonConstants.PRICE_TYPE.PROMOTION,
                    totalPrice: null,
                    memo: null,
                    warehouseId: vm.mainStock ? vm.mainStock.id : null
                };
                orderDetail.product = promotion;
                if (!vm.checkAProductSameItemTypeHasInOrderDetails(orderDetail)) {
                    vm.changeStock(orderDetail);
                    vm.order.orderDetails.push(orderDetail);
                }
            })
         }, function(error) {});
            $scope.$on('$destroy', unsubscribe);

            // vm.modalInstancePromotions.result.then(function(promotions) {
            //     angular.forEach(promotions, function(promotion) {
            //         var orderDetail = {
            //             productItemId: promotion.id,
            //             productItemType: productItemType.value,
            //             unitOfAccount: '',
            //             quantity: 1,
            //             price: promotion.retailPrice,
            //             totalPrice: null,
            //             memo: null,
            //             warehouseId: vm.mainStock ? vm.mainStock.id : null
            //         };
            //         orderDetail.product = promotion;
            //         if (!vm.checkAProductSameItemTypeHasInOrderDetails(orderDetail)) {
            //             vm.changeStock(orderDetail);
            //             vm.order.orderDetails.push(orderDetail);
            //         }
            //     })
            // }, function(error) {});

        }
        // delete items product
        vm.deleteItems = function(id) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/order/order-detail-delete-dialog.html',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    vm.confirmDelete = function() {
                        for (var i = 0; i < vm.order.orderDetails.length; i++) {
                            for (var j = 0; j < vm.idCheckeds.length; j++) {
                                if (vm.order.orderDetails[i].product.id === vm.idCheckeds[j])
                                    vm.order.orderDetails.splice(i, 1);
                            }
                        }
                        vm.modalInstance.close();
                        vm.selectedAll = false;
                    };

                    vm.close = function() {
                        vm.modalInstance.dismiss('cancel');
                    }
                }
            });
        }
        vm.idCheckeds = null;
        vm.checkAll = false;
        vm.isNotSelected = true;

        vm.checkAll = function() {
            var i = 0;
            if (vm.selectedAll) {
                vm.selectedAll = true;
                vm.isNotSelected = true;
            } else {
                vm.selectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.order.orderDetails, function(item) {
                i++;
                item.seleted = vm.selectedAll;
            });
            vm.checkOrderProduct();
        }

        vm.checkOrderProduct = function() {
            vm.idCheckeds = [];

            angular.forEach(vm.order.orderDetails, function(item) {
                if (!!item.seleted) {
                    vm.idCheckeds.push(item.product.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.selectedAll = false;
            } else if (vm.idCheckeds.length === vm.order.orderDetails.length) {
                vm.selectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.isNotSelected = false;
                vm.selectedAll = false;
            }
        }

        vm.isOrderNewState = function() {
            return $state.is('order.new');
        }

        function onSelect(customer) {
            vm.order.contactName = customer.name;
            vm.order.contactPhone = customer.phone;
            vm.order.deliverAdress = customer.address1;
            if(customer.deliveryProvinceId){
                vm.order.deliveryProvinceId = customer.deliveryProvinceId;
                vm.getDistricts();
            }
        }

        vm.orderInPhase = function() {
            return (!vm.order.id)|| vm.order.phase == 1 || vm.flag;
        }

        vm.orderInWritingStatus = function() {
            return vm.order.phase == 1 && vm.order.status == CommonConstants.ORDER_WRITING;
        }

        // Đọc số thành chữ
        function readMoneyToWord(money){
            vm.order.moneyToWords = ConvertMoneyToWordService.readNumber12Digits(money).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
            return ConvertMoneyToWordService.readNumber12Digits(money);
        }

    // button waiting cofirm
        function getDistricts() {
            if(vm.order.deliveryProvinceId){
                $http.get('/api/list-districts', {params: { provinceId : vm.order.deliveryProvinceId}}).then(function (response) {
                    vm.districts = response.data;
                })
            }
        }

        function getWards() {
            if(vm.order.deliveryDistrictId) {
                $http.get('/api/list-wards', {params: { districtId : vm.order.deliveryDistrictId}}).then(function (response) {
                    vm.wards = response.data;
                })
            }
        }

        //
        vm.getPaymentAmount = function(){
            var money = 0;
            angular.forEach(vm.order.payments, function(item) {
               money = money + item.paymentAmount;
            })
            return money;
        }
    }
})();
