(function() {
	'use strict';

	angular.module('crmApp').controller('CreateReportProductCareController',
			CreateReportProductCareController);

	CreateReportProductCareController.$inject = ['careProduct', '$scope', '$uibModalInstance', '$state', 'UserUtils', 'DateFormatService',
			'SettingUtils', 'ParseLinks', 'AlertService', 'paginationConstants', '$http', '$localStorage', 'CareHistory' ];

	function CreateReportProductCareController(careProduct, $scope, $uibModalInstance, $state, UserUtils, DateFormatService,
			SettingUtils, ParseLinks, AlertService,
			paginationConstants, $http, $localStorage, CareHistory) {
		var vm = this;
		vm.account = $localStorage.account;
		vm.careHistory = {};
		vm.careHistory.staffId = vm.account.id;
		var loadAll = loadAll;
		var CARE_TYPE = 'CARE_TYPE';
		vm.careHistory.actionDate = new Date();
		if(careProduct){
			vm.careHistory.careMissionId = careProduct.carePlan.careMissionId;
			vm.careHistory.carePlanId = careProduct.carePlan.id;
			vm.careHistory.plannedDate = careProduct.carePlan.plannedDate;
			// vm.careHistory.careTypeId = careProduct.careTypeId;
		}

		loadAll();
		// load all
		function loadAll(){
			configDateTime();

			SettingUtils.get({
				'keys' : [CARE_TYPE]
			}, function(success) {
				vm.caretypies = [];
				angular.forEach(success, function(value, key) {
					if (value.key === CARE_TYPE) {
						vm.caretypies.push(value);
					}
				});
			}, function(error) {
			});
		}
		// date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			vm.dateOptions = DateFormatService.getDateOptions();

			vm.openDateFrom = function() {
				vm.isDateFromOpened = true;
			}
		}
		//
		vm.clear = function(){
			 $uibModalInstance.dismiss('cancel');
		}
		//
		vm.save = function(){
			angular.element(".rm-error").removeClass('remove-error');
			var invalid1 = angular.element('.ng-invalid');
			var reportedContentEditor = $scope.$root.editors['careHistoryToolbar'];

			vm.statusError = false;
			if ($scope.formProductCare.$invalid) {
				$scope.$broadcast('show-errors-check-validity');
				$scope.$broadcast('show-errors-check-validity-html5');
				if (invalid1.length != 0) {
					invalid1.first().focus();
					vm.statusError = true;
					reportedContentEditor.focus();
				}
			} else {
				CareHistory.save(vm.careHistory, function success(reponse){
					$uibModalInstance.dismiss('cancel');
					$scope.$emit('crmApp:save-success-report-care-for-product-care', careProduct.carePlan.id);
				}, function error(){
				});
				$scope.$broadcast('show-errors-reset');
				angular.element(".rm-error").addClass('remove-error');
				$scope.$broadcast('show-errors-reset-html5');
			}
		}
	}
})();
