(function() {
    "use strict";
    angular.module("crmApp")
        .constant("CommonConstants", {
            'CURRENT_VERSION': '3.7',
            //Authority
            'ANONYMOUS': 'ANONYMOUS',
            'CUSTOMER_ALL': 'CUSTOMER_ALL',
            'ORDER_ALL': 'ORDER_ALL',
            'ORDER_CONFIRM': 'ORDER_CONFIRM',
            'TEAM_VIEW': 'TEAM_VIEW',
            'TEAM_EDIT': 'TEAM_EDIT',
            'TEAM_DELETE': 'TEAM_DELETE',
            'TEAM_ADD': 'TEAM_ADD',
            'CUSTOMER_TEAM': 'CUSTOMER_TEAM',
            'ORDER_TEAM': 'ORDER_TEAM',
            'CUSTOMER_ADD': 'CUSTOMER_ADD',
            'CUSTOMER_DELETE': 'CUSTOMER_DELETE',
            'CUSTOMER_EDIT': 'CUSTOMER_EDIT',
            'CUSTOMER_VIEW': 'CUSTOMER_VIEW',
            'ORDER_ADD': 'ORDER_ADD',
            // 'ORDER_CANCEL': 'ORDER_CANCEL',
            'ORDER_DELETE': 'ORDER_DELETE',
            'ORDER_EDIT': 'ORDER_EDIT',
            'ORDER_VIEW': 'ORDER_VIEW',
            'PRODUCT_ADD': 'PRODUCT_ADD',
            'PRODUCT_ALL': 'PRODUCT_ALL',
            'PRODUCT_DELETE': 'PRODUCT_DELETE',
            'PRODUCT_EDIT': 'PRODUCT_EDIT',
            'PRODUCT_VIEW': 'PRODUCT_VIEW',
            'WAREHOUSE_ALL': 'WAREHOUSE_ALL',
            'USER_ALL': 'USER_ALL',
            'USER_VIEW': 'USER_VIEW',
            'USER_ADD': 'USER_ADD',
            'USER_DELETE': 'USER_DELETE',
            'USER_EDIT': 'USER_EDIT',
            'CUSTOMER_CARE': 'CUSTOMER_CARE',
            'PRODUCT_CARE': 'PRODUCT_CARE',
            'WAREHOUSE_GOODS_ISSUE_SEND_REQUEST': 'WAREHOUSE_GOODS_ISSUE_SEND_REQUEST',
            'ORDER_USED_TO_EDIT': 'ORDER_USED_TO_EDIT',
            'REVENUE_ALL': 'REVENUE_ALL',
            'REVENUE_VIEW': 'REVENUE_VIEW',
            'ORDER_REPORT': 'ORDER_REPORT',
          	'ORDER_VIEW_ALL_SALES': 'ORDER_VIEW_ALL_SALES',
            'ORDER_VIEW_PRICE': 'ORDER_VIEW_PRICE',
            'REPORT_REVENUE': 'REPORT_REVENUE',
	          'REPORT_PRODUCT': 'REPORT_PRODUCT',
            'REPORT_WAREHOUSE': 'REPORT_WAREHOUSE',
            'REPORT_DEBIT': 'REPORT_DEBIT',

            // 'ADMIN': 'ADMIN',
            // 'SALES': 'SALES',
            // 'ACCOUNTANT': 'ACCOUNTANT',
            // 'STOCK': 'STOCK',
            // 'PRODUCT_MANAGER': 'PRODUCT_MANAGER',
            // 'USER': 'USER',
            // 'DIRECTOR': 'DIRECTOR',
            'EDIT_DELIVERY_INFO': 'EDIT_DELIVERY_INFO',
            'EDIT_PAYMENT_INFO': 'EDIT_PAYMENT_INFO',
            'WAREHOUSE_VIEW': 'WAREHOUSE_VIEW',
            'WAREHOUSE_VIEW_GOODS_RECEIPT': 'WAREHOUSE_VIEW_GOODS_RECEIPT',
            'WAREHOUSE_VIEW_GOODS_ISSUE': 'WAREHOUSE_VIEW_GOODS_ISSUE',
            'WAREHOUSE_ADD': 'WAREHOUSE_ADD',
            'WAREHOUSE_EDIT': 'WAREHOUSE_EDIT',
            'WAREHOUSE_GOODS_RECEIPT': 'WAREHOUSE_GOODS_RECEIPT',
            'WAREHOUSE_GOODS_ISSUE': 'WAREHOUSE_GOODS_ISSUE',
            'WAREHOUSE_DELETE': 'WAREHOUSE_DELETE',
            'CUSTOMER_EDIT_PHONE_NUMBER': 'CUSTOMER_EDIT_PHONE_NUMBER',
            //Description of Role
            'Admin': 'ADMIN',
            'Sales': 'SALES',
            'Accountant': 'ACCOUNTANT',
            'Stock': 'STOCK',
            'Product_Manager': 'PRODUCT_MANAGER',
            'Director': 'DIRECTOR',
            'SalesManager': 'SALES_MANAGER',
            'SalesLeader': 'SALES_LEADER',
            'HAS_NOT_ROLE_MESSAGE': 'Bạn không có quyền để thực hiện chức năng này.',
            'HAS_NOT_AUTHORITY_TO_EDIT_CUSTOMER_PHONE': 'Bạn không thể chỉnh sửa số điện thoại của khách hàng.',
            'ORDER_WRITING': 1,
            'ORDER_PENDING': 2,
            'ORDER_WAITING_DELIVERY_AND_COLLECTION': 3,
            'ORDER_WAITING_DELIVERY': 4,
            'ORDER_WAITING_COLLECTION': 5,
            'ORDER_COMPLETED': 6,
            'ORDER_WAITING_CONFIRM': 7,
            'ORDER_CANCEL': 8,
            'ORDER_REQUEST_CANCEL': 9,
            'ORDER_MISSING_DELIVERY': 10,
            'ORDER_MISSING_PAYMENT': 11,
            'ORDER_WAIT_WAREHOUSING': 12,
            'COMMENT_ACTION_COMMENT': 'bình luận',
            'COMMENT_ACTION_CREATE_ORDER': 'lập đơn hàng',
            //Setting
            'GOODS_RECEIPT': 1,
            'GOODS_ISSUE': 2,
            'PRODUCT_ITEM_TYPE_PRODUCT': 1,
            'PRODUCT_ITEM_TYPE_OBJECT': 2,
            'PRODUCT_ITEM_TYPE_WORKER': 3,
            'PRODUCT_ITEM_TYPE_PROMOTION': 4,

            'DO_NOT_DELETE': 'Không thể xóa khách hàng có đơn hàng',

            'PRICE_TYPE': {
                'RETAIL': 1,
                'WHOLESALE': 2,
                'AGENT': 3,
                'PROMOTION': 4
            },

            'RoleId': {
                'ADMIN': 1,
                'SALES': 2,
                'ACCOUNTANT': 3,
                'STOCK': 4,
                'PRODUCT_MANAGER': 5,
                'DIRECTOR': 6,
                'SALES_MANAGER': 7,
                'SALES_LEADER': 8
            },
            'RoleName': {
                'ADMIN': 'ADMIN',
                'SALES': 'SALES',
                'ACCOUNTANT': 'ACCOUNTANT',
                'STOCK': 'STOCK',
                'PRODUCT_MANAGER': 'PRODUCT_MANAGER',
                'DIRECTOR': 'DIRECTOR',
                'SALES_MANAGER': 'SALES_MANAGER',
                'SALES_LEADER': 'SALES_LEADER'
            },
            'DELIVER_METHOD': {
                'INTERNAL': 1,
                'VIETTEL': 2,
                'GHTK': 3,
                'CHANH_XE': 4
            }

        });
})();
