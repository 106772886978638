(function() {
    'use strict';

    angular.module('crmApp').controller('OrderDetailController',
        OrderDetailController);

    OrderDetailController.$inject = ['SettingUtils', 'DateFormatService', 'ViettelPostService', 'UserUtils', 'Comment', '$uibModal',
        '$scope', '$http', '$rootScope', '$stateParams', 'entity', 'Order',
        'User', 'OrderDetail', '$state', '$window', 'Principal',
        'CommonConstants', '$localStorage', '$filter', 'AlertService', 'Payment', '$cookieStore',
        'StringUtils', 'GHTKService', '$resource'
    ];

    function OrderDetailController(SettingUtils, DateFormatService, ViettelPostService, UserUtils, Comment, $uibModal, $scope,
        $http, $rootScope, $stateParams, entity, Order, User, OrderDetail,
        $state, $window, Principal, CommonConstants, $localStorage, $filter, AlertService, Payment, $cookieStore,
        StringUtils, GHTKService, $resource) {

        var vm = this;
        vm.order = entity;
        vm.ProductGreaterThanInStock = false;
        vm.isChangeOrderStatus = false;

        vm.noOfDifferentDayBetweeen2Date = function(dateOne, dateTwo) {
            if (dateOne !== null && dateTwo !== null) {
                var dayOfDateOne = $filter('date')(dateOne, 'dd');
                var monthOfDateOne = $filter('date')(dateOne, 'MM');
                var yearOfDateOne = $filter('date')(dateOne, 'yyyy');
                var dayOfDateTwo = $filter('date')(dateTwo, 'dd');
                var monthOfDateTwo = $filter('date')(dateTwo, 'MM');
                var yearOfDateTwo = $filter('date')(dateTwo, 'yyyy');

                var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                var firstDate = new Date(yearOfDateOne, monthOfDateOne,
                    dayOfDateOne);
                var secondDate = new Date(yearOfDateTwo, monthOfDateTwo,
                    dayOfDateTwo);

                var diffDays = Math.round(((firstDate.getTime() - secondDate
                    .getTime()) / (oneDay)));
                return Number(diffDays);
            }
            return "";
        };

        vm.absNumber = function(num) {
            if (angular.isNumber(num)) {
                return Math.abs(num);
            } else {
                return "";
            }
        };
        // date
        function configDateTime() {
            vm.altInputFormats = DateFormatService.getAltInputFormats();
            vm.dateFormat = DateFormatService.getDateFormat();
            vm.dateOptions = {
                startingDay: 1,
                minDate: new Date()
            };

            vm.openDateFrom = function() {
                vm.isDateFromOpened = true;
            }
            vm.datePickerOpenStatus = function() {
                vm.isOrderDateOpened = true;
            }
        }
        vm.getCollectedAmount = getCollectedAmount;
        vm.load = load;
        vm.hasAnyAuthority = Principal.hasAnyAuthority;
        vm.load();
        vm.comments = null;

        //FIXME
        //use this variable to know has new comment and open comment area
        vm.hasNewComment = false;
        var stompClient = null;
        var subscriber = null;
        var subscriberOrderChange = null;


        function connectAndSubscribeServer() {
            var loc = $window.location;
            var url = '//' + loc.host + loc.pathname + 'websocket/tracker';
            var authToken = angular.fromJson($localStorage.authenticationToken).access_token;
            url += '?access_token=' + authToken;
            var socket = new SockJS(url);
            stompClient = Stomp.over(socket);
            stompClient.connect({}, function(frame) {
                subscriber = stompClient.subscribe("/topic/receiveComment/" +
                    vm.order.id,
                    function(data) {
                        receiveComment(angular.fromJson(data.body));
                    });
                subscriberOrderChange = stompClient.subscribe("/topic/receiveChangeOrder/" + vm.order.id, function (data) {
                    if(data.body){
                        Order.get({id: vm.order.id}, function success(order) {
                            vm.order = order
                        }, function error() {

                        });
                    }
                })
            })
        }

        $rootScope.$on('unsubscribe-comment-socket', function() {
            if (subscriber !== null) {
                subscriber.unsubscribe();
            }
        });

        $rootScope.$on('unsubscribe-order-change-socket', function() {
            if (subscriberOrderChange !== null) {
                subscriberOrderChange.unsubscribe();
            }
        });

        function receiveComment(comment) {
            if (comment.orderId == vm.order.id) {
                var checkExit = false;
                vm.hasNewComment = false;

                for (var i = 0; i < vm.comments.length; i++) {
                    if (vm.comments[i].id == comment.id) {
                        checkExit = true;
                        vm.comments[i] = comment;
                        break;
                    }
                }

                if (!checkExit) {
                    vm.comments.push(comment);
                    vm.hasNewComment = true;
                }

                /*var actionDescription = comment.actionDescription;
                if(actionDescription != CommonConstants.COMMENT_ACTION_COMMENT){
                  Order.get({
                      id: vm.order.id
                  }, function success(order) {
                      vm.order = order;
                  }, function error() {});
                } else {
                  //if(!$scope.$$phase) {
                    //$digest or $apply
                  //}
                }*/

                $scope.$digest();
            }
        };
        vm.createOrder = function() {
            // new order in phase order. 0: quotation, 1:order
            vm.order.phase = 1;
            Order.update(vm.order, onSaveSuccess, onSaveError);
        }

        vm.getListUserTagedFromComment = function (s) {
            var r = /@\[[a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+:(\d+)\]/g
            var m = r.exec(s)
            var a = []
            while (m != null) {
              a.push(Number(m[1]))
              m = r.exec(s)
            }
            var unique = a.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            })
            return unique
        }

        var listUserTaged = [];
        vm.sendNotificationUserTaged = function (s) {
            listUserTaged = vm.getListUserTagedFromComment(s)
            if(listUserTaged != null && listUserTaged.length > 0){
                $http({
                    url: "/api/tag-user-notification",
                    method: "GET",
                    params: {
                        orderId: vm.order.id,
                        listUserTaged: listUserTaged,
                    }
                }).then(function (response) {
                })
            }
        }

        vm.sendComment = function(comment){
            vm.sendNotificationUserTaged(comment.content);
            if (stompClient) {
                stompClient.send("/topic/sendComment/" + vm.order.id, {},
                    angular.toJson(comment));
            }
        }

        vm.sendCommentNormal = function(comment) {
            vm.comment = comment;
            vm.comment.orderId = vm.order.id;
            vm.comment.staffId = vm.accountId;
            if(!vm.comment.action){
              vm.comment.action = 0;
            }

            if(!vm.comment.actionDescription){
              vm.comment.actionDescription = CommonConstants.COMMENT_ACTION_COMMENT;
            }

            if (vm.isNotBlank(vm.comment.content)) {
                vm.sendComment(vm.comment);
            }
        }

        vm.isCurrentStockRole = function() {
            return getCurrentRoleName() == CommonConstants.Stock;
        };

        function getCurrentRoleName() {
            var roleName = null;
            angular.forEach($localStorage.account.roles, function(role, key) {
                if (role.id == $localStorage.currentRoleId) {
                    roleName = role.name;
                    return false;
                }
            });
            return roleName;
        }
        // status show hidden
        vm.notProcessStatus = function(status){
            return status == 1;
        }
        vm.processingStatus = function(status){
            return status == 2;
        }
        vm.packedStatus = function(status){
            return status == 3;
        }
        vm.sendedToDeliveryStatus = function(status){
            return status == 4;
        }
         vm.receivingStatus = function(status){
            return status == 5;
        }
         vm.receivedGoodsStatus = function(status){
            return status == 6;
        }
        // change button process
        vm.changeProcessStatus = function(value){
            if(vm.actionAccountantConfirmCollect == true){
                vm.actionAccountantConfirmCollect = false;
            }
            if(value == 1){
                vm.order.stockProcessStatus = value;
                vm.order.stockReceivedStatus = value;
            }
            else if(value == 2 || value == 3 || value == 4)
                vm.order.stockProcessStatus = value;
            else if(value == 5 || value == 6)
                vm.order.stockReceivedStatus = value;

            if(vm.sendedToDeliveryStatus(value)){
              vm.stockConfirmExport();
            } else if(vm.notProcessStatus(value) || vm.processingStatus(value) || vm.packedStatus(value) || vm.receivingStatus(value)){
              Order.update(vm.order, onSaveSuccess,
                  onSaveError);
            } else if(vm.receivedGoodsStatus(value)){
                vm.stockWaitWarehouseConfirm();
            }
        }
        //
        vm.nameProcess = null;
        vm.getNameStockProcessStatus = function(ProcessStatus, ReceivedStatus){
            angular.forEach(vm.stockProcessStatus, function(value, key) {
                if(vm.order.category === 4){
                    if(value.value == ReceivedStatus){
                        vm.nameProcess = value.name;
                    }
                    if (ReceivedStatus === null && value.value === 1) {
                        vm.nameProcess = value.name;
                    }
                }else{
                    if(value.value == ProcessStatus){
                        vm.nameProcess = value.name;
                    }
                }
            });
            return vm.nameProcess;
        }
        // get setting
        function getDataFromSetting() {
            var STOCK_PROCESS_STATUS = 'STOCK_PROCESS_STATUS';
            SettingUtils.get({
                'keys': [STOCK_PROCESS_STATUS]
            }, function(success) {
                vm.stockProcessStatus = [];
                angular.forEach(success, function(value, key) {
                    if (value.key === STOCK_PROCESS_STATUS) {
                        if (vm.order.category === 4 && (value.value === 1 || value.value === 5 || value.value === 6))
                            vm.stockProcessStatus.push(value);
                        else if(vm.order.category !== 4 && (value.value === 1 || value.value === 2 || value.value === 3 || value.value === 4))
                            vm.stockProcessStatus.push(value);
                    }
                });
            }, function(error) {});
        }
        //
        function load() {
            //
            getDataFromSetting();
            vm.account = UserUtils.getAccount();
            vm.accountId = vm.account.id;
            configDateTime();

            if(vm.order.category && vm.order.category == 4){
              Order.get({
                id : vm.order.refOrderId
              }, function(response){
                vm.orderOrigin = response;
                vm.customerChange = 0; //Tiền trả khách hàng
                vm.customerPay = 0; //Tiền thu khách hàng

                if((vm.orderOrigin.totalPayAmount - (vm.order.totalPayAmount + vm.order.deferredRevenue)) >= 0){
                  vm.customerPay = vm.orderOrigin.totalPayAmount - (vm.order.totalPayAmount + vm.order.deferredRevenue);
                } else {
                  vm.customerChange = (vm.order.totalPayAmount + vm.order.deferredRevenue) - vm.orderOrigin.totalPayAmount;
                }
              });
            }

            $http({
                url: '/api/get-order-comment-by-order-id',
                method: 'GET',
                params: {
                    id: vm.order.id
                }
            }).then(function(response) {
                vm.comments = response.data;
                //subscribe server for new comments
                connectAndSubscribeServer();
            });
            //
             $http({
                url: '/api/orders-aud/'+vm.order.id,
                method: 'GET'
            }).then(function(response) {
              vm.order.audit = response.data.orderDetailAud;
            });
            //

            // get information company
            // var company_tax_code = 'company_tax_code';
            var company_website = 'company_website';
            var company_name = 'company_name';
            var company_address = 'company_address ';
            var company_phone = 'company_phone';
            var company_email = 'company_email';
            var STOCK_PROCESS_STATUS = 'STOCK_PROCESS_STATUS';
            vm.companyWebsite = "";
            vm.companyName = "";
            vm.companyAddress = "";
            vm.companyPhone = "";
            vm.companyEmail = "";
            vm.stockProcessStatus = "";
            $http({
                url: '/api/general-setting',
                method: 'GET'
            }).then(function(response) {
                vm.generalSetting = response.data;
                angular.forEach(vm.generalSetting, function(generalSetting) {
                   if(generalSetting.key === company_website){
                        vm.companyWebsite = generalSetting.value;
                   }else if(generalSetting.key === company_name){
                        vm.companyName = generalSetting.value;
                   }
                   else if(generalSetting.key == company_address){
                        vm.companyAddress = generalSetting.value;
                   }
                   else if(generalSetting.key === company_phone){
                        vm.companyPhone = generalSetting.value;
                   }else if(generalSetting.key === company_email){
                        vm.companyEmail = generalSetting.value;
                   }
                });
            });
        };

        $scope.date = new Date();

        var unsubscribe = $rootScope.$on('crmApp:orderUpdate', function(event,
            result) {
            vm.order = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.redirectEmail = function() {
            $state.go('order.email', {
                id: entity.id
            });
        }
        vm.orderReport = function(orderName) {
            // if (vm.isCurrentStockRole() == true) {
            //     $window.open("public/pdf/open/" + vm.order.id + "/" + vm.order.stockOrderReport);
            // } else {
                $window.open("public/pdf/open/" + vm.order.id + "/" + vm.order.orderReport);
            // }
        }
        vm.orderCollection = function () {
            $window.open("public/pdf/open/" + vm.order.id + "/" + vm.order.orderCollection);
        }
        vm.orderDelivery = function(){
            $window.open("public/pdf/open/" + vm.order.id + "/" + vm.order.orderDelivery);
        }
        vm.shipmentReport = function(orderName) {
            $window.open("public/pdf/open/" + vm.order.id + "/" + vm.order.shipmentReport);
        }

        function getCollectedAmount() {
            var result = 0;
            if (vm.order && vm.order.payments && vm.order.payments.length > 0) {
                angular.forEach(vm.order.payments, function(payment) {
                    result += payment.paymentAmount;
                });
            }
            return result;
        }

        vm.theNumberOfPaymented = function() {
            return vm.order && vm.order.payments ? vm.order.payments.length : 0;
        }

        /*vm.getCurrentTheNumOfPayment = function() {
            var theNumberOfPaymented = vm.theNumberOfPaymented();
            var currentTheNumOfPayment = theNumberOfPaymented + 1;
            if (currentTheNumOfPayment > vm.getOrderPayTime()) {
                return vm.getOrderPayTime();
            } else {
                return currentTheNumOfPayment;
            }
        };*/

        /*vm.getOrderPayTime = function() {
            return vm.order.payTimes && vm.order.payTimes > 0 ? vm.order.payTimes : 1;
        };*/

        vm.isPaymenting = function() {
            return vm.payment &&
                ((vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION && vm.order.goodsIssue) ||
                    vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION);
        };

        vm.isDelivery = function() {

            return vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY ||
                    vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION;
        };

        vm.isPending = function(){
            return vm.order.category == 4 && vm.order.status == CommonConstants.ORDER_PENDING;
        }
        vm.changeOrderStatus = function(status, isGoStatusBefore) {
            if (isGoStatusBefore) {
                if (status == CommonConstants.ORDER_PENDING) {

                } else if (status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION) {
                    vm.order.goodsIssue = false;
                    vm.order.collectedMoney = false;
                } else if (vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY &&
                    status == CommonConstants.ORDER_WAITING_COLLECTION) {
                    //STOCK CANCEL DELIVERY
                    vm.order.collectedMoney = false;
                } else if (vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION &&
                    status == CommonConstants.ORDER_WAITING_DELIVERY) {
                    //ACCOUNTANT CANCEL COLLECTION
                    vm.order.goodsIssue = false;
                } else if (vm.order.status == CommonConstants.ORDER_COMPLETED &&
                    status == CommonConstants.ORDER_WAITING_COLLECTION) {
                    //DIRECTOR CANCEL COMPLETED TO WAITING COLLECTION
                    vm.order.collectedMoney = false;
                } else if (vm.order.status == CommonConstants.ORDER_COMPLETED &&
                    status == CommonConstants.ORDER_WAITING_DELIVERY) {
                    //DIRECTOR CANCEL COMPLETED TO WAITING DELIVERY
                    vm.order.goodsIssue = false;
                } else if(vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL &&
                    status == CommonConstants.ORDER_CANCEL &&
                    (vm.isOrderCollectedMoney() || Number(vm.getCollectedAmount()) > 0)){
                    //da thu tien hoac 1 phan --> yeu cau huy --> huy thu tien --> huy
                    vm.order.collectedMoney = false;
                } else if(vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL &&
                    status == CommonConstants.ORDER_CANCEL){
                  //da xuat kho --> yeu cau huy --> huy xuat kho --> huy
                  vm.order.goodsIssue = false;
                } else if(vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL &&
                    status == CommonConstants.ORDER_REQUEST_CANCEL){
                  //yeu cau huy nhung can ca huy xuat kho va huy 1 phan tien da Thu
                  if(vm.order.cancelCollectedMoney){
                    vm.order.collectedMoney = false;
                  } else if(vm.order.cancelGoodsIssue){
                    vm.order.goodsIssue = false;
                  }
                }
            } else {
                if (status == CommonConstants.ORDER_WAITING_COLLECTION) {
                    //STOCK CONFIRM DELIVERY
                    vm.order.goodsIssue = true;
                } else if (status == CommonConstants.ORDER_WAITING_DELIVERY) {
                    //ACCOUNTANT CONFIRM COLLECT MONEY
                    vm.order.collectedMoney = true;
                } else if (status == CommonConstants.ORDER_COMPLETED) {
                    //GO TO COMPLETE
                    vm.order.goodsIssue = true;
                    vm.order.collectedMoney = true;
                }
                //cancel - default goodsIssue, collectedMoney is false
            }

            //update order status;
            vm.order.status = status;
            vm.isChangeOrderStatus = true;
            Order.update(vm.order, onSaveSuccess,
                onSaveError);
            $scope.$broadcast('show-errors-reset');
        };

        vm.isOrderGoodsIssued = function(){
          return vm.order.goodsIssue;
        };

        vm.isOrderCollectedMoney = function(){
          return vm.order.collectedMoney;
        };

        vm.resetDataOnPopupConfirmOrderStatus = function() {
            vm.payment = null;
            vm.statusMoney = null;
            vm.statusObject = null;
            vm.content = '';
            vm.order.cancelCollectedMoney = false;
            vm.order_cancelCollectedMoney = false; //use in onSaveSuccess
            vm.order.cancelGoodsIssue = false;
            vm.order_cancelGoodsIssue = false;
        };
        // 
        vm.statusObject = "";
        function checkObject(orders){
            angular.forEach(orders, function(order) {
                if(order.productItemType == 2 && (order.received == 0 || order.received == null)){
                    vm.statusObject = "Vật tư chưa đủ để xuất đơn hàng";
                }
            })
            
        }
        //
        vm.collectMoneyFull = function () {
            if(vm.isPaymenting()){
                vm.payment.paymentAmount = Number(vm.order.totalPayAmount) - Number(vm.getCollectedAmount());
            }
        }
        // dialog confirm order status
        vm.statusMoney = "";
        vm.isSaving = false;
        vm.cofirmOrderStatus = function(status, isGoStatusBefore) {

            vm.modalInstance = $uibModal
                .open({
                    templateUrl: 'app/order/confirm-order-status-dialog.html',
                    backdrop: 'static',
                    scope: $scope,
                    controller: function() {
                        vm.cofirm = function(arr) {
                            vm.isSaving = true;
                            vm.statusMoney = "";
                            vm.statusObject = "";
                            if (vm.formOrder.$invalid) {
                                $scope.$broadcast('show-errors-check-validity');
                                var invalidControl = angular.element('input.ng-invalid');
                                if (invalidControl != null) {
                                    invalidControl.first().focus();
                                }
                            } else {
                                // check money
                                if (vm.isPaymenting()) {
                                    if (Number(vm.payment.paymentAmount) + Number(vm.getCollectedAmount()) > Number(vm.order.totalPayAmount)) {
                                        // vm.payment.paymentAmount + vm.getCollectedAmount() > vm.order.totalPayAmount
                                        // vm.payment.paymentAmount
                                        vm.statusMoney = "Số tiền này đã vượt quá tổng tiền. Vui lòng nhập lại số tiền khác."
                                    }else if(Number(vm.order.totalPayAmount) != Number(vm.getCollectedAmount()) && (vm.payment.paymentAmount === undefined || vm.payment.paymentAmount === null || vm.payment.paymentAmount === "")){
                                        if (vm.formOrder.$invalid) {
                                            $scope.$broadcast('show-errors-check-validity');
                                            var invalidControl = angular.element('input.ng-invalid');
                                            if (invalidControl != null) {
                                                invalidControl.first().focus();
                                            }
                                        }
                                    }else {
                                        if(Number(vm.order.totalPayAmount) - Number(vm.getCollectedAmount()) == 0){
                                            vm.payment.paymentAmount = 0;
                                        }
                                        if(vm.dayOfReceipt){
                                            vm.payment.dayOfReceipt = new Date(vm.dayOfReceipt);
                                        }
                                        Payment.save(vm.payment, function success(success) {
                                            if (Number(vm.getCollectedAmount()) + Number(vm.payment.paymentAmount) == vm.order.totalPayAmount) {
                                                vm.changeOrderStatus(status, isGoStatusBefore);
                                            } else {
                                                //save comment
                                                vm.comment = {};
                                                vm.comment.orderId = vm.order.id;
                                                vm.comment.staffId = vm.accountId;
                                                vm.comment.content = vm.content;
                                                vm.comment.actionDescription = vm.actionDescription;
                                                vm.comment.action = vm.action; //only use for comment
                                                vm.content = '';
                                                // if (vm.isNotBlank(vm.comment.content)) {
                                                    //Send comment real time
                                                    vm.sendComment(vm.comment);
                                                // }
                                                Order.get({
                                                    id: vm.order.id
                                                }, function success(order) {
                                                    vm.order = order;

                                                }, function error() {});
                                                vm.resetDataOnPopupConfirmOrderStatus();
                                            }

                                        }, function error() {
                                            Order.get({
                                                id: vm.order.id
                                            }, function success(order) {
                                                vm.order = order;

                                            }, function error() {});
                                            vm.resetDataOnPopupConfirmOrderStatus();
                                        });
                                        vm.modalInstance.close();
                                    }

                                } else if(vm.isPending() && isGoStatusBefore == false){
                                    // check warehouse
                                    $http.get('/api/get-return-warehouse')
                                    .then(function(response) {
                                        vm.refWarehouses = response.data;
                                        if(vm.refWarehouses == "" || vm.refWarehouses == null){
                                           vm.messageRefWarehouses = "Bạn chưa có kho hàng trả, vui lòng tạo kho hàng trả!";
                                        }else{
                                            vm.changeOrderStatus(status, isGoStatusBefore);
                                            vm.modalInstance.close();
                                        }
                                    })
                                }else if(vm.isDelivery()){
                                    // check object not receiced
                                    if(status == 8 || status == 9 || status == 3){
                                        vm.changeOrderStatus(status, isGoStatusBefore);
                                        vm.modalInstance.close();
                                    }else{
                                        checkObject(vm.order.orderDetails);
                                        if(vm.statusObject == null || vm.statusObject == ""){
                                            vm.changeOrderStatus(status, isGoStatusBefore);
                                            vm.modalInstance.close();
                                        }
                                    }
                                    
                                }
                                else{
                                    vm.messageRefWarehouses = "";
                                    vm.changeOrderStatus(status, isGoStatusBefore);
                                    vm.modalInstance.close();
                                }
                            }
                        };

                        vm.cancel = function() {
                            vm.resetDataOnPopupConfirmOrderStatus();
                            vm.modalInstance.dismiss('cancel');
                            $rootScope.$on('close-modal', function(){
                                vm.modalInstance.dismiss('cancel');
                            });
                        }
                    }
                });
        }

        vm.salesWaitingConfirm = function() {
            vm.titleStatus = "Chờ Xác Nhận";
            vm.actionDescription = "Chờ Xác Nhận Đơn Hàng";
            vm.action = CommonConstants.ORDER_WAITING_CONFIRM; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_CONFIRM, false);
        }

         vm.stockWaitWarehouseConfirm = function() {
            vm.titleStatus = "Xác Nhận";
            vm.actionDescription = "Đã Nhận Hàng";
            vm.action = CommonConstants.ORDER_WAIT_WAREHOUSING; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_COMPLETED, false);
        }

        vm.salesCancelConfirm = function() {
            vm.titleStatus = "Không Xác Nhận";
            vm.actionDescription = "Không Xác Nhận Đơn Hàng";
            vm.action = CommonConstants.ORDER_WRITING; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WRITING, false);
        }

        vm.salesConfirm = function() {
            vm.titleStatus = "Xác Nhận";
            vm.actionDescription = "Xác Nhận Đơn Hàng";
            vm.action = CommonConstants.ORDER_PENDING; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_PENDING, false);
        }

        vm.salesCancelApparove = function() {
            vm.messageRefWarehouses = "";
            vm.titleStatus = "Không Duyệt";
            vm.actionDescription = "Không Duyệt Đơn Hàng";
            vm.action = CommonConstants.ORDER_WAITING_CONFIRM; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_CONFIRM, true);
        }

        vm.refWarehouses = [];
        vm.messageRefWarehouses = null;
        vm.accountantComfirmApparove = function() {
            if(vm.order.category == 4){
                vm.titleStatus = "Duyệt";
                vm.actionDescription = "Duyệt Đơn Hàng";
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_WAIT_WAREHOUSING, false);
            }else{
                vm.checkTheNumberOfProductGreaterThanInStock();
                vm.titleStatus = "Duyệt";
                vm.actionDescription = "Duyệt Đơn Hàng";
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION, false);
            }
        }
        //kiem tra
        vm.theNumberOfProductGreaterThanInStock = function(data) {
            var theNumberOfProduct = 0;
            theNumberOfProduct = theNumberOfProduct + vm.countTheNumberOfProductBothNormalAndPromotion(data.warehouseId, data.productItemId);
            theNumberOfProduct = theNumberOfProduct - vm.countTheNumberOfProductBothNormalAndPromotionHadBought(data.warehouseId, data.productItemId);
            return data.numberInOrders + theNumberOfProduct - data.numberInStock;
        };
        vm.countTheNumberOfProductBothNormalAndPromotion = function(warehouseId, productId) {
            var count = 0;
            if (vm.order.orderDetails) {
                angular.forEach(vm.order.orderDetails, function(orderDetail) {
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += orderDetail.quantity;
                    }
                });
            }
            return count;
        };
        vm.countTheNumberOfProductBothNormalAndPromotionHadBought = function(warehouseId, productId) {
            var count = 0;
            var orderDetail = null;
            if (vm.order.orderDetails) {
                for (var i = 0; i < vm.order.orderDetails.length; i++) {
                    orderDetail = vm.order.orderDetails[i];
                    if (orderDetail.warehouseId == warehouseId && orderDetail.productItemId == productId) {
                        count += orderDetail.quantity;
                    }
                }
            }
            return count;
        };

        vm.isQuantityOfOneProductGreaterThanQuantityInStock = function(){
          var result = false;
          angular.forEach(vm.order.productQuantities, function(productQuantity) {
              if(vm.countTheNumberOfProductBothNormalAndPromotion(productQuantity.warehouseId, productQuantity.productItemId) > productQuantity.numberInStock){
                result = true;
                return false;
              }
          });
          return result;
        };

        vm.checkTheNumberOfProductGreaterThanInStock = function() {
            vm.status = true;
            angular.forEach(vm.order.productQuantities, function(productQuantities) {
                if (vm.status == true && vm.theNumberOfProductGreaterThanInStock(productQuantities) > 0) {
                    vm.ProductGreaterThanInStock = true;
                    vm.status = false;
                }
            })
        }
        // end
        vm.directorNotConfirmExportAndCollect = function() {
            vm.titleStatus = "Hủy Chờ Xuất Kho & Thu Tiền";
            vm.actionDescription = "Hủy Chờ Xuất Kho & Thu Tiền";
            vm.action = CommonConstants.ORDER_PENDING; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_PENDING, true);
        }

        vm.stockNotConfirmExport = function() {
            vm.titleStatus = "Huỷ Xuất Kho";
            vm.actionDescription = "Hủy Xuất Kho";
            if (vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY &&
                vm.order.collectedMoney == false) {
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION; //only use for comment
                vm.cofirmOrderStatus(
                    CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION,
                    true);
            } else if (vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY &&
                vm.order.collectedMoney) {
                vm.action = CommonConstants.ORDER_WAITING_COLLECTION; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_COLLECTION,
                    true);
            } else if(vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL &&
                vm.order.goodsIssue){
                //huy xuat kho
                if(Number(vm.getCollectedAmount()) > 0){
                  //da thu 1 phan tien va da xuat kho --> yeu cau huy --> huy xuat kho --> cho huy -->huy thu tien --> huy
                  vm.order.cancelGoodsIssue = true;
                  vm.order_cancelGoodsIssue = true; //use in onSaveSuccess to cancel viettelpost, ghtk
                  vm.action = CommonConstants.ORDER_REQUEST_CANCEL; //only use for comment
                  vm.cofirmOrderStatus(CommonConstants.ORDER_REQUEST_CANCEL, true);
                } else {
                  //da xuat kho --> yeu cau huy --> huy xuat kho --> huy
                  vm.action = CommonConstants.ORDER_CANCEL; //only use for comment
                  vm.cofirmOrderStatus(CommonConstants.ORDER_CANCEL, true);
                }
            }
        }

        vm.stockConfirmExport = function() {
            vm.order.estimatedDateOfDelivery = new Date();
            vm.titleStatus = "Xác Nhận Xuất Kho";
            vm.actionDescription = "Xác Nhận Xuất Kho";
            if (vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY && vm.order.collectedMoney) {
              //FROM WAITING DELIVERY TO COMPLETED
              vm.action = CommonConstants.ORDER_COMPLETED; //only use for comment
              vm.cofirmOrderStatus(CommonConstants.ORDER_COMPLETED, false);
            } else {
              //fORM WAITING DELIVERY AND COLLECTION TO WAITING COLLECTION
              vm.action = CommonConstants.ORDER_WAITING_COLLECTION; //only use for comment
              vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_COLLECTION,
                false);
            }
        };

        vm.createCancelOrder = function() {

        };

        vm.accountantNotConfirmCollect = function() {
            vm.titleStatus = "Huỷ Thu Tiền";
            vm.actionDescription = "Huỷ Thu Tiền";
            if (vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION &&
                vm.order.goodsIssue == false) {
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION; //only use for comment
                vm.cofirmOrderStatus(
                    CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION,
                    true);
            } else if (vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION &&
                vm.order.goodsIssue) {
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_DELIVERY,
                    true);
            } else if(vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL &&
                (vm.isOrderCollectedMoney() || Number(vm.getCollectedAmount()) > 0)){
                if(vm.order.goodsIssue){
                  //da thu 1 phan tien va da xuat kho --> yeu cau huy --> huy thu tien --> cho huy --> huy xuat kho --> huy
                  vm.order.cancelCollectedMoney = true;
                  vm.order_cancelCollectedMoney = true; //use in onSaveSuccess
                  vm.action = CommonConstants.ORDER_REQUEST_CANCEL; //only use for comment
                  vm.cofirmOrderStatus(CommonConstants.ORDER_REQUEST_CANCEL, true);
                } else {
                  //da thu tien hoac 1 phan --> yeu cau huy --> huy thu tien --> huy
                  vm.action = CommonConstants.ORDER_CANCEL; //only use for comment
                  vm.cofirmOrderStatus(CommonConstants.ORDER_CANCEL, true);
                }
            }
        }
        vm.actionAccountantConfirmCollect = false;
        vm.accountantConfirmCollect = function() {
            vm.dayOfReceipt = new Date();
            vm.resetDataOnPopupConfirmOrderStatus();
            vm.titleStatus = "Xác Nhận Thu Tiền";
            vm.actionDescription = "Xác Nhận Thu Tiền";
            vm.actionAccountantConfirmCollect = true;
            vm.payment = {};
            vm.payment.orderId = vm.order.id;
            vm.payment.staffId = vm.accountId;
            vm.payment.customerId = vm.order.customerId;
            vm.payment.paymentType = vm.order.payType;
            if (vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION &&
                vm.order.goodsIssue) {
                //FROM WAITING MONEY TO COMPLETED
                vm.action = CommonConstants.ORDER_COMPLETED; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_COMPLETED, false);
            } else {
                //FROM WIATING DELIVERY AND COLLECTION TO WAITING DELIVERY
                vm.action = CommonConstants.ORDER_WAITING_DELIVERY; //only use for comment
                vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_DELIVERY,
                    false);
            }
        }

        vm.directorConfirmGoToExport = function() {
            vm.titleStatus = "Trở Về Chờ Xuất Kho";
            vm.actionDescription = "Trở Về Chờ Xuất Kho";
            vm.action = CommonConstants.ORDER_WAITING_DELIVERY; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_DELIVERY, true);
        }
        vm.restoreOrderStatus = function(){
            vm.titleStatus = "Đang soạn";
            vm.actionDescription = "Đang soạn";
            vm.action = CommonConstants.ORDER_WRITING; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WRITING, false);
        }
        vm.directorConfirmGoToCollect = function() {
            vm.titleStatus = "Trở Về Chờ Thu Tiền";
            vm.actionDescription = "Trở Về Chờ Thu Tiền";
            vm.action = CommonConstants.ORDER_WAITING_COLLECTION; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_WAITING_COLLECTION, true);
        }

        vm.salesLeaderOrManagerCancelOrder = function() {
            vm.titleStatus = "Hủy"; //title popup
            vm.actionDescription = "Hủy Đơn Hàng"; //action in comment
            vm.action = CommonConstants.ORDER_CANCEL; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_CANCEL, false);
            console.log("hủy nhé");
        }
        vm.orderWaitingCancel = true;
        vm.salesLeaderOrManagerRequestCancelOrder = function() {
            vm.orderWaitingCancel = false;
            vm.titleStatus = "Yêu Cầu Hủy"; //title popup
            vm.actionDescription = "Yêu Cầu Hủy Đơn Hàng"; //action in comment
            vm.action = CommonConstants.ORDER_REQUEST_CANCEL; //only use for comment
            vm.cofirmOrderStatus(CommonConstants.ORDER_REQUEST_CANCEL, false);
        }


        function onSaveSuccess(response) {
            vm.order = response;

            if(vm.isChangeOrderStatus){
              //save comment
              vm.comment = {};
              vm.comment.orderId = vm.order.id;
              vm.comment.staffId = vm.accountId;
              vm.comment.content = vm.content;
              vm.comment.actionDescription = vm.actionDescription;
              vm.comment.action = vm.action; //only use for comment
              vm.comment.changeOrderStatus = true; //only use for change actionDescription title on server side
              vm.content = '';
              if(vm.isSaving){
                vm.sendComment(vm.comment);
              }


              vm.isChangeOrderStatus = false;
            }

            if(vm.orderInCancelStatus() || (vm.orderInRequestCancelStatus() && vm.order_cancelGoodsIssue)){
              if(vm.order.viettelPostId){
                ViettelPostService.cancel(vm.order);
              } else if(vm.order.ghtkId){
                GHTKService.cancel(vm.order);
              }
            }

            vm.resetDataOnPopupConfirmOrderStatus();
        }

        function onSaveError() {
            vm.resetDataOnPopupConfirmOrderStatus();
            Order.get({
                id: vm.order.id
            }, function success(order) {
                vm.order = order;
            }, function error() {});
        }

        vm.titleStatus = "";
        vm.orderInWritingStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WRITING;
        }
        vm.orderInWaitingConfirmStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_CONFIRM;
        }

        vm.hasObject = function(orderDetails){
            var result = false;
            angular.forEach(orderDetails, function(item) {
                if(item.productItemType === 2){
                    result = true;
                }
            })
            return result;
        }
        vm.orderInPendingStatus = function() {
            return vm.order.status == CommonConstants.ORDER_PENDING;
        }
        vm.orderInWaitingDeliveryAndCollectionStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION;
        }
        vm.orderInWaitingDeliveryStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY;
        }
        vm.orderInConfirmDelivery = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION ||
                vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY ||
                vm.order.status == CommonConstants.ORDER_WAIT_WAREHOUSING;
        }
        vm.orderInWaitingCollectionStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION;
        }
        vm.orderInCancelCollectionStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION;
        }
        vm.orderInComfirmCollectionStatus = function() {
            return vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY_AND_COLLECTION ||
                vm.order.status == CommonConstants.ORDER_WAITING_COLLECTION;
        }
        vm.orderCancelStatus = function(){
            return vm.order.status == CommonConstants.ORDER_CANCEL;
        }
        vm.orderInCancelStatus = function() {
            return vm.order.status == CommonConstants.ORDER_CANCEL;
        }
        vm.orderInRequestCancelStatus = function() {
            return vm.order.status == CommonConstants.ORDER_REQUEST_CANCEL;
        }
        vm.orderInRefundStatus = function(){
            return vm.order.refundOrderId != null;
        }
        vm.orderInCompletedStatus = function() {
            return vm.order.status == CommonConstants.ORDER_COMPLETED && vm.order.category != 4 && !vm.order.refundOrderId;
        }
        vm.orderInPhase = function() {
            return (vm.order.phase == 1);
        }
        vm.orderInEditStatus = function() {
            return (vm.order.phase == 0 || vm.order.status == CommonConstants.ORDER_WRITING);
        }
        vm.orderInDeliveryStatus = function() {
            return (vm.order.status == CommonConstants.ORDER_WAITING_DELIVERY);
        }

        vm.orderInPaymentStatus = function() {
            return !vm.order.collectedMoney;
        }

        vm.orderInEditDelivery = function() {
            return !vm.order.goodsIssue;
        }

        vm.ghtkShipmentOrder = function() {
            return vm.order.status === CommonConstants.ORDER_WAITING_DELIVERY;
        }

        vm.orderInShipment = function() {
        	return vm.order.status === CommonConstants.ORDER_WAITING_COLLECTION || vm.order.status === CommonConstants.ORDER_COMPLETED;
        }

        vm.isViettelPostDeliverMethod = function() {
        	return vm.order.deliverMethod == CommonConstants.DELIVER_METHOD.VIETTEL;
        }

        vm.isGHTKDeliverMethod = function() {
        	return vm.order.deliverMethod == CommonConstants.DELIVER_METHOD.GHTK;
        }

        vm.isNotBlank = function(data) {
            if (data == null || data.trim() == "" || data == undefined) {
                return false;
            }
            return true;
        }

        //  edit payment and edit delivery
        vm.editPayment = function() {
            vm.modalInstantEditPayment = $uibModal
                .open({
                    templateUrl: 'app/order/order-detail-edit-payment-dialog.html',
                    controller: 'OrderDetailEditPaymentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function() {
                            return vm.order;
                        }
                    }
                });
            vm.modalInstantEditPayment.result.then(function(temp) {
                if (temp != null) {
                    vm.order.paymentDueDate = temp;
                }
            });
        }

        vm.editDelivery = function() {
            vm.modalInstantEditDelivery = $uibModal
                .open({
                    templateUrl: 'app/order/order-detail-edit-delivery-dialog.html',
                    controller: 'OrderDetailEditDeliveryController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function() {
                            return vm.order;
                        }
                    }
                });
            vm.modalInstantEditDelivery.result.then(function(temp) {
                if (temp != null) {
                    vm.order.deliveryDate = temp;
                }
            });
        }

        vm.confirmStock = function() {
            vm.modalInstantconfirmStock = $uibModal
                .open({
                    templateUrl: 'app/order/order-detail-confirm-stock-dialog.html',
                    controller: 'OrderDetailConfirmStockDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'app-modal-window',
                    resolve: {
                        entity: ['$stateParams', 'Order',
                            function($stateParams, Order) {
                                return Order.get({
                                    id: vm.order.id
                                }).$promise;
                            }
                        ]
                    }
                });
            vm.modalInstantconfirmStock.result.then(function(temp) {
                $rootScope.$on('close-modal', function(){
                    vm.modalInstantconfirmStock.dismiss('cancel');
                });
            });
        }

        // Post action for controller

        if($stateParams.action){
            if (vm.order.category != 4 && $stateParams.action == 'waiting_confirm') {
                vm.salesWaitingConfirm();
            } else if(vm.order.category == 4 && ($stateParams.action == 'view' || $stateParams.action == 'waiting_confirm')){
                vm.actionDescription = "Chờ Duyệt Đơn Hàng";
                vm.changeOrderStatus(CommonConstants.ORDER_WRITING, false);
            }
             $state.go('.', {action: undefined} );
        }


        vm.GHTKShipmentOrder = function() {
            $http.post('/api/get-info-ghtk-ship-order-by-order', vm.order).then(onGetSuccess, onGetError);
        }

        function onGetSuccess(response) {
            vm.modalInstanceOrderShipment = $uibModal.open({
                templateUrl: 'app/order-shipment/ghtk/info-dialog.html',
                controller: 'OrderShipmentInfoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return response.data;
                    }
                }
            });
            vm.modalInstanceOrderShipment.result.then(function() {
                //$state.reload();
            })
        }

        function onGetError(response) {}

        vm.checkStatus = function() {
            vm.modalInstantCheckStatus = $uibModal.open({
                templateUrl: 'app/order-shipment/ghtk/check-status-dialog.html',
                controller: 'OrderShipmentCheckStatusController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function() {
                        return vm.order;
                    }
                }
            })
        }

        vm.checkStatusViettelPost = function () {
            vm.modalInstantCheckStatusViettelPost = $uibModal.open({
                templateUrl: 'app/order-shipment/viettel-post/check-status-dialog.html',
                controller: 'CheckStatusViettelPostController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function() {
                        return vm.order;
                    }
                }
            })
        }

        vm.loginDialog = function() {
            var viettelPostAccount = $localStorage.viettelPostAccount;
            if (viettelPostAccount != null && viettelPostAccount != undefined) {
                vm.modalInstantCreateOrder = $uibModal.open({
	                templateUrl: 'app/order-shipment/viettel-post/create-order.html',
	                controller: 'ViettelPostCreateOrderController',
	                controllerAs: 'vm',
                    backdrop: 'static',
	                size: 'lg',
                    resolve: {
                        entity: function() {
                            return vm.order;
                        }
                    }
	            })
            } else {
                vm.modalInstantLogin = $uibModal.open({
                    templateUrl: 'app/order-shipment/viettel-post/login-dialog.html',
                    controller: 'ViettelPostLoginController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function() {
                            return vm.order;
                        }
                    }
                })
            }
        }
        // import object
        vm.showPopupConfirmImportOb = function(orders){
            vm.modalInstantImportOb = $uibModal
                .open({
                    templateUrl: 'app/order/confirm-order-import-object.html',
                    controller: 'OrderDetailConfirmImportObController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'app-modal-window',
                    resolve: {
                        objects: function(){
                            return orders;
                        }
                    }
                });
            vm.modalInstantImportOb.result.then(function(temp) {
                $rootScope.$on('close-modal', function(){
                    vm.modalInstantImportOb.dismiss('cancel');
                });
            });
        }
    }
})();
