(function() {
	'use strict';
	angular.module('crmApp').factory('EmailTemplate', EmailTemplate);

	EmailTemplate.$inject = [ '$resource' ];

	function EmailTemplate($resource) {
		var resourceUrl = 'api/email-templates/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET'
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();
