(function() {
	'use strict';

	angular.module('crmApp').controller('StockGoodIssueController',
			StockGoodIssueController);

	StockGoodIssueController.$inject = ['$state', '$http', 'pagingParams'];

	function StockGoodIssueController($state, $http, pagingParams) {

		var vm = this;
		vm.page = 1;
		vm.entries = '10';
		vm.showItem = 10;
		vm.showName = "sản phẩm cần xuất";
		vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.totalItems = null;

		// get inform order from order
		if($state.params.idOrder){
			$http.get('/api/order-detail-by-id?id=' + $state.params.idOrder)
			.success(function(success) {
				vm.OrderDetails = success;
			});
		}
		// save
		vm.save = function(){
			$state.go('stock.watch-good-issue', null, {
                reload: true
            });
		}
		//
		 function transition() {

		 }
	}
})();
