(function() {
	'use strict';

	angular.module('crmApp').controller('NotificationOrderStatusController',
		NotificationOrderStatusController);

	NotificationOrderStatusController.$inject = ['$scope', '$resource'];

	function NotificationOrderStatusController($scope, $resource) {
		var vm = this;
		vm.notifications = null;
		vm.loadAll = loadAll;
		loadAll();
		function loadAll(){
			var Notification = $resource('api/all-notification');
	        Notification.query({}, function success(staffNotificationList){
	        	vm.notifications = staffNotificationList;
	        })
		}
		vm.limit = 10;
		vm.loadMore = function(){
			var increamented = vm.limit + 10;
      		vm.limit = increamented > vm.notifications.length ? vm.notifications.length : increamented;
		}
	}
})();