(function() {
    'use strict';

    angular.module('crmApp').controller(
        'OrderCreateUpdateProductDialogController',
        OrderCreateUpdateProductDialogController);

    OrderCreateUpdateProductDialogController.$inject = ['$rootScope', '$uibModal','$timeout', '$scope',
        'Product', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants', '$http', 'SettingUtils', '$uibModalInstance'
    ];

    function OrderCreateUpdateProductDialogController($rootScope, $uibModal, $timeout, $scope,
        Product, ParseLinks, AlertService, pagingParams,
        paginationConstants, $http, SettingUtils, $uibModalInstance) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.addProducts = addProducts;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.currentSearch = null;
        vm.changePage = changePage;
        vm.transition = transition;
        vm.entries = '10';
        vm.page = 1;
        var PRODUCT_TYPE = 'PRODUCT_TYPE';
        vm.showName = "sản phẩm";
        // get setting
        getDataFromSetting();

        vm.loadAll();
        function loadAll() {
            vm.loadPage();
        }

        vm.showItem = 10;
         function transition() {
             if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function changePage(){
             var isHasAddProductToProduct = false;
            angular.forEach(vm.products, function(product, key) {
                if (product.selected) {
                    isHasAddProductToProduct = true;
                    return true;
                }
            });

            if(isHasAddProductToProduct){
                vm.modalInstanceChange = $uibModal.open({
                        template: '<popup-component  title="vm.title" content="vm.content" close="vm.closePopup()" save-change="vm.saveChange()" dont-save-change="vm.dontSaveChange()"></popup-component>',
                        size: 'md',
                         backdrop: 'static',
                        scope: $scope,
                        controller: function() {
                            var vm = this;
                            vm.title = "THÔNG BÁO";
                            vm.content = "Bạn có muốn lưu trước khi chuyển trang không? ";
                                vm.closePopup = function() {
                                    //FIXME check truong hop nhay nhieu page
                                     close();

                                };
                                vm.saveChange = function(){
                                    //save and change page
                                    saveChangePopup();
                                };
                                vm.dontSaveChange = function(){
                                    dontsaveChangePopup();
                                };
                        },
                        controllerAs:"vm"
                });
                // function close popup

                function close(){
                    vm.modalInstanceChange.dismiss('cancel');
                    vm.page = vm.currentSearch.page + 1;
                }
                function saveChangePopup(){
                    vm.addProducts(true);
                    vm.modalInstanceChange.dismiss('cancel');
                }
                function dontsaveChangePopup(){
                    vm.modalInstanceChange.dismiss('cancel');
                    vm.transition();
                }

            } else {
                vm.transition();
            }
        }
        function loadPage(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                Product.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.productCO) {
                    vm.productCO = {};
                }
                                vm.productCO.addInfoQuantity = true;
                vm.productCO.page = vm.page - 1;
                vm.productCO.size = vm.entries;
                vm.productCO.sort = sort();
                vm.currentSearch = angular.copy(vm.productCO);
                vm.showItem = vm.entries;
                Product.query(vm.currentSearch, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                // fetchSelectedAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        // search product
        vm.search = function() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.loadPage();
        }

        // add products
       function addProducts(isAddProductWhenChangePage) {
            var newProducts = [];
             angular.forEach(vm.products, function(product, key) {
                if (product.selected) {
                        newProducts.push(product);
                    }
            })

            if (newProducts.length > 0){
                if(isAddProductWhenChangePage){
                    //save before change page
                     $scope.$emit('crmApp:addProductSuccess', newProducts);
                     vm.transition();
                }else{
                    //Add product when press Add on popup
                    $scope.$emit('crmApp:addProductSuccess', newProducts);
                    $uibModalInstance.dismiss('cancel');

                }
            }else{
                 vm.displayError = "Chưa có sản phẩm để thêm. Vui lòng chọn một sản phẩm.";
            }
        }

        function getDataFromSetting() {
            SettingUtils.get({
                'keys': [PRODUCT_TYPE]
            }, function(success) {
                vm.productTypies = [];

                angular.forEach(success, function(value, key) {
                    if (value.key === PRODUCT_TYPE) {
                        vm.productTypies.push(value);
                    }
                });

                angular.element('#productTypeId').selectpicker('refresh');
            }, function(error) {});
        }

        vm.checkProduct = function() {
             vm.idCheckeds = [];
            angular.forEach(vm.products, function(product) {
                if (!!product.selected) {
                    vm.idCheckeds.push(product.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
                vm.isSelectedAll = false;
            } else if (vm.idCheckeds.length === vm.products.length) {
                vm.isSelectedAll = true;
            } else {
                vm.isNotSelected = false;
                vm.isSelectedAll = false;
            }
        }

        vm.selectedAll = function() {
           var i = 0;
            if (vm.isSelectedAll) {
                vm.isSelectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.isSelectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.products, function(product) {
                i++;
                if (i <= vm.entries)
                    product.selected = vm.isSelectedAll;
            });
            vm.checkProduct();
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $rootScope.$on('close-modal', function(){
            $uibModalInstance.close();
        });
    }

})();
