(function() {
    'use strict';

    angular.module('crmApp').controller('UserManagementDialogController',
        UserManagementDialogController);

    UserManagementDialogController.$inject = ['$resource', '$timeout', 'SettingUtils', 'DateFormatService', 'UploadFileService', '$stateParams', 'entity', 'roles',
        'User', '$state', '$scope', '$http', '$filter', 'UserUtils', 'CommonConstants', 'AlertService', 'CheckPhone'
    ];

    function UserManagementDialogController($resource, $timeout, SettingUtils, DateFormatService, UploadFileService, $stateParams, entity, roles, User, $state,
        $scope, $http, $filter, UserUtils, CommonConstants, AlertService, CheckPhone) {
        var vm = this;
        vm.languages = null;
        vm.save = save;
        vm.hasAuthority = hasAuthority;
        vm.user = entity;
        vm.roles = roles;
        vm.userBeforeEdit = angular.copy(vm.user);
        vm.loadData = loadData;
        // upload file
        var fd = new FormData();
        vm.inputType = 'password';
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.user = false;
        vm.staff_statuses = [];
        vm.staff_levels = [];
        vm.authorityChecked = [];
        vm.teams = [];
        vm.flagSelectTeam = true;
        var STAFF_LEVEL = 'STAFF_LEVEL';
        var STAFF_STATUS = 'STAFF_STATUS';
        vm.temp = {
            "id": 2,
            "name": "USER",
            "description": "A default framework permission",
            "selected": true
        }
        vm.loadTeam = function(){
            var StaffTeam = $resource('api/get-team-of-one-staff-not-team-leader');
            StaffTeam.get({staffId: vm.user.id}, function success(staffTeam){
              if(staffTeam && staffTeam.teamId){
                vm.user.teamId = staffTeam.teamId;
              }
            }, function error(){

            });
        }
        // upload image
        vm.isUploadFileInProgress = false;
        vm.uploadFileNameList = [];
        vm.messagePhone = "";
        vm.load = function() {
            vm.loadData();
            $timeout( function(){
              disabledOption();
            }, 1000 );

            vm.loadTeam();

            function getAllUser() {
                // check phone number
                vm.phoneNumber = CheckPhone.checkPhoneAll();
                vm.check = function() {
                    for (var i = 0; i < vm.phoneNumber.length; i++) {
                        if (vm.user.phone === vm.phoneNumber[i]) {
                            return true;
                        }
                    }
                    return false;
                }
                vm.checkNumberPhone = function() {
                    if (vm.check()) {
                        vm.messagePhone = "Số điện thoại đã trùng";
                        vm.statusPhone = true;
                    } else {
                        vm.statusPhone = false;
                    }
                }
            };

            getAllUser();
            if (vm.user.id) {
                if (vm.user.birthday) {
                    vm.user.birthday = new Date(vm.user.birthday);
                }
                if (vm.user.identityCard) {
                    vm.user.identityCard = Number(vm.user.identityCard);
                }
            } else {
                vm.user.birthday = null;
                vm.user.avatar = null;
                vm.user.resizeAvatar = null;
            }
            SettingUtils.get({
                'keys': [STAFF_LEVEL, STAFF_STATUS]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === STAFF_LEVEL) {
                        vm.staff_levels.push(value);
                    } else if (value.key === STAFF_STATUS) {
                        vm.staff_statuses.push(value);
                    }
                });
            }, function(error) {});
        };
        // resize image
        vm.myImage='';
        vm.myCroppedImage='';

        var handleFileSelect=function(evt) {
          var file=evt.currentTarget.files[0];
          var reader = new FileReader();
          reader.onload = function (evt) {
            $scope.$apply(function($scope){
              vm.myImage=evt.target.result;
            });
          };
          reader.readAsDataURL(file);
        };
        angular.element(document.querySelector('#fileInput')).on('change',handleFileSelect);
        // end  resize image
        vm.load();
        vm.altInputFormats = DateFormatService.getAltInputFormats();
        vm.dateFormat = DateFormatService.getDateFormat();

        vm.dateOptions = {
            startingDay: 1,
            maxDate: new Date()
        };

        vm.openCalendar = function() {
            vm.isBirthdayOpened = true;
        }

        vm.inputType = 'password';
        vm.hideShowPassword = function() {
            if (vm.inputType == 'password') {
                vm.inputType = 'text';
            } else {
                vm.inputType = 'password';
            }
        };

        // them cac address_long va address_lat
        $scope.$on('place_changed', function(e, place) {
            vm.user.addressLong = place.geometry.location.lat();
            vm.user.addressLat = place.geometry.location.lng();
        });

        function onUpdateSuccess(result) {
            // delete file upload before but no save in system
            if (vm.uploadFileNameList.length > 1) {
                var index = vm.uploadFileNameList.indexOf(vm.user.avatar);
                if (index != -1) {
                    vm.uploadFileNameList.splice(index, 1);
                    deleteUploadFileButNoSave(vm.uploadFileNameList);
                }
            }

            vm.user = result;
            if (vm.user.id) {
                if (vm.user.birthday) {
                    vm.user.birthday = new Date(vm.user.birthday);
                }
                if (vm.user.identityCard) {
                    vm.user.identityCard = Number(vm.user.identityCard);
                }
                var StaffTeam = $resource('api/get-team-of-one-staff-not-team-leader');
                StaffTeam.get({staffId: vm.user.id}, function success(staffTeam){
                  if(staffTeam && staffTeam.teamId){
                    vm.user.teamId = staffTeam.teamId;
                  }
                }, function error(){

                });
            } else {
                vm.user.birthday = null;
                vm.user.avatar = null;
                vm.user.resizeAvatar = null;
            }

            var hasChangeRole = false;
            if (vm.userBeforeEdit.roleIds !== null && vm.userBeforeEdit.roleIds !== undefined) {
                if (vm.userBeforeEdit.roleIds.length != vm.user.roleIds.length) {
                    hasChangeRole = true;
                } else {
                    for (var i = 0; i < vm.userBeforeEdit.roleIds.length; i++) {
                        if (!vm.user.roleIds.includes(vm.userBeforeEdit.roleIds[i])) {
                            hasChangeRole = true;
                            break;
                        }
                    }

                    if (vm.user.login === UserUtils.getAccount().login && (hasChangeRole || vm.userBeforeEdit.name != vm.user.name || vm.userBeforeEdit.avatar != vm.user.avatar)) {
                        window.location.reload();
                    } else {
                        vm.userBeforeEdit = angular.copy(vm.user);
                    }

                    vm.isSaving = false;
                }
            } else {
                hasChangeRole = true;
            }

            if (hasChangeRole) {
                window.location.reload();
            } else {
                vm.userRoleIdsBeforeEdit = vm.user.roleIds;
            }
            vm.loadTeam();
            console.log(vm.user);
            vm.isSaving = false;
        }

        function onSaveSuccess(result) {
            // delete file upload before but no save in system
            if (vm.uploadFileNameList.length > 1) {
                var index = vm.uploadFileNameList.indexOf(vm.user.avatar);
                if (index != -1) {
                    vm.uploadFileNameList.splice(index, 1);
                    deleteUploadFileButNoSave(vm.uploadFileNameList);
                }
            }

            if (vm.change) {
                $state.go('user-management', null, {
                    reload: true
                });
            }
            vm.isSaving = false;
        }

        vm.change = false;

        function load() {
            SettingUtils.get({
                'keys': [STAFF_LEVEL, STAFF_STATUS]
            }, function(success) {
                angular.forEach(success, function(value, key) {
                    if (value.key === STAFF_LEVEL) {
                        vm.staff_levels.push(value);
                    } else if (value.key === STAFF_STATUS) {
                        vm.staff_statuses.push(value);
                    }
                });
            }, function(error) {});
        };

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.editForm.$invalid) {
                var invalidControl = angular.element('.form-control.ng-invalid');
                if(invalidControl != null){
                    invalidControl.first().focus();
                }
            }else if(vm.statusPhone){
               angular.element('#field_phone').first().focus();
            }
            else {
                vm.user.authsId = [];
                angular.forEach(vm.authorities, function(authorities) {
                    if(vm.authorityChecked[authorities.id] === true){
                        vm.user.authsId.push(authorities.id);
                    }
                });

                if (vm.hasAuthority()) {
                    vm.isSaving = true;
                    //upload resize staff avatar
                    if(vm.myImage)
                        uploadFile();

                    if (vm.user.id !== null) {
                        User.update(vm.user, onUpdateSuccess, onSaveError);
                    } else {
                        //vm.user.auths = [];
                        //vm.user.auths.push(vm.temp);
                        vm.user.langKey = 'en';
                        if (vm.user.genderId != 1 && vm.user.genderId != 2) {
                            vm.user.genderId = 3;
                        }
                        vm.change = true;
                        User.save(vm.user, onSaveSuccess, onSaveError);
                    }
                }
                $scope.$broadcast('show-errors-reset');
            }
        }

        vm.hideShowPassword = function() {
            if (vm.inputType == 'password')
                vm.inputType = 'text';
            else
                vm.inputType = 'password';
        };

        function isImage(fileType) {
            if (fileType.indexOf("image") !== -1) return true;
            return false;
        }

        function getExtensionFile(fileName) {
            return fileName.split('.').pop();
        }

        $scope.fileSelected = function(element) {
            vm.isUploadFileInProgress = true;
            // if not exits file return
            // if file more than 32MB then return
            if (!element.files[0] || (element.files[0].size / 1024 / 1024 > 32)) {
                return;
            }
            // if not image return
            if (!isImage(element.files[0].type)) {
                return;
            }
            // delete file in FormData if exist
            if (fd.has("file")) {
                fd.delete("file");
            }

            // validate file name
            var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
            var validateFileName = fileName + new Date().getTime();
            var extension = getExtensionFile(element.files[0].name);
            var finalFileName = validateFileName + "." + extension;

            fd.append("file", element.files[0]);
            fd.set("file", element.files[0], finalFileName);

            UploadFileService.uploadOneFile('/api/save-user-avatar', fd, function(success, status) {
                // body...
                vm.user.avatar = finalFileName;
                vm.isUploadFileInProgress = false;
                vm.uploadFileNameList.push(finalFileName);
            }, function(error, status) {
                vm.isUploadFileInProgress = false;
            });
        }
        function uploadFile(){
            // Split the base64 string in data and contentType
            var block = vm.myCroppedImage.split(";");
            // Get the content type of the image
            var contentType = block[0].split(":")[1];// In this case "image/png"
            //get the type of the image
            var type = contentType.split("/")[1];// In this case "image/png"
            // get the real base64 content of the file
            var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

            var blob = b64toBlob(realData, contentType);

             // validate file name
            var validateFileName = new Date().getTime();
            var finalFileName = "resize-img-" + validateFileName + "." + type;

            vm.user.resizeAvatar = finalFileName;

            fd.append("file", blob);
            fd.set("file", blob, finalFileName);

            UploadFileService.uploadOneFile('/api/save-user-avatar', fd, function(success, status) {
                // body...
                vm.isUploadFileInProgress = false;
            }, function(error, status) {
                vm.isUploadFileInProgress = false;
            });
        }
        function b64toBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                var byteCharacters = atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

              var blob = new Blob(byteArrays, {type: contentType});
              return blob;
            }
        function deleteUploadFileButNoSave(fileNames) {
            $http({
                method: 'GET',
                url: '/api/delete-user-avatar',
                params: {
                    fileNames: fileNames
                }
            });
        };

        function hasAuthority() {
            if (UserUtils.hasAuthorityName(CommonConstants.USER_ADD) || vm.user.login === UserUtils.getAccount().login) {
                return true;
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
                return false;
            }
        };

        function loadData() {
            $http({
                url: "/api/get-all-team",
                method: "GET",
            }).success(function (response) {
                if(vm.user.id){
                  if(response && response.length > 0){
                    for(var i = 0; i < response.length; i++){
                      if(vm.user.id != response[i].teamLeaderId){
                        vm.teams.push(response[i]);
                      }
                    }
                  }
                } else {
                  vm.teams = response;
                }
            }).error(function (response) {
            })
             $http.get('/api/authorities').success(function(success) {
                 vm.authorities = success;
             });
             $http.get('/api/role-authority').success(function(success) {
                vm.roleAuthority = success;
                if(vm.user.auths !== null){
                    angular.forEach(vm.user.auths, function(auths) {
                        angular.forEach(vm.roleAuthority, function(roleAuthority) {
                            if(auths.id === roleAuthority.authorityId)
                                vm.authorityChecked[auths.id] = true;
                        });
                    });
                }
             });
        }
        vm.showSelectTeam = function () {
            disabledOption();
            checkPermission();
        }
        function checkPermission() {
            vm.authorityChecked = [];
            if(vm.user.roleIds !== null){
               angular.forEach(vm.user.roleIds, function(roleIds) {
                   angular.forEach(vm.authorities, function(authorities) {
                        angular.forEach(vm.roleAuthority, function(roleAuthority) {
                            if(roleIds === roleAuthority.roleId && authorities.id === roleAuthority.authorityId)
                               vm.authorityChecked[authorities.id] = true;
                        });
			       });
			   });
            }
        }
        function disabledOption() {
            vm.flagSelectTeam = true;
            if(vm.user.roleIds && vm.user.roleIds.length > 0){
              for(var i = 0; i < vm.user.roleIds.length; i++){
                if(vm.user.roleIds[i] === CommonConstants.RoleId.SALES || vm.user.roleIds[i] === CommonConstants.RoleId.SALES_LEADER || vm.user.roleIds[i] === CommonConstants.RoleId.SALES_MANAGER){
                  vm.flagSelectTeam = false;
                  break;
                }
              }
            }else{
              angular.element(document.querySelector('.bs-placeholder')).addClass('disabled');
              // $timeout( function(){
              //   angular.element(".bs-placeholder").addClass('disabled');
              // }, 1000 );
            }
            // disabled
            if(vm.flagSelectTeam){
              angular.element(document.querySelector('.bs-placeholder')).addClass('disabled');
                // $timeout( function(){
                //     angular.element(".bs-placeholder").addClass('disabled');
                // }, 1000 );
            }else{
              angular.element(document.querySelector('.bs-placeholder')).removeClass('disabled');
                // $timeout( function(){
                //   angular.element(".bs-placeholder").removeClass('disabled');
                // }, 1000 );
            }
        }
    }
})();
