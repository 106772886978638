(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("crmApp")

.constant("ENV", "prod")

.constant("VERSION", "4.2")

;
})();
