(function() {
    'use strict';

    angular
    .module('crmApp')
    .config(stateAbsConfig)
    .config(stateConfig);

    stateAbsConfig.$inject = ['$stateProvider'];

    function stateAbsConfig($stateProvider) {
        $stateProvider.state('customer', {
            url: '/customer',
            abstract: true,
            parent: 'app'
        });
    }

    stateConfig.$inject = ['$stateProvider', 'CommonConstants'];

    function stateConfig($stateProvider, CommonConstants) {
        $stateProvider
        .state('customer.list', {
            parent: 'customer',
            url: '/list?page&sort&search',
            data: {
                authorities: [CommonConstants.CUSTOMER_VIEW],
                pageTitle: 'Danh sách khách hàng'
            },
            views: {
                'content@': {
                    templateUrl: 'app/customer/customers.html',
                    controller: 'CustomerController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('customer.detail', {
            parent: 'customer',
            url: '/{id}',
            data: {
                authorities: [CommonConstants.CUSTOMER_VIEW],
                pageTitle: 'Chi tiết khách hàng'
            },
            views: {
                'content@': {
                    templateUrl: 'app/customer/customer-detail.html',
                    controller: 'CustomerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Customer', function($stateParams, Customer) {
                    return Customer.get({
                        id: $stateParams.id
                    }).$promise;
                }]

            }
        })
        .state('customer.detail-with-phone-number', {
            parent: 'customer',
            url: '/phone/{phoneNum}',
            data: {
              authorities: [CommonConstants.CUSTOMER_VIEW],
              pageTitle: 'Chi tiết khách hàng'
            },
            views: {
                'content@': {
                    templateUrl: 'app/customer/customer-detail.html',
                    controller: 'CustomerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CustomerPhone', function($stateParams, CustomerPhone) {
                    return CustomerPhone.get({
                        phoneNum: $stateParams.phoneNum
                    }).$promise;
                }]
            },
            onEnter : function($state, entity) {
              if (!entity.id) {
                $state.go('accessdenied');
              }
            }
        })
        .state('customer.new', {
            parent: 'customer',
            url: '/0/create',
            data: {
                authorities: [CommonConstants.CUSTOMER_ADD],
                 pageTitle: 'Thêm mới khách hàng'
            },
             views: {
                'content@': {
                    templateUrl: 'app/customer/customer-dialog.html',
                    controller: 'CustomerDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
               entity: function() {
                    return {
                        name: null,
                        phone: null,
                        birthday: null,
                        address1: null,
                        genderId: null,
                        email: null,
                        contactName: null,
                        faceBookUrl: null,
                        zaloId: null,
                        linkedInUrl: null,
                        postalCode: null,
                        websiteUrl: null,
                        stateProvinceId: null,
                        address2: null,
                        companyName: null,
                        companyTel: null,
                        companyFax: null,
                        companyMail: null,
                        invoiceAddress: null,
                        taxCode: null,
                        sourceId: null,
                        serviceTypeId: null,
                        memo: null,
                        businessId: null,
                        companyBusinessId: null,
                        companyFoundDate: null,
                        companyTypeProject: null,
                        companySourceId: null,
                        twitterUrl: null
                    };
                }
            }
        })
        .state('customer.edit', {
            parent: 'customer',
            url: '/{id}/edit',
            data: {
                authorities: [CommonConstants.CUSTOMER_EDIT],
                 pageTitle: 'Cập nhật khách hàng'
            },
             views: {
                'content@': {
                    templateUrl: 'app/customer/customer-dialog.html',
                    controller: 'CustomerDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Customer', function($stateParams, Customer) {
                    return Customer.get({
                        id: $stateParams.id
                    }).$promise;
                }]
            }
        })
        .state('customer.edit-with-phone-number', {
            parent: 'customer',
            url: '/phone/{phoneNum}/edit',
            data: {
                authorities: [CommonConstants.CUSTOMER_EDIT],
                 pageTitle: 'Cập nhật khách hàng'
            },
             views: {
                'content@': {
                    templateUrl: 'app/customer/customer-dialog.html',
                    controller: 'CustomerDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CustomerPhone', function($stateParams, CustomerPhone) {
                    return CustomerPhone.get({
                        phoneNum: $stateParams.phoneNum
                    }).$promise;
                }]
            }
        })
        .state('customer.delete', {
            parent: 'customer.list',
            url: '/delete/{id}',
            data: {
                authorities: [CommonConstants.CUSTOMER_DELETE],
                 pageTitle: 'Xóa khách hàng'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/customer/customer-delete-dialog.html',
                    controller: 'CustomerDeleteController',
                    controllerAs: 'vm',
                     backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function() {
                            return {
                                id: $stateParams.id
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('customer.list', null, {
                        reload: true
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
