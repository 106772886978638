(function() {
	'use strict';

	angular.module('crmApp').controller('ViettelPostLoginController',
    ViettelPostLoginController);

	ViettelPostLoginController.$inject = ['$scope', 'entity', '$http', '$uibModalInstance', '$uibModal', '$localStorage'];

	function ViettelPostLoginController($scope, entity, $http, $uibModalInstance, $uibModal, $localStorage) {
        var vm = this;
        vm.order = entity;

        vm.close = function() {
			$uibModalInstance.close();
		}

        vm.login = function () {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.formLogin.$invalid) {
                var invalidControl = angular.element('input.ng-invalid').first();
                if (invalidControl != null) {
                    invalidControl.focus();
                }
            } else{
                $http.post('/api/viettel-post-login', vm.account).then(onLonginSuccess, onLoginError);
            }

        }

        function onLonginSuccess(response) {
            if(response.data.userId != null) {
                $localStorage.viettelPostAccount = response.data;
                if(vm.order.viettelPostId){
                    $http({
                        url: "/api/cancel-viettel-post",
                        method: "POST",
                        params: {
                            token: $localStorage.viettelPostAccount.tokenKey,
                            viettelPostId: vm.order.viettelPostId,
                        }
                    }).success(function (response) {
                    }).error(function (response) {
                    })
                    $uibModalInstance.close();
                }else{
                    vm.modalInstantCreateOrder = $uibModal.open({
                        templateUrl: 'app/order-shipment/viettel-post/create-order.html',
                        controller: 'ViettelPostCreateOrderController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return vm.order;
                            }
                        }
                    });
                    $uibModalInstance.close();    
                }
            }
        }

        function onLoginError(response) {
        }
	}
})();
