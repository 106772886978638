(function() {
	'use strict';

	angular.module('crmApp').controller(
			'CustomerCareReportPlanDialogController',
			CustomerCareReportPlanDialogController);

	CustomerCareReportPlanDialogController.inject = [ 'CustomerCare', '$scope',
			'UserUtils', 'CommonConstants', 'AlertService',
			'$uibModalInstance', 'CareHistory', 'SettingUtils', '$state',
			'idCarePlan', '$http', 'CarePlan', '$rootScope']

	function CustomerCareReportPlanDialogController(CustomerCare, $scope,
			UserUtils, CommonConstants, AlertService, $uibModalInstance,
			CareHistory, SettingUtils, $state, idCarePlan, $http, CarePlan, $rootScope) {
		var vm = this;
		vm.hasAuthority = hasAuthority;
		vm.entity = idCarePlan;
		vm.isCloseCareMissionInPlan = false;
		vm.account = UserUtils.getAccount();

		vm.CARE_STATUS_NEW = 1; // Mới
		vm.CARE_STATUS_INPROGRESS = 2; //Đang Xử lý
		vm.CARE_STATUS_CLOSE = 3; //Kết thucs

		var CARE_HISTORY_CLOSED_STATUS = "CARE_HISTORY_CLOSED_STATUS";
		var CARE_TYPE = "CARE_TYPE";
		vm.closeStatus = [];
		vm.caretypies = [];
		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		vm.careHistoryInCarePlan = {
			closeStatusId : 0
		};

		SettingUtils.get({
			'keys' : [ CARE_HISTORY_CLOSED_STATUS, CARE_TYPE ]
		}, function(success) {
			vm.closeStatus = [];

			angular.forEach(success, function(value, key) {
				if (value.key === CARE_HISTORY_CLOSED_STATUS) {
					vm.closeStatus.push(value);
				} else if (value.key === CARE_TYPE) {
					vm.caretypies.push(value);
				}
			});
		}, function(error) {

		});

		function getCarePlanHttp() {
			$http
					.get('/api/care-plan/' + vm.entity)
					.success(
							function(success) {
								vm.carePlan = angular.copy(success);
								vm.careMission = angular
										.copy(vm.carePlan.careMission);
								if (vm.carePlan.careTypeId) {
									vm.careHistoryInCarePlan.careTypeId = vm.carePlan.careTypeId;
								}
							});
		}
		;

		vm.clickFinishCareMissionInCarePlan = function() {
			if (vm.isCloseCareMissionInPlan) {
				vm.careHistoryInCarePlan.closeStatusId = 1;
			} else {
				vm.careHistoryInCarePlan.closeStatusId = 0;
			}
		}

		vm.saveCarePlanEdit = function() {
			$scope.$broadcast('show-errors-check-validity');
			if ($scope.formCare.$invalid) {
				var invalidControl = angular.element('textarea.ng-invalid')
						.first();
				if (invalidControl.length != 0) {
					invalidControl.focus();
				}
			} else {
				if (vm.hasAuthority()) {
					if (vm.carePlan.careMissionId !== null) {
						vm.careHistoryInCarePlan.careMissionId = vm.carePlan.careMissionId;
					}
					if (vm.carePlan.id !== null) {
						vm.careHistoryInCarePlan.carePlanId = vm.carePlan.id;
					}
					vm.careHistoryInCarePlan.actionDate = new Date();
					vm.careHistoryInCarePlan.staffId = vm.account.id;

					CareHistory.save(vm.careHistoryInCarePlan, onSaveSuccess,
							onSaveError);
				}
			}
		}

		getCarePlanHttp();

		function onSaveSuccess(result) {
			vm.careHistory = {};
			vm.careHistory.reportedContent = '';
			//set closeStatusId for care plan
			if(result.closeStatusId == 1){
				vm.carePlan.closeStatusId = result.closeStatusId;
				CarePlan.update(vm.carePlan,
						function success(success){}, function error(){});
			}

			if (vm.isCloseCareMissionInPlan) {
				vm.isCloseCareMissionInPlan = false;
				if (vm.carePlan.careMissionId !== null) {
					vm.careMission.closedCareHistoryId = result.id;
					vm.careMission.closedDate = new Date();
					vm.careMission.careStatusId = vm.CARE_STATUS_CLOSE;
					if (result.closeStatusId == 1) {
						vm.careMission.careResultId = 1;
					}
					CustomerCare.update(vm.careMission, function(success) {
						vm.careMission = success;
						if (vm.careMission.assignedDate) {
							vm.careMission.assignedDate = new Date(
									vm.careMission.assignedDate);
						}
						if (vm.careMission.closedDate) {
							vm.careMission.closedDate = new Date(
									vm.careMission.closedDate);
						}
						$uibModalInstance.close(true);
					}, function(error) {
						$uibModalInstance.close(true);
					});
				} else {
					$uibModalInstance.close(true);
				}
			} else {
				$uibModalInstance.close(true);
			}
			vm.isSaving = false;
		}
		;
		$rootScope.$on('close-modal', function(){
		     $uibModalInstance.close(true);
		});
		var onSaveError = function() {
			vm.isSaving = false;
		};

		function hasAuthority() {
			if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_CARE)) {
				return true;
			} else {
				AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
				return false;
			}
		}
		;
	}
	;
}());
