(function() {
    'use strict';

    angular.module('crmApp').controller('StockAddProductController',
        StockAddProductController);

    StockAddProductController.$inject = ['$rootScope', 'ParseLinks', 'AlertService', '$scope', 'pagingParams', 'ProductWarehouse', 'UserUtils', 'CommonConstants', '$timeout', '$http', '$uibModalInstance', '$uibModal', 'SettingUtils', 'entity', '$resource'];

    function StockAddProductController($rootScope, ParseLinks, AlertService, $scope, pagingParams, ProductWarehouse, UserUtils, CommonConstants, $timeout, $http, $uibModalInstance, $uibModal, SettingUtils, entity, $resource) {

        var vm = this;
        var PRODUCT_TYPE = 'PRODUCT_TYPE';

        //search criteria, some init paging
        vm.currentSearch = null;
        vm.changePage = changePage;
        vm.addProducts = addProducts;
        vm.transition = transition;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "sản phẩm";
        vm.showItem = 10;
        vm.totalItems = null;
        vm.search = search;
        vm.productWarehouses = [];
        vm.stock = entity;
        vm.initialization = initialization;
        vm.initialization();

        function initialization() {
            SettingUtils.get({
                'keys': [PRODUCT_TYPE]
            }, function(success) {
                vm.productTypes = [];

                angular.forEach(success, function(value, key) {
                    if (value.key === PRODUCT_TYPE) {
                        vm.productTypes.push(value);
                    }
                });
            }, function(error) {
                vm.productTypes = [];
            });

            getAccount();
            vm.search();
        };

        function getAccount() {
            vm.account = UserUtils.getAccount();
            vm.accountId = vm.account.id;
        }

        vm.parseFloat = function(value) {
            return parseFloat(value);
        };

        // search product
        function search(isSearchWithConditionBefore) {
            var ProductInWarehouse = $resource('api/get-product-for-warehouse');

            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                ProductInWarehouse.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.productCO) {
                    vm.productCO = {};
                }
                vm.productCO.page = vm.page - 1;
                vm.productCO.size = vm.entries;
                vm.productCO.sort = sort();
                vm.productCO.stockId = vm.stock.id;
                vm.currentSearch = angular.copy(vm.productCO);
                vm.showItem = vm.entries;
                ProductInWarehouse.query(vm.currentSearch, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productWarehouses = data;
                vm.productWarehousesClone = angular.copy(vm.productWarehouses);
                vm.isSelectedAll = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            if (vm.currentSearch) {
                vm.search(true);
            }
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
        $rootScope.$on('close-modal', function(){
            vm.clear();
        });
        function changePage() {
            var isHasAddProductToWarehouse = false;
            angular.forEach(vm.productWarehouses, function(productWarehouse, key) {
                if (productWarehouse.selected) {
                    isHasAddProductToWarehouse = true;
                    return true;
                }
            });
			if(isHasAddProductToWarehouse){
                vm.modalInstanceChange = $uibModal.open({
                    template: '<popup-component  title="vm.title" content="vm.content" close="vm.closePopup()" save-change="vm.saveWhenPageChange()" dont-save-change="vm.dontSaveAndPageChange()"></popup-component>',
                    size: 'md',
                     backdrop: 'static',
                    scope: $scope,
                    controller: function() {
                        var vm = this;
                        vm.title = "THÔNG BÁO";
                        vm.content = "Bạn có muốn lưu trước khi chuyển trang không? ";
                            vm.closePopup = function() {
                                close();
                            };
                            vm.saveWhenPageChange = function(){
                                //save and change page
                                saveChangePopup();
                            };
                            vm.dontSaveAndPageChange = function(){
                                dontsaveChangePopup();
                            };
                    },
                    controllerAs:"vm"
                });
                // function close popup
                function close(){
                    vm.page = vm.currentSearch.page + 1;
                    vm.modalInstanceChange.dismiss('cancel');
                }
                function saveChangePopup(){
                    vm.addProducts(true);
                    vm.modalInstanceChange.dismiss('cancel');
                    if(vm.pageStatus){
                        vm.page = vm.currentSearch.page + 1;
                    }
                }
                function dontsaveChangePopup(){
                    vm.modalInstanceChange.dismiss('cancel');
                    vm.transition();
                }
			} else {
				vm.transition();
			}
        }

        // vm.messageZero
        vm.pageStatus = false;
        function addProducts(isAddProductWhenChangePage) {
            vm.messageZero = '';
            vm.productWarehousesToSave = [];
            var statusAdd = false;
            angular.forEach(vm.productWarehouses, function(productWarehouse, key) {
                if (productWarehouse.selected) {
                    productWarehouse.goodsReceipt.staffId = vm.accountId;
                    vm.productWarehousesToSave.push(productWarehouse);
                    if (productWarehouse.goodsReceipt.quantity === 0) {
                        statusAdd = true;
                        return false;
                    }
                }
            });
            if (vm.productWarehousesToSave.length > 0 && !statusAdd) {
                vm.pageStatus = false;
				if(isAddProductWhenChangePage){
					//Add product when press Confirm Save when change page
					ProductWarehouse.save(vm.productWarehousesToSave, function success(success) {
						$scope.$emit('crmApp:addProductToWarehouseSuccess', success);
						vm.transition();
					}, function error() {});
				} else {
					//Add product when press Add on popup
					ProductWarehouse.save(vm.productWarehousesToSave, function success(success) {
						$scope.$emit('crmApp:addProductToWarehouseSuccess', success);
						$uibModalInstance.dismiss('cancel');
					}, function error() {});
				}
            }else if(statusAdd){
                vm.messageZero = 'Vui lòng nhập số lượng sản phẩm!';
                vm.pageStatus = true;
            }
            else{
                vm.messageZero = "Chưa có sản phẩm để thêm. Vui lòng chọn một sản phẩm.";
            }
        }

        vm.selectedAll = function() {
            angular.forEach(vm.productWarehouses, function(productWarehouse, key) {
                productWarehouse.selected = vm.isSelectedAll;
            });
        };

        // status
        vm.checkProduct = function(index) {
            var theNumberOfChecked = 0;
            angular.forEach(vm.productWarehouses, function(productWarehouse, key) {
                if (productWarehouse.selected) {
                    theNumberOfChecked = theNumberOfChecked + 1;
                }
            });

            if (vm.productWarehouses.length == theNumberOfChecked) {
                vm.isSelectedAll = true;
            } else {
                vm.isSelectedAll = false;
            }
        };

    } // end controller
})();
