(function() {
    'use strict';
    angular
        .module('crmApp')
        .service('DateFormatService', DateFormatService);

        DateFormatService.$inject = [];
        function DateFormatService() {
        	var vm = this;

        	
	        vm.getAltInputFormats = function(){
		        return ['d!/M!/yyyy'];
	        }
	        vm.getDateFormat = function () {
	        	 return  'dd/MM/yyyy';
	        }
	       	
	       	vm.getDateOptions = function () {
	       		vm.dateOptions = {
		            startingDay: 1
		        };
		        return vm.dateOptions;
	       	}

	        vm.getFormatDateTime = function (dateValue, timeValue) {
	        	 if (dateValue) {
	                if (timeValue) {
	                    dateValue.setHours(timeValue.getHours());
	                    dateValue.setMinutes(timeValue.getMinutes());
	                } else {
	                    dateValue.setHours(0);
	                    dateValue.setMinutes(0);
	                }
	                dateValue.setSeconds(0);
	            } else {
	                if (timeValue) {
	                    dateValue = new Date();
	                    dateValue.setHours(timeValue.getHours());
	                    dateValue.setMinutes(timeValue.getMinutes());
	                    dateValue.setSeconds(0);
	                }
	            }
	            return dateValue;
	        }
        }
 }());