(function() {
    'use strict';

    angular
        .module('crmApp')
        .factory('UserUtils', UserUtils);

    UserUtils.$inject = ['$localStorage', 'CommonConstants'];

    function UserUtils($localStorage, CommonConstants) {
        var service = {
            getAccount: getAccount,
            hasAuthorityName: hasAuthorityName
        };

        return service;

        function hasAuthorityName(authorityName) {
            var account = $localStorage.account;
            var authorities = account.authorities;
            var result = false;
            if (authorities) {
                angular.forEach(authorities, function(authority, key) {
                    if (authority == authorityName) {
                        result = true;
                        return false;
                    }
                });
            }
            return result;
        }

        function getAccount(){
          var account = $localStorage.account;
          return account;
        }
    }
})();
