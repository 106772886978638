(function() {
    'use strict';

    angular.module('crmApp').controller('DepartmentDetailController',
        DepartmentDetailController);

    DepartmentDetailController.$inject = ['ParseLinks', 'AlertService', '$resource', 'teamId', '$scope', '$state', '$http', 'SettingUtils', 'Team', 'pagingParams'];

    function DepartmentDetailController(ParseLinks, AlertService, $resource, teamId, $scope, $state, $http, SettingUtils, Team, pagingParams) {
    	var vm = this;
    	vm.loadAll = loadAll;
        vm.loadAll();

        vm.search = search;
        vm.loadPage = loadPage;

        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.currentSearch = null;
        vm.transition = transition;
        vm.entries = '10';
        vm.showItem = 10;
        vm.isNotSelected = true;
        vm.selectedAll = false;

        vm.page = 1;
        vm.totalItems = null;
        vm.showName = "thành viên";
        vm.memberTeam = [];
        // function load all
         function loadAll() {
           var StaffTeam = $resource('api/get-member-of-a-team');
           StaffTeam.query({teamId: teamId}, onSuccess, onError);
         }

         function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            // vm.totalItems = headers('X-Total-Count');
            // vm.queryCount = vm.totalItems;
             vm.memberTeam = data;
         }

         function onError(){
            AlertService.error(error.data.message);
         }

        // transition
        function transition() {

        }
        // search
        function search() {
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = false;
			loadPage();
		};
		//
		function loadPage(isSearchWithConditionBefore){

		}
        vm.displayRole = function (roles) {
            var result = '';
            if(roles !== null && roles !== undefined
                    && roles.length > 0){
                angular.forEach(roles, function(role, key) {
                    result += role.name;
                    if (key != (roles.length - 1)) {
                        result += ', ';
                    }
                });

            }
            return result;
        }
    }
})();
