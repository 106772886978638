(function() {
    'use strict';
    var revenueAchievement = {
        templateUrl: 'app/dashboard/home/home-revenue-achievement.html',
        controller: RevenueAchievementController,
        bindings: {
            revenue: '<',
            target: '<'
        }
    };
    RevenueAchievementController.inject = ['$scope', '$element', '$http'];

    function RevenueAchievementController($http, $scope, $element, $attrs) {
        var $ctrl = this;
        // return total Expected Revenue
        $ctrl.totalExpected = function(){
                return $ctrl.totalExpectedRevenue <= 0 ? 0 : ($ctrl.totalExpectedRevenue);
            }
        $scope.$watch('$ctrl.revenue', function() {
            
            //get Expected Revenue
            $http.get('/api/get-expected-revenue', {}).then(function (response) {
                $ctrl.totalExpectedRevenue = response.data;
            })

            $ctrl.totalRevenueMonthBefore = 0;
            $ctrl.totalRevenueCurrentMonth = 0;
            if ($ctrl.revenue !== undefined && $ctrl.revenue !== null && $ctrl.revenue.length > 0) {
                var revenueCurrentMonth = $ctrl.revenue[0];
                var revenueMonthBefore = $ctrl.revenue[1];

                var revenueCurrentMonthData = [];
                var revenueMonthBeforeData = [];
                var targetData = [];

                var totalRevenueCurrentMonthTmp = 0;
                angular.forEach(revenueCurrentMonth, function(value, key) {
                    totalRevenueCurrentMonthTmp += value.revenue;
                    revenueCurrentMonthData.push(totalRevenueCurrentMonthTmp);
                    $ctrl.totalRevenueCurrentMonth += value.revenue;
                });

                var  totalRevenueMonthBeforeTmp = 0;
                angular.forEach(revenueMonthBefore, function(value, key) {
                    totalRevenueMonthBeforeTmp += value.revenue;
                    revenueMonthBeforeData.push(totalRevenueMonthBeforeTmp);
                    $ctrl.totalRevenueMonthBefore += value.revenue;
                });

                function daysInMonth(month, year) {
                    return new Date(year, month, 0).getDate();
                }

                var numberOfDaysCurrentMonth = daysInMonth(revenueCurrentMonth[0].monthRevenue, revenueCurrentMonth[0].yearRevenue);
                var numberOfDaysMonthBefore = daysInMonth(revenueMonthBefore[0].monthRevenue, revenueMonthBefore[0].yearRevenue);
                var numberOfDays = numberOfDaysCurrentMonth > numberOfDaysMonthBefore ? numberOfDaysCurrentMonth : numberOfDaysMonthBefore;
                var numberOfDaysLabelArr = [];
                var labelVal = '';

                for (var i = 1; i <= numberOfDays; i++) {
                    targetData.push($ctrl.target);
                    labelVal = i < 10 ? '0' + i : i;
                    numberOfDaysLabelArr.push(labelVal);
                }

                var statisticsChartCanvas2 = $element.find('#statisticsChart2').get(0).getContext('2d');
                // This will get the first returned node in the jQuery collection.
                var statisticsChart2 = new Chart(statisticsChartCanvas2);


                var statisticsChartData2 = {
                    labels: numberOfDaysLabelArr,
                    datasets: [{
                            label: 'Tháng trước',
                            strokeColor: '#00c0ef',
                            pointColor: '#00c0ef',
                            pointStrokeColor: '#00c0ef',
                            data: revenueMonthBeforeData
                        },
                        {
                            label: 'Tháng này',
                            strokeColor: '#55ff55',
                            pointColor: '#55ff55',
                            pointStrokeColor: '#55ff55',
                            data: revenueCurrentMonthData
                        },
                        {
                            label: 'Mục tiêu',
                            strokeColor: '#ff5555',
                            pointColor: '#ff5555',
                            pointStrokeColor: '#ff5555',
                            data: targetData
                        },
                    ]
                };

                var statisticsChartOptions2 = {
                    // Boolean - If we should show the scale at all
                    showScale: true,
                    // Boolean - Whether grid lines are shown across the chart
                    scaleShowGridLines: false,
                    // String - Colour of the grid lines
                    scaleGridLineColor: 'rgba(0,0,0,.05)',
                    // Number - Width of the grid lines
                    scaleGridLineWidth: 1,
                    // Boolean - Whether to show horizontal lines (except X axis)
                    scaleShowHorizontalLines: true,
                    // Boolean - Whether to show vertical lines (except Y axis)
                    scaleShowVerticalLines: true,
                    // Boolean - Whether the line is curved between points
                    bezierCurve: false,
                    // Number - Tension of the bezier curve between points
                    bezierCurveTension: 0.3,
                    // Boolean - Whether to show a dot for each point
                    pointDot: false,
                    // Number - Radius of each point dot in pixels
                    pointDotRadius: 4,
                    // Number - Pixel width of point dot stroke
                    pointDotStrokeWidth: 1,
                    // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
                    pointHitDetectionRadius: 20,
                    // Boolean - Whether to show a stroke for datasets
                    datasetStroke: true,
                    // Number - Pixel width of dataset stroke
                    datasetStrokeWidth: 2,
                    // Boolean - Whether to fill the dataset with a color
                    datasetFill: false,
                    // String - A legend template
                    legendTemplate: '<ul class=\'<%=name.toLowerCase()%>-legend\'><% for (var i=0; i<datasets.length; i++){%><li><span style=\'background-color:<%=datasets[i].lineColor%>\'></span><%=datasets[i].label%></li><%}%></ul>',
                    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
                    maintainAspectRatio: true,
                    // Boolean - whether to make the chart responsive to window resizing
                    responsive: true
                };
                // Create the line chart
                statisticsChart2.Line(statisticsChartData2, statisticsChartOptions2);

                // ---------------------------
                // - END MONTHLY SALES CHART -
                // ---------------------------

            }
        });

    }

    angular.module('crmApp').component('revenueAchievement', revenueAchievement);
}());
