(function() {
    'use strict';

    angular.module('crmApp').config(stateAbsConfig).config(stateConfig);

    stateAbsConfig.$inject = ['$stateProvider'];

    function stateAbsConfig($stateProvider) {
        $stateProvider.state('stock', {
            url: '/stock',
            parent: 'app'
        });
    }

    stateConfig.inject = ['$stateProvider', 'CommonConstants'];
    function stateConfig($stateProvider, CommonConstants) {
        $stateProvider
            .state(
                'list-stock', {
                    parent: 'stock',
                    url: '/list-stock',
                    data: {
                        authorities: [
                            CommonConstants.WAREHOUSE_VIEW
                        ],
                        pageTitle: 'Danh sách kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock.html',
                            controller: 'ListStockController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    predicate: PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search: $stateParams.search
                                };
                            }
                        ],
                    }
                }).state(
                'stock.new', {
                    parent: 'stock',
                    url: '/create',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_ADD
                        ],
                        pageTitle: 'Tạo mới kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-create-update.html',
                            controller: 'StockCreateUpdateController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: function() {
                            return {
                                warehouseCode: null,
                                name: null,
                                description: null,
                                address: null,
                                stockkeeper: null,
                                stockType : null
                            };
                        }
                    }
                }).state(
                'stock.edit', {
                    parent: 'stock',
                    url: '/{id}/edit',
                    data: {
                         authorities: [CommonConstants.WAREHOUSE_EDIT
                        ],
                        pageTitle: 'Cập nhật kho'
                    },
                    views: {
                        'content@': {
                           templateUrl: 'app/stock/stock-create-update.html',
                            controller: 'StockCreateUpdateController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: ['$stateParams', 'Stock',
                            function($stateParams, Stock) {
                                return Stock.get({
                                    id: $stateParams.id
                                }).$promise;
                            }
                        ]

                    }
                }).state(
                'stock.watch', {
                    parent: 'stock',
                    url: '/watch-the-stock/{id}',
                    data: {
                        authorities: [
                            CommonConstants.WAREHOUSE_VIEW
                        ],
                        pageTitle: 'Xem kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-watch.html',
                            controller: 'StockWatchController',
                            controllerAs: 'vm'
                        }
                    },
                     params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        entity: ['$stateParams', 'Stock',
                            function($stateParams, Stock) {
                                if($stateParams.id){
                                    return Stock.get({
                                        id: $stateParams.id
                                    }).$promise;
                                }else{
                                    return null;
                                }

                            }
                        ],
                        pagingParams : [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page : PaginationUtil
                                        .parsePage($stateParams.page),
                                sort : $stateParams.sort,
                                predicate : PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                ascending : PaginationUtil
                                        .parseAscending($stateParams.sort),
                                search : $stateParams.search
                            };
                        } ]
                    }
                }).state(
                'good-receipt', {
                    parent: 'stock',
                    url: '/good-receipt/{id}',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_GOODS_RECEIPT
                        ],
                        pageTitle: 'Nhập kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-good-receipt.html',
                            controller: 'StockGoodReceiptController',
                            controllerAs: 'vm'
                        }
                    },
                     params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                    pagingParams : [
                    '$stateParams',
                    'PaginationUtil',
                    function($stateParams, PaginationUtil) {
                        return {
                            page : PaginationUtil
                                    .parsePage($stateParams.page),
                            sort : $stateParams.sort,
                            predicate : PaginationUtil
                                    .parsePredicate($stateParams.sort),
                            ascending : PaginationUtil
                                    .parseAscending($stateParams.sort),
                            search : $stateParams.search
                        };
                    } ],
                    entity: ['$stateParams', 'Stock',
                        function($stateParams, Stock) {
                            if($stateParams.id){
                                return Stock.get({
                                    id: $stateParams.id
                                }).$promise;
                            }else{
                                return null;
                            }

                        }
                    ]
                }
                }).state(
                'good-issue', {
                    parent: 'stock',
                    url: '/good-issue',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_GOODS_ISSUE_SEND_REQUEST,
                          CommonConstants.WAREHOUSE_GOODS_ISSUE
                        ],
                        pageTitle: 'Xuất kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-good-issue.html',
                            controller: 'StockGoodIssueController',
                            controllerAs: 'vm'
                        }
                    },
                    params:{
                    	idOrder: null,
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams : [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page : PaginationUtil
                                        .parsePage($stateParams.page),
                                sort : $stateParams.sort,
                                predicate : PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                ascending : PaginationUtil
                                        .parseAscending($stateParams.sort),
                                search : $stateParams.search
                            };
                        }]
                    }
                }).state(
                'stock.history', {
                    parent: 'stock',
                    url: '/history-the-stock',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_VIEW
                        ],
                        pageTitle: 'Lịch sử nhập xuất'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-history.html',
                            controller: 'StockHistoryController',
                            controllerAs: 'vm'
                        }
                    },
                     params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams : [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page : PaginationUtil
                                        .parsePage($stateParams.page),
                                sort : $stateParams.sort,
                                predicate : PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                ascending : PaginationUtil
                                        .parseAscending($stateParams.sort),
                                search : $stateParams.search
                            };
                        } ]
                    }
                }).state(
                'stock.watch-good-receipt', {
                    parent: 'stock',
                    url: '/{id}/watch-good-receip',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_VIEW_GOODS_RECEIPT
                        ],
                        pageTitle: 'Xem nhập kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-watch-good-receipt.html',
                            controller: 'StockWatchGoodReceiptController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                         entity: ['$stateParams', 'Stock',
                            function($stateParams, Stock) {
                                return Stock.get({
                                    id: $stateParams.id
                                }).$promise;
                            }
                        ]
                    }
                }).state(
                'stock.watch-good-issue', {
                    parent: 'stock',
                    url: '/watch-good-issue',
                    data: {
                        authorities: [CommonConstants.WAREHOUSE_VIEW_GOODS_ISSUE
                        ],
                        pageTitle: 'Xem xuất kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-watch-good-issue.html',
                            controller: 'StockWatchGoodIssueController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: function() {
                            return {};
                        }
                    }
                }).state(
                'stock.confirm-product', {
                    parent: 'stock',
                    url: '/confirm-product',
                    data: {
                        authorities: [
                            CommonConstants.WAREHOUSE_GOODS_ISSUE
                        ],
                        pageTitle: 'Xác nhận điều chuyển kho'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/stock/stock-confirm-product.html',
                            controller: 'StockConfirmProductController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: function() {
                            return {};
                        }
                    }
                });
    }
}());
