(function() {
    'use strict';

    angular.module('crmApp').controller('StockGoodReceiptController',
        StockGoodReceiptController);

    StockGoodReceiptController.$inject = ['entity', 'paginationConstants', 'pagingParams', 'UserUtils', 'SettingUtils', 'ParseLinks', 'AlertService', '$http', '$rootScope', '$scope', 'Stock', '$state', 'ProductWarehouse', '$uibModal', '$stateParams', 'PaginationUtil', 'UploadFileService'];

    function StockGoodReceiptController(entity, paginationConstants, pagingParams, UserUtils, SettingUtils, ParseLinks, AlertService, $http, $rootScope, $scope, Stock, $state, ProductWarehouse, $uibModal, $stateParams, PaginationUtil, UploadFileService) {

        var vm = this;
        var PRODUCT_TYPE = 'PRODUCT_TYPE';
        vm.account = UserUtils.getAccount();
        vm.accountId = vm.account.id;
        vm.stocksSelect = [];
        vm.loadPage = loadPage;
        vm.loadPage();
        vm.search = search;

        //search criteria, some init paging
        vm.currentSearch = null;
        vm.changePage = changePage;
        vm.transition = transition;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.entries = '10';
        vm.page = 1;
        vm.showName = "sản phẩm";
        vm.showItem = 10;
        vm.totalItems = null;

        if(entity){
            vm.stock = entity;
            vm.stockId = vm.stock.id;
        }
        // load page
        function loadPage() {
            $http.get('/api/get-all-stocks')
                .then(function(success) {
                    vm.stocksSelect = success.data;
                    // stock
                    // angular.element('#stock').selectpicker('refresh');
                });

            SettingUtils.get({
                'keys': [PRODUCT_TYPE]
            }, function(success) {
                vm.productTypes = [];

                angular.forEach(success, function(value, key) {
                    if (value.key === PRODUCT_TYPE) {
                        vm.productTypes.push(value);
                    }
                });
            }, function(error) {
                vm.productTypes = [];
            });
            search(false);
        }

        var unsubscribe = $rootScope.$on('crmApp:addProductToWarehouseSuccess', function(event, result) {
            vm.search(true);
        });
        $scope.$on('$destroy', unsubscribe);

        vm.changeStock = function(warehouseId) {
            if (!vm.productWarehouseCO) {
                vm.productWarehouseCO = {};
                vm.productWarehouseCO.productName = '';
                vm.productWarehouseCO.productCode = '';
                vm.productWarehouseCO.productTypeId = null;
            }
            search();
        };

        function setStockByStockId(id) {
            for (var i = 0; i < vm.stocksSelect.length; i++) {
                if (vm.stocksSelect[i].id == id) {
                    vm.stock = angular.copy(vm.stocksSelect[i]);
                    break;
                }
            }
        };

        function changePage() {
            if (vm.currentSearch && vm.currentSearch.page != vm.page - 1) {
                var productWarehousesForSave = vm.getProductWarehousesForSave();
                if (productWarehousesForSave.length > 0) {
                    vm.modalInstanceProduct = $uibModal.open({
                        templateUrl: 'app/stock/stock-good-receipt-message-save-dialog.html',
                        size: 'md',
                         backdrop: 'static',
                        scope: $scope,
                        controller: function() {
                            vm.closePopup = function() {
                                //FIXME check truong hop nhay nhieu page
                                vm.modalInstanceProduct.dismiss('cancel');
                                vm.page = vm.currentSearch.page + 1;
                            };
                            vm.saveWhenPageChange = function() {
                                //save and change page
                                vm.save();
                                vm.modalInstanceProduct.dismiss('cancel');
                            };
                            vm.dontSaveAndPageChange = function(){
                                vm.modalInstanceProduct.dismiss('cancel');
                                vm.transition();
                            };
                        }
                    });
                } else {
                    vm.transition();
                }
            } else {
                vm.transition();
            }
        }

        vm.importExcel = function(){
            // file component: components/form/form-import-excel.component.js
            // file html: template/import-excel.html
            vm.modalInstance = $uibModal.open({
                templateUrl: 'app/stock/stock-good-receipt-excel.html',
                 backdrop: 'static',
                scope: $scope,
                controller: function() {
                    vm.message = "NHẬP KHO";
                    var fdExcelProducts = new FormData();
                    $scope.fileExcelSelected = function(element) {
                      vm.isUploadFileInProgress = true;
                      // if not exits file return
                      // if file more than 32MB then return
                      if (!element.files[0] || (element.files[0].size/1024/1024 > 32)) {
                          return;
                      }

                      // delete file in FormData if exist
                      if (fdExcelProducts.has("file")) {
                          fdExcelProducts.delete("file");
                      }
                      // validate file name
                      var fileName = element.files[0].name.substr(0, element.files[0].name.lastIndexOf('.')) || element.files[0].name;
                      var validateFileName = fileName + new Date().getTime();
                      var extension = getExtensionFile(element.files[0].name);
                      var finalFileName = validateFileName + "." + extension;

                      fdExcelProducts.append("file", element.files[0]);
                      fdExcelProducts.set("file", element.files[0], finalFileName);
                    };

                   vm.saveExcel = function(){
                     UploadFileService.uploadOneFile('/api/import-excel-products-into-warehouse', fdExcelProducts, function(success, status) {
                        $state.go('good-receipt', null, {
                             reload: true
                         });
                         vm.isUploadFileInProgress = false;
                     }, function(error, status) {
                         vm.isUploadFileInProgress = false;
                     });
                   }

                   vm.clodePopup = function(){
                        close();
                   }
                }
            });

             function close() {
                vm.modalInstance.dismiss('cancel');
            }
        }

        function getExtensionFile(fileName) {
            return fileName.split('.').pop();
        }

        function transition() {
            if (vm.currentSearch && vm.currentSearch.warehouseId) {
                vm.search(true);
            }
        }
        function search(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                vm.showItem = vm.entries;
                ProductWarehouse.query(vm.currentSearch, onSuccess, onError);
            } else if (vm.stockId) {
                getQuery();
                vm.productWarehouseCO.warehouseId = vm.stockId;
                setStockByStockId(vm.stockId);
                vm.currentSearch = angular.copy(vm.productWarehouseCO);
                vm.showItem = vm.entries;
                ProductWarehouse.query(vm.currentSearch, onSuccess, onError);
            }else if(entity){
                getQuery();
                vm.productWarehouseCO.warehouseId = entity.id;
                setStockByStockId(entity.id);
                vm.currentSearch = angular.copy(vm.productWarehouseCO);

                vm.showItem = vm.entries;
                ProductWarehouse.query(vm.currentSearch, onSuccess, onError);
            }

            // sort, page, size
            function getQuery(){
                 if (!vm.productWarehouseCO) {
                    vm.productWarehouseCO = {};
                }
                vm.productWarehouseCO.page = vm.page - 1;
                vm.productWarehouseCO.size = vm.entries;
                vm.productWarehouseCO.sort = sort();
            }
            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productWarehouses = data;
                vm.productWarehousesClone = angular.copy(vm.productWarehouses);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        };

        vm.parseFloat = function(value) {
            if (value >= 0)
                return parseFloat(value);

            return 0;
        };

        vm.refresh = function() {
            if (vm.currentSearch && vm.currentSearch.warehouseId) {
                vm.page = vm.currentSearch.page + 1;
                vm.entries = vm.currentSearch.size;
                vm.stockId = vm.currentSearch.warehouseId;
                //manual set stock select picker when change id by script
                setStockByStockId(vm.currentSearch.warehouseId);
                vm.productWarehouseCO.productName = vm.currentSearch.productName;
                vm.productWarehouseCO.productCode = vm.currentSearch.productCode;
                vm.productWarehouseCO.productTypeId = vm.currentSearch.productTypeId;
                vm.search();
            } else {
                vm.stockId = null;
                vm.productWarehouseCO = {};
                vm.productWarehouseCO.productName = '';
                vm.productWarehouseCO.productCode = '';
                vm.productWarehouseCO.productTypeId = null;
            }
        };

        // save
        vm.save = function() {
            var productWarehousesForSave = vm.getProductWarehousesForSave();
            if (productWarehousesForSave.length > 0) {
                ProductWarehouse.update(productWarehousesForSave,
                    function success() {
                        vm.search(true);
                        // $state.go('stock.watch-good-receipt', null, {
                        //     reload: true
                        // });
                    },
                    function error() {});
            } else {

            }



        };

        // add product
        vm.showProduct = function() {
            vm.modalInstanceProduct = $uibModal.open({
                url: '/?page&sort&search',
                templateUrl: 'app/stock/stock-good-receipt-add-product-dialog.html',
                size: 'lg',
                 backdrop: 'static',
                controller: 'StockAddProductController',
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    entity: vm.stock,
                    pagingParams: [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil
                                    .parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil
                                    .parsePredicate($stateParams.sort),
                                ascending: PaginationUtil
                                    .parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }
                    ]
                }
            });
        };

        vm.getProductWarehousesForSave = function() {
            var productWarehousesForSave = [];
            vm.messageZero = '';
            angular.forEach(vm.productWarehouses, function(productWarehouse, key) {
                angular.forEach(vm.productWarehousesClone, function(aProductWarehousesClone, key2) {
                    // Neu so luong san pham < 0
                    if (productWarehouse.goodsReceipt.quantity < 0) {
                        productWarehouse.goodsReceipt.quantity = 0;
                    }
                    if (productWarehouse.warehouseId == aProductWarehousesClone.warehouseId &&
                        productWarehouse.productId == aProductWarehousesClone.productId &&
                        productWarehouse.goodsReceipt.quantity != aProductWarehousesClone.goodsReceipt.quantity &&
                        productWarehouse.goodsReceipt.quantity > 0) {
                        productWarehouse.goodsReceipt.staffId = vm.accountId;
                        productWarehousesForSave.push(productWarehouse);
                        return false;
                    }

                });
            });

            return productWarehousesForSave;
        };

        vm.displayValue = function(value) {
            if (value != undefined && value != null && value.trim() != '') {
                return value.trim();
            } else {
                return '';
            }
        };

    } // end controller
})();
