(function() {
    'use strict';

    angular.module('crmApp').controller('CustomerPotentialController',
        CustomerPotentialController);

    CustomerPotentialController.$inject = ['$state','pagingParams', '$http','ParseLinks'];

    function CustomerPotentialController($state, pagingParams, $http, ParseLinks) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.page = 1;
        vm.entries = '10';
         vm.currentSearch = null;
        vm.totalItems = null;
        vm.showName = "khách hàng";
        vm.showItem = 10;
        vm.loadAll();
        vm.customerPotential = [];
        function loadAll(){
             vm.loadPage();
        }

        function transition() {
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function loadPage(isSearchWithConditionBefore) {
            console.log();
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                onSuccess();
            }
            else {
                if (!vm.customerPotentialCO) {
                    vm.customerPotentialCO = {};
                }
                vm.customerPotentialCO.page = vm.page - 1;
                vm.customerPotentialCO.size = vm.entries;
                vm.customerPotentialCO.sort = sort();
                vm.currentSearch = angular.copy(vm.customerPotentialCO);
                vm.showItem = vm.entries;
                onSuccess() ;
                }


            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess() {
                 $http.get('/api/ext/get-all-marketing-customer-reference',
                    {params: vm.currentSearch}
                ).then(function(response){
                    console.log(response);
                    vm.links = ParseLinks.parse(response.headers('link'));
                    vm.totalItems = response.headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.customerPotential = response.data;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        vm.displaySettingListName = function(settings) {
            var result = '';
            if (settings !== null && settings !== undefined &&
                settings.length > 0) {
                angular.forEach(settings, function(setting, key) {
                    result += setting.name;
                    if (key != (settings.length - 1)) {
                        result += ', ';
                    }
                });
            }
            return result;
        };
    }
})();
