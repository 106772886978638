(function() {
	'use strict';

	angular.module('crmApp').controller('ReportProductController',
		ReportProductController);

	ReportProductController.$inject = [ 'SettingUtils', '$scope', '$resource',
	'pagingParams', 'DateFormatService', '$window', '$http', 'entity',
	'$state', '$uibModal', '$localStorage'];

	function ReportProductController(SettingUtils, $scope, $resource,
		pagingParams, DateFormatService, $window, $http, entity, $state, $uibModal, $localStorage) {
		var vm = this;
		vm.entity = entity;
		vm.loadAll = loadAll;
		vm.predicate = pagingParams.predicate;
		vm.reverse = false;
		vm.search = search;
		vm.datePickerOpenStatus = {};
		vm.currentSearch = null;
		vm.nowDate = new Date();
		vm.productCodes = [];
		vm.orderStatus = [];
		vm.reportProducts = null;
		vm.ReportProductCO = {
			productCode : [],
			orderStatus : []
		};
		vm.ReportProductDirective = [];
		vm.OrderStatusDirective = [];
		vm.loadAll();

		function loadAll() {
			configDateTime();
			getProductCode();

			// get data
			if (vm.entity.ReportProduct != null) {
				vm.searchProduct = JSON.parse(atob(vm.entity.ReportProduct));
				vm.ReportProductCO.productCode = vm.searchProduct.productCode;
				vm.ReportProductCO.orderStatus = vm.searchProduct.orderStatus;

				if (vm.searchProduct.dateFrom != null) {
					vm.ReportProductCO.dateFrom = new Date(
						vm.searchProduct.dateFrom);
				}
				if (vm.searchProduct.dateTo != null) {
					vm.ReportProductCO.dateTo = new Date(
						vm.searchProduct.dateTo);
				}
				vm.searchProductClone = angular.copy(vm.searchProduct);
				var ReportProduct = $resource('api/report-product');
				ReportProduct.query(vm.searchProduct, function success(
					reportProducts) {
					vm.reportProducts = reportProducts;
					vm.totalOrderPrice = vm.getTotalOrderPrice(reportProducts);
					vm.totalQuantity = vm.getTotalQuantity(reportProducts);
					vm.totalPrice = vm.getTotalPrice(reportProducts);
				}, function error() {

				});
			}
			// get setting

			var ORDER_STATUS = 'ORDER_STATUS';
			SettingUtils.get({
				'keys' : [ ORDER_STATUS ]
			}, function(success) {
				angular.forEach(success, function(value, key) {
					if (value.key === ORDER_STATUS) {
						vm.orderStatus = success;
					}
				});
			}, function(error) {
			});
		}

		function addParameterToUrl(parameter, paramName, paramValue){
			if(paramValue){
				if(parameter.length == 0){
					parameter += '?';
				} else {
					parameter += '&';
				}
				parameter += (paramName + '='+ paramValue);
			}
			return parameter;
		}

		function isNull(val){
			if(val === null || val === undefined){
				return true;
			} else {
				return false;
			}
		}

		vm.excel = function(){
			var parameter = "";
			var dateFrom = isNull(vm.searchProductClone.dateFrom) ? vm.searchProductClone.dateFrom : new Date(vm.searchProductClone.dateFrom).toISOString();
			var dateTo = isNull(vm.searchProductClone.dateTo) ? vm.searchProductClone.dateTo : new Date(vm.searchProductClone.dateTo).toISOString();
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "orderStatus", vm.searchProductClone.orderStatus);
			parameter = addParameterToUrl(parameter, "productCode", vm.searchProductClone.productCode);
			parameter = addParameterToUrl(parameter, "currentLogin", $localStorage.account.login);
			$window.open("public/export-excel-product" + parameter);
		};

		function getProductCode() {
			$http.get('/api/get-all-product').success(function(success) {
				if (success && success.length > 0) {
					vm.productCodes = success;
				}
			});
		}
		// display product
		vm.displayProductCode = function(productCode) {
			var result = '';
			if (productCode !== null && productCode !== undefined
				&& productCode.length > 0) {
				angular.forEach(productCode, function(code, key) {
					result += code;
					if (key != (productCode.length - 1)) {
						result += ', ';
					}
				});

		}
		return result;
	}
	vm.displayProductName = function(products, productCode) {
		var result = '';
		if (products !== null && products !== undefined
			&& products.length > 0) {
			angular.forEach(products, function(product, key) {
				angular.forEach(productCode, function(code, key) {
					if (product.productCode == code) {
						result += product.productName;
						if (key != (products.length - 1)) {
							result += ', ';
						}
					}
				})
			});
	}
	return result;

}
vm.print = function() {
	$window.print();
}
vm.openCalendar = function(date) {
	vm.datePickerOpenStatus[date] = true;
};
function configDateTime() {
	vm.altInputFormats = DateFormatService.getAltInputFormats();
	vm.dateFormat = DateFormatService.getDateFormat();
	vm.dateOptions = DateFormatService.getDateOptions();

	vm.openDateFrom1 = function() {
		vm.isDateFromOpened1 = true;
	}

	vm.openDateFrom2 = function() {
		vm.isDateFromOpened2 = true;
	}
}

vm.required = false;

function search() {
	if (vm.ReportProductCO.productCode.length == 0) {
		vm.required = true;
	} else {
		vm.required = false;
		if (vm.ReportProductCO.dateFrom) {
			vm.ReportProductCO.dateFrom = new Date(
				vm.ReportProductCO.dateFrom);
		}
		if (vm.ReportProductCO.dateTo) {
			vm.ReportProductCO.dateTo = new Date(
				vm.ReportProductCO.dateTo);
		}
		$state.go('report-product.search', {
			search : btoa(JSON.stringify(vm.ReportProductCO))
		});
	}

}
		// quantity product
		vm.totalQuantity = 0;
		vm.getTotalQuantity = function(reportProducts) {
			var totalQuantity = 0;
			// calculate total quantity of each order detail in that order
			angular.forEach(reportProducts, function(reportProduct) {
				totalQuantity += reportProduct.quantity;
			})
			return totalQuantity;
		}

		// total money
		vm.totalPrice = 0;
		vm.getTotalPrice = function(reportProducts) {
			var totalPrice = 0;
			angular.forEach(reportProducts,
				function(reportProduct) {
					// if(reportProduct.order.category && reportProduct.order.category == 4){
					// 	tottalPrice -= reportProduct.quantity * reportProduct.realPrice;
					// } else {
					// 	tottalPrice += reportProduct.quantity * reportProduct.realPrice;
					// }
					if(reportProduct.orderCategory && reportProduct.orderCategory == 4){
						totalPrice -= reportProduct.quantity * reportProduct.realPrice;
					} else {
						totalPrice += reportProduct.quantity * reportProduct.realPrice;
					}
				})
			return totalPrice;
		}
		// total order price
		vm.totalOrderPrice = 0;
		vm.object = [ {
			id : null
		} ];
		vm.getTotalOrderPrice = function(reportProducts) {
			var totalOrderPrice = 0;
			var i = 0;
			angular.forEach(reportProducts, function(reportProduct) {
				// if (reportProduct.order.id !== vm.object[i].id) {
				// 	if(!reportProduct.order.category && reportProduct.order.category == 4){
				// 		totalOrderPrice -= reportProduct.order.totalPrice;
				// 	} else {
				// 		totalOrderPrice += reportProduct.order.totalPrice;
				// 	}
				// 	vm.object.push({
				// 		id : reportProduct.order.id
				// 	});
				// 	i++;
				// }
				// Calculate total price of order if order is not refund.
				if(reportProduct.orderCategory && reportProduct.orderCategory != 4)
				{
					totalOrderPrice += reportProduct.orderTotalPrice;
				}
				else
				{
					totalOrderPrice -= reportProduct.orderTotalPrice;
				}
			})
			return totalOrderPrice;
		}

		// get name product
		vm.nameProduct = null;
		vm.changeProduct = function(value) {
			angular.forEach(vm.productCodes, function(reportProduct) {
				if (reportProduct.productCode === value) {
					vm.nameProduct = reportProduct.productName;
				}
			})
		}

		vm.disableButton = function(){
			if(vm.reportProducts === undefined || vm.reportProducts == null){
				return true;
			}else{
				return false;
			}
		}

	}
})();
