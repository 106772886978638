(function() {
    var notificationComponent = {
        controller: notificationComponentController
    };

    notificationComponentController.inject = ['$scope', '$rootScope', 'JhiTrackerService', 'Principal', 'webNotification', '$location', '$resource'];

    function notificationComponentController($scope, $rootScope, JhiTrackerService, Principal, webNotification, $location, $resource) {
        var $ctrl = this;
        function getMessage(content){
            webNotification.showNotification("CRM LOVESTONE", {
            body: content,
            // icon: avatar,
            onClick: function onNotificationClicked() {
            },
            autoClose: 1000000 //auto close the notification after 4 seconds (you can manually close it via hide function)
        }, function onShow(error, hide) {
            if (error) {
                window.alert('Unable to show notification: ' + error.message);
            } else {
                setTimeout(function hideNotification() {
                    hide(); //manually close the notification (you can skip this if you use the autoClose option)
                }, 5000000);
            }
        });
        }
        $ctrl.$onInit = function(){
        	var subscriber;
        	JhiTrackerService.getConnected().then(function(stompClient){
        		Principal.identity().then(function(account) {
        			subscriber = stompClient.subscribe('/activity/' + account.id + '/user', function(data) {
                        var orderStatusMessage = angular.fromJson(data.body);
                        if(orderStatusMessage.event === "tag"){
                            $rootScope.$broadcast("tag", orderStatusMessage);
                        }else{
                            $rootScope.$broadcast("send-message", orderStatusMessage);
                        }
        			});
        		});
        	});

        	$scope.$on('$destroy', function(){
        		 if(angular.isDefined(subscriber)){
        			 subscriber.unsubscribe();
        		 }
        	 });

            $ctrl.userAvatar = null;
            var config = {};
            $rootScope.$on('send-message', function (event, args) {
                getMessage(args.message);
                $rootScope.$broadcast("send-message-header", null);
            })

            $ctrl.userAvatar = null;
            var config = {};
            $rootScope.$on('tag', function (event, args) {
                getMessage(args.message);
                 // var image = args.baseUrl+"/public/image/user/"+args.userAvatar;
                $rootScope.$broadcast("send-message-header", null);
            });
        };
    }
    angular.module('crmApp').component('notification', notificationComponent);
})();
