(function() {
	'use strict';

	angular.module('crmApp').controller('ViettelPostCreateOrderController',
    ViettelPostCreateOrderController);

	ViettelPostCreateOrderController.$inject = ['$scope', 'entity', '$http', '$uibModalInstance', '$uibModal', '$localStorage'];

	function ViettelPostCreateOrderController($scope, entity, $http, $uibModalInstance, $uibModal, $localStorage) {
        var vm = this;
        vm.inventories = [];
        vm.services = [];
        vm.serviceExtra = [];
        vm.provinces = [];
        vm.districts = [];
        vm.wards = [];
		vm.getDistricts = getDistricts;
        vm.getWards = getWards;
        vm.order = entity;
        vm.status = {
        	firstOpen: true,
        	secondOpen: true,
        	threeOpen: true,
        	fourOpen: true,
        };

        vm.viettelPostAccount = $localStorage.viettelPostAccount;
        if(vm.viettelPostAccount != null || vm.viettelPostAccount != undefined){
        	var tokenKey = $localStorage.viettelPostAccount.tokenKey;
        	getListProvince();
        	getListInventory();
        	getInfoOrder();
        	getListServiceExtra();
        	if (vm.order.deliveryProvinceId){
                vm.getDistricts();
            }
            if (vm.order.deliveryDistrictId){
                vm.getWards();
            }
        }

        function getListInventory() {
			$http({
	        	url: "/api/get-list-inventory",
	        	method: "POST",
	        	params: {
	        		tokenKey: tokenKey
	        	}
	        }).success(function (response) {
	        	vm.inventories = response;
	        }).error(function (response) {
	        })
		}

		function getInfoOrder() {
			$http.post("/api/get-viettel-post-order", vm.order).then(onGetSuccess, onGetError);
		}

		function getListServiceExtra() {
			$http({
	        	url: "/api/get-list-service-extra",
				method: "GET",

	        }).then(function (response) {
	        	vm.serviceExtra = response.data;
	        })
		}

		vm.showService = function () {
			$http({
				url: "/api/get-list-service",
				method: "GET",
				params: {
					type: vm.orderShipment.SERVICE_TYPE
				}
			}).success(function (response) {
				vm.services = response;
			}).error(function (response) {
			})
		}

        function onGetSuccess(response) {
        	vm.orderShipment = response.data;
        }

        function onGetError(response) {
        }

        vm.showInfoSender = function () {
        	var inventory = vm.orderShipment.inventory;
        	vm.orderShipment.SENDER_FULLNAME = inventory.NAME;
        	vm.orderShipment.SENDER_PHONE = inventory.PHONE;
        	vm.orderShipment.SENDER_ADDRESS = inventory.ADDRESS;
        	vm.orderShipment.SENDER_WARD = inventory.WARDS_ID;
        	vm.orderShipment.SENDER_DISTRICT = inventory.DISTRICT_ID;
        	vm.orderShipment.SENDER_PROVINCE = inventory.PROVINCE_ID;
        	vm.orderShipment.GROUPADDRESS_ID = inventory.GROUPADDRESS_ID;
        }

        vm.close = function() {
			$uibModalInstance.close();
		}

		vm.confirm = function () {
			$scope.$broadcast('show-errors-check-validity');
            if ($scope.comfirmOrderShipmentForm.$invalid) {
	            var invalidControl = angular.element('input.ng-invalid')
	                .first() ||
	                angular.element('select.ng-invalid').first() ||
	                angular.element('option.ng-invalid').first();
	            if (invalidControl != null) {
	                invalidControl.focus();
	            }
			} else {
				if (vm.orderShipment.SENDER_EMAIL === null) {
					vm.orderShipment.SENDER_EMAIL = "";
				}
				if (vm.orderShipment.RECEIVER_EMAIL === null) {
					vm.orderShipment.RECEIVER_EMAIL = "";
				}
				if (vm.orderShipment.ORDER_SERVICE_ADD === null) {
					vm.orderShipment.ORDER_SERVICE_ADD = "";
				}
				if (vm.orderShipment.ORDER_VOUCHER === null) {
					vm.orderShipment.ORDER_VOUCHER = "";
				}
				if (vm.orderShipment.ORDER_NOTE === null) {
					vm.orderShipment.ORDER_NOTE = "";
				}
				if (vm.orderShipment.PRODUCT_DESCRIPTION === null) {
					vm.orderShipment.PRODUCT_DESCRIPTION = "";
				}
				var token = $localStorage.viettelPostAccount.tokenKey;
				$http({
		        	url: "/api/viettel-post-shipment-order",
		        	method: "POST",
		        	params: {
		        		token: token
		        	},
		        	data: vm.orderShipment
		        }).success(function (response) {
		        	$uibModalInstance.close();
		        }).error(function (response) {
		        	$uibModalInstance.close();
		        })
			}
		}
		function getListProvince(){
            $http.get('/api/list-provinces', {}).then(function (response) {
                vm.provinces = response.data;
            })
        }
        function getDistricts() {
            if(vm.order.deliveryProvinceId){
                $http.get('/api/list-districts', {params: { provinceId : vm.order.deliveryProvinceId}}).then(function (response) {
                    vm.districts = response.data;
                })
            }
        }

        function getWards() {
            if(vm.order.deliveryDistrictId) {
                $http.get('/api/list-wards', {params: { districtId : vm.order.deliveryDistrictId}}).then(function (response) {
                    vm.wards = response.data;
                })
            }
        }
	}
})();
