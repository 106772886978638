(function() {
    'use strict';
    angular.module('crmApp').factory('InformationConfiguration', InformationConfiguration);

    InformationConfiguration.$inject = [ '$resource' ];

    function InformationConfiguration($resource) {
        var resourceUrl = '/api/general-setting';

        return $resource(resourceUrl, {}, {
            'get' : {
                method : 'GET',
                isArray: true
            },
            'update' : {
                method : 'PUT',
                isArray: true
            }
        });
    }
})();
