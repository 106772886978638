(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider', 'CommonConstants' ];

	function stateConfig($stateProvider, CommonConstants) {
		$stateProvider
				.state(
						'order',
						{
							parent : 'app',
							url : '/order?page&sort&search&status',
							data : {
								authorities : [ CommonConstants.ORDER_VIEW ],
								pageTitle : 'Đơn hàng'
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/orders.html',
									controller : 'OrderController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'lastModifiedDate,desc',
									squash : true
								},
								search : null,
								status: null
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ]
							}
						})
				.state(
						'order-detail',
						{
							parent : 'app',
							url : '/order/{id}',
							data : {
								authorities : [ CommonConstants.ORDER_VIEW ],
								pageTitle : 'Chi tiết đơn hàng'
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-detail.html',
									controller : 'OrderDetailController',
									controllerAs : 'vm'
								}
							},
							params : {
								action: null
							},
							resolve : {
								entity : [ '$stateParams', 'Order',
										function($stateParams, Order) {
											return Order.get({
												id : $stateParams.id
											}).$promise;
										} ]
							},
							onEnter : function(CommentTrackerService) {

							},
							onExit : function(CommentTrackerService) {
								CommentTrackerService.unsubscribeCommentSocket();
								CommentTrackerService.unsubscribeOrderChangeSocket();
							}
						})
				.state(
						'warehousing',
						{
							parent : 'app',
							url : '/warehousing/{id}',
							data : {
								authorities : [ CommonConstants.WAREHOUSE_VIEW_GOODS_ISSUE ],
								pageTitle : 'Chi tiết xuất kho'
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-warehousing.html',
									controller : 'OrderWareHousingController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'Order',
										function($stateParams, Order) {
											return Order.get({
												id : $stateParams.id
											}).$promise;
										} ]
							}
						})
				.state('order.new',
						{
							parent : 'order',
							url : '/new/{id}',
							data : {
								authorities : [ CommonConstants.ORDER_ADD ],
								pageTitle : 'Tạo đơn hàng'
							},
							params : {
								toQuote : null
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-create-update.html',
									controller : 'OrderCreateUpdateController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										orderCode : null,
										staffId : null,
										customerId : null,
										contactPhone : null,
										contactName : null,
										careId : null,
										category : null,
										phase : null,
										commitStatus : null,
										cancelledKbn : null,
										cancelledComment : null,
										processState : null,
										paymentState : null,
										orderDate : null,
										orderComplete : null,
										totalPrice : null,
										totalDiscount : null,
										vatIncluded : null,
										vatRate : null,
										totalPayAmount : null,
										payType : null,
										payMethod : null,
										deleted : false,
										deliverAdress : null,
										deliverType : null,
										payTimes : null,
										stockProcessStatus: 1
									};
								},
								customer : [ 'Customer', '$stateParams',
										function(Customer, $stateParams) {
											if ($stateParams.id) {
												return Customer.get({
													id : $stateParams.id
												}).$promise;
											} else {
												return null;
											}
										}]
							}
						})
				.state('order.new-with-phone-number',
						{
							parent : 'order',
							url : '/phone/{phoneNum}/new',
							data : {
								authorities : [ CommonConstants.ORDER_ADD ],
								pageTitle : 'Tạo đơn hàng'
							},
							params : {
								toQuote : null
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-create-update.html',
									controller : 'OrderCreateUpdateController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : function() {
									return {
										orderCode : null,
										staffId : null,
										customerId : null,
										contactPhone : null,
										contactName : null,
										careId : null,
										category : null,
										phase : null,
										commitStatus : null,
										cancelledKbn : null,
										cancelledComment : null,
										processState : null,
										paymentState : null,
										orderDate : null,
										orderComplete : null,
										totalPrice : null,
										totalDiscount : null,
										vatIncluded : null,
										vatRate : null,
										totalPayAmount : null,
										payType : null,
										payMethod : null,
										deleted : false,
										deliverAdress : null,
										deliverType : null,
										payTimes : null,
										stockProcessStatus: 1
									};
								},
								customer : [ 'CustomerPhone', '$stateParams',
										function(CustomerPhone, $stateParams) {
											if ($stateParams.phoneNum) {
												return CustomerPhone.get({
													phoneNum : $stateParams.phoneNum
												}).$promise;
											} else {
												return null;
											}
										}]
							}
						})
				.state(
						'order.edit',
						{
							parent : 'order',
							url : '/{id}/edit',
							data : {
								authorities : [ CommonConstants.ORDER_EDIT ],
								pageTitle : 'Cập nhật đơn hàng'
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-create-update.html',
									controller : 'OrderCreateUpdateController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								entity : [ '$stateParams', 'Order',
										function($stateParams, Order) {
											return Order.get({
												id : $stateParams.id
											}).$promise;
										} ],
								customer : function() {
									return null
								},
								index : function() {
								}

							}
						})
						.state(
								'order.refund',
								{
									parent : 'order',
									url : '/{id}/refund',
									data : {
										authorities : [ CommonConstants.ORDER_ADD],
										pageTitle : 'Tạo đơn hàng trả'
									},
									views : {
										'content@' : {
											templateUrl : 'app/order/order-create-update.html',
											controller : 'OrderCreateUpdateController',
											controllerAs : 'vm'
										}
									},
									resolve : {
										entity : [ '$stateParams', 'Order',
												function($stateParams, Order) {
													return Order.get({
														id : $stateParams.id
													}).$promise;
												} ],
										customer : function() {
											return null
										},
										index : function() {
										}

									}
								})
				.state(
						'order.email',
						{
							parent : 'order',
							url : '/email/{id}',
							data : {
								authorities : [ CommonConstants.ORDER_VIEW ],
								pageTitle : 'Gửi thông tin đơn hàng'
							},
							views : {
								'content@' : {
									templateUrl : 'app/order/order-email.html',
									controller : 'OrderEmailController',
									controllerAs : 'vm'
								}
							},
							params : {
								'filePath' : null
							},
							resolve : {
								entity : [ '$stateParams', 'Order',
										function($stateParams, Order) {
											if ($stateParams.id) {
												return Order.get({
													id : $stateParams.id
												}).$promise;

											} else {
												return null;
											}
										} ]
							}
						});
	}

})();
