(function() {
	'use strict';

	angular.module('crmApp').controller('ReportStockController',
			ReportStockController);

	ReportStockController.$inject = [ '$scope', '$resource',
			'DateFormatService', '$window', '$http', '$state', '$uibModal'];

	function ReportStockController($scope, $resource, DateFormatService,
			$window, $http, $state, $uibModal) {
		var vm = this;
		vm.reportInventoryClone = {};
		vm.reportInventoryCO = {
				dateTo: null,
				dateFrom :null
			};
		vm.productCodes = [];
		vm.stocksSelect = [];
		vm.typeReports = [
			{value:1, name: "Tồn Kho Theo Ngày"},
			{value:2, name: "Tồn Kho Theo Kho"},
			{value:3, name: "Tổng Hợp Nhập Xuất Tồn"},
			{value:4, name: "Danh Sách Hàng Trả Về"},
			{value:5, name: "Danh Sách Điều Chuyển"}
			// ,
			// {value:6, name: "Danh Sách Phiếu Xuất Khác"}
		];
		vm.quarters = [
			{value:1, name: "Quý 1"},
			{value:2, name: "Quý 2"},
			{value:3, name: "Quý 3"},
			{value:4, name: "Quý 4"}
		];

		vm.filters = [
			{value:1, name: "Theo Ngày"},
			{value:2, name: "Theo Tháng"},
			{value:3, name: "Theo Quý"}
		];
		vm.months = [
			{value:1, name: "Tháng 1"},
			{value:2, name: "Tháng 2"},
			{value:3, name: "Tháng 3"},
			{value:4, name: "Tháng 4"},
			{value:5, name: "Tháng 5"},
			{value:6, name: "Tháng 6"},
			{value:7, name: "Tháng 7"},
			{value:8, name: "Tháng 8"},
			{value:9, name: "Tháng 9"},
			{value:10, name: "Tháng 10"},
			{value:11, name: "Tháng 11"},
			{value:12, name: "Tháng 12"}
		]
		// 
		vm.loadAll = loadAll;
		vm.reportStockStock = reportStockStock;
		vm.reportTranferStock = reportTranferStock;
		vm.reportStockProductRefund = reportStockProductRefund;
		vm.reportStockDate = reportStockDate;
		vm.reportStockListDelivery = reportStockListDelivery;
		vm.reportStockGoodReceiptIssue = reportStockGoodReceiptIssue;

		vm.showHide = function(filter){
			if(filter == 2 ){
				vm.hideShowDate = false;
				vm.hideShowMonth = true;
				vm.hideShowQuarter = false;
				vm.hideDate = true;
			}else if(filter == 3){
				vm.hideShowDate = false;
				vm.hideShowMonth = false;
				vm.hideShowQuarter = true;
				vm.hideDate = true;
			}else if(filter == 1){
				vm.hideShowDate = true;
				vm.hideShowMonth = false;
				vm.hideShowQuarter = false;
				vm.hideDate = false;
			}else if(filter == null){
				vm.hideShowDate = false;
				vm.hideShowMonth = false;
				vm.hideShowQuarter = false;
				vm.hideDate = false;
			}
			$scope.$broadcast('show-errors-reset');
		}
		// vm.updateAllWarehouseProductAud = updateAllWarehouseProductAud;
		vm.loadAll();

		function loadAll() {
			vm.value = 1;
			vm.filter = 1;
			vm.showHide(vm.filter);
			configDateTime();
			getProductCode();
		}

		
		//Danh Sach Phieu xuat khac
		function reportStockListDelivery(){
			var config = {
				params: vm.reportInventoryCO,
				headers : {'Accept' : 'application/json'}
			};
			$http.get('/api/report-stock-list-delivery',config).success(function(success) {
				vm.reportStockListDeliverys = success;
				vm.data = vm.reportStockListDeliverys;
			});
		}

		function reportStockDate(){
			vm.reportInventoryClone = angular.copy(vm.reportInventoryCO);
			var config = {
				params: vm.reportInventoryCO,
				headers : {'Accept' : 'application/json'}
			};
			$http.get('/api/report-stock-date',config).success(function(success) {
				vm.reportStockDates = success;
				vm.data = vm.reportStockDates;
				angular.forEach(vm.reportStockDates, function(reportStockDate, key) {
					var totalQuantity = 0;
					angular.forEach(reportStockDate.warehouseInProduct, function(warehouseInProduct, key) {
						totalQuantity += warehouseInProduct;
					});
					vm.reportStockDates[key].total = totalQuantity;
				});
			});
		}
		function reportStockProductRefund() {
			vm.reportInventoryClone = angular.copy(vm.reportInventoryCO);
			var config = {
				params: vm.reportInventoryCO,
				headers : {'Accept' : 'application/json'}
			};
			$http.get('/api/report-stock-product-refund',config).success(function(success) {
				vm.reportStockProductRefunds = success;
				vm.data = vm.reportStockProductRefunds;
			});
		}

		function reportTranferStock() {
			vm.reportInventoryClone = angular.copy(vm.reportInventoryCO);
			var config = {
			 params: vm.reportInventoryCO,
			 headers : {'Accept' : 'application/json'}
			};
			$http.get('/api/report-tranfer-stock',config).success(function(success) {
				vm.reportTranferStocks = success;
				vm.data = vm.reportTranferStocks;
			});
		}

		function reportStockStock() {
			vm.reportInventoryClone = angular.copy(vm.reportInventoryCO);
			var config = {
			 params: vm.reportInventoryCO,
			 headers : {'Accept' : 'application/json'}
			};
			$http.get('/api/report-stock-stock',config).success(function(success) {
				vm.reportStockStocks = success;
				vm.data = vm.reportStockStocks;
			});
		}

		function reportStockGoodReceiptIssue(){
			$scope.$broadcast('show-errors-check-validity');
			if (vm.reportForm.$invalid) {
				var invalidControl = angular.element('.ng-invalid').first();
				if (invalidControl != null) {
					invalidControl.focus();
				}
			} else{
				if(vm.filter == 1){
					vm.year = null; vm.reportInventoryCO.month = null; vm.reportInventoryCO.quarter = null;
				}else if (vm.filter == 2){
					vm.reportInventoryCO.year = vm.year.getFullYear();
					vm.reportInventoryCO.quarter = null; vm.reportInventoryCO.dateFrom = null; vm.reportInventoryCO.dateTo = null;
				}else if (vm.filter == 3){
					vm.reportInventoryCO.year = vm.year.getFullYear();
					vm.reportInventoryCO.dateFrom = null; vm.reportInventoryCO.dateTo = null; vm.reportInventoryCO.month = null;
				}
				vm.reportInventoryClone = angular.copy(vm.reportInventoryCO);
				var config = {
				params: vm.reportInventoryCO,
				headers : {'Accept' : 'application/json'}
				};
				$http.get('/api/report-stock-good-receipt-issue',config).success(function(success) {
					vm.reportStockGoodReceiptIssues = success;
					vm.data = vm.reportStockGoodReceiptIssues;
				});
			}
		}
		//Export excel 
		vm.excel = function(){
			if(vm.value == 1){
				vm.exportExcelStockDate();
			} else if(vm.value == 2){
				vm.exportExcelStockStock();
			} else if(vm.value == 3){
				vm.exportExcelStockGoodReceiptIssue();
			} else if(vm.value == 4){
				vm.exportExcelStockProductRefund();
			} else if(vm.value == 5){
				vm.exportExcelStockListTransfer();
			}
		};
		function addParameterToUrl(parameter, paramName, paramValue){
			if(paramValue){
				if(parameter.length == 0){
					parameter += '?';
				} else {
					parameter += '&';
				}
				parameter += (paramName + '='+ paramValue);
			}
			return parameter;
		}

		function isNull(val){
			if(val === null || val === undefined){
				return true;
			} else {
				return false;
			}
		}
		vm.exportExcelStockStock = function(){
			var dateTo = isNull(vm.reportInventoryClone.dateTo) ? vm.reportInventoryClone.dateTo : vm.reportInventoryClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "warehouseId", vm.reportInventoryClone.warehouseId);
			$window.open('public/export-excel-stock-stock' + parameter);
		};
		vm.exportExcelStockGoodReceiptIssue = function(){
			var parameter = "";
			parameter = addParameterToUrl(parameter, "warehouseId",vm.reportInventoryClone.warehouseId);
			if(vm.filter == 1){
				var dateFrom = isNull(vm.reportInventoryClone.dateFrom) ? vm.reportInventoryClone.dateFrom : vm.reportInventoryClone.dateFrom.toISOString();
				var dateTo = isNull(vm.reportInventoryClone.dateTo) ? vm.reportInventoryClone.dateTo : vm.reportInventoryClone.dateTo.toISOString();
				parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
				parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			}else if (vm.filter == 2){
				parameter = addParameterToUrl(parameter, "month", vm.reportInventoryClone.month);
				parameter = addParameterToUrl(parameter, "year", vm.reportInventoryClone.year);
			}else if (vm.filter == 3){
				parameter = addParameterToUrl(parameter, "quarter", vm.reportInventoryClone.quarter);
				parameter = addParameterToUrl(parameter, "year", vm.reportInventoryClone.year);
			}

			$window.open('public/export-excel-stock-good-receipt-issue' + parameter);
		};
		vm.exportExcelStockDate = function(){
			var dateTo = isNull(vm.reportInventoryClone.dateTo) ? vm.reportInventoryClone.dateTo : vm.reportInventoryClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			parameter = addParameterToUrl(parameter, "productCode", vm.reportInventoryClone.productCode);
			
			$window.open('public/export-excel-stock-date' + parameter);
		};
		vm.exportExcelStockListTransfer = function(){
			var dateFrom = isNull(vm.reportInventoryClone.dateFrom) ? vm.reportInventoryClone.dateFrom : vm.reportInventoryClone.dateFrom.toISOString();
			var dateTo = isNull(vm.reportInventoryClone.dateTo) ? vm.reportInventoryClone.dateTo : vm.reportInventoryClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			$window.open('public/export-excel-stock-list-transfer' + parameter);
		};
		vm.exportExcelStockProductRefund = function(){
			var dateFrom = isNull(vm.reportInventoryClone.dateFrom) ? vm.reportInventoryClone.dateFrom : vm.reportInventoryClone.dateFrom.toISOString();
			var dateTo = isNull(vm.reportInventoryClone.dateTo) ? vm.reportInventoryClone.dateTo : vm.reportInventoryClone.dateTo.toISOString();

			var parameter = "";
			parameter = addParameterToUrl(parameter, "dateFrom", dateFrom);
			parameter = addParameterToUrl(parameter, "dateTo", dateTo);
			
			$window.open('public/export-excel-stock-product-refund' + parameter);
		};
		// display stock
		vm.displayStockName = function(Stocks, StockReports) {
			var result = '';
			if (Stocks !== null && Stocks !== undefined
					&& Stocks.length > 0) {
				angular.forEach(Stocks, function(stock, key1) {
					angular.forEach(StockReports, function(stockReport, key2) {
						if (stock.id == stockReport) {
							result += stock.name;
							if (key2 != (Stocks.length - 1)) {
								result += ', ';
							}
						}
					})
				});
			}
			return result;
		}
		vm.displayProductName = function(products, productCode) {
			var result = '';
			if (products !== null && products !== undefined
					&& products.length > 0) {
				angular.forEach(products, function(product, key) {
					angular.forEach(productCode, function(code, key) {
						if (product.productCode == code) {
							result += product.productName;
							if (key != (products.length - 1)) {
								result += ', ';
							}
						}
					})
				});
			}
			return result;

		}
		// print
		vm.print = function() {
			$window.print();
		}
		// get product
		function getProductCode() {
			$http.get('/api/get-all-product').success(function(success) {
				if (success && success.length > 0) {
					vm.productCodes = success;
				}
			});

			$http.get('/api/get-all-stocks').then(function(success) {
				vm.stocksSelect = success.data;
			}).$promise;
		}
		// config date
		function configDateTime() {
			vm.altInputFormats = DateFormatService.getAltInputFormats();
			vm.dateFormat = DateFormatService.getDateFormat();
			// vm.dateOptions = DateFormatService.getDateOptions();
			 vm.dateOptions = {
	            startingDay: 1,
	            maxDate: new Date()
	        };

			vm.altInputFormatsDate = 'dd';
			vm.dateFormatDate = 'dd';

			vm.altInputFormatsMonth = 'MM';
			vm.dateFormatMonth = 'MM';

			vm.altInputFormatsYear = 'yyyy';
			vm.dateFormatYear = 'yyyy';

			vm.dateOptionsDate = {
				formatYear : 'dd',
				startingDay : 1,
				minMode : 'day',

			};
			vm.dateOptionsMonth = {
				formatYear : 'MM',
				startingDay : 1,
				minMode : 'month',

			};
			vm.dateOptionsYear = {
				formatYear : 'yyyy',
				startingDay : 1,
				minMode : 'year',

			};

			vm.openDateFrom1 = function() {
				vm.isDateFromOpened1 = true;
			}
			vm.openDateFrom2 = function() {
				vm.isDateFromOpened2 = true;
			}
			vm.openDateFrom3 = function() {
				vm.isDateFromOpened3 = true;
			}
			vm.openDateFrom4 = function() {
				vm.isDateFromOpened4 = true;
			}
		}
		//
		vm.displayUI = function(value) {
			vm.disableButton();
			vm.reportStockProductRefunds = [];
			vm.reportStockListDeliverys = [];
			vm.reportStockDates = [];
			vm.reportTranferStocks = [];
			vm.reportStockStocks = [];
			vm.reportInventoryCO = {
				dateTo: null,
				dateFrom :null
			};
			vm.reportRevenueInventoryClone = {};

			vm.data = [];
			$scope.$broadcast('show-errors-reset');
		}

		
		vm.chooseMonth = function(date){
			var month = date.getMonth()+1;
			if(month == 1 || month == 2 || month == 3){
				vm.year = 1;
			}else if(month == 4 || month == 5 || month == 6){
				vm.year = 2;
			}else if(month == 7 || month == 8 || month == 9){
				vm.year = 3;
			}else{
				vm.year = 4;
			}
		}

		vm.disableButton = function(){
			if(vm.data === undefined || vm.data.length == 0){
				return true;
			}else{
				return false;
			}
			
		}
	}
})();
