(function() {
    'use strict';
    var historyPaymentTemplate = {
        template: '<div class="box-payment operator" ng-click="$ctrl.showBoxHistory();" title="Lịch sử thanh toán">' +
    				'<div class="icon-payment">' +
    				'<i class="fa fa-credit-card-alt" aria-hidden="true"></i>' +
    				'</div></div>',
        controller: historyPaymentTemplateController,
        bindings: {
            id: '<'
        }
    };

    historyPaymentTemplateController.inject = ['$scope', '$element', '$uibModal','$http'];

    function historyPaymentTemplateController( $scope, $element, $uibModal, $http) {
        var $ctrl = this;
        $ctrl.showBoxHistory = function(){
            $ctrl.modalInstance = $uibModal.open({
                templateUrl: 'app/template/history-payment.html',
                size: 'lg',
                scope: $scope,
                controller: function() {
                $http.get('/api/get-payment-history-of-an-order/'+$ctrl.id).success(function(success) {
                    $ctrl.paymentHistorys = success;
                })
                $ctrl.closeHistory = function() {
                    closePopup();
                };
            }
        });
            function closePopup(){
                $ctrl.modalInstance.dismiss('cancel');
            }

        }
    }
    angular.module('crmApp').component('historyPaymentTemplate', historyPaymentTemplate);
})();
