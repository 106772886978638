(function() {
    'use strict';

    angular
        .module('crmApp')
        .controller('RoleController', RoleController);

    RoleController.$inject = ['$scope', '$state', 'Role', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$http'];

    function RoleController ($scope, $state, Role, ParseLinks, AlertService, pagingParams, paginationConstants, $http) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.userRoles = [];
        vm.loadAll();

        function loadAll () {
            Role.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.roles = data;
                var i = 0;
                $http.get('/api/users').success(function(success) {
                    angular.forEach(vm.roles, function(value, key){
                        vm.userRoles.push({id: value.id,roleName: value.name,permission: '',users: ''});
                        angular.forEach(value.authorities, function(valuePermission, key){
                            vm.userRoles[i].permission += valuePermission.description + ', ';
                        });
                        angular.forEach(success, function(valueUser, key){
                            if(valueUser.roleIds.indexOf(value.id) >= 0){
                                vm.userRoles[i].users += valueUser.name + ',';
                            }
                        });
                        i++;
                    });
                });
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
