(function() {
    'use strict';

    angular.module('crmApp').controller('CustomerController',
        CustomerController);

    CustomerController.$inject = ['$rootScope', '$localStorage', '$scope', '$state', '$timeout',
        'SettingUtils', 'Customer', 'ParseLinks', 'AlertService',
        'pagingParams', 'paginationConstants', '$http', '$uibModal', 'UserUtils', 'CheckPhone', 'CommonConstants', '$window'
    ];

    function CustomerController($rootScope, $localStorage, $scope, $state, $timeout, SettingUtils,
        Customer, ParseLinks, AlertService, pagingParams,
        paginationConstants, $http, $uibModal, UserUtils, CheckPhone, CommonConstants, $window) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = false;
        vm.transition = transition;
        vm.searchCustomerHandler = searchCustomerHandler;
        vm.clear = clear;
        vm.currentCheck;
        vm.page = 1;
        vm.idCheckeds = null;
        vm.entries = '10';
        vm.isNotSelected = true;
        vm.checkAll = false;
        vm.setActive = setActive;
        vm.totalItems = null;
        vm.showName = "khách hàng";
        vm.searchCustomerUseCriteria = searchCustomerUseCriteria;
        vm.currentSearch = null;
        var CUSTOMER_SERVICE_TYPE = 'CUSTOMER_SERVICE_TYPE';
        var CUSTOMER_SOURCE = 'CUSTOMER_SOURCE';
        var CUSTOMER_GENDER = 'CUSTOMER_GENDER';
        var CUSTOMER_TYPE = 'CUSTOMER_TYPE';
        vm.loadAll();

        vm.checkAll = function() {
            var i = 0;
            if (vm.selectedAll) {
                vm.selectedAll = true;
                vm.isNotSelected = false;
            } else {
                vm.selectedAll = false;
                vm.isNotSelected = true;
            }
            angular.forEach(vm.customers, function(customer) {
                i++;
                if (i <= vm.entries)
                    customer.selected = vm.selectedAll;
            });
            vm.checkCustomer();
        }
        vm.refresh = function() {
            // body...
            $state.go('customer.list', null, {
                reload: true
            });
        }
        vm.toQuote = function() {
            // body...
            $state.go('order.new', {
                toQuote: "toQuote"
            }, {
                reload: true
            });
        }
        vm.checkCustomer = function() {
            vm.idCheckeds = [];

            angular.forEach(vm.customers, function(customer) {
                if (!!customer.selected) {
                    vm.idCheckeds.push(customer.id);
                }
            })
            if (vm.idCheckeds.length === 0) {
                vm.isNotSelected = true;
            } else {
                vm.isNotSelected = false;
            }
        }

        vm.showEntries = function() {
            vm.loadAll();
        }

        function getCurrentAccount() {
            vm.account = UserUtils.getAccount();
            vm.loginName = vm.account.login;
            vm.isCustomerAll = UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_ALL);
        }

        function loadAll() {
            vm.loadPage();
            SettingUtils.get({
                'keys': [CUSTOMER_SERVICE_TYPE, CUSTOMER_SOURCE, CUSTOMER_TYPE]
            }, function(success) {
                vm.servicies = [];
                vm.sourcies = [];
                vm.genders = [];
                vm.customerTypes = [];
                angular.forEach(success, function(value, key) {
                    if (value.key === CUSTOMER_SERVICE_TYPE) {
                        vm.servicies.push(value);
                    } else if (value.key === CUSTOMER_SOURCE) {
                        vm.sourcies.push(value);
                    } else if (value.key == CUSTOMER_TYPE) {
                        vm.customerTypes.push(value);
                    }
                });
                // angular.element('#serviceTypeId').selectpicker('refresh');
                // angular.element('#sourceId').selectpicker('refresh');
            }, function(error) {

            });

            if ($localStorage && $localStorage.currentRoleId) {
                getCurrentAccount();
            } else {
                var unsubscribe = $rootScope.$on('crmApp:has.account.info', function(event, result) {
                    getCurrentAccount();
                });

                $scope.$on('$destroy', unsubscribe);
            }
        }

        function loadPage(isSearchWithConditionBefore) {
            if (vm.currentSearch && isSearchWithConditionBefore) {
                //search with condition before
                vm.currentSearch.page = vm.page - 1;
                vm.currentSearch.sort = sort();
                Customer.query(vm.currentSearch, onSuccess, onError);
            } else {
                if (!vm.customerCO) {
                    vm.customerCO = {};
                }
                vm.customerCO.isPhone = CheckPhone.isPhoneNumber(vm.customerCO.phone);
                vm.customerCO.page = vm.page - 1;
                vm.customerCO.size = vm.entries;
                vm.customerCO.sort = sort();
                vm.currentSearch = angular.copy(vm.customerCO);
                vm.showItem = vm.entries;
                Customer.query(vm.currentSearch, onSuccess, onError);
            }


            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customers = data;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

				vm.disableButton = function(){
					if(vm.customers === undefined || vm.customers == null){
						return true;
					}else{
						return false;
					}
				}

				function addParameterToUrl(parameter, paramName, paramValue){
					if(paramValue){
						if(parameter.length == 0){
							parameter += '?';
						} else {
							parameter += '&';
						}
						parameter += (paramName + '='+ paramValue);
					}
					return parameter;
				}

				vm.excel = function(){
					var parameter = "";
					var isPhoneParameter = vm.currentSearch.isPhone ? vm.currentSearch.isPhone : false;
					parameter = addParameterToUrl(parameter, "phone", vm.currentSearch.phone);
					parameter = addParameterToUrl(parameter, "serviceTypeId", vm.currentSearch.serviceTypeId);
					parameter = addParameterToUrl(parameter, "custTypeId", vm.currentSearch.custTypeId);
					parameter = addParameterToUrl(parameter, "sourceId", vm.currentSearch.sourceId);
					parameter = addParameterToUrl(parameter, "isPhone", isPhoneParameter);
					parameter = addParameterToUrl(parameter, "currentLogin", $localStorage.account.login);
					$window.open("public/export-excel-customer" + parameter);
				};

        function setActive(Customer, isActivated) {
            Customer.activated = isActivated;
            Customer.update(Customer, function() {
                vm.loadAll();
                vm.clear();
            });
        }
        vm.showItem = 10;

        function transition() {
            if (vm.currentSearch) {
                vm.loadPage(true);
            }
        }

        function searchCustomerHandler(keyEvent) {
            if (keyEvent.which === 13) {
                vm.page = 1;
                vm.predicate = 'id';
                vm.reverse = false;
                vm.loadAll();
            }
        }

        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchCustomer = null;
            vm.loadAll();
        }

        function searchCustomerUseCriteria() {
            $scope.$broadcast('show-errors-check-validity');
            if ($scope.searchForm.$invalid) {
                var invalidControl = angular.element('input.ng-invalid')
                    .first() ||
                    angular.element('select.ng-invalid').first() ||
                    angular.element('option.ng-invalid').first();
                if (invalidControl != null) {
                    invalidControl.focus();
                }
            } else {
                vm.loadAll();
            }
        }

        vm.displaySettingListName = function(settings) {
            var result = '';
            if (settings !== null && settings !== undefined &&
                settings.length > 0) {
                angular.forEach(settings, function(setting, key) {
                    result += setting.name;
                    if (key != (settings.length - 1)) {
                        result += ', ';
                    }
                });
            }
            return result;
        };

        vm.deleteCustomer = function(customerId) {
            vm.modalInstanceDelete = $uibModal.open({
                templateUrl: 'app/customer/customer-delete-dialog.html',
                controller: 'CustomerDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md'
            });
            vm.modalInstanceDelete.result.then(function(response) {
                if (response === true) {
                    deleteCustomer(customerId);
                    $state.reload();
                }
            });
            $rootScope.$on('close-modal', function() {
                vm.modalInstanceDelete.close();
            });
        }

        function deleteCustomer(id) {
            if (UserUtils.hasAuthorityName(CommonConstants.CUSTOMER_DELETE)) {
                $http.get('/api/customers-delete?id=' + id)
                    .success(function(success) {});
            } else {
                AlertService.error(CommonConstants.HAS_NOT_ROLE_MESSAGE);
            }
        }
    }
})();
