(function() {
	'use strict';

	angular.module('crmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state(
			'configuration-setting',
			{
				parent : 'admin',
				url : '/configuration-setting',
				data : {
					authorities : [ 'ADMIN' ],
					pageTitle : 'Cấu hình chung'
				},
				views : {
					'content@' : {
						templateUrl : 'app/admin/configuration-setting/configuration-setting.html',
						controller : 'ConfigurationSettingController',
						controllerAs : 'vm'
					}
				},
				params : {
					page : {
						value : '1',
						squash : true
					},
					sort : {
						value : 'key,asc',
						squash : true
					},
					search : null,
					status: null
				},
				resolve : {
					pagingParams : [
							'$stateParams',
							'PaginationUtil',
							function($stateParams, PaginationUtil) {
								return {
									page : PaginationUtil
											.parsePage($stateParams.page),
									sort : $stateParams.sort,
									predicate : PaginationUtil
											.parsePredicate($stateParams.sort),
									ascending : PaginationUtil
											.parseAscending($stateParams.sort),
									search : $stateParams.search
								};
							} ]
				}
			})
	}
})();
