(function() {
    'use strict';
    var confirmComponent = {
        templateUrl: 'app/template/popup-confirm.html',
        controller: confirmComponentController,
        bindings: {
            message:"<",
            save:"&",
            close: "&",
            content:"<"
        }
    };

    confirmComponentController.inject = ['$uibModal', '$scope'];

    function confirmComponentController($uibModal, $scope) { 
        var $ctrl = this;
        $ctrl.confirm = function () {
            $ctrl.save();
        }

        $ctrl.closePopup = function () {
            $ctrl.close();
        }
    }

    angular.module('crmApp').component('confirmComponent', confirmComponent);
})();
