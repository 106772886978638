(function() {
	'use strict';

	angular.module('crmApp').controller('ComposeEmailController',
			ComposeEmailController);

	ComposeEmailController.$inject = [ '$scope', 'EmailSendWithAttachment',
			'entity', 'Principal', '$http', '$state', 'orderInfo' ];

	function ComposeEmailController($scope, EmailSendWithAttachment, entity,
			Principal, $http, $state, orderInfo) {
		var vm = this;
		vm.attachmentFile = null;
		vm.composerEmail = entity;

		vm.getAccount = getAccount;

		getAccount();

		function getAccount() {
			Principal.identity().then(function(account) {
				vm.composerEmail.from = account.email;
			})
		}
		// get info order to send email
		if (orderInfo != null) {
			vm.composerEmail.to = orderInfo.customer.email;
		}

		vm.save = function() {
			$http({
				url : '/api/send-email',
				method : 'POST',
				params : {
					from : vm.composerEmail.from,
					to : vm.composerEmail.to,
					subject : vm.composerEmail.subject,
					content : vm.composerEmail.content,
					isMultipart : vm.composerEmail.isMultipart,
					isHtml : vm.composerEmail.isHtml
				}
			}).success(function(response) {
				$state.go('email-box');
			}).error(function(response) {
				$state.go('compose-email');
			})
		}

		$scope.uploadFile = function(files) {
			vm.attachmentFile = files;
		};

		vm.sendEmail = function() {
			EmailSendWithAttachment.sendWithAttachment(
					'/api/send-email-with-attachment', vm.composerEmail.to,
					vm.composerEmail.subject, vm.composerEmail.content,
					vm.attachmentFile, function onSendSuccess() {
					});
		};
	}
})();
